import {
  IGaugeDefination,
  ILineDefination,
  IPieDefinations
} from "../../interfaces";
import Carousel from "../shared/components/carousel.component";
import PanelWrapper from "./dash-wrapper-panel.component";

interface ICarouselProps {
  title: string;
  panels: (ILineDefination | IPieDefinations | IGaugeDefination)[];
}

const CarouselWrapper: React.FC<ICarouselProps> = ({ title, panels }) => {
  const childCnt = panels.length > 3 ? 3 : panels.length;
  const scaleDown = panels.length > 2 ? true : false;
  return (
    <div>
      <Carousel
        childPerPage={childCnt}
        childCount={panels.length}
        header={title}
      >
        {panels.map(
          (
            panel: ILineDefination | IPieDefinations | IGaugeDefination,
            index
          ) => (
            <div className="w-max float-left ml-4" key={panel.id}>
              <PanelWrapper
                panel={panel}
                key={panel.id}
                inCarousel={scaleDown}
              />
            </div>
          )
        )}
      </Carousel>
    </div>
  );
};

export default CarouselWrapper;
