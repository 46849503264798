import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import useFleetAndDevicesStore from "@store/fleet-and-devices/fleet-and-devices.store";
import GenericTable from "@components/generic-table/table.component";
import dateService from "@services/date.service";
import { useConfirmDelete } from "../../shared/hooks/use-confirm-delete.hooks";
import ShowLoading from "@app/shared/components/loading.component";
import { useGetFleetDevices } from "@app/shared/hooks/get/fleet-devices";
import CreateDevice from "../fleet/fad-create-device.component";
import { useDeleteDeviceMutation } from "@app/shared/hooks/delete/delete-device";
import { toast } from "react-toastify";
import Pagination from "@app/shared/components/pagination.component";

function RenderDevices() {
  const [selectedFleet] = useFleetAndDevicesStore((state) => [
    state.selectedFleet
  ]);

  const { openConfirmDeleteModal } = useConfirmDelete();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const {
    data: devicesResponse,
    isFetching,
    isLoading,
    status
  } = useGetFleetDevices(selectedFleet.id, { page, limit: pageSize });

  const deleteDeviceMutation = useDeleteDeviceMutation(selectedFleet.id);

  const deleteDevice = useCallback(
    async (deviceId: string) => {
      deleteDeviceMutation.mutate(deviceId, {
        onSuccess: (ok) => {
          if (ok) {
            toast.success("Deleted device successfully!");
          }
        }
      });
    },
    [deleteDeviceMutation]
  );

  const optionsToRender = useMemo(() => {
    return (
      devicesResponse?.devices?.map(
        ({ id, device_name, connected, status_flags, created_at }) => {
          return {
            device_name: (
              <Link
                to={{
                  pathname: "/fleet-and-devices/projects/device-details",
                  search: `?code=${id}` // inject code value into template
                }}
              >
                {device_name}
              </Link>
            ),
            connected:
              connected === null
                ? "Never Connected"
                : connected === false
                ? "Disconnected"
                : "Connected",
            status_flags,
            created_at: dateService.convertUTCToLocalDate(created_at),
            delete: (
              <button
                className="pl-2"
                onClick={() =>
                  openConfirmDeleteModal(
                    () => deleteDevice(id),
                    "Are you sure you want to delete this device?"
                  )
                }
                type="button"
                aria-label="delete device"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 4H3.33333H14"
                    stroke="#E21B17"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.33337 4.00016V2.66683C5.33337 2.31321 5.47385 1.97407 5.7239 1.72402C5.97395 1.47397 6.31309 1.3335 6.66671 1.3335H9.33337C9.687 1.3335 10.0261 1.47397 10.2762 1.72402C10.5262 1.97407 10.6667 2.31321 10.6667 2.66683V4.00016M12.6667 4.00016V13.3335C12.6667 13.6871 12.5262 14.0263 12.2762 14.2763C12.0261 14.5264 11.687 14.6668 11.3334 14.6668H4.66671C4.31309 14.6668 3.97395 14.5264 3.7239 14.2763C3.47385 14.0263 3.33337 13.6871 3.33337 13.3335V4.00016H12.6667Z"
                    stroke="#E21B17"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.66663 7.3335V11.3335"
                    stroke="#E21B17"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.33337 7.3335V11.3335"
                    stroke="#E21B17"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )
          };
        }
      ) || []
    );
  }, [deleteDevice, devicesResponse, openConfirmDeleteModal]);

  if (
    isFetching ||
    isLoading ||
    devicesResponse?.devices === undefined ||
    status === "loading"
  ) {
    return <ShowLoading />;
  }

  return devicesResponse?.devices?.length ? (
    <main className="flex-1 px-4 py-8 pt-5 space-y-4 overflow-y-auto lg:px-8 sm:px-6">
      <h1 className="text-2xl font-medium text-contentColor">Devices</h1>
      <hr className="border-background-layer3" />
      <div className="flex flex-col mt-12">
        <div className="-my-2 overflow-x-auto xl:-mx-8 px-4 lg:px-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden">
              {devicesResponse?.devices && optionsToRender.length ? (
                <>
                  <GenericTable
                    column={Object.keys(optionsToRender[0])}
                    row={optionsToRender}
                    searchField={
                      optionsToRender.length
                        ? Object.keys(optionsToRender[0])[1]
                        : ""
                    }
                    searchTerm=""
                  />
                  {devicesResponse?.meta ? (
                    <Pagination
                      setPage={setPage}
                      setPageSize={setPageSize}
                      meta={devicesResponse?.meta}
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </main>
  ) : (
    <CreateDevice />
  );
}

export default RenderDevices;
