import {
  PencilSquareIcon,
  PlusIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import useRuleEngineStore, {
  IRule
} from "@store/rule-engine/rule-engine.store";
import { Button } from "@tremor/react";
import React, { useState } from "react";
import RuleEngine from "./rule-engine.component";
import RuleTriggerTypeSelector from "./rule-trigger-type-selector.component";
import { useNavigate } from "react-router-dom";

interface IRuleItem {
  rule: IRule;
  ind: number;
}

const RuleItem: React.FC<IRuleItem> = ({ rule, ind }) => {
  const navigate = useNavigate();

  const [rules, setRules, clearRuleData, clearLocalRuleData] =
    useRuleEngineStore((state) => [
      state.rules,
      state.setRules,
      state.clearRuleData,
      state.clearLocalRuleData
    ]);

  return (
    <>
      <div className="flex flex-col gap-2 bg-background-layer1 border border-background-layer3 p-3">
        <div className="flex gap-2 justify-between">
          <div className="flex gap-2">
            <input
              placeholder="Rule Name"
              value={rule.name.trim()}
              className="bg-background max-w-md w-full px-2 py-1 text-contentColor border-background-layer3 rounded-sm border-2"
              readOnly
              // onChange={(e) => {
              //   const newRules = {...rules};
              //   newRules[rule.name] = {
              //     ...newRules[rule.name],
              //     name: e.target.value
              //   };

              //   setRules(newRules);
              // }}
            />
            <RuleTriggerTypeSelector
              ruleTriggerType={rule.triggerType}
              setRuleTriggerType={(newVal) => {
                const newRules = { ...rules };
                newRules[rule.name] = {
                  ...newRules[rule.name],
                  triggerType: newVal
                };

                setRules(newRules);
              }}
            />
          </div>
          <TrashIcon
            width={20}
            className="cursor-pointer text-red-500"
            onClick={() => {
              const newRules = { ...rules };
              delete newRules[rule.name];

              clearLocalRuleData(rule.name);
              setRules(newRules);
              clearRuleData();
            }}
          />
        </div>
        <textarea
          placeholder="Description"
          value={rule.description}
          className="bg-background w-full px-2 py-1 text-contentColor border-background-layer3 rounded-sm border-2"
          onChange={(e) => {
            const newRules = { ...rules };
            newRules[rule.name] = {
              ...newRules[rule.name],
              description: e.target.value
            };

            setRules(newRules);
          }}
        />
        <div className="justify-end flex mt-4">
          <Button
            className="bg-primary !text-white"
            // variant="secondary"
            // size="xs"
            disabled={!rule.name || !rule.triggerType || !rule.description}
            onClick={() => rule.id? navigate(rule.id) : navigate("new-rule", {state: {ruleName: rule.name}})}
            icon={PencilSquareIcon}
          >
            Edit
          </Button>
        </div>
      </div>
    </>
  );
};

export default RuleItem;
