import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useServiceProvider } from "../shared/hooks";
import {
  getAuthRedirect,
  getOnboardingStep,
} from "../shared/utils/helper.util";
import { Field, Form, Formik } from "formik"

function OnboardingWelcome() {
  const [showPass, setShowPass] = useState(false);
  const serviceProvider = useServiceProvider(),
    navigate = useNavigate();

  const getStarted = () => {
    const step = getOnboardingStep(serviceProvider.onboardingStatus),
      redirectUri = getAuthRedirect(step);
    navigate(redirectUri);
  };

  const handleSubmit = async (
    values: {
      email: string;
      password: string;
    }, { resetForm }) => {

  }

  return (
    <React.Fragment>
      <div className="flex flex-col mt-9 mb-6">
        <h2 className="text-5xl font-semibold text-gray-800">Welcome</h2>

        <p className="mt-6 text-lg text-gray-600">
          Create a free account to get started!
        </p>
      </div>

      <div className="mb-6">
        <h1 className="text-xl mb-7 text-black font-bold">Join now</h1>
        <button className="w-full py-3.5 text-sm flex justify-center items-center border border-solid border-primary rounded">
         

          <h1 className="ml-2">Login with Golain</h1>
        </button>
      </div>

      <div className="mb-4">
        <div className="w-full flex items-center">
          <div className="w-full border border-solid border-[#B7B9C1]"></div>
          <div className="mx-9 text-[#B7B9C1] text-sm font-medium">Or</div>
          <div className="w-full border border-solid border-[#B7B9C1]"></div>
        </div>
      </div>

      <Formik initialValues={{ email: "", password: "" }} onSubmit={handleSubmit}>
        <Form className="flex flex-col flex-1">
          <div className="flex-1">
            <div className="mb-5">
              <label className="text-sm font-medium text-[#B7B9C1]">Email</label>

              <Field
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
              />
            </div>

            <div className="mb-5">
              <div className="flex justify-between items-center">
                <label className="text-sm font-medium text-[#B7B9C1]">Password</label>
                <h1 className="text-sm italic text-[#546CCC] cursor-pointer">Forgot Password?</h1>
              </div>
              <div className="relative flex justify-end items-center">
                <Field
                  type={showPass ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Password"
                  className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                />
                <button onClick={() => setShowPass(!showPass)} type="button" className="absolute mt-2 mr-3">
                  {showPass ?
                    (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.99999 2C11.5947 2 14.5853 4.58667 15.2127 8C14.586 11.4133 11.5947 14 7.99999 14C4.40532 14 1.41466 11.4133 0.787323 8C1.41399 4.58667 4.40532 2 7.99999 2ZM7.99999 12.6667C9.35964 12.6664 10.6789 12.2045 11.7419 11.3568C12.8049 10.509 13.5486 9.32552 13.8513 8C13.5475 6.67554 12.8033 5.49334 11.7404 4.64668C10.6775 3.80003 9.35886 3.33902 7.99999 3.33902C6.64112 3.33902 5.32245 3.80003 4.25957 4.64668C3.1967 5.49334 2.4525 6.67554 2.14866 8C2.45139 9.32552 3.19511 10.509 4.25809 11.3568C5.32107 12.2045 6.64034 12.6664 7.99999 12.6667V12.6667ZM7.99999 11C7.20434 11 6.44128 10.6839 5.87867 10.1213C5.31606 9.55871 4.99999 8.79565 4.99999 8C4.99999 7.20435 5.31606 6.44129 5.87867 5.87868C6.44128 5.31607 7.20434 5 7.99999 5C8.79564 5 9.5587 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.5587 10.6839 8.79564 11 7.99999 11ZM7.99999 9.66667C8.44202 9.66667 8.86594 9.49107 9.1785 9.17851C9.49106 8.86595 9.66666 8.44203 9.66666 8C9.66666 7.55797 9.49106 7.13405 9.1785 6.82149C8.86594 6.50893 8.44202 6.33333 7.99999 6.33333C7.55796 6.33333 7.13404 6.50893 6.82148 6.82149C6.50892 7.13405 6.33332 7.55797 6.33332 8C6.33332 8.44203 6.50892 8.86595 6.82148 9.17851C7.13404 9.49107 7.55796 9.66667 7.99999 9.66667Z" fill="#09121F" />
                    </svg>) :
                    (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.22806 12.5213L4.94072 12.1759L5.46539 10.2166C4.68004 9.92697 3.95014 9.50486 3.30739 8.96862L1.87206 10.4046L0.928724 9.46128L2.36472 8.02595C1.55411 7.05512 1.00945 5.89046 0.784058 4.64595L2.09606 4.40662C2.60206 7.20795 5.05272 9.33328 8.00006 9.33328C10.9467 9.33328 13.3981 7.20795 13.9041 4.40662L15.2161 4.64528C14.991 5.88996 14.4465 7.05486 13.6361 8.02595L15.0714 9.46128L14.1281 10.4046L12.6927 8.96862C12.05 9.50486 11.3201 9.92697 10.5347 10.2166L11.0594 12.1766L9.77206 12.5213L9.24673 10.5613C8.42163 10.7027 7.57849 10.7027 6.75339 10.5613L6.22806 12.5213Z" fill="#09121F" />
                    </svg>)
                  }
                </button>
              </div>
            </div>

            <button
              // to="/onboarding/availability"
              // type="submit"
              onClick={getStarted}
              className="w-1/2 px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80">
              "Login" Skip For Now
            </button>
            <div className="flex text-sm mt-6">
              <h1>Don’t have an account?&nbsp;</h1>
              <h1 className="font-bold text-primary cursor-pointer">Sign Up</h1>
            </div>
          </div>

        </Form>
      </Formik>

    </React.Fragment>
  );
}

export default OnboardingWelcome;
