import React, { useEffect, useId, useMemo, useRef, useState } from "react";
import L, { LatLngExpression } from "leaflet";
import { MapContainer, TileLayer, useMap, useMapEvents } from "react-leaflet";
import { ICluster, ILocation, IMapView } from "../../../../interfaces";

import "leaflet/dist/leaflet.css";
import LocationMarker from "./dash-map-marker-component";
import LocationCluster from "./dash-map-cluster-component";
import { Button } from "@tremor/react";
import useThemeStore from "@store/theme.store";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { data, docks, sampleShadow } from "./tempData";

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const tileLayerProps = {
  "dark-default": {
    url: "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  },
  golain: {
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }
};

const device = {
  device_id: "5785dcbc-7061-4bb6-881e-dd9439917d4e",
  device_name: "bike-1",
  fleet_id: "4f89b152-df44-4535-98c3-1ead94114902",
  location: { lat: 18.56349754, lgt: 73.79955292 }
};

const ICONS_MAP = {
  NO_RIDE: "STOP",
  REQUESTED: "STOP",
  ACCEPTED: "RUNNING",
  ACTIVE: "RUNNING",
  PAUSED: "RUNNING",
  END_REQUESTED: "MAINTENANCE"
};

const _devices = [];

data.forEach((feature, ind) =>
  _devices.push({
    ...device,
    location: {
      lat: feature.geometry.coordinates[1],
      lgt: feature.geometry.coordinates[0]
    },
    device_name: "bike-" + (ind + 3),
    shadow: {
      ...sampleShadow,
      rideStatus: Object.keys(ICONS_MAP)[Math.trunc(Math.random() * 6)]
    }
  })
);

docks.forEach((feature, ind) =>
  _devices.push({
    ...device,
    location: {
      lat: feature.geometry.coordinates[1],
      lgt: feature.geometry.coordinates[0]
    },
    device_name: "dock-" + (ind + 1),
    fleet_id: "8ca69880-a313-4ea0-abc0-a5ebda31858e",
    shadow: {
      ...sampleShadow,
      rideStatus: Object.keys(ICONS_MAP)[Math.trunc(Math.random() * 6)]
    }
  })
);

const Bounds = ({ onChangeBoundingBox }) => {
  const maphand = useMap();

  useMapEvents({
    zoomend: () => {
      onChangeBoundingBox(
        maphand
          .getBounds()
          .toBBoxString()
          .split(",")
          .map((ele) => +ele)
      );
    },
    moveend: () => {
      onChangeBoundingBox(
        maphand
          .getBounds()
          .toBBoxString()
          .split(",")
          .map((ele) => +ele)
      );
    }
  });
  return null;
};

const center = (bounding_box: number[]) => {
  return {
    lng: (bounding_box[0] + bounding_box[2]) / 2,
    lat: (bounding_box[1] + bounding_box[3]) / 2
  };
};

const MapView: React.FC<IMapView> = ({
  devices,
  clusters,
  bounding_box,
  deviceShadows,
  deviceShadowDefs,
  icons,
  onChangeBoundingBox,
  geoMapAdditionalInfo
}) => {
  const resizeObserverRef = React.useRef<ResizeObserver>(null);

  const [theme] = useThemeStore((state) => [state.theme]);
  const [showAdditoinalInfo, setShowAdditoinalInfo] = useState(true);
  const tileRef = useRef(null);

  const mapCenter = useMemo(() => {
    return center(bounding_box) as LatLngExpression;
  }, [bounding_box]);
  const mapRef = useRef(null);

  const id = useId();

  useEffect(() => {
    const resizeObserver = resizeObserverRef.current;
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (tileRef.current && mapRef.current) {
      tileRef.current.setUrl(tileLayerProps[theme].url, false);

      // force a re-render on tile layer
      if (theme === "golain") {
        mapRef.current.zoomOut();
      } else {
        mapRef.current.zoomIn();
      }
    }
  }, [theme]);

  const tempDevices = useMemo(() => {
    const _d = [...devices, ..._devices];

    return _d;
  }, [devices]);

  return (
    <div className="h-full w-full p-2 mb-2 -mt-[15px]">
      <MapContainer
        ref={(ref) => {
          if (!ref) return;
          mapRef.current = ref;
          const container = document.getElementById(id);
          if (!container) return;

          L.DomEvent.disableClickPropagation(
            container
          ).disableScrollPropagation(container);

          resizeObserverRef.current = new ResizeObserver(() => {
            if (!ref) {
              return;
            }
            if (ref.getContainer()) {
              ref?.invalidateSize({ pan: false });
            }
          });
          if (container) {
            resizeObserverRef.current.observe(container);
          }
        }}
        id={id}
        center={mapCenter}
        zoom={10}
        zoomSnap={0.45}
        style={{
          width: "100%",
          height: "100%",
          marginTop: "6px",
          borderRadius: "6px",
          zIndex: 0
        }}
      >
        <TileLayer
          ref={tileRef}
          attribution={tileLayerProps[theme].attribution}
          url={tileLayerProps[theme].url}
        />

        {tempDevices.map((device: ILocation, ind) => (
          <LocationMarker
            key={device.device_id + "-marker-" + ind}
            shadow={deviceShadows.current[device.device_id]}
            shadowsRef={deviceShadows.current}
            {...device}
          />
        ))}
        {clusters.map((cluster: ICluster) => (
          <LocationCluster
            key={cluster.cluster_id}
            {...cluster}
            // FIXME: clusters should be outside devices
            icon={
              icons.devices.cluster
                ? icons.devices.cluster[cluster.cluster_id - 1]
                : undefined
            }
          />
        ))}

        <Bounds onChangeBoundingBox={onChangeBoundingBox} />
      </MapContainer>
      <div className="bg-background border-2 max-h-80 overflow-auto border-background-layer1 shadow-xl text-contentColor rounded-md p-4 absolute bottom-16 right-4">
        {tempDevices.length || clusters.length ? (
          <>
            {tempDevices.length ? (
              <div className="flex flex-col">
                <span className="text-base font-medium">
                  Devices ({tempDevices.length}):
                </span>
                <div className="flex flex-col">
                  {tempDevices.map((device: ILocation, ind) => (
                    <div
                      className="flex items-center"
                      key={device.device_id + "-" + ind}
                    >
                      <span className="text-sm font-medium whitespace-nowrap">
                        {device.device_name}:
                      </span>
                      <span className="text-sm ml-2">
                        {device.location.lat.toFixed(2)},{" "}
                        {device.location.lgt.toFixed(2)}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}{" "}
            {clusters.length ? (
              <div className="flex flex-col">
                <span className="text-base font-medium">
                  Clusters ({clusters.length}):
                </span>
                <div className="flex flex-col">
                  {clusters.map((cluster: ICluster) => (
                    <div
                      className="flex items-center"
                      key={cluster.cluster_id}
                    >
                      <span className="text-base font-medium whitespace-nowrap">
                        {cluster.cluster_id}:
                      </span>
                      <span className="text-sm ml-2">
                        {cluster.location.lat.toFixed(2)},{" "}
                        {cluster.location.lgt.toFixed(2)}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <div className="flex flex-col items-center justify-center h-full">
            <span className="text-sm font-medium">
              There is no data to show in this view.
            </span>
            <Button
              size="xs"
              className="mt-3"
              onClick={() => {
                mapRef.current.zoomOut();
              }}
            >
              Try zooming out?
            </Button>
          </div>
        )}
      </div>

      {showAdditoinalInfo && Object.keys(geoMapAdditionalInfo).length ? (
        <div className="absolute bottom-16 left-4 flex flex-col gap-2 border-[1px] border-background-layer2 bg-background p-2 shadow-xl rounded-md">
          <div className="flex gap-2 flex-nowrap w-full justify-between ml-1">
            <span className="text-sm">Rides Overview:</span>
            <XMarkIcon
              width={12}
              className="text-contentColor cursor-pointer"
              onClick={() => setShowAdditoinalInfo(false)}
            />
          </div>
          {Object.keys(geoMapAdditionalInfo).map((key, ind) => (
            <div
              key={geoMapAdditionalInfo[key].title + ind}
              className="bg-background-layer0.5 border-[1px] border-background-layer2 text-contentColor rounded-md p-2"
            >
              <div className="flex items-center" key={key}>
                <span className="text-sm font-medium whitespace-nowrap">
                  {geoMapAdditionalInfo[key].title}:
                </span>
                <span className="text-sm ml-2">
                  {geoMapAdditionalInfo[key].data[0].count}
                </span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="absolute bottom-16 left-4 bg-background border-[1px] border-background-layer2 p-2 shadow-xl rounded-md">
          <Bars3Icon
            className="h-6 w-6 text-contentColor cursor-pointer"
            onClick={() => setShowAdditoinalInfo(true)}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(MapView);
