const data = [
  {
    type: "Feature",
    properties: {},
    geometry: {
      coordinates: [73.799553, 18.563498],
      type: "Point"
    }
  },
  {
    type: "Feature",
    properties: {},
    geometry: {
      coordinates: [73.7972469629849, 18.563803359827887],
      type: "Point"
    }
  },
  {
    type: "Feature",
    properties: {},
    geometry: {
      coordinates: [73.79896596579269, 18.560221926907374],
      type: "Point"
    }
  },
  {
    type: "Feature",
    properties: {},
    geometry: {
      coordinates: [73.79814146103689, 18.557962990060346],
      type: "Point"
    }
  },
  {
    type: "Feature",
    properties: {},
    geometry: {
      coordinates: [73.80272018485383, 18.562825234427734],
      type: "Point"
    }
  },
  {
    type: "Feature",
    properties: {},
    geometry: {
      coordinates: [73.80313955657994, 18.56023834201929],
      type: "Point"
    }
  },
  {
    type: "Feature",
    properties: {},
    geometry: {
      coordinates: [73.8012341895409, 18.559080781661322],
      type: "Point"
    }
  },
  {
    type: "Feature",
    properties: {},
    geometry: {
      coordinates: [73.80234760799271, 18.565262106976135],
      type: "Point"
    }
  },
  {
    type: "Feature",
    properties: {},
    geometry: {
      coordinates: [73.79510541168358, 18.5610508247956],
      type: "Point"
    }
  }
];

const sampleShadow = {
  backupBatteryCharge: 40,
  backupBatteryStatus: "BACKUP_CHARGING_COMPLETED",
  batteryCharge: 40,
  batteryStatus: "CHARGING_COMPLETED",
  currentDock: "dock1",
  dockingStatus: "AT_DOCKING_STATION",
  lastDock: "dock2",
  lockStatus: "LOCKED",
  nextDock: "dock2",
  rideID: "8352bb17-4718-4590-bb24-f907ee4b1bae",
  userID: "test-user-1"
};

const docks = [
  {
    type: "Feature",
    properties: {},
    geometry: {
      coordinates: [73.79768397598491, 18.561884999328072],
      type: "Point"
    }
  },
  {
    type: "Feature",
    properties: {},
    geometry: {
      coordinates: [73.80362235039419, 18.562890440621402],
      type: "Point"
    }
  }
];

export { data, sampleShadow, docks };
