import React, { useEffect, useState } from "react";
import MethodsMenu from "../../http-response-node/methods-menu.component";
import HTTPHeadersEditor from "./http-headers-editor.component";
import Editor from "@monaco-editor/react";
import Modal from "@app/shared/components/modal.component";
import ActionTypeDropdown from "../action-type-dropdown.component";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { validURL } from "@app/shared/utils/helper.util";
import { VariableInput } from "@app/rule-engine/variable-input.component";
import HTTPQueryParamsEditor from "./http-query-params-editor.component";

type ActiveTab = "headers" | "body" | "query-params";

interface IParam {
  key: string;
  value: string;
}

const HTTPActionDefinitionEditor = ({
  actionDefinition,
  setActionDefinition,
  setActionType,
  actionTypes,
  actionType
}) => {
  const [httpUrl, setURL] = useState(actionDefinition.url ?? "");
  const [method, setMethod] = useState(actionDefinition.method ?? "get");
  const [headers, setHeaders] = useState(actionDefinition.headers ?? []);
  const [params, setParams] = useState<IParam[]>(
    actionDefinition.params ?? []
  );
  const [body, setBody] = useState(actionDefinition.body ?? {});

  const [activeTab, setActiveTab] = useState<ActiveTab>("headers");
  const [jsonEditorModalOpen, setJsonEditorModalOpen] = useState(false);
  const [modalJSON, setModalJSON] = useState({});

  useEffect(() => {
    const hasErrors = false;

    setActionDefinition({
      url: httpUrl,
      method,
      headers,
      body,
      params,
      hasErrors
    });
  }, [httpUrl, method, headers, body, setActionDefinition, params]);

  return (
    <>
      <div className="bg-background-layer1 w-full h-full p-4 rounded-md border border-background-layer3">
        <div className="flex justify-between">
          <ActionTypeDropdown
            setActionType={setActionType}
            actionTypes={actionTypes}
            setActionDefinition={setActionDefinition}
            buttonComponent={
              <div className="flex gap-2 bg-background-layer3 rounded-sm px-2 py-1 items-center">
                <span className="uppercase">{actionType}</span>
                <ChevronDownIcon width={16} />
              </div>
            }
          />
          <div className="flex gap-2">
            <button
              className="bg-background-layer3 text-contentColor px-2 py-1 rounded-sm"
              onClick={() => setJsonEditorModalOpen(true)}
            >
              JSON
            </button>
            <MethodsMenu method={method} setMethod={setMethod} />
          </div>
        </div>
        {/* URL */}
        <div className="flex flex-col gap-1 nodrag mt-2">
          <VariableInput
            type="url"
            placeholder="https://example.com"
            value={httpUrl}
            className="nodrag bg-background w-full px-2 py-1 text-sm text-contentColor border-background-layer3 rounded-sm border-2"
            onChange={(e) => setURL(e.target.value)}
          />
          {/* {!validURL(httpUrl) ? (
            <span className="text-red-500">Enter a valid URL.</span>
          ) : null} */}
        </div>

        <div className="flex nodrag border-b border-background-layer3 mt-2">
          {/* tabs */}
          <div className="flex gap-2  mt-2">
            <button
              className={`${
                activeTab === "headers"
                  ? "border-b-2 border-primaryLight"
                  : "bg-background-layer1"
              } px-2 py-1 rounded-sm text-contentColor`}
              onClick={() => setActiveTab("headers")}
            >
              Headers
            </button>
            {method !== "get" ? (
              <button
                className={`${
                  activeTab === "body"
                    ? "border-b-2 border-primaryLight"
                    : "bg-background-layer1"
                } px-2 py-1 rounded-sm text-contentColor`}
                onClick={() => setActiveTab("body")}
              >
                Body
              </button>
            ) : (
              <button
                className={`${
                  activeTab === "query-params"
                    ? "border-b-2 border-primaryLight"
                    : "bg-background-layer1"
                } px-2 py-1 rounded-sm text-contentColor`}
                onClick={() => setActiveTab("query-params")}
              >
                Query Params
              </button>
            )}
          </div>
        </div>

        {activeTab === "headers" ? (
          <div className="flex gap-2 nodrag mt-2">
            <HTTPHeadersEditor headers={headers} setHeaders={setHeaders} />
          </div>
        ) : activeTab === "query-params" ? (
          <div className="flex gap-2 nodrag mt-2">
            <HTTPQueryParamsEditor params={params} setParams={setParams} />
          </div>
        ) : (
          <div className="flex gap-2 nodrag mt-2">
            <Editor
              height="200px"
              width="100%"
              theme="vs-dark"
              language="json"
              value={JSON.stringify(body, null, 2)}
              onChange={(e) => {
                try {
                  setBody(JSON.parse(e));
                } catch (err) {}
              }}
              options={{
                readOnly: false,
                minimap: {
                  enabled: false
                },
                lineNumbers: "off"
              }}
            />
          </div>
        )}
      </div>
      <Modal
        open={jsonEditorModalOpen}
        setOpen={setJsonEditorModalOpen}
        title="JSON Editor"
        className="w-full max-w-2xl"
        disableClickOutside
      >
        <div className="flex flex-col gap-4 p-6 bg-background-layer1 text-contentColor">
          <h1 className="text-lg font-bold">Set HTTP Action JSON</h1>
          <p>
            All your changes will be overwritten using this JSON editor.
            <br />
          </p>
          <Editor
            height="500px"
            width="100%"
            theme="vs-dark"
            language="json"
            value={JSON.stringify(modalJSON, null, 2)}
            onChange={(e) => {
              try {
                setModalJSON(JSON.parse(e));
              } catch (err) {}
            }}
            options={{
              readOnly: false,
              minimap: {
                enabled: false
              }
            }}
          />
          <div className="flex gap-4 justify-end">
            <button
              className="bg-background-layer3 text-contentColor px-2 py-1 rounded-sm"
              onClick={() => setJsonEditorModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="bg-green-500 text-contentColor px-2 py-1 rounded-sm"
              onClick={() => {
                const method = modalJSON["method"].toLowerCase();
                const url = modalJSON["url"];
                const headers = modalJSON["headers"];
                const body = modalJSON["body"];

                if (method) {
                  const validMethods = [
                    "get",
                    "post",
                    "put",
                    "delete",
                    "patch"
                  ];
                  if (validMethods.includes(method)) {
                    setMethod(method);
                  }
                }
                if (url) setURL(url);
                if (headers) {
                  setHeaders(
                    Object.keys(headers).map((key) => ({
                      key,
                      value: headers[key]
                    }))
                  );
                }
                if (body) setBody(body);

                setJsonEditorModalOpen(false);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HTTPActionDefinitionEditor;
