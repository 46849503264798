import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { IShadowResponse } from "@interfaces/fad.interface";

export const getShadow = async (
  projectId: string,
  orgId: string,
  fleetId: string,
  deviceId: string,
  params,
  axiosParams: any = {}
) => {
  const { data, ok } = await networkService.get<IShadowResponse>(
    `projects/${projectId}/fleets/${fleetId}/devices/${deviceId}/shadow`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      },
      ...axiosParams
    }
  );

  if (ok) {
    return data.shadow;
  } else return null;
};

export const useGetShadow = (
  fleetId: string,
  deviceId: string,
  params: any = {},
  successCb: ((data: any) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    [
      "getShadow",
      selectedProject.id,
      user.selectedOrg.id,
      fleetId,
      deviceId,
      params
    ],
    async () => {
      const shadow = await getShadow(
        selectedProject.id,
        user.selectedOrg.id,
        fleetId,
        deviceId,
        params
      );

      successCb && successCb(shadow);

      return shadow;
    },
    {
      enabled:
        !!selectedProject.id &&
        !!user.selectedOrg.id &&
        !!fleetId &&
        !!deviceId,
      onError: (err) => console.error(err)
    }
  );

  return res;
};
