import produce from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../../app/shared/config";
import {
  HTTPResponse,
  ICondition,
  IContext,
  IInput
} from "@app/rule-engine/rule-engine.helper";

export type TriggerType = "HTTP" | "MQTT";
export type MQTTDefDirection = "any" | "ingress" | "egress";

export interface IRule {
  name: string;
  description: string;
  triggerType: TriggerType;
  ruleData?: any;
  id?: string;
  version?: number;
  createdAt?: string;
  // for MQTT
  definition?: {
    data_kind: string;
    direction: MQTTDefDirection;
    devices: string[];
  };
}

interface IRuleEngineState {
  rules: Record<string, IRule>;

  // these store the data of currently opened rule
  actionData: Record<string, Record<string, any>>;
  ruleData: {
    conditions?: Array<ICondition>;
    inputs?: Array<IInput>;
    fetchedContexts?: Array<IContext>;
  };
  responseData: Record<string, HTTPResponse>;

  // rule name -> reactflow instance object
  reactFlowData: Record<string, any>;

  // this is to persist rule that haven't been sent to the backend yet.
  // rule name -> all rule related data
  localRulesData: Record<
    string,
    {
      ruleData: any;
      actionData: Record<string, Record<string, any>>;
      responseData: Record<string, HTTPResponse>;
      ruleDetails: IRule;
      orgId: string;
      projId: string;
    }
  >;
  setReactFlowData: (
    ruleName: string,
    reactFlow: any,
    ruleData: any,
    actionData: Record<string, Record<string, any>>,
    responseData: Record<string, HTTPResponse>,
    ruleDetails: IRule,
    orgId: string,
    projId: string
  ) => void;
  setActionData: (actionData: any) => void;
  setRuleData: (ruleData: any) => void;
  setResponseData: (
    Data:
      | Record<string, HTTPResponse>
      | ((d: Record<string, HTTPResponse>) => Record<string, HTTPResponse>)
  ) => void;
  setRules: (
    rules:
      | Record<string, IRule>
      | ((r: Record<string, IRule>) => Record<string, IRule>)
  ) => void;
  clearRuleData: () => void;
  clearLocalRuleData: (ruleName: string) => void;
}

const useRuleEngineStore = create<IRuleEngineState>()(
  persist(
    devtools(
      (set) => ({
        rules: {},
        ruleData: {},
        actionData: {},
        responseData: {},
        reactFlowData: null,
        localRulesData: {},
        setReactFlowData: (
          ruleName,
          reactFlow,
          ruleData,
          actionData,
          responseData,
          ruleDetails,
          orgId,
          projId
        ) =>
          set(
            produce((state: IRuleEngineState) => {
              if (!state.reactFlowData) {
                state.reactFlowData = {};
              }
              state.reactFlowData[ruleName] = reactFlow;
              state.localRulesData[ruleName] = {
                ruleData,
                actionData,
                responseData,
                ruleDetails,
                orgId,
                projId
              };
            })
          ),
        setActionData: (actionDataOrFunc) =>
          set(
            produce((state: IRuleEngineState) => {
              state.actionData =
                typeof actionDataOrFunc === "function"
                  ? actionDataOrFunc(state.actionData)
                  : actionDataOrFunc;
            })
          ),
        setRuleData: (ruleDataOrFunc) =>
          set(
            produce((state: IRuleEngineState) => {
              state.ruleData =
                typeof ruleDataOrFunc === "function"
                  ? ruleDataOrFunc(state.ruleData)
                  : ruleDataOrFunc;
            })
          ),
        setResponseData: (responseDataOrFunc) =>
          set(
            produce((state: IRuleEngineState) => {
              state.responseData =
                typeof responseDataOrFunc === "function"
                  ? responseDataOrFunc(state.responseData)
                  : responseDataOrFunc;
            })
          ),
        setRules: (rulesOrFunc) =>
          set(
            produce((state: IRuleEngineState) => {
              state.rules =
                typeof rulesOrFunc === "function"
                  ? rulesOrFunc(state.rules)
                  : rulesOrFunc;
            })
          ),
        clearRuleData: () =>
          set(
            produce((state: IRuleEngineState) => {
              state.ruleData = {};
              state.actionData = {};
              state.responseData = {};
            })
          ),
        clearLocalRuleData: (ruleName: string) =>
          set(
            produce((state: IRuleEngineState) => {
              const newLocalRulesData = { ...state.localRulesData };
              delete newLocalRulesData[ruleName];

              const newReactFlowState = { ...state.reactFlowData };
              delete newReactFlowState[ruleName];

              state.localRulesData = newLocalRulesData;
              state.reactFlowData = newReactFlowState;
            })
          )
      }),
      { name: "rule-engine", serialize: { options: true } }
    ),
    {
      name: "rule-engine", // unique name
      getStorage: () => appConstants.keys.storage // (optional) by default, 'localStorage' is used
    }
  )
);

export default useRuleEngineStore;
