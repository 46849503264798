import {
  ArrowLeftIcon,
  CodeBracketSquareIcon,
  InformationCircleIcon,
  PlayIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import { useState, useRef, useCallback, useEffect } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  Controls,
  applyNodeChanges,
  applyEdgeChanges,
  ReactFlowInstance,
  Connection,
  Edge,
  Node
} from "reactflow";
import "reactflow/dist/style.css";
import useUndoable from "use-undoable";

import "./styles.css";
import Sidebar from "./sidebar-component";
import { NodeType, nodeTypes } from "./node-types.helper";
import AdditionalDataSiddebar from "./additional-data-sidebar.component";
import {
  HTTPResponse,
  getEdgeId,
  getId,
  serializeRuleJSON
} from "./rule-engine.helper";
import useRuleEngineStore, {
  TriggerType
} from "@store/rule-engine/rule-engine.store";
import { Button } from "@tremor/react";
import { toast } from "react-toastify";
import { useCreateRule } from "@app/shared/hooks/post/create-rule";
import { useUpdateRule } from "@app/shared/hooks/patch/update-rule";
import dateService from "@services/date.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import _ from "lodash";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import ButtonEdge from "./edges/delete-button-edge.component";

interface IPlaygroundProps {
  title?: string;
  ruleDetails?: {
    name: string;
    description: string;
    triggerType: TriggerType;
    version?: number;
  };
  actionEditor?: boolean;
  actionData?: any;
  responseData?: Record<string, HTTPResponse>;
  fetchedRuleData?: any;
  setResponseData?: (responseData: Record<string, HTTPResponse>) => void;
  setEditAction?: (action: any) => void;
  setActionData?: (data: any) => void;
}

let initialNodes = [
  {
    id: getId(),
    type: NodeType.ruleEditorNode,
    data: {},
    position: { x: 250, y: 5 }
  }
];

let actionEditorInitialNodes = [
  {
    id: getId(),
    type: NodeType.actionEditorNode,
    data: {},
    position: { x: 250, y: 5 }
  }
];

const edgeTypes = {
  buttonEdge: ButtonEdge
};

const PlayGround: React.FC<IPlaygroundProps> = ({
  title = "",
  ruleDetails,
  actionData,
  actionEditor = false,
  responseData,
  fetchedRuleData,
  setResponseData,
  setEditAction,
  setActionData
}) => {
  const mounted = useRef(false);
  const saveIntervalRef = useRef(null);
  const reactFlowWrapper = useRef(null);
  const deletedNodeId = useRef<string>("");
  const lastRuleJSON = useRef(null);

  const [lastSaved, setLastSaved] = useState(null);
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance>(null);

  const navigate = useNavigate();

  const [ruleData, rules, setRules] = useRuleEngineStore((state) => [
    state.ruleData,
    state.rules,
    state.setRules
  ]);

  const [reactFlowData, setReactFlowData, clearLocalRuleData] =
    useRuleEngineStore((state) => [
      state.reactFlowData,
      state.setReactFlowData,
      state.clearLocalRuleData
    ]);

  const selectedProject = useFleetAndDevicesStore(
    (state) => state.selectedProject
  );
  const user = useAuthStore((state) => state.user);

  const [elements, setElements, { undo, canUndo, redo, canRedo }] =
    useUndoable<{ nodes: Node[]; edges: Edge[] }>(
      { nodes: [], edges: [] },
      {
        behavior: "destroyFuture"
      }
    );

  const triggerUpdate = useCallback(
    (t, v, ignore = false) => {
      setElements(
        (e) => ({
          nodes: t === "nodes" ? v : e.nodes,
          edges: t === "edges" ? v : e.edges
        }),
        "destroyFuture",
        ignore
      );
    },
    [setElements]
  );

  const onNodesChange = useCallback(
    (changes) => {
      if (changes[0].type === "remove") {
        deletedNodeId.current = changes[0].id;
        const deletedNode = elements.nodes.find((n) => n.id === changes[0].id);

        switch (deletedNode.type) {
          case NodeType.actionSequenceNode:
            const _actionData = { ...actionData };
            delete _actionData[deletedNode.id];
            setActionData(_actionData);
            break;
          case (NodeType.ruleEditorNode, NodeType.actionEditorNode):
            return;
          default:
            break;
        }

        if (
          deletedNode.type === NodeType.ruleEditorNode ||
          deletedNode.type === NodeType.actionEditorNode
        ) {
          return;
        }
      }
      // don't save these changes in the history
      let ignore = ["select", "position", "dimensions"].includes(
        changes[0].type
      );

      triggerUpdate(
        "nodes",
        applyNodeChanges(changes, elements.nodes),
        ignore
      );
    },
    [triggerUpdate, elements.nodes, actionData, setActionData]
  );

  const onNodesDelete = useCallback(
    (deleted: Node[]) => {
      const deletedNode = deleted[0];
      if (
        deletedNode.type === NodeType.ruleEditorNode ||
        deletedNode.type === NodeType.actionEditorNode
      ) {
        return;
      }
      const newNodes = elements.nodes.filter((n) => n.id !== deletedNode.id);

      const edges = elements.edges.filter((edge) => {
        return edge.target !== deletedNode.id;
      });

      setTimeout(
        () => setElements((s) => ({ edges: edges, nodes: newNodes })),
        0
      );
    },
    [elements.edges, elements.nodes, setElements]
  );

  const onEdgesChange = useCallback(
    (changes) => {
      // don't save these changes in the history
      let ignore = ["select"].includes(changes[0].type);

      setElements(
        (e) => ({
          ...e,
          edges: applyEdgeChanges(changes, elements.edges)
        }),
        "destroyFuture",
        ignore
      );
    },
    [setElements, elements.edges]
  );

  useEffect(() => {
    if (mounted.current || elements.nodes.length) {
      return;
    }

    let initialNodesArray = actionEditor
      ? actionEditorInitialNodes
      : initialNodes;

    let initialEdges = [];
    let initialRuleEditorEdges: Edge[] = [];

    if (
      !actionEditor &&
      ruleDetails.triggerType === "MQTT" &&
      initialNodes.length === 1
    ) {
      const newNodeId = getId();

      initialNodes.push({
        id: newNodeId,
        type: NodeType.ruleTriggerTypeNode,
        data: {
          name: ruleDetails.name
        },
        position: { x: 0, y: -50 }
      });

      initialRuleEditorEdges.push({
        id: getEdgeId(),
        source: newNodeId,
        target: initialNodes[0].id,
        animated: true
      });
    }

    const foundLocalNodesData =
      reactFlowData !== null && reactFlowData[title] && !actionEditor;
    if (foundLocalNodesData) {
      const flow = reactFlowData[title];

      if (flow) {
        initialNodesArray = flow.nodes || initialNodesArray;
        initialEdges =
          flow.edges || (actionEditor ? [] : initialRuleEditorEdges);
      }
    }
    const newNodes = initialNodesArray.map((node) => {
      switch (node.type) {
        case NodeType.actionEditorNode:
          return {
            ...node,
            data: {
              ...node.data,
              setActionData,
              actionData
            }
          };

        case NodeType.ruleEditorNode:
          return {
            ...node,
            data: {
              ...node.data,
              fetchedRuleData,
              foundLocalNodesData,
              title,
              setElements,
              setEditAction
            }
          };

        case NodeType.actionSequenceNode:
          return {
            ...node,
            data: {
              ...node.data,
              setEditAction,
              setActionData
            }
          };

        case NodeType.httpResponseNode:
          return {
            ...node,
            data: {
              ...node.data,
              setResponseData
            }
          };

        default:
          return node;
      }
    });

    setTimeout(
      () =>
        setElements(
          {
            nodes: newNodes,
            edges: !foundLocalNodesData ? initialRuleEditorEdges : initialEdges
          },
          "destroyFuture",
          true
        ),
      0
    );

    if (!mounted.current) {
      saveIntervalRef.current = setInterval(saveReactFlow, 30000);
      mounted.current = true;
    }

    // window.addEventListener("beforeunload", beforeUnloadHandler);

    return () => {
      mounted.current = true;
      initialNodes = [
        {
          id: getId(),
          type: NodeType.ruleEditorNode,
          data: {},
          position: { x: 250, y: 5 }
        }
      ];
      clearInterval(saveIntervalRef.current);
    };
  }, []);

  const onConnect = useCallback(
    (connection: Connection) => {
      const newEdge: Edge = {
        ...connection,
        id: getEdgeId(),
        type: "buttonEdge"
      };

      if (connection.sourceHandle.startsWith("conditions")) {
        if (connection.sourceHandle.endsWith("true")) {
          newEdge.style = { stroke: "green" };
        } else if (connection.sourceHandle.endsWith("false")) {
          newEdge.style = { stroke: "red" };
        }
      }
      setElements((e) => ({
        ...e,
        edges: addEdge(newEdge, e.edges)
      }));
    },
    [setElements]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top
      });
      const newNodeId = getId();
      const data: any = { label: `${type} node`, id: newNodeId };

      switch (type) {
        case NodeType.actionSequenceNode:
          data.setEditAction = setEditAction;
          data.actionData = {
            [newNodeId]: {}
          };
          data.setActionData = setActionData;
          break;

        case NodeType.httpResponseNode:
          data.responseData = responseData;
          data.setResponseData = setResponseData;
          break;

        default:
          break;
      }

      const newNode = {
        id: newNodeId,
        type,
        position,
        data
      };

      setElements((state) => ({
        ...state,
        nodes: [...state.nodes, newNode]
      }));
    },
    [
      reactFlowInstance,
      responseData,
      setActionData,
      setEditAction,
      setElements,
      setResponseData
    ]
  );

  const saveReactFlow = useCallback(() => {
    if (reactFlowInstance) {
      const flow = reactFlowInstance.toObject();

      setReactFlowData(
        title,
        flow,
        ruleData,
        actionData,
        responseData,
        ruleDetails,
        user.selectedOrg.id,
        selectedProject.id
      );
      setLastSaved(dateService.getCurrentUTCDate());
    }
  }, [
    actionData,
    reactFlowInstance,
    responseData,
    ruleData,
    ruleDetails,
    selectedProject.id,
    setReactFlowData,
    title,
    user.selectedOrg.id
  ]);

  const onCloseClick = () => {
    saveReactFlow();
    navigate("/rule-engine");
  };

  const createRuleMutation = useCreateRule();
  const updateRuleMutation = useUpdateRule();

  const onCreateRuleCLick = useCallback(() => {
    const ruleDetails = rules[title];

    let gaveError = false;

    // check if actions that have not been run once exist:
    Object.keys(actionData).forEach((actionSeqNodeId) => {
      if (gaveError) {
        return;
      }
      Object.keys(actionData[actionSeqNodeId]).forEach((actionNodeId) => {
        if (
          !gaveError &&
          !("action_id" in actionData[actionSeqNodeId][actionNodeId])
        ) {
          // TODO: highlight Action Sequence Node which has errors
          toast.error(
            "Some of the action sequence node has actions that have not been run once. Please run them first!"
          );
          gaveError = true;
          return;
        }
      });
    });

    // check if there's a connection for all condition false handles with http response node for HTTP Rule
    ruleData.conditions.forEach((cond, ind) => {
      if (gaveError) return;

      const edge = elements.edges.find(
        (e) =>
          e.sourceHandle === `conditions-${ind}-false` &&
          e.targetHandle.startsWith("http-response")
      );

      if (!edge) {
        toast.error(
          `Condition ${ind} does not have a false reponse. Please add an HTTP Response Node and attach it to it's false output.`
        );
        gaveError = true;
        return;
      }
    });

    if (gaveError) {
      return;
    }

    // Clear local rule data so that fetched data from the server is set when the rule is run.
    // As some params are set from the server side when a rule is created.
    clearLocalRuleData(ruleDetails.name);

    const ruleJSON = serializeRuleJSON(
      ruleDetails,
      ruleData,
      actionData,
      responseData,
      elements.edges
    );

    if (!lastRuleJSON.current) {
      lastRuleJSON.current = ruleJSON;
    } else {
      if (_.isEqual(ruleJSON, lastRuleJSON.current)) {
        toast.success("Updated Rule Successfully!");
        return;
      } else {
        lastRuleJSON.current = ruleJSON;
      }
    }

    if (!ruleDetails?.id) {
      createRuleMutation.mutate(ruleJSON, {
        onSuccess: (id) => {
          const newRules = { ...rules };
          newRules[title] = {
            ...newRules[title],
            id
          };

          toast.success(
            <>
              Created Rule Successfully!
              {ruleDetails.triggerType === "MQTT" ? (
                <>
                  <br /> Note: MQTT Rules take about 2-3 mins to create. <br />
                  Hang tight.
                </>
              ) : null}
              `
            </>
          );
          setRules(newRules);
        }
      });
    } else {
      updateRuleMutation.mutate(
        { data: ruleJSON, ruleId: ruleDetails.id },
        {
          onSuccess: (ok) => {
            if (ok) {
              toast.success("Updated Rule Successfully!");
            }
          }
        }
      );
    }
  }, [
    actionData,
    clearLocalRuleData,
    createRuleMutation,
    elements.edges,
    responseData,
    ruleData,
    rules,
    setRules,
    title,
    updateRuleMutation
  ]);

  useEffect(() => {
    const newNodes = [...elements.nodes].map((n) => {
      if (n.type === NodeType.ruleEditorNode) {
        return {
          ...n,
          data: {
            ...n.data,
            fetchedRuleData
          }
        };
      }

      return n;
    });

    setElements((e) => ({
      ...e,
      nodes: newNodes
    }));
  }, [fetchedRuleData]);

  const onSaveClick = () => {
    saveReactFlow();
  };

  return (
    <div className="w-screen h-screen fixed z-50 top-0 left-0 bottom-0 right-0 bg-background text-contentColor">
      <div className=" py-2 px-4 lg:px-6 flex bg-background-layer1 items-center rounded-md h-20">
        {actionEditor ? (
          <ArrowLeftIcon
            width={20}
            className="text-contentColor cursor-pointer mr-4"
            onClick={() =>
              setEditAction({
                name: "",
                description: ""
              })
            }
          />
        ) : (
          <CodeBracketSquareIcon width={28} className="mr-2" />
        )}

        {actionEditor ? (
          <span className="text-xl font-semibold mr-2">Action Editor:</span>
        ) : (
          <span className="text-xl font-semibold mr-2">Rule Editor:</span>
        )}
        <span
          className={`text-xl ${
            actionEditor ? "text-green-500" : "text-yellow-500"
          }`}
        >
          {title}
        </span>

        {!actionEditor ? (
          <>
            <div className="ml-2 border-l-2 flex items-center border-background-layer3">
              <Button
                color="green"
                size="xs"
                className="ml-4"
                icon={PlayIcon}
                onClick={onCreateRuleCLick}
              >
                Create Rule
              </Button>
              <Button
                size="xs"
                variant="secondary"
                className="ml-4"
                // icon={PlayIcon}
                onClick={onSaveClick}
              >
                Save
              </Button>
              {lastSaved ? (
                <span className="ml-2 font-medium text-contentColorLight text-base">
                  {/* Last Saved: {dateService.convertUTCToLocalDate(lastSaved)} */}
                </span>
              ) : null}
              <span
                data-tooltip-id="missing-nodes-tooltip"
                className="ml-2 cursor-pointer text-primary"
              >
                <InformationCircleIcon width={28} />
              </span>
            </div>
            <XMarkIcon
              width={20}
              className="text-contentColor ml-auto cursor-pointer"
              onClick={onCloseClick}
            />
          </>
        ) : null}
      </div>
      <div className="dndflow w-full h-full">
        <ReactFlowProvider>
          <Sidebar />
          <div className="reactflow-wrapper" ref={reactFlowWrapper}>
            <ReactFlow
              nodes={elements.nodes}
              nodeTypes={nodeTypes}
              edges={elements.edges}
              onNodesChange={onNodesChange}
              onNodesDelete={onNodesDelete}
              onEdgesChange={onEdgesChange}
              edgeTypes={edgeTypes}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}
              fitView
            >
              <Controls
                position="top-right"
                className=" flex flex-col bg-white"
              >
                <button
                  disabled={!canUndo}
                  onClick={() => undo()}
                  className="text-black disabled:opacity-50"
                >
                  U
                </button>

                <button
                  disabled={!canRedo}
                  onClick={() => redo()}
                  className="text-black disabled:opacity-50"
                >
                  R
                </button>
              </Controls>
            </ReactFlow>
          </div>
          {!actionEditor ? (
            <AdditionalDataSiddebar actionData={ruleData} />
          ) : (
            <AdditionalDataSiddebar actionData={actionData} />
          )}
        </ReactFlowProvider>
      </div>
      <Tooltip id="missing-nodes-tooltip">
        Cannot see your existing nodes? Try refreshing the page!
      </Tooltip>
    </div>
  );
};

const beforeUnloadHandler = (event) => {
  // Recommended
  event.preventDefault();

  // Included for legacy support, e.g. Chrome/Edge < 119
  event.returnValue = true;
};

export default PlayGround;
