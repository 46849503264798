import { useState, useEffect, useCallback } from "react";
import { Formik, Field, Form } from "formik";

export const GaugeForm = ({ onChangeHandler, initState }) => {
  // const didMount = useRef(false);
  const [panelData, setPanelData] = useState({ ...initState });
  const [error, setErrors] = useState({
    minDisplay: false,
    maxDisplay: false,
    minThres: false,
    maxThres: false,
    unit: false
  });

  const handleSubmit = () => {};

  const isValid = useCallback(() => {
    let retVal = true;
    let errors = { ...error };
    if (panelData.maxDisplay <= panelData.minDisplay) {
      errors.maxDisplay = true;
      retVal = false;
    } else errors.maxDisplay = false;

    if (panelData.minDisplay >= panelData.maxDisplay) {
      errors.minDisplay = true;
      retVal = false;
    } else errors.minDisplay = false;

    if (
      panelData.minThres < panelData.minDisplay ||
      panelData.minThres > panelData.maxDisplay
    ) {
      errors.minThres = true;
      retVal = false;
    } else errors.minThres = false;

    if (
      panelData.maxThres < panelData.minDisplay ||
      panelData.maxThres > panelData.maxDisplay
    ) {
      errors.maxThres = true;
      retVal = false;
    } else errors.maxThres = false;

    if (panelData.unit.length <= 0) {
      errors.unit = true;
      retVal = false;
    } else errors.unit = false;

    setErrors({ ...errors });

    return retVal;
  }, [
    error,
    panelData.maxDisplay,
    panelData.maxThres,
    panelData.minDisplay,
    panelData.minThres,
    panelData.unit.length
  ]);

  const handleOnChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    //validate
    const id = e.target.id;
    const val =
      id === "unit"
        ? e.target.value
        : id === "gradientEnabled"
        ? e.target.checked
        : Number(e.target.value);

    setPanelData((panel) => ({
      ...panel,
      [id]: val
    }));
  };

  useEffect(() => {
    if (isValid()) {
      onChangeHandler(panelData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelData]);

  return (
    <Formik initialValues={{ ...panelData }} onSubmit={handleSubmit}>
      <Form className="flex flex-col flex-1" onChange={handleOnChange}>
        <div className="flex flex-row mt-2">
          <div className="mb-5">
            <label className="text-sm font-medium text-gray-600">
              Minimun Display
            </label>
            <Field
              type="number"
              id="minDisplay"
              name="minDisplay"
              placeholder="Minimum Display"
              autoComplete="off"
              value={panelData.minDisplay}
              className={`block w-11/12 p-3 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                error["minDisplay"]
                  ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                  : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
              }`}
            />
          </div>

          <div className="mb-5">
            <label className="text-sm font-medium text-gray-600">
              Maximum Display
            </label>
            <Field
              type="number"
              id="maxDisplay"
              name="maxDisplay"
              placeholder="Maximum Display"
              autoComplete="off"
              value={panelData.maxDisplay}
              className={`block w-11/12 p-3 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                error["maxDisplay"]
                  ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                  : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
              }`}
            />
          </div>
        </div>

        <div className="flex flex-row">
          <div className="mb-5">
            <label className="text-sm font-medium text-gray-600">
              Minimun Threshold
            </label>
            <Field
              type="number"
              id="minThres"
              name="minThres"
              placeholder="Minimum Threshold"
              autoComplete="off"
              value={panelData.minThres}
              className={`block w-11/12 p-3 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                error["minThres"]
                  ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                  : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
              }`}
            />
          </div>

          <div className="mb-5">
            <label className="text-sm font-medium text-gray-600">
              Maximum Threshold
            </label>
            <Field
              type="number"
              id="maxThres"
              name="maxThres"
              placeholder="Maximum Threshold"
              autoComplete="off"
              value={panelData.maxThres}
              className={`block w-11/12 p-3 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                error["maxThres"]
                  ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                  : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
              }`}
            />
          </div>
        </div>
        <div className="flex flex-row">
          <div className="mb-5">
            <label className="text-sm font-medium text-gray-600">Unit</label>
            <Field
              type="text"
              id="unit"
              name="unit"
              placeholder="Unit"
              autoComplete="off"
              value={panelData.unit}
              className={`block w-11/12 p-3 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                error["unit"]
                  ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                  : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
              }`}
            />
          </div>
          <div className="flex flex-row items-center mt-2">
            <Field
              type="checkbox"
              id="gradientEnabled"
              name="gradientEnabled"
              autoComplete="off"
              checked={panelData.gradientEnabled}
              className="block p-3 my-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
            />
            <label className="text-sm font-medium text-gray-600 ml-3">
              Enable Gradient
            </label>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
