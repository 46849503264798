import { ClockIcon } from "@heroicons/react/24/outline";
import React from "react";

export enum DatapointDefinitionTabs {
  DETAILS = "details",
  VERSIONS = "versions",
  USAGE = "usage",
  RETENTION = "retention"
}

const iconsMap = {
  [DatapointDefinitionTabs.DETAILS]: (activeTab) => (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 14.625C3.5 14.1277 3.69754 13.6508 4.04917 13.2992C4.40081 12.9475 4.87772 12.75 5.375 12.75H15.5"
        stroke={
          activeTab === DatapointDefinitionTabs.DETAILS ? "#546CCC" : "#565759"
        }
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.375 1.5H15.5V16.5H5.375C4.87772 16.5 4.40081 16.3025 4.04917 15.9508C3.69754 15.5992 3.5 15.1223 3.5 14.625V3.375C3.5 2.87772 3.69754 2.40081 4.04917 2.04917C4.40081 1.69754 4.87772 1.5 5.375 1.5V1.5Z"
        stroke={
          activeTab === DatapointDefinitionTabs.DETAILS ? "#546CCC" : "#565759"
        }
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  [DatapointDefinitionTabs.VERSIONS]: (activeTab) => (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5599 10.2372L16.4614 10.7779C16.5171 10.8112 16.5631 10.8584 16.5951 10.9147C16.627 10.9711 16.6438 11.0349 16.6438 11.0997C16.6438 11.1645 16.627 11.2282 16.5951 11.2846C16.5631 11.341 16.5171 11.3881 16.4614 11.4214L9.49769 15.5997L2.53394 11.4214C2.47833 11.3881 2.43229 11.341 2.40033 11.2846C2.36836 11.2282 2.35156 11.1645 2.35156 11.0997C2.35156 11.0349 2.36836 10.9711 2.40033 10.9147C2.43229 10.8584 2.47833 10.8112 2.53394 10.7779L3.43544 10.2372L9.49769 13.8747L15.5599 10.2372ZM9.88319 3.34392L16.4614 7.29042C16.5171 7.32372 16.5631 7.37086 16.5951 7.42725C16.627 7.48364 16.6438 7.54735 16.6438 7.61217C16.6438 7.67699 16.627 7.74071 16.5951 7.7971C16.5631 7.85349 16.5171 7.90063 16.4614 7.93392L9.49769 12.1122L2.53394 7.93392C2.47833 7.90063 2.43229 7.85349 2.40033 7.7971C2.36836 7.74071 2.35156 7.67699 2.35156 7.61217C2.35156 7.54735 2.36836 7.48364 2.40033 7.42725C2.43229 7.37086 2.47833 7.32372 2.53394 7.29042L9.11144 3.34392C9.22809 3.27384 9.36161 3.23682 9.49769 3.23682C9.63378 3.23682 9.76655 3.27384 9.88319 3.34392ZM9.49769 4.86117L4.91294 7.61217L9.49769 10.3632L14.0824 7.61217L9.49769 4.86117Z"
        fill={
          activeTab === DatapointDefinitionTabs.VERSIONS
            ? "#546CCC"
            : "#565759"
        }
      />
    </svg>
  ),
  [DatapointDefinitionTabs.USAGE]: (activeTab) => (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25 13.5H3.5V7.5H7.25V13.5ZM5.75 12V9H5V12H5.75ZM10.25 12V6H9.5V12H10.25ZM11.75 13.5H8V4.5H11.75V13.5ZM14.75 12V3H14V12H14.75ZM16.25 13.5H12.5V1.5H16.25V13.5ZM17 16.5H2.75V15H17V16.5Z"
        fill={
          activeTab === DatapointDefinitionTabs.USAGE ? "#546CCC" : "#565759"
        }
      />
    </svg>
  ),
  [DatapointDefinitionTabs.RETENTION]: (activeTab) => (
    <ClockIcon
      className={`${
        activeTab === DatapointDefinitionTabs.RETENTION
          ? "text-[#546CCC]"
          : "text-[#565759]"
      }`}
      width={16}
    />
  )
};

interface IDatapointDefinitionDetailsTabs {
  activeTab: DatapointDefinitionTabs;
  setActiveTab: (tab: DatapointDefinitionTabs) => void;
}

const ShadowDefinitionDetailsTabs: React.FC<
  IDatapointDefinitionDetailsTabs
> = ({ activeTab, setActiveTab }) => {
  return (
    <div className="bg-background border-b border-background-layer3">
      <div className=" md:grid md:gap-4 md:grid-cols-5 ">
        {(
          Object.keys(DatapointDefinitionTabs) as Array<
            keyof typeof DatapointDefinitionTabs
          >
        ).map((key) => (
          <button
            onClick={() => setActiveTab(DatapointDefinitionTabs[key])}
            className={`${
              activeTab === DatapointDefinitionTabs[key]
                ? "text-primary border-b-2 border-primary"
                : ""
            } flex items-center px-2 py-5 justify-center -mb-px text-center text-contentColorLight lg:px-4 whitespace-nowrap`}
          >
            {
              <div className="flex flex-row justify-between items-center">
                {iconsMap[DatapointDefinitionTabs[key]](activeTab)}

                <span
                  className={`${
                    activeTab === DatapointDefinitionTabs[key] &&
                    "text-primborder-primary font-medium"
                  } text-sm ml-2`}
                >
                  {DatapointDefinitionTabs[key].toUpperCase()}
                </span>
              </div>
            }
          </button>
        ))}
      </div>
    </div>
  );
};

export default ShadowDefinitionDetailsTabs;
