import Editor from "@monaco-editor/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { loggerService } from "../../../services";
import { useAuthStore } from "../../../store";
import useFleetAndDevicesStore from "../../../store/fleet-and-devices/fleet-and-devices.store";
import useShadowStore from "../../../store/shadow/shadow.store";
import { FieldError } from "../../shared/components";
import {
  copyToClipboard,
  pasteFromClipboard
} from "../../shared/utils/helper.util";
import DeviceCreationSteps from "../components/device-creation-steps.component";
import SuggestionPanel from "../components/fad-suggestion-panel.component";
import { string, object } from "yup";
import { IDataPolicy } from "@interfaces/shared.interface";
import { useGetDeviceDataPolicies } from "@app/shared/hooks/get/device-data-policies";
import { useGetDataPointDefinitions } from "@app/shared/hooks/get";
import { useCreateDataPointDefinition } from "@app/shared/hooks/post/create-data-point-definition";
import { useLinkDataPoint } from "@app/shared/hooks/post/link-data-point";
import { useUnlinkDataPoint } from "@app/shared/hooks/delete/unlink-data-point";
import { useGetDeviceDataPoints } from "@app/shared/hooks/get/device-data-points";
import useThemeStore from "@store/theme.store";

const defaultDatapoint = `/*
*
* The following is an example of a datapoint definition.
* Here we create a root message called battery and define its fields.
* The root message can have nested messages and fields.
* 
* Battery has a sub message called cell, which is defined above it.
* We also have an enum called battery_state, which is used in the battery message.
*
* Uncomment by using Ctrl + / or Cmd + / on Mac
* 
* Note: The root message name, fields, and enums should be unique.
* 
**/

// syntax="proto3";
//
// enum battery_state{
//     UNKNOWN = 0;
//     CHARGING = 1;
//     DISCHARGING = 2;
//     CHARGED = 3;
//     DISCHARGED = 4;
//     OVERCHARGED = 5;
//     OVERDISCHARGED = 6;
//     OVERTEMPERATURE = 7;
//     OVERCURRENT = 8;
//     OVERVOLTAGE = 9;
//     UNDERVOLTAGE = 10;
//     UNDERTEMPERATURE = 11;
//     UNDERCURRENT = 12;
//     FAULT = 13;
// }

// message cell{
//     float voltage = 1;
//     float in_current = 2;
//     float out_current = 3;
//     float temperature = 4;
// }

// message battery{
//     float nominal_voltage = 1;
//     float nominal_capacity = 2;
//     float percentage_capacity = 3;
//     uint32 num_cells = 4;
//     repeated cell cells = 5;
//     float voltage = 6;
//     float in_current = 7;
//     float out_current = 8;
//     battery_state state = 9;
// }
`;
const newDatapointDefinitionSchema = object().shape({
  datapointName: string().required("Please enter data-point name."),
  rootProtoMsgName: string().required("Please enter message name.")
});

function ApplyDataPoints() {
  /* Hooks And Hooks Variables */
  const navigate = useNavigate();

  const [updateAuthUser] = useAuthStore((state) => [state.updateAuthUser]);
  const [datapoint, setMaximizeDatapoint, setDatapoint] = useShadowStore(
    (state) => [
      state.datapoint,
      state.setMaximizeDatapoint,
      state.setDatapoint
    ]
  );
  const [selectedFleet, deviceCreation] = useFleetAndDevicesStore((state) => [
    state.selectedFleet,
    state.deviceCreation
  ]);

  /* State Variables */
  const [createDatapoint, setCreateDatapoint] = useState(false);
  const [datapolicy, setDataPolicy] = useState<IDataPolicy | undefined>();
  const [selectedDatapoint, setSelectedDatapoint] = useState<
    { value: string; label: string }[]
  >([]);
  const [customDatapoint, setCustomDatapoint] = useState(defaultDatapoint);
  const [errorMsgs, setErrorMsgs] = useState({
    predefinedDatapoint: "",
    customDatapoint: ""
  });

  const [theme] = useThemeStore((state) => [state.theme]);

  useGetDeviceDataPolicies(selectedFleet.id, {}, (dp) => {
    setDataPolicy(dp[0]);
  });

  const { data: predefinedDatapoint } = useGetDataPointDefinitions();

  const { data: deviceDataPoints } = useGetDeviceDataPoints();

  useEffect(() => {
    updateAuthUser({ deviceCreationStatus: "2" });
  }, [updateAuthUser]);

  useEffect(() => {
    if (datapoint) {
      setCustomDatapoint(datapoint);
    }
  }, [datapoint]);

  const handleMaximizeEditor = () => {
    setDatapoint(customDatapoint);
    setMaximizeDatapoint({ state: true, readOnly: false });
  };

  const dataPointDefinitionMutation = useCreateDataPointDefinition();

  const submitCustomDatapoint = async (
    values: {
      datapointName: string;
      rootProtoMsgName: string;
    },
    { resetForm }
  ) => {
    if (!customDatapoint) {
      setErrorMsgs({
        ...errorMsgs,
        customDatapoint: "Please define data-point"
      });
    } else {
      setErrorMsgs({ ...errorMsgs, customDatapoint: "" });
    }

    if (predefinedDatapoint?.some((dp) => dp.name === values.datapointName)) {
      return toast.error(
        "Data Point Definition with this name already exists!"
      );
    }

    const file = new File([customDatapoint], "datap.proto", {
      type: "text/plain"
    });

    const formData = new FormData();
    formData.append("protoFile", file);
    formData.append("name", values.datapointName);
    formData.append("message_name", values.rootProtoMsgName);

    dataPointDefinitionMutation.mutate(formData, {
      onSuccess: async () => {
        toast.success(
          "Created new data point definition: " + values.datapointName
        );
        setCustomDatapoint(defaultDatapoint);
        resetForm();
      }
    });

    // setPredefinedDatapoint([
    //   ...predefinedDatapoint,
    //   {
    //     id: datapointResponse.data.dataPointDefinitionId,
    //     name: values.datapointName
    //   }
    // ]);

    setCreateDatapoint(false);
  };

  const handleDatapointEditor = (value: string) => {
    setCustomDatapoint(value);
  };

  const linkDPMutation = useLinkDataPoint(
    selectedFleet.id,
    deviceCreation.newly_created_device_id
  );

  const unlinkDPMutation = useUnlinkDataPoint(
    selectedFleet.id,
    deviceCreation.newly_created_device_id
  );

  const handleDatapointSelect = async (
    datapoint: { value: string; label: string }[]
  ) => {
    if (errorMsgs.predefinedDatapoint) {
      setErrorMsgs({ ...errorMsgs, predefinedDatapoint: "" });
    }

    try {
      if (deviceCreation?.newly_created_device_id) {
        if (selectedDatapoint.length < datapoint.length) {
          const newlyAdded = [...datapoint].pop();

          const payload = {
            data_point_name: newlyAdded.label,
            device_data_policy_id: datapolicy.id,
            data_point_definition_id: newlyAdded.value,
            active: true
          };

          linkDPMutation.mutate(payload);
        } else if (selectedDatapoint.length > datapoint.length) {
          const tempDP = selectedDatapoint.filter(
            ({ value: id1 }) =>
              !datapoint.some(({ value: id2 }) => id2 === id1)
          );

          const ddp = deviceDataPoints?.find(
            (ddp) =>
              ddp.data_point_definition_id === tempDP[0].value &&
              ddp.device_id === deviceCreation?.newly_created_device_id
          );

          if (!ddp) {
            toast.error("Could not find Device Data Point ID");
            return;
          }

          unlinkDPMutation.mutate(ddp.id);
        }
      }
    } catch (error) {
      loggerService.error(error);
    }

    setSelectedDatapoint([...datapoint]);
  };

  const handleClickNext = () => {
    if (selectedDatapoint.length) {
      navigate("/fleet-and-devices/projects/download-certificates/");
    }
  };

  const downloadFile = () => {
    const filename = document.getElementById("datapointName")["value"];

    if (filename) {
      const url = window.URL.createObjectURL(
        new Blob([customDatapoint], { type: "text/plain" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}.proto`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      toast.warning("To download please enter data-point name.");
    }
  };

  const options = useMemo(() => {
    return (
      predefinedDatapoint?.map(({ name, id }) => {
        return { value: id, label: name };
      }) || []
    );
  }, [predefinedDatapoint]);

  return (
    <>
      <div className="flex w-full h-full">
        <div className="w-5/12 pb-8">
          <div className="mt-7 mb-5 w-10/12">
            <DeviceCreationSteps />
          </div>

          <div>
            <h1 className="text-lg text-left font-medium mb-2.5">
              Apply Data-points
            </h1>

            <Formik
              initialValues={{ datapointName: "", rootProtoMsgName: "" }}
              validationSchema={newDatapointDefinitionSchema}
              onSubmit={submitCustomDatapoint}
            >
              <Form>
                {/* Conditional Render For Predefined Data-points or data-point name */}
                {createDatapoint ? (
                  <>
                    <div className="form-group mb-5 w-10/12">
                      <label className="flex font-medium text-sm mb-2">
                        Data-point Name
                      </label>
                      <Field
                        type="text"
                        id="datapointName"
                        name="datapointName"
                        placeholder="Data-point Name"
                        className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                      />
                      <ErrorMessage name="datapointName">
                        {(msg) => <FieldError message={msg} />}
                      </ErrorMessage>
                    </div>

                    <div className="form-group mb-5 w-10/12">
                      <label className="flex font-medium text-sm mb-2">
                        Root proto message name
                      </label>
                      <Field
                        type="text"
                        id="rootProtoMsgName"
                        name="rootProtoMsgName"
                        placeholder="Root proto message name"
                        className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                      />
                      <ErrorMessage name="rootProtoMsgName">
                        {(msg) => <FieldError message={msg} />}
                      </ErrorMessage>
                    </div>
                  </>
                ) : (
                  <div className="form-group mb-5 w-10/12">
                    <label className="flex font-medium text-sm mb-2">
                      Select Data-point
                    </label>
                    <Select
                      isDisabled={createDatapoint}
                      placeholder="Select"
                      isSearchable={false}
                      options={options}
                      value={selectedDatapoint}
                      onChange={handleDatapointSelect}
                      isClearable={false}
                      isMulti
                      classNames={{
                        menu: () => "!bg-background-layer1 !text-contentColor",
                        control: () =>
                          "!bg-background !text-contentColor !border-background-layer3 !rounded-md focus:!ring focus:!ring-opacity-40 focus:!ring-primary focus:!border-primaryLight sm:!text-sm",
                        valueContainer: () => "!text-contentColor",
                        singleValue: () => "!text-contentColor",
                        menuList: () => "!text-contentColor",
                        option: () =>
                          "!text-contentColor hover:!bg-background-layer2 !bg-background-layer1 !border-background-layer3",
                        noOptionsMessage: () =>
                          "!text-contentColor !bg-background-layer1",
                        multiValue: () =>
                          "!bg-background-layer3 !text-contentColor",
                        multiValueLabel: () => "!text-contentColor"
                      }}
                    />
                    {errorMsgs.predefinedDatapoint ? (
                      <FieldError message={errorMsgs.predefinedDatapoint} />
                    ) : (
                      ""
                    )}
                  </div>
                )}

                {/* Conditional Render for datapoint Def Editor */}
                {createDatapoint && (
                  <div className="form-group mb-5 w-10/12">
                    <label className="flex font-medium text-sm mb-2">
                      Data-point Definition
                    </label>
                    <div className="p-2 bg-[#F7F6F3] rounded-lg border border-solid border-[#D4D4D4] w-min lg:w-full">
                      <div>
                        <div className="flex justify-between items-center">
                          <ul className="flex justify-center items-center">
                            <li className="flex items-center">
                              <button
                                type="button"
                                onClick={() =>
                                  copyToClipboard(customDatapoint)
                                }
                                className="text-xs text-[#565759] flex justify-center items-center"
                              >
                                Copy
                              </button>
                              <div className="mx-3.5 h-3 border border-solid border-[#E8E8E8]"></div>
                            </li>

                            <li className="flex items-center">
                              <button
                                type="button"
                                onClick={() =>
                                  pasteFromClipboard().then((res) =>
                                    setCustomDatapoint(res)
                                  )
                                }
                                className="text-xs text-[#565759] flex justify-center items-center"
                              >
                                Paste
                              </button>
                              <div className="mx-3.5 h-3 border border-solid border-[#E8E8E8]"></div>
                            </li>

                            <li className="flex items-center">
                              <button
                                type="button"
                                className="text-xs text-[#565759] flex justify-center items-center"
                              >
                                Upload
                              </button>
                              <div className="mx-3.5 h-3 border border-solid border-[#E8E8E8]"></div>
                            </li>

                            <li className="flex items-center mr-3.5">
                              <button
                                type="button"
                                onClick={downloadFile}
                                className="text-xs text-[#565759] flex justify-center items-center"
                              >
                                Download
                              </button>
                            </li>
                          </ul>

                          <div className="flex justify-center items-center">
                            <button
                              onClick={handleMaximizeEditor}
                              type="button"
                              className="p-1 bg-white rounded"
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.6663 2H14.6663V6H13.333V3.33333H10.6663V2ZM1.33301 2H5.33301V3.33333H2.66634V6H1.33301V2ZM13.333 12.6667V10H14.6663V14H10.6663V12.6667H13.333ZM2.66634 12.6667H5.33301V14H1.33301V10H2.66634V12.6667Z"
                                  fill="#546CCC"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div className="my-2 border border-solid border-[#E8E8E8]"></div>
                      </div>
                      <Editor
                        height="14vh"
                        language="cpp"
                        value={customDatapoint}
                        onChange={handleDatapointEditor}
                        theme={
                          theme === "golain" || theme === "none"
                            ? "vs"
                            : "vs-dark"
                        }
                        options={{
                          lineNumbers: "off",
                          minimap: { enabled: false },
                          scrollbar: { vertical: "hidden" },
                          overviewRulerBorder: false,
                          overviewRulerLanes: 0,
                          folding: false,
                          matchBrackets: "never",
                          theme:
                            theme === "golain" || theme === "none"
                              ? "vs"
                              : "vs-dark"
                        }}
                      />
                    </div>
                    {errorMsgs.customDatapoint ? (
                      <FieldError message={errorMsgs.customDatapoint} />
                    ) : (
                      ""
                    )}
                  </div>
                )}
                <h1 className="flex mb-5 text-sm text-gray-400">
                  {createDatapoint ? "Or Select from" : "Or"} &nbsp;
                  <span
                    className="font-medium text-primary cursor-pointer"
                    onClick={() => setCreateDatapoint(!createDatapoint)}
                  >
                    {createDatapoint
                      ? "Predefined Data-point"
                      : "Create New Data-point"}
                  </span>
                </h1>
                {createDatapoint && (
                  <div>
                    <button
                      type="submit"
                      className="px-5 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
                    >
                      Done
                    </button>
                  </div>
                )}
              </Form>
            </Formik>
          </div>

          <div
            className={`w-10/12 ${
              createDatapoint ? "mt-6 pb-6" : "mt-12"
            } flex items-center space-x-4`}
          >
            <Link
              to="/fleet-and-devices/projects/download-certificates/"
              className="w-1/2 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-background-layer1"
            >
              Skip
            </Link>

            <button
              onClick={handleClickNext}
              disabled={!selectedDatapoint.length}
              className={`w-1/2 ${
                !selectedDatapoint.length
                  ? "disabled:bg-gray-400"
                  : "hover:bg-opacity-80"
              } px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary`}
            >
              Next
            </button>
          </div>
        </div>

        <div className="w-7/12">
          <SuggestionPanel type="datapoint" />
        </div>
      </div>
    </>
  );
}

export default ApplyDataPoints;
