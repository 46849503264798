import moment, { Moment } from "moment-timezone";
import { IOption } from "../interfaces";
import { useAuthStore } from "../store";

export const API_DATE_FORMAT = "YYYY-MM-DD",
  API_TIME_FORMAT = "HH:mm:ss",
  USER_TIME_FORMAT = "HH:mm",
  USER_TIME_FORMAT_12H = "hh:mm A";

class DateService {
  timezones = moment.tz.names();

  private static _instance: DateService;
  static getInstance(): DateService {
    if (!DateService._instance) {
      DateService._instance = new DateService();
    }

    return DateService._instance;
  }

  convertToUTC(dateString: string) {
    if (!dateString) return null;
    return moment(dateString).utc();
  }

  convertToUTC2(dateString: string) {
    if (!dateString) return null;
    return moment.utc(dateString);
  }

  convertToUserTimezone(momentObj: moment.Moment) {
    if (!momentObj) return null;
    return momentObj.tz(this.getUserTimezone());
  }

  convertTo12HFormat(value: string) {
    return moment(value, USER_TIME_FORMAT).format(USER_TIME_FORMAT_12H);
  }

  // convert | UTC timeString <---> timezone timeString
  convertUtcTimeStrToUserTimeStr(
    timeString: string,
    targetFormat: string = USER_TIME_FORMAT
  ) {
    return moment
      .utc(timeString, API_TIME_FORMAT, true)
      .tz(this.getUserTimezone())
      .format(targetFormat);
  }

  convertUserTimeStrToUtcTimeStr(
    timeString: string,
    sourceFormat: string = USER_TIME_FORMAT
  ) {
    return moment
      .tz(timeString, sourceFormat, this.getUserTimezone())
      .utc()
      .format(API_TIME_FORMAT);
  }

  // convert | date obj <---> UTC Date
  convertDateObjToMoment(date: Date | string) {
    return moment(date);
  }

  convertDateObjToISOString(date: Date) {
    return moment(date).toISOString();
  }

  convertUTCDateToUserTimezone(dateString: string) {
    return this.convertToUserTimezone(this.parseUTCDate(dateString));
  }

  convertUTCDateToDateObj(dateString: string) {
    return this.convertUTCDateToUserTimezone(dateString).toDate();
  }

  convertStringToDateObj(
    dateString: string,
    format: string = API_DATE_FORMAT
  ) {
    return moment(dateString, format);
  }

  convertDateObjToUTCDateFormatted(dateObj: Date) {
    return this.convertToUTC(dateObj.toISOString()).format();
  }

  // convert | UTC dateString & timeString ----> Browser format (local format)
  convertUtcDateTimeToBrowserFormat(
    dateString: string,
    timeString: string,
    targetFormat: string
  ) {
    const momentObj = this.parseUTCDateTime(dateString, timeString);
    return momentObj ? momentObj.local().format(targetFormat) : null;
  }

  getTimezones(): Array<IOption> {
    return this.timezones.map((zone) => {
      return {
        value: zone,
        label: this._getTimezoneLabel(zone)
      };
    });
  }

  getCurrentDate(format: string = API_DATE_FORMAT) {
    return moment().format(format);
  }

  getCurrentUTCDate() {
    return moment().utc();
  }

  getDateWithUnit(
    amount: moment.DurationInputArg1,
    unit: moment.unitOfTime.DurationConstructor,
    format: string = API_DATE_FORMAT
  ) {
    return moment().add(amount, unit).format(format);
  }

  getWeekDay(dayOfWeek: number) {
    return moment.weekdaysShort(dayOfWeek);
  }

  getWeekDays(): Array<string> {
    return moment.weekdays();
  }

  getTimeStrFromMoment(dateObj: Moment) {
    if (!dateObj) return;
    return dateObj.format(API_TIME_FORMAT);
  }

  getLocalTimezone() {
    return moment.tz.guess();
  }

  getUserTimezone() {
    const serviceProvider = useAuthStore.getState().user;
    if (serviceProvider && serviceProvider.timeZone) {
      return serviceProvider.timeZone;
    }

    return this.getLocalTimezone();
  }

  getMomentDate() {
    return moment();
  }

  convertDateStrToMoment(
    date: string,
    format: string = API_DATE_FORMAT
  ): moment.Moment {
    return moment(date);
  }

  getUserDateTime() {
    return moment().tz(this.getUserTimezone());
  }

  getStartOfMonth(momentObj: moment.Moment): string {
    return `${momentObj.format("YYYY")}-${momentObj.format("MM")}-01`;
  }

  // Converts a moment date time object to user time zone
  parseUTCDate(dateString: string) {
    return moment.utc(dateString);
  }

  parseUTCDateTime(dateString: string, timeString: string) {
    return moment.utc(dateString).add(moment.duration(timeString));
  }

  parseUTCDateTimeToFormat(
    dateString: string,
    timeString: string,
    format: string
  ) {
    const utcDate = this.parseUTCDateTime(dateString, timeString);
    return utcDate.format(format);
  }

  // Converts give date string & time string, to user time zone and returns a string in the given format
  convertUtcDateTimeToUserTimezone(
    dateStr: string,
    timeStr: string,
    format: string
  ) {
    const utcDate = this.parseUTCDateTime(dateStr, timeStr);
    return this.convertToUserTimezone(utcDate).format(format);
  }

  convertToYYYYMMDDFormat(data) {
    return moment(data).format(API_DATE_FORMAT);
  }

  convertToDDMMYYYYFormat(data) {
    return moment(data).format("DD-MMM-YYYY");
  }

  convertUTCToLocalDate(
    utcDate: string,
    showS: boolean = false,
    showMS: boolean = false
  ) {
    if (moment(utcDate).isSame(moment(), "day")) {
      return moment.utc(utcDate).fromNow();
    } else {
      return moment
        .utc(utcDate)
        .local()
        .format(
          `DD-MM-YYYY HH:mm${showS ? ":ss" : ""}${showMS ? ":SSS" : ""}`
        );
    }
  }

  getDay(date) {
    return moment(date).isoWeekday();
  }

  private _getTimezoneLabel(zone: string) {
    return (
      "UTC" +
      (moment().tz(zone).utcOffset() / 60 < 0 ? "" : "+") +
      Math.floor(moment().tz(zone).utcOffset() / 60) +
      ":" +
      (Math.floor(moment().tz(zone).utcOffset() % 60).toString().length === 2
        ? Math.floor(moment().tz(zone).utcOffset() % 60)
        : Math.floor(moment().tz(zone).utcOffset() % 60).toString() + "0") +
      " " +
      zone
    );
  }

  parseRelativeDate(relativeDate) {
    const regex = /^(-?)(\d+)([dwmy])$/i;
    const match = relativeDate.match(regex);

    if (!match) {
      throw new Error("Invalid relative date format");
    }

    const [, sign, value, unit] = match;
    const momentDate = dateService.getMomentDate();

    switch (unit.toLowerCase()) {
      case "d":
        if (sign === "-") {
          momentDate.subtract(value, "days");
        } else {
          momentDate.add(value, "days");
        }
        break;
      case "w":
        if (sign === "-") {
          momentDate.subtract(value, "weeks");
        } else {
          momentDate.add(value, "weeks");
        }
        break;
      case "m":
        if (sign === "-") {
          momentDate.subtract(value, "months");
        } else {
          momentDate.add(value, "months");
        }
        break;
      case "y":
        if (sign === "-") {
          momentDate.subtract(value, "years");
        } else {
          momentDate.add(value, "years");
        }
        break;
      default:
        throw new Error("Invalid relative date unit");
    }

    return momentDate;
  }
}

const dateService = DateService.getInstance();
export default dateService;
