import { useMemo } from "react";
import { Formik, Form, Field } from "formik";
import useFleetAndDevicesStore from "@store/fleet-and-devices/fleet-and-devices.store";
import { toast } from "react-toastify";
import { IDevice } from "@interfaces/index";
import { copyToClipboard } from "@utils/helper.util";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";

function DetailsTab({ deviceId }) {
  const [devices] = useFleetAndDevicesStore((state) => [state.devices]);

  const handleSubmit = () => {};

  function copyTo(id: string) {
    let c = document.getElementById(id);
    copyToClipboard(c["value"]);
  }

  const selectedDevice: IDevice | undefined = useMemo(() => {
    const foundDevice = devices.find((device) => device.id === deviceId);

    if (!foundDevice) {
      toast.warning("Device not found!!!");
    }

    return foundDevice;
  }, [devices, deviceId]);

  return (
    <>
      {!!selectedDevice && (
        <Formik initialValues={{ deviceId: "" }} onSubmit={handleSubmit}>
          <Form>
            <div>
              <h1 className="text-lg text-left font-medium mb-2.5">
                Device Details
              </h1>

              <div className="flex">
                <div className="w-[396px]">
                  <div className="mb-5">
                    <label className="text-sm font-medium text-contentColorLight">
                      Device ID
                    </label>

                    <div className="flex items-end">
                      <Field
                        type="text"
                        id="deviceId"
                        name="deviceId"
                        value={selectedDevice.id}
                        placeholder="Device ID"
                        className="block w-10/12 p-3 mt-2 bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      />
                      <div>
                        <button
                          type="button"
                          onClick={() => copyTo("deviceId")}
                          className="block ml-3.5 bg-background-layer1 p-3 rounded"
                        >
                          <ClipboardDocumentListIcon
                            className="text-primaryLight"
                            width={20}
                          />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mb-5">
                    <label className="text-sm font-medium text-contentColorLight">
                      Device Name
                    </label>

                    <div className="flex items-end">
                      <Field
                        type="text"
                        id="deviceName"
                        name="deviceName"
                        value={selectedDevice.device_name}
                        placeholder="Device Name"
                        className="block w-10/12 p-3 mt-2 bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      />
                      <div>
                        <button
                          type="button"
                          onClick={() => copyTo("deviceName")}
                          className="block ml-3.5 bg-background-layer1 p-3 rounded"
                        >
                          <ClipboardDocumentListIcon
                            className="text-primaryLight"
                            width={20}
                          />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mb-5">
                    <label className="text-sm font-medium text-contentColorLight">
                      Certificate ID
                    </label>

                    <div className="flex items-end">
                      <Field
                        type="text"
                        id="certificateId"
                        name="certificateId"
                        value={selectedDevice.certificate_id}
                        placeholder="Certificate ID"
                        className="block w-10/12 p-3 mt-2 bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      />
                      <div>
                        <button
                          type="button"
                          onClick={() => copyTo("certificateId")}
                          className="block ml-3.5 bg-background-layer1 p-3 rounded"
                        >
                          <ClipboardDocumentListIcon
                            className="text-primaryLight"
                            width={20}
                          />
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* <div className="w-10/12 form-group mb-5 flex justify-between">
                                    <label className="flex font-medium text-sm mb-2">Connect to IOT hub</label>
                                    <div>
                                        <label className="inline-flex relative items-center cursor-pointer">
                                            <input
                                                type="checkbox"
                                                defaultChecked
                                                className="sr-only peer"
                                            />
                                            <div className="w-11 h-6 bg-gray-200  rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-background-layer3 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#0AB63B]"></div>
                                            <span className="ml-3 text-sm font-medium">Active</span>
                                        </label>
                                    </div>
                                </div> */}
                </div>
                <div className="mx-7"></div>
                <div className="w-[396px]">
                  <div className="mb-5">
                    <label className="text-sm font-medium text-contentColorLight">
                      Topics Slug
                    </label>

                    <div className="flex items-end">
                      <Field
                        type="text"
                        id="topicsSlug"
                        name="topicsSlug"
                        value={selectedDevice.topic_slug}
                        placeholder="Topics Slug"
                        className="block w-10/12 p-3 mt-2 bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      />
                      <div>
                        <button
                          type="button"
                          onClick={() => copyTo("topicsSlug")}
                          className="block ml-3.5 bg-background-layer1 p-3 rounded"
                        >
                          <ClipboardDocumentListIcon
                            className="text-primaryLight"
                            width={20}
                          />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mb-5">
                    <label className="text-sm font-medium text-contentColorLight">
                      Created At
                    </label>

                    <div className="flex items-end">
                      <Field
                        type="text"
                        id="createdAt"
                        name="createdAt"
                        value={selectedDevice.created_at}
                        placeholder="Created At"
                        className="block w-10/12 p-3 mt-2 bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      />
                      <div>
                        <button
                          type="button"
                          onClick={() => copyTo("createdAt")}
                          className="block ml-3.5 bg-background-layer1 p-3 rounded"
                        >
                          <ClipboardDocumentListIcon
                            className="text-primaryLight"
                            width={20}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className='mt-3'>
                        <button
                            type='button'
                            className="inline-block px-16 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
                        >
                            Modify
                        </button>
                    </div> */}
          </Form>
        </Formik>
      )}
    </>
  );
}

export default DetailsTab;
