import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import DetailsTab from "./device-details/fad-details-tab.component";
import DeviceDetailsTabs from "../components/fad-device-details-tabs.component";
import DataPointsTab from "./device-details/fad-data-points.component";
import DeviceShadowTab from "./device-details/fad-device-shadow.component";
import DeviceDashboard from "./device-details/fad-device-dashboard.component";
import { useFleetAndDevicesStore } from "@store/index";
import { useGetDevices } from "@app/shared/hooks/get/devices";
import ShowLoading from "@app/shared/components/loading.component";

const tab_map = {
  details: 0,
  "device-shadow": 1,
  "data-points": 2,
  dashboard: 3
};

const DeviceDetails = () => {
  const [searchParams] = useSearchParams();
  const deviceId = searchParams.get("code");
  const tab = searchParams.get("tab");

  const [setDevices] = useFleetAndDevicesStore((state) => [state.setDevices]);

  const { isLoading, isFetching } = useGetDevices({}, (devices) => {
    setDevices(devices);
  });

  /* State Variables */
  const [activeTab, setActiveTab] = useState(tab ? tab_map[tab] : 0);

  const tabs = {
    0: <DetailsTab deviceId={deviceId} />,
    1: <DeviceShadowTab deviceId={deviceId} />,
    2: <DataPointsTab deviceId={deviceId} />,
    3: <DeviceDashboard deviceId={deviceId} />
  };

  if (isLoading || isFetching) {
    return <ShowLoading />;
  }

  return (
    <>
      <DeviceDetailsTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <>{tabs[activeTab]}</>
    </>
  );
};

export default DeviceDetails;

// fad device details
