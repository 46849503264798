import { useMemo } from "react";
import { Form, Formik, Field } from "formik";
import useOTAReleaseStore from "../../../../store/ota/ota-release.store";
import { toast } from "react-toastify";
import { IReleaseProps } from "../../../../interfaces";
import { useDeleteDeployments } from "@app/shared/hooks/delete/delete-deployment";
import { useConfirmDelete } from "@app/shared/hooks/use-confirm-delete.hooks";

interface IDeploymentProps {
  deployment_name: string;
  max_retries: string;
  retry_interval: string;
  target_fleets: string;
}
interface IDeployment {
  id: string;
  ota_release_id: string;
  initiated: boolean;
  scheduled_at: string;
  project_id: string;
  org_id: string;
  created_at: string;
  props: IDeploymentProps;
}
interface IIndividualStatusTabProps {
  deploymentId: string;
}

const Overview = (props: IIndividualStatusTabProps) => {
  const { deploymentId } = props;
  const [deploymentList, releaseList] = useOTAReleaseStore((state) => [
    state.deploymentList,
    state.releaseList
  ]);

  const handleFormSubmit = async () => {};

  const { openConfirmDeleteModal } = useConfirmDelete();

  const deleteDeploymentMutation = useDeleteDeployments();

  const deleteDeployment = async () => {
    openConfirmDeleteModal(
      () => deleteDeploymentMutation.mutate(deploymentId),
      "Are you sure you want to delete this deployment?"
    );
  };

  const selectedDeployment: IDeployment | undefined = useMemo(() => {
    const foundDeployment = deploymentList.filter(
      (deployment) => deployment.id === deploymentId
    );

    if (foundDeployment.length) {
      return foundDeployment[0];
    } else {
      toast.warning("Device not found!!!");
      return undefined;
    }
  }, [deploymentId, deploymentList]);

  const selectedDeploymentRelease: IReleaseProps | undefined = useMemo(() => {
    const foundRelease = releaseList.filter(
      (release) => release.id === selectedDeployment?.ota_release_id
    );

    if (foundRelease.length) {
      return foundRelease[0].props;
    } else {
      toast.warning("Release not found!!!");
      return undefined;
    }
  }, [selectedDeployment, releaseList]);

  return (
    <div className="flex w-full">
      {!!selectedDeployment && (
        <Formik initialValues={{ name: "" }} onSubmit={handleFormSubmit}>
          <Form className="w-full flex">
            <div className="w-5/12 pb-8">
              <div className="w-10/12 flex flex-col flex-1">
                <h1 className="text-lg text-left font-medium mb-2.5">
                  General
                </h1>
                <div className="flex-1">
                  <div className="flex-1">
                    <div className="mb-5">
                      <label className="text-sm font-medium text-contentColorLight">
                        Release
                      </label>

                      <Field
                        type="text"
                        id="release"
                        name="release"
                        placeholder="Release"
                        value={selectedDeploymentRelease.release_name}
                        className="block w-full p-3 mt-2 border-background-layer3 bg-background text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      />
                      {/* {inputFieldErrors.deviceName ? <FieldError message={inputFieldErrors.deviceName} /> : null} */}
                    </div>

                    <div className="mb-5">
                      <label className="text-sm font-medium text-contentColorLight">
                        Device Type
                      </label>

                      <Field
                        type="text"
                        id="deviceType"
                        name="deviceType"
                        placeholder="N/A"
                        value={selectedDeploymentRelease.device_types}
                        className="block w-full p-3 mt-2 border-background-layer3 bg-background text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      />
                    </div>

                    <div className="mb-5">
                      <label className="text-sm font-medium text-contentColorLight">
                        Device Group
                      </label>

                      <Field
                        type="text"
                        id="deviceGroup"
                        name="deviceGroup"
                        placeholder="Target Fleet/s"
                        value={selectedDeployment.props.target_fleets}
                        className="block w-full p-3 mt-2 border-background-layer3 bg-background text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      />
                    </div>

                    <div className="mb-5">
                      <label className="text-sm font-medium text-contentColorLight">
                        No. of retry
                      </label>

                      <Field
                        type="text"
                        id="retry"
                        name="retry"
                        placeholder="No. of retry"
                        value={selectedDeployment.props.max_retries}
                        className="block w-full p-3 mt-2 border-background-layer3 bg-background text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="w-full flex justify-between mt-3">
                    {/* <button
                                        type='button'
                                        className="w-full mr-3 inline-block px-16 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
                                    >
                                        Edit Details
                                    </button> */}

                    <button
                      type="button"
                      onClick={deleteDeployment}
                      className="w-full ml-3 px-8 py-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-red-500 border-red-500 hover:bg-background-layer1"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-3"></div>
            <div className="w-5/12 pb-8">
              <div className="w-10/12 flex flex-col flex-1">
                <h1 className="text-lg text-left font-medium mb-2.5">
                  Schedule
                </h1>
                <div className="flex-1">
                  <div className="mb-5">
                    <label className="text-sm font-medium text-contentColorLight">
                      Created at
                    </label>

                    <Field
                      type="text"
                      id="createdAt"
                      name="createdAt"
                      placeholder="Created at"
                      value={selectedDeployment.created_at}
                      className="block w-full p-3 mt-2 border-background-layer3 bg-background text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                    />
                  </div>

                  <div className="mb-5">
                    <label className="text-sm font-medium text-contentColorLight">
                      Start time
                    </label>

                    <Field
                      type="text"
                      id="startTime"
                      name="startTime"
                      placeholder="Start time"
                      value={selectedDeployment.scheduled_at}
                      className="block w-full p-3 mt-2 border-background-layer3 bg-background text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                    />
                  </div>

                  <div className="mb-5">
                    <label className="text-sm font-medium text-contentColorLight">
                      End time
                    </label>

                    <Field
                      type="text"
                      id="endTime"
                      name="endTime"
                      placeholder="End time"
                      value={selectedDeployment.scheduled_at}
                      className="block w-full p-3 mt-2 border-background-layer3 bg-background text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                    />
                  </div>

                  <div className="mb-5">
                    <label className="text-sm font-medium text-contentColorLight">
                      Rollout pattern
                    </label>

                    <Field
                      type="text"
                      id="rollPattern"
                      name="rollPattern"
                      placeholder="N/A"
                      className="block w-full p-3 mt-2 border-background-layer3 bg-background text-contentColor rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default Overview;
