import { Icon, LatLngExpression } from "leaflet";
import { Marker, Popup, Tooltip } from "react-leaflet";
import { ILocation } from "../../../../interfaces";
import { Link } from "react-router-dom";
import { MapPinIcon } from "@heroicons/react/24/outline";
import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { loggerService } from "@services/logger";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import dockIcon from "@assets/icons/Metro-M.svg";
import dockIconLight from "@assets/icons/Metro-M-light.svg";
import useThemeStore from "@store/theme.store";

const ICONS_MAP = {
  NO_RIDE: "STOP",
  REQUESTED: "STOP",
  ACCEPTED: "RUNNING",
  ACTIVE: "RUNNING",
  PAUSED: "RUNNING",
  END_REQUESTED: "MAINTENANCE"
};

// const ICON_URLS = {
//   STOP: "https://bacrialm.sirv.com/icons/bike-stop.png",
//   RUNNING: "https://bacrialm.sirv.com/icons/bike-running.png",
//   MAINTENANCE: "https://bacrialm.sirv.com/icons/bike-maintenance.png"
// };

const ICON_URLS = {
  STOP: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
  RUNNING:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
  MAINTENANCE:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-gold.png",
  DOCK: dockIcon,
  DOCK_LIGHT: dockIconLight
};

const LocationMarker: React.FC<ILocation> = ({
  device_id,
  fleet_id,
  device_name,
  location,
  shadow,
  shadowsRef
}) => {
  const position = {
    lat: location.lat,
    lng: location.lgt
  } as LatLngExpression;
  const [shadowState, setShadowState] = useState(shadow);
  const [user] = useAuthStore((state) => [state.user]);
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const theme = useThemeStore((state) => state.theme);

  useEffect(() => {
    setShadowState(shadow);
  }, [shadow]);

  const onStatusChange = (key, value) => {
    networkService
      .post<any>(
        `projects/${selectedProject.id}/fleets/${fleet_id}/devices/${device_id}/shadow`,
        { shadow: { ...shadowState, [key]: value } },
        {
          headers: {
            "ORG-ID": user.selectedOrg.id
          }
        }
      )
      .then((res) => {
        if (res.ok) {
          shadowsRef.current = {
            ...shadowsRef.current,
            [device_id]: { ...shadowState, [key]: value }
          };
          setShadowState({ ...shadowState, [key]: value });
          toast.success(`${key} updated successfully`);
        }
      })
      .catch((err) => {
        loggerService.error(err);
      });
  };

  const isDock = fleet_id === "8ca69880-a313-4ea0-abc0-a5ebda31858e";

  return (
    <Marker
      position={position}
      key={device_id}
      icon={
        new Icon({
          iconUrl: isDock
            ? theme === "dark-default"
              ? ICON_URLS.DOCK_LIGHT
              : ICON_URLS.DOCK
            : shadowState
            ? ICON_URLS[ICONS_MAP[shadowState.rideStatus ?? "NO_RIDE"]]
            : ICON_URLS.STOP,
          iconSize: [isDock ? 70 : 19, isDock ? 70 : 31],
          iconAnchor: [isDock ? 20 : 12, 41],
          popupAnchor: [isDock ? 15 : 1, -34],
          tooltipAnchor: [isDock ? 14 : -5, isDock ? 30 : -10],
          shadowAnchor: [16, 50],
          shadowUrl: isDock
            ? undefined
            : "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
          shadowSize: [41, 41]
        })
      }
    >
      <Popup className="">
        <div className="flex flex-col gap-1 ">
          <div className="text-base font-medium ">{device_name}</div>
          <div className="flex gap-1 items-center">
            <MapPinIcon width={18} />
            <div>
              {location.lat.toFixed(6)}, {location.lgt.toFixed(6)}
            </div>
          </div>
          <div className="text-sm text-contentColorLight flex flex-col gap-1 mt-1">
            <span className="font-medium">Ride Status:</span>
            {
              <RideStatusSelect
                value={shadowState?.rideStatus ?? "NO_RIDE"}
                onChange={(val) => onStatusChange("rideStatus", val)}
              />
            }
          </div>
          <div className="text-sm text-contentColorLight flex flex-col gap-1 mt-1">
            <span className="font-medium">Lock Status:</span>
            {
              <LockStatusSelect
                value={shadowState?.lockStatus ?? "LOCK_DISABLED"}
                onChange={(val) => onStatusChange("lockStatus", val)}
              />
            }
          </div>
          <Link
            to={`/fleet-and-devices/projects/device-details?code=${device_id}`}
            className="!text-white mt-2 w-full px-2 py-1 rounded-md bg-blue-500 hover:bg-blue-600 text-center text-sm"
          >
            View Device
          </Link>
        </div>
      </Popup>
      <Tooltip direction="bottom" opacity={1}>
        {device_name}
      </Tooltip>
    </Marker>
  );
};

const RideStatusSelect = ({ value, onChange }) => {
  const options = [
    { value: "NO_RIDE", label: "No Ride" },
    { value: "REQUESTED", label: "Requested" },
    { value: "ACCEPTED", label: "Accepted" },
    { value: "ACTIVE", label: "Active" },
    { value: "PAUSED", label: "Paused" },
    { value: "END_REQUESTED", label: "End Requested" }
  ];

  return (
    <select
      value={value ?? "NO_RIDE"}
      onChange={(e) => onChange(e.target.value)}
      className="block bg-background-layer1 w-full py-1 px-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm border-background-layer2 focus:ring-primary focus:border-primaryLight"
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

const LockStatusSelect = ({ value, onChange }) => {
  const options = [
    { value: "LOCK_DISABLED", label: "Lock Disabled" },
    { value: "LOCKED", label: "Locked" },
    { value: "UNLOCK_REQUESTED", label: "Unlock Requested" },
    { value: "UNLOCKED", label: "Unlocked" }
  ];

  return (
    <select
      value={value ?? "LOCK_DISABLED"}
      onChange={(e) => onChange(e.target.value)}
      className="block bg-background-layer1 w-full py-1 px-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm border-background-layer2 focus:ring-primary focus:border-primaryLight"
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default LocationMarker;
