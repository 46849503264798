/* global window */
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import maplibregl from "!maplibre-gl";
import maplibreglWorker from "maplibre-gl/dist/maplibre-gl-csp-worker";

import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { Map } from "react-map-gl";
import {
  AmbientLight,
  PointLight,
  LightingEffect,
  MapView
} from "@deck.gl/core/typed";
import DeckGL from "@deck.gl/react/typed";
import { PolygonLayer } from "@deck.gl/layers/typed";
import { TripsLayer } from "@deck.gl/geo-layers";
import moment from "moment";
import { Button } from "@tremor/react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

maplibregl.workerClass = maplibreglWorker;

// Source data CSV
const DATA_URL = {
  BUILDINGS:
    "https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/trips/buildings.json", // eslint-disable-line
  TRIPS:
    "https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/trips/trips-v7.json" // eslint-disable-line
};

const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 1.0
});

const pointLight = new PointLight({
  color: [255, 255, 255],
  intensity: 2.0,
  position: [73.74498938125106, 18.641493586040696, 200]
});

const lightingEffect = new LightingEffect({ ambientLight, pointLight });

const material = {
  ambient: 1,
  diffuse: 1,
  shininess: 32,
  specularColor: [60, 64, 70]
};

const DEFAULT_THEME = {
  buildingColor: [74, 80, 87],
  trailColor0: [253, 128, 93],
  trailColor1: [23, 184, 190],
  material,
  effects: [lightingEffect]
};

const INITIAL_VIEW_STATE = {
  latitude: 18.641493586040696,
  longitude: 73.74498938125106,
  zoom: 13,
  pitch: 45,
  bearing: 0
};

const MAP_STYLE =
  "https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json";

const landCover = [
  [
    [18.641493586040696, 73.74498938125106],
    [18.483084764728673, 73.74498938125106],
    [18.483084764728673, 73.96061501419484],
    [18.641493586040696, 73.96061501419484]
  ]
];

const mainView = new MapView({ id: "main", controller: true });

export default function RidePath({
  title,
  pathData,

  buildings = DATA_URL.BUILDINGS,
  trips = DATA_URL.TRIPS,
  trailLength = 300,
  initialViewState = INITIAL_VIEW_STATE,
  mapStyle = MAP_STYLE,
  theme = DEFAULT_THEME,
  loopLength = 3700, // unit corresponds to the timestamp in source data
  animationSpeed = 1
}) {
  const [viewState, setViewState] = useState<any>(initialViewState);
  const [initialViewSet, setInitialViewSet] = useState(false);
  const [time, setTime] = useState(0);
  const animation = useRef<any>();
  const deck = useRef<any>();
  const formattedDataRef = useRef<any>();
  const [following, setFollowing] = useState(true);

  let maxTime = useMemo(
    () => pathData.length - 1 || loopLength,
    [loopLength, pathData.length]
  );
  const animate = useCallback(() => {
    setTime((t) => {
      const newTime = (t + animationSpeed) % maxTime;
      return newTime;
    });

    animation.current.id = window.requestAnimationFrame(animate);
  }, [animationSpeed, maxTime]);

  useEffect(() => {
    if (!following) {
      return;
    }
    // get current point being rendered and set view state
    let toSubtractFromTime = 430;
    let newTime = time - toSubtractFromTime;
    if (newTime < 0) {
      newTime = 0;
    }

    if (pathData.length) {
      const { latitude, longitude } = pathData[Math.floor(newTime / 4.8)];
      setViewState((curState) => ({
        ...curState,
        latitude: longitude,
        longitude: latitude
      }));
    }

    return () => {};
  }, [time, pathData, maxTime, following]);

  useEffect(() => {
    if (!animation.current) {
      animation.current = { id: null };
    }
    const currAnimation = animation.current;
    animation.current.id = window.requestAnimationFrame(animate);
    return () => window.cancelAnimationFrame(currAnimation.id);
  }, [animate]);

  useEffect(() => {
    if (pathData.length && !initialViewSet) {
      const { latitude, longitude } = pathData[0];
      setViewState((curState) => ({
        ...curState,
        latitude: longitude,
        longitude: latitude
      }));
      setInitialViewSet(true);
    }
  }, [initialViewSet, pathData]);

  const formattedData = useMemo(() => {
    const path = [];
    const timestamps = [];
    if (!pathData || pathData.length === 0) {
      return [];
    }
    let minTimestamp = moment(pathData[0].timestamp).valueOf();
    pathData.forEach((item) => {
      let momentValueOf = moment(item.timestamp).valueOf();
      path.push([item.latitude, item.longitude]);
      timestamps.push((momentValueOf - minTimestamp) / 1000);
    });

    const data = [
      {
        path,
        timestamps
      }
    ];

    formattedDataRef.current = data;
    return data;
  }, [pathData]);

  const onViewStateChange = useCallback(
    ({ viewState: newViewState }) => {
      if (initialViewSet) {
        setFollowing(false);
      }

      setViewState(() => newViewState);
    },
    [initialViewSet]
  );

  const layers = [
    new PolygonLayer({
      id: "ground",
      data: landCover,
      getPolygon: (f) => f,
      stroked: false,
      getFillColor: [0, 0, 0, 0]
    }),
    new TripsLayer({
      id: "trips",
      data: formattedData,
      getPath: (d) => d.path,
      getTimestamps: (d) => d.timestamps,
      getColor: (d) => theme.trailColor0,
      getPosition: (d) => d.path[time],
      opacity: 1,
      widthMinPixels: 5,
      rounded: true,
      trailLength,
      currentTime: time,
      shadowEnabled: false
    })
  ];

  return (
    <div className="h-[100%] p-2 mb-2">
      {pathData.length ? (
        <DeckGL
          ref={deck}
          views={mainView}
          layers={layers}
          effects={theme.effects}
          viewState={viewState}
          onViewStateChange={onViewStateChange}
          style={{ height: "100%", width: "100%", position: "relative" }}
        >
          <Map
            reuseMaps
            mapLib={maplibregl}
            mapStyle={mapStyle}
            attributionControl={false}
          />
          <Button
            tooltip={following ? "Stop Following" : "Follow Ride"}
            variant="light"
            style={{
              borderRadius: "50%"
            }}
            onClick={() => {
              setFollowing((cur) => !cur);
              if (!following) {
                setViewState((curState) => ({
                  ...curState,
                  zoom: 13
                }));
              }
            }}
            className="absolute cursor-pointer bottom-10 right-4 bg-background-layer1 rounded-full p-4"
          >
            {following ? (
              <EyeSlashIcon className="w-6 h-6 text-contentColor" />
            ) : (
              <EyeIcon className="w-6 h-6 text-contentColor" />
            )}
          </Button>
          <div className="maplibregl-ctrl-attrib-inner text-contentColorLight text-xs absolute bottom-2 right-2">
            ©{" "}
            <a
              href="https://carto.com/about-carto/"
              target="_blank"
              rel="noopener noreferrer"
            >
              CARTO
            </a>
            , ©{" "}
            <a
              href="http://www.openstreetmap.org/about/"
              target="_blank"
              rel="noreferrer"
            >
              OpenStreetMap
            </a>{" "}
            contributors
          </div>
        </DeckGL>
      ) : null}
    </div>
  );
}
