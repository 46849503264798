import TransitionedMenu from "@app/shared/components/transitioned-men.component";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

const RuleTriggerTypeSelector = ({ ruleTriggerType, setRuleTriggerType }) => {
  return (
    <TransitionedMenu
      buttonComponent={
        <div
          className={`flex px-2 py-1 gap-2 rounded-sm uppercase text-contentColor items-center bg-background-layer2`}
        >
          <span>{ruleTriggerType}</span>
          <ChevronDownIcon width={16} />
        </div>
      }
    >
      <Menu.Item>
        {({ active }) => (
          <button
            className={`w-full flex gap-1 items-center uppercase ${
              active && "bg-background-layer2"
            } min-w-[6rem] text-left whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer3 transition-all duration-200`}
            onClick={() => setRuleTriggerType("HTTP")}
          >
            HTTP
          </button>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <button
            className={`w-full flex gap-1 items-center uppercase ${
              active && "bg-background-layer2"
            } min-w-[6rem] text-left whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer3 transition-all duration-200`}
            onClick={() => setRuleTriggerType("MQTT")}
          >
            MQTT
          </button>
        )}
      </Menu.Item>
    </TransitionedMenu>
  );
};

export default RuleTriggerTypeSelector;
