import { UserRole } from "../app/shared/config";
import { IOrganization } from "./shared.interface";

export enum OnboardingStep {
  Welcome = "0",
  Profile = "1",
  Availability = "2",
  Organization = "3"
}

export interface IServiceProvider {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  active: boolean;
  organizationVerified: boolean;
  onboardingStatus: string;
  fleetCreationStatus: string;
  deviceCreationStatus: string;
  panelCreationStatus: string;
  deploymentCreationStatus: string;
  timeZone: string;
  profilePic: string;
  selectedOrg: IOrganization;
  organization: IOrganization[];
  roles: Array<UserRole>;
}
