import { isEmpty } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IGaugeDefination,
  ILineDefination,
  IPieDefinations
} from "../../interfaces";
import { useDashboardStore } from "../../store";
import DashboardUtilsHeader from "./components/dash-filter-header.component";
import PanelWrapper from "./dash-wrapper-panel.component";
import CarouselWrapper from "./dash-carousel-warpper.component";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { CoreProps, Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { GRID_COLS, generateLayout } from "./dash.helper";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { useGetDashboardPanels } from "@app/shared/hooks/get/dashboard-panels";
import ShowLoading from "@app/shared/components/loading.component";
import ShowError from "@app/shared/components/error.component";
import { Badge, Color } from "@tremor/react";
import { useGetFleets } from "@app/shared/hooks/get/fleets";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface IDashboardDetails {
  device?: any;
  onAddPanel?: () => void;
  hideAddPanel?: boolean;
}

const Details: React.FC<IDashboardDetails> = ({
  device,
  onAddPanel,
  hideAddPanel
}) => {
  const navigate = useNavigate();
  const wrapperRef = useRef(null);
  const panelErrors = useRef({});
  const errorToastTimer = useRef(null);
  const [geoMapAdditionalInfo, setGeoMapAdditionalInfo] = useState({});
  const [showUtilsHeader, setShowUtilsHeader] = useState(false);

  const [editDashboard, savedLayouts] = useDashboardStore((state) => [
    state.editingLayout,
    state.layouts
  ]);

  const devicePanels = getEvInfPanels(device);

  const [
    dashboards,
    panels,
    carousels,
    activeDashboard,
    setPanels,
    setCarousels,
    clearCreatePanelState,
    zoomLevel,
    showAddPanel
  ] = useDashboardStore((state) => [
    state.dashboards,
    state.panels,
    state.carousels,
    state.activeDashboard,
    state.setPanels,
    state.setCarousels,
    state.clearCreatePanelState,
    state.zoomLevel,
    state.showAddPanel
  ]);

  const sortPanels = (obj: any) => {
    const sortedObj = {};
    const rowKeys = Object.keys(obj);

    if (rowKeys.length) {
      rowKeys.forEach((row) => {
        const sortedPanels = obj[row].sort(
          (a, b) => a.definition.panel_position - b.definition.panel_position
        );

        sortedObj[row] = sortedPanels;
      });
    }
    return { ...sortedObj };
  };

  const { isLoading, error } = useGetDashboardPanels(
    activeDashboard?.id,
    {},
    (dashboardPanels) => {
      if (device?.id) return;
      if (dashboardPanels?.length) {
        let carouselObj = {};
        let panelsArr = [];
        dashboardPanels.forEach((panel) => {
          if (panel.definition.arrangement) {
            if (panel.definition.arrangement.carousel in carouselObj) {
              carouselObj[panel.definition.arrangement.carousel].push(panel);
            } else {
              carouselObj[panel.definition.arrangement.carousel] = [panel];
            }
          } else {
            panelsArr.push(panel);
          }
        });
        if (!isEmpty(carouselObj)) {
          setCarousels(sortPanels(carouselObj));
        }
        if (panelsArr.length) {
          const newState = {
            currentBreakpoint: "lg",
            compactType: "vertical",
            resizeHandles: ["se"],
            mounted: true,
            // generate layouts according to the current breakpoint,
            // and merge them with the saved layouts
            layouts: {
              ...Object.keys(GRID_COLS).reduce((acc, cur) => {
                acc[cur] = generateLayout(panelsArr, GRID_COLS[cur], ["se"]);
                return acc;
              }, {}),
              ...savedLayouts
            }
          };
          setLayoutState(newState);
          setPanels(panelsArr);
        }
      } else {
        setPanels([]);
      }
    }
  );

  const { data: fleets } = useGetFleets();

  useEffect(() => {
    if (activeDashboard.id) {
      clearCreatePanelState();
    }
  }, [activeDashboard.id, clearCreatePanelState]);

  useEffect(() => {
    if (!wrapperRef.current) return;
    wrapperRef.current.style.zoom = `${zoomLevel}%`;
  }, [zoomLevel]);

  useEffect(() => {
    let timer = errorToastTimer.current;

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const renderCarouselPanels = () => {
    const carouselKeys = Object.keys(carousels);
    return carouselKeys.map((carousel) => (
      <CarouselWrapper title={carousel} panels={carousels[carousel]} />
    ));
  };

  const handleAddPanel = () => {
    if (device?.id) {
      onAddPanel && onAddPanel();
      return;
    }

    if (fleets.length > 0) {
      navigate("/dashboard/new-panel-type");
    } else {
      toast.error(
        "Please add at least one Fleet before creating a new panel!"
      );
    }
  };

  const handlePanelError = (
    panelId: string,
    panelTitle: string,
    error: string
  ) => {
    panelErrors.current[panelId] = { error, panelTitle };

    // batch the errors into one toast to avoid spamming the user with toasts
    if (Object.keys(panelErrors.current).length === 1) {
      errorToastTimer.current = setTimeout(() => {
        if (Object.keys(panelErrors.current).length)
          toast.error(
            <div>
              <span className="text-sm font-bold mb-1 block">
                There were some errors fetching the data of following panels:{" "}
              </span>
              <div className="flex flex-col">
                {Object.keys(panelErrors.current).map((panelId) => (
                  <div className="flex items-center" key={panelId}>
                    <span className="text-xs font-medium whitespace-nowrap  ">
                      {panelErrors.current[panelId].panelTitle}:
                    </span>
                    <span className="text-xs ml-2">
                      {panelErrors.current[panelId].error}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          );
        panelErrors.current = {};
        clearTimeout(errorToastTimer.current);
      }, 500);
    }
  };

  const [layoutState, setLayoutState] = useState({
    currentBreakpoint: "lg",
    compactType: "vertical",
    resizeHandles: ["se"],
    mounted: false,
    layouts: {
      ...Object.keys(GRID_COLS).reduce((acc, cur) => {
        acc[cur] = generateLayout(panels, GRID_COLS[cur], ["se"]);
        return acc;
      }, {}),
      ...savedLayouts
    }
  });

  const onBreakpointChange: (Breakpoint) => void = (breakpoint) => {
    setLayoutState((curState) => ({
      ...curState,
      currentBreakpoint: breakpoint
    }));
  };

  const panelsToRender = (device?.id ? devicePanels : panels) as any;

  const memoizedPanels = useMemo(() => {
    if (!layoutState.layouts[layoutState.currentBreakpoint]?.length) return;
    if (device?.id) return [];

    return panelsToRender.map(
      (panel: ILineDefination | IPieDefinations | IGaugeDefination) => (
        <div
          key={panel.id}
          data-grid={layoutState.layouts[layoutState.currentBreakpoint].find(
            (el) => el.i === panel.id
          )}
          className={`${editDashboard ? "select-none" : ""} ${
            !editDashboard && "hide-resize"
          }`}
        >
          <PanelWrapper
            panel={panel}
            key={panel.id}
            handlePanelError={handlePanelError}
            geoMapAdditionalInfo={geoMapAdditionalInfo}
            setGeoMapAdditionalInfo={setGeoMapAdditionalInfo}
          />
        </div>
      )
    );
  }, [
    layoutState.layouts,
    layoutState.currentBreakpoint,
    device?.id,
    panelsToRender,
    editDashboard,
    geoMapAdditionalInfo
  ]);

  // need to do this because for some reason RGL calls the onLayoutChange callback with
  // a layout object that arranges all the panels into a single column
  useEffect(
    () => {
      setLayoutState((curState) => ({
        ...curState,
        layouts: {
          ...Object.keys(GRID_COLS).reduce((acc, cur) => {
            acc[cur] = generateLayout(panels, GRID_COLS[cur], ["se"]);
            return acc;
          }, {}),
          ...savedLayouts
        }
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeDashboard.id]
  );
  if (isLoading) {
    return <ShowLoading />;
  }

  if (error) {
    return <ShowError />;
  }

  if (!dashboards.length) {
    return;
  }

  return (
    <main className="w-full relative h-auto py-4 overflow-y-auto sm:px-4">
      <DashboardUtilsHeader
        showUtilsHeader={showUtilsHeader}
        hideAddPanel={hideAddPanel}
        addPanel={handleAddPanel}
        layouts={layoutState.layouts}
        setLayouts={setLayoutState}
        panels={panels}
        setShowUtilsHeader={setShowUtilsHeader}
      />
      {!showUtilsHeader ? (
        <Cog6ToothIcon
          width={20}
          className="absolute top-2 right-2 cursor-pointer"
          onClick={() => setShowUtilsHeader(true)}
        />
      ) : null}
      <div ref={wrapperRef} className="w-full py-4">
        {!isEmpty(carousels) && renderCarouselPanels()}
        <div className="flex flex-wrap gap-4 w-full h-full">
          {
            <ResponsiveGridLayout
              className="layout w-full"
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={GRID_COLS}
              rowHeight={260}
              width={1200}
              isDraggable={editDashboard}
              isResizable={editDashboard}
              layouts={layoutState.layouts}
              onBreakpointChange={onBreakpointChange}
              measureBeforeMount={false}
              useCSSTransforms={layoutState.mounted}
              compactType={layoutState.compactType as CoreProps["compactType"]}
              preventCollision={!layoutState.compactType}
              onLayoutChange={(layout, layouts) => {
                if (!layout.length) return;

                // save the current layout to all the breakpoints, let the library handle the arrangement
                const newLayouts = Object.keys(GRID_COLS).reduce(
                  (acc, cur) => {
                    if (!layout.length) return acc;
                    acc[cur] = layout;
                    return acc;
                  },
                  {}
                );
                if (Object.keys(newLayouts).length) {
                  setLayoutState((curState) => ({
                    ...curState,
                    layouts: newLayouts
                  }));
                }
              }}
            >
              {memoizedPanels}
            </ResponsiveGridLayout>
          }
        </div>
      </div>
      <Tooltip
        id="panel-error-tooltip"
        className="border border-red-500"
        style={{
          zIndex: 100000,
          backgroundColor: "#F87171",
          color: "#fff"
        }}
      />
      <Tooltip
        id={`tooltip-badge-hover`}
        className="z-20 transform"
        variant="light"
        border={"1px solid black"}
        render={({ activeAnchor }) => {
          const labels = activeAnchor
            ?.getAttribute("data-tooltip-labels")
            ?.split(",");
          const values = activeAnchor
            ?.getAttribute("data-tooltip-values")
            ?.split(",");
          const colorsProp = activeAnchor?.getAttribute("data-tooltip-colors");
          const colors = colorsProp ? JSON.parse(colorsProp) : {};

          return (
            <div className="flex flex-col gap-1">
              {labels?.map((label, i) => (
                <Badge
                  size="xs"
                  key={i}
                  color={colors[label] as Color}
                  className={`text-xs`}
                >
                  <span style={{ fontSize: "0.2rem !important" }}>
                    {label}: {values[i]}
                  </span>
                </Badge>
              ))}
            </div>
          );
        }}
      />
    </main>
  );
};

function getEvInfPanels(device) {
  if (!device?.id) return [];
  const ev_inv_dev_map_panel = {
    panel_type: "GENERIC",
    title: "Device Location",
    definition: {
      panel_type: "GEO_MAP_LOCATION",
      title: "Device Location"
    },
    dataConfig: {
      data_point_id: "4165553f-722b-4485-8d3b-2c32b026e14c",
      select: [
        {
          alias: "ridePath",
          expression: {
            pattern: "st_asgeojson(st_makeline({c}))",
            values: {
              c: "geom"
            }
          }
        }
      ],
      where: [
        {
          device_id: {
            eq: "35ba339d-901c-4608-a3e9-b35637fb05ab"
          }
        }
      ],
      order: [
        {
          time: -1
        }
      ]
    }
  };

  return [ev_inv_dev_map_panel];
}

export default Details;
