import { AuthCallback } from "casdoor-react-sdk";
import * as Setting from "./casdoor-setting.helper";
import { useSearchParams } from "react-router-dom";

export const Auth = () => {
  const [searchParams] = useSearchParams();
  const inviteToken = searchParams.get("token");

  return (
    <AuthCallback
      sdk={Setting.CasdoorSDK}
      serverUrl={Setting.ServerUrl}
      saveTokenFromResponse={(res: any) => {
        Setting.SetToken(res.data);
        if (inviteToken) {
          Setting.goToLink("/invite?token=" + inviteToken);
        } else {
          Setting.goToLink("/");
        }
      }}
      isGetTokenSuccessful={(res: any) => {
        return res?.ok === 1;
      }}
    />
  );
};
