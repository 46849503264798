import TransitionedMenu from "@app/shared/components/transitioned-men.component";
import { Menu } from "@headlessui/react";

const ActionTypeDropdown = ({
  setActionType,
  actionTypes,
  buttonComponent,
  setActionDefinition
}) => {
  return (
    <TransitionedMenu buttonComponent={buttonComponent}>
      {Object.values(actionTypes).map((type: string) => (
        <Menu.Item key={"act-type-drop-" + type}>
          {({ active }) => (
            <button
              className={`w-full flex gap-1 items-center uppercase ${
                active && "bg-background-layer2"
              } min-w-[6rem] text-left whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer3 transition-all duration-200`}
              onClick={() => {
                setActionType(type);
                setActionDefinition({});
              }}
            >
              {type}
            </button>
          )}
        </Menu.Item>
      ))}
    </TransitionedMenu>
  );
};

export default ActionTypeDropdown;
