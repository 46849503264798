import produce from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../app/shared/config";

interface IWalkthroughState {
  activeWalkthrough: string;
  activeWalkthroughItem: string;
  setActiveWalkthrough: (x: string) => void;
  setActiveWalkthroughItem: (x: string) => void;
}

const useWalkthroughStore = create<IWalkthroughState>()(
  persist(
    devtools(
      (set) => ({
        activeWalkthrough: "golain-overview",
        activeWalkthroughItem: "collaborators",
        setActiveWalkthrough: (x) =>
          set(
            produce((state) => {
              state.activeWalkthrough = x;
            })
          ),
        setActiveWalkthroughItem: (x) =>
          set(
            produce((state) => {
              state.activeWalkthroughItem = x;
            })
          )
      }),
      { name: "walkthrough", serialize: { options: true } }
    ),
    {
      name: "walkthrough", // unique name
      getStorage: () => localStorage // (optional) by default, 'localStorage' is used
    }
  )
);

export default useWalkthroughStore;
