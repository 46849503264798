import { useEffect, useMemo } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { IDevice, IFleet } from "@interfaces/index";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { useConfirmDelete } from "@app/shared/hooks/use-confirm-delete.hooks";
import TransitionedMenu from "@app/shared/components/transitioned-men.component";
import { Menu } from "@headlessui/react";
import {
  ArrowPathIcon,
  CheckIcon,
  ChevronDownIcon,
  PlusIcon,
  RectangleGroupIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import { Device } from "@app/shared/icons";
import { useDeleteFleet } from "@app/shared/hooks/delete/delete-fleet";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useGetFleets } from "@app/shared/hooks/get/fleets";

function FleetHeader({ baseURL, showCreate, showRefresh, showDelete }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const deviceId = searchParams.get("code");
  const [user] = useAuthStore((state) => [state.user]);
  const [
    fleets,
    devices,
    selectedFleet,
    selectedProject,
    setFleets,
    setSelectedFleet
  ] = useFleetAndDevicesStore((state) => [
    state.fleets,
    state.devices,
    state.selectedFleet,
    state.selectedProject,
    state.setFleets,
    state.setSelectedFleet
  ]);

  const { openConfirmDeleteModal } = useConfirmDelete();

  useGetFleets({}, (fleets) => {
    setFleets(fleets);
  });

  useEffect(() => {
    if (fleets?.length) {
      if (
        !selectedFleet.id ||
        selectedFleet.project_id !== selectedProject.id ||
        selectedFleet.org_id !== user.selectedOrg.id
      ) {
        if (
          fleets[0].project_id === selectedProject.id &&
          fleets[0].org_id === user.selectedOrg.id
        ) {
          // only change after new fleets have been fetched yet.
          setSelectedFleet(fleets[0]);
        }
      }
    } else {
      setSelectedFleet({} as IFleet);
    }
  }, [
    fleets,
    selectedFleet.id,
    selectedFleet.org_id,
    selectedFleet.project_id,
    selectedProject.id,
    setSelectedFleet,
    user.selectedOrg.id
  ]);

  const handleFleetSelection = (fleetId) => {
    const selectedFleet = fleets.find((f) => f.id === fleetId);
    setSelectedFleet(selectedFleet);

    queryClient.invalidateQueries({ queryKey: ["getDevices"] });
    navigate(baseURL);
  };

  const deleteFleetMutation = useDeleteFleet();

  const deleteFleet = async () => {
    deleteFleetMutation.mutate(selectedFleet.id, {
      onSuccess: () => {
        toast.success("Deleted fleet successfully!");
        setSelectedFleet(fleets.length ? fleets[0] : ({} as IFleet));
      }
    });
  };

  const selectedDevice: IDevice | undefined = useMemo(() => {
    const foundDevice = devices?.filter((device) => device.id === deviceId);

    if (foundDevice) {
      return foundDevice[0];
    } else {
      return undefined;
    }
  }, [devices, deviceId]);

  return (
    <>
      <div className="bg-background text-contentColor border-b border-b-background-layer3 lg:px-6 sm:px-6 ">
        <div className="flex justify-between items-center py-3">
          <div className="flex flex-col">
            <div className="flex items-center mb-3">
              <TransitionedMenu
                buttonComponent={
                  <div className="flex gap-6 text-lg font-medium hover:bg-background-layer1 text-contentColor px-2 rounded-md focus:ring-1 focus:ring-offset-2 focus:ring-offset-background-layer2 focus:ring-primary">
                    {selectedFleet?.fleet_name || "Select Fleet"}
                    <ChevronDownIcon width={18} />
                  </div>
                }
              >
                {fleets &&
                  fleets.map((fleet) => (
                    <Menu.Item key={fleet.id}>
                      {({ active }) => (
                        <button
                          onClick={() => handleFleetSelection(fleet.id)}
                          className={`w-full flex gap-1 items-center ${
                            fleet.id === selectedFleet.id &&
                            "bg-background-layer2"
                          } min-w-[10rem] text-left whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer1 transition-all duration-200`}
                        >
                          {fleet.id === selectedFleet.id && (
                            <CheckIcon width={14} />
                          )}
                          {fleet.fleet_name}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
              </TransitionedMenu>
            </div>
            <div className="ml-2">
              <p className="text-sm text-contentColorLight">
                {`Fleet & Devices  >`}&nbsp;
                <Link to="/fleet-and-devices/projects">
                  <span className="inline-flex items-center gap-1">
                    <span>Fleet</span>
                    <span>{selectedFleet?.fleet_name}</span>
                  </span>
                  &nbsp;
                </Link>
                {deviceId ? `> ${selectedDevice?.device_name}` : ""}
              </p>
            </div>
          </div>
          <ul className="flex justify-center items-center">
            {showRefresh && (
              <li className="ml-10 mr-8 items-center cursor-pointer">
                <button
                  onClick={() => window.location.reload()}
                  className="ml-4 mx-1 text-sm flex justify-center items-center"
                >
                  <ArrowPathIcon width={14} />

                  <span className="pl-2 text-sm">Refresh</span>
                </button>
              </li>
            )}
            {/* <li
                            className="ml-10 flex items-center cursor-pointer">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 10C14 10.3536 13.8595 10.6928 13.6095 10.9428C13.3594 11.1929 13.0203 11.3333 12.6667 11.3333H4.66667L2 14V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H12.6667C13.0203 2 13.3594 2.14048 13.6095 2.39052C13.8595 2.64057 14 2.97971 14 3.33333V10Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>


                            <span className="pl-2 text-sm">Message</span>
                        </li>
                        <li
                            className="ml-10 flex items-center cursor-pointer">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 5.33325C13.1046 5.33325 14 4.43782 14 3.33325C14 2.22868 13.1046 1.33325 12 1.33325C10.8954 1.33325 10 2.22868 10 3.33325C10 4.43782 10.8954 5.33325 12 5.33325Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 14.6667C13.1046 14.6667 14 13.7713 14 12.6667C14 11.5622 13.1046 10.6667 12 10.6667C10.8954 10.6667 10 11.5622 10 12.6667C10 13.7713 10.8954 14.6667 12 14.6667Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M5.72656 9.00659L10.2799 11.6599" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10.2732 4.34009L5.72656 6.99342" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                            <span className="pl-2 text-sm">Share</span>
                        </li>
                        <li
                            className="ml-10 flex items-center cursor-pointer">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.2"
                                stroke="currentColor"
                                className="w-5 h-5">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                />
                            </svg>

                            <span className="pl-2 text-sm">Export</span>
                        </li> */}
            {showDelete && fleets.length > 0 && (
              <li className="mr-8 flex items-center cursor-pointer">
                <button
                  onClick={() =>
                    openConfirmDeleteModal(
                      deleteFleet,
                      "Are you sure you want to delete this fleet?"
                    )
                  }
                  className="text-sm flex justify-center items-center"
                >
                  <TrashIcon width={14} />
                  <span className="pl-2 text-sm">Delete</span>
                </button>
              </li>
            )}
            {showCreate && (
              <TransitionedMenu
                menuPosition="right"
                buttonComponent={
                  <div className="flex gap-4 text-base bg-primary text-white px-2 py-2 rounded-md">
                    <span className="flex gap-1">
                      <PlusIcon width={18} /> Create
                    </span>
                    <ChevronDownIcon width={18} />
                  </div>
                }
              >
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/fleet-and-devices/projects/about-device"
                      className={`w-full flex gap-2 items-center min-w-[10rem] text-left whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer1 transition-all duration-200`}
                    >
                      <Device width={14} /> New Device
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/fleet-and-devices/projects/about-fleet"
                      className={`w-full flex gap-2 items-center min-w-[10rem] text-left whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer1 transition-all duration-200`}
                    >
                      <RectangleGroupIcon width={16} /> New Fleet
                    </Link>
                  )}
                </Menu.Item>
              </TransitionedMenu>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default FleetHeader;
