import { memo, useCallback, useEffect, useId, useRef, useState } from "react";
import NodeTemplate from "../node-template.component";
import {
  ArrowPathIcon,
  InformationCircleIcon,
  PlayIcon,
  TrashIcon
} from "@heroicons/react/24/outline";

import { Tooltip } from "react-tooltip";
import { serializeActionJSON } from "./action-node.helper";
import {
  IContext,
  IInput,
  validateContextVariable
} from "@app/rule-engine/rule-engine.helper";
import useRuleEngineStore from "@store/rule-engine/rule-engine.store";
import { Button } from "@tremor/react";
import { useCreateAction } from "@app/shared/hooks/post/create-action";
import { useUpdateAction } from "@app/shared/hooks/patch/update-action";
import { toast } from "react-toastify";
import { VariableInput } from "@app/rule-engine/variable-input.component";
import _ from "lodash";
import { useConfirmDelete } from "@app/shared/hooks/use-confirm-delete.hooks";

const ActionNode = ({
  data,
  isConnectable,
  actionSequenceNodeId,
  shadowDefs = []
}) => {
  const [allActionData, ruleData, setActionData] = useRuleEngineStore(
    (state) => [state.actionData, state.ruleData, state.setActionData]
  );
  const lastActionJSON = useRef(null);

  const [actionRunning, setActionRunning] = useState(false);

  const tooltipId = useId();

  const { openConfirmDeleteModal } = useConfirmDelete();

  const createActionMutation = useCreateAction();
  const updateActionMutation = useUpdateAction();

  useEffect(() => {
    if (!data.nodeId || !data.id) return;
    // for new actions
    if (!allActionData[data.nodeId][data.id].name) return;

    lastActionJSON.current = serializeActionJSON(
      allActionData[data.nodeId][data.id]
    );
  }, []);

  if (!data.nodeId || !allActionData[data.nodeId]) {
    return null;
  }

  const actionData =
    data.nodeId && data.id ? allActionData[data.nodeId][data.id] : {};

  const hasActionChanged = () =>
    !_.isEqual(lastActionJSON.current, serializeActionJSON(actionData));

  const onActionRun = () => {
    setActionRunning(true);
    const actionJSON = serializeActionJSON(actionData);

    if (!lastActionJSON.current) {
      lastActionJSON.current = actionJSON;
    } else {
      if (
        _.isEqual(actionJSON, lastActionJSON.current) &&
        actionData.action_id
      ) {
        toast.success("Updated action successfully!");
        return;
      } else {
        lastActionJSON.current = actionJSON;
      }
    }

    if (!actionData.action_id) {
      createActionMutation.mutate(actionJSON, {
        onSuccess: (action_id) => {
          setActionData({
            ...allActionData,
            [data.nodeId]: {
              ...allActionData[data.nodeId],
              [data.id]: {
                ...allActionData[data.nodeId][data.id],
                action_id
              }
            }
          });

          toast.success("Created action successfully!");
        },

        onSettled: () => {
          setActionRunning(false);
        }
      });
    } else {
      updateActionMutation.mutate(
        { data: actionJSON, actionId: actionData.action_id },
        {
          onSuccess: (ok) => {
            if (ok) {
              toast.success("Updated action successfully!");
            }
          },

          onSettled: () => {
            setActionRunning(false);
          }
        }
      );
    }
  };

  const deleteActionNode = () => {
    const newActionData = { ...allActionData };

    const newNodeActionData = { ...newActionData[data.nodeId] };
    delete newNodeActionData[data.id];

    newActionData[data.nodeId] = newNodeActionData;

    setActionData(newActionData);
  };

  const runButtonDisabled =
    actionData?.fetchedContexts?.some((fC) => fC.error) ||
    actionData?.actionDefinition?.hasErrors;
  return (
    <NodeTemplate>
      <div className="flex gap-2 text-xs">
        {!actionRunning ? (
          <Button
            size="xs"
            variant="light"
            tooltip={
              runButtonDisabled
                ? "Please resolve the errors in this Action."
                : ""
            }
            disabled={runButtonDisabled}
            icon={() =>
              runButtonDisabled ? (
                <InformationCircleIcon
                  width={28}
                  color="red"
                  className="cursor-not-allowed"
                />
              ) : (
                <PlayIcon
                  width={28}
                  className="text-green-500 fill-current"
                  onClick={onActionRun}
                />
              )
            }
          />
        ) : (
          <ArrowPathIcon width={20} className="text-green-500 animate-spin" />
        )}
        <input
          placeholder="Action Name"
          value={actionData?.name ?? ""}
          className="nodrag w-full text-sm text-contentColor bg-background-layer0.5 px-2 py-1 hover:outline outline-background-layer3"
          onChange={(e) => {
            data.setActionData({ ...actionData, name: e.target.value });
          }}
        />
        <Button
          icon={TrashIcon}
          color="red"
          variant="light"
          onClick={() => {
            !actionData.action_id
              ? openConfirmDeleteModal(
                  deleteActionNode,
                  "This action has not been created yet. Delete it will permanently delete all of it's data. Are you sure?"
                )
              : hasActionChanged()
              ? openConfirmDeleteModal(
                  deleteActionNode,
                  "Deleting this action will discard all of it's changes. Are you sure?"
                )
              : deleteActionNode();
          }}
        />
      </div>

      {actionData?.inputs?.length ? (
        <div className="nodrag mt-2">
          <h3 className="mb-2">Input Values:</h3>
          <div className="flex gap-2 w-full flex-col">
            {actionData?.inputs?.map((input: IInput) => {
              let val = actionData.inputValues
                ? actionData.inputValues[input.key]
                : "";

              const contexts: IContext[] = ruleData.fetchedContexts || [];

              let hasError = true;

              if (val) {
                if (
                  val.startsWith("{{") &&
                  val.endsWith("}}") &&
                  val.length > 4
                ) {
                  let toTest = val.substring(2, val.length - 2);

                  if (
                    contexts.some((c) =>
                      validateContextVariable(c, shadowDefs, toTest)
                    ) ||
                    ruleData.inputs?.some((input) => input.key === toTest)
                  ) {
                    hasError = false;
                  } else {
                    hasError = true;
                  }
                } else {
                  hasError = false;
                }
              }

              if (!val) {
                hasError = false;
              }

              return (
                <div
                  className="flex flex-col gap-1 border border-background-layer3 w-full p-2 pt-1 rounded-sm"
                  key={`${input.key}-input-value`}
                >
                  <span>{input.key}</span>
                  <VariableInput
                    placeholder={`'${input.key}' value`}
                    value={val}
                    onChange={(e) => {
                      data.setActionData(
                        (prev) => ({
                          ...prev,
                          inputValues: {
                            ...prev.inputValues,
                            [input.key]: e.target.value
                          }
                        }),
                        data.id
                      );
                    }}
                  />
                  {hasError ? (
                    <span className="text-red-500 text-left w-full">
                      Invalid Variable reference: {val}
                    </span>
                  ) : val === "" ? (
                    <span className="text-red-500 text-left w-full">
                      '{input.key}' cannot be empty!
                    </span>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      <div className="flex gap-2 nodrag mt-3">
        <button
          data-tooltip-id={tooltipId}
          disabled={!actionData?.name?.trim()}
          className="bg-background-layer3 text-contentColor w-full px-2 py-1 rounded-sm disabled:opacity-50"
          onClick={() => {
            data.setEditAction({
              name: actionData?.name,
              id: data.id,
              actionSequenceNodeId
            });
          }}
        >
          Edit Action
        </button>
      </div>

      {!actionData?.name?.trim() ? (
        <Tooltip
          id={tooltipId}
          clickable
          place="bottom"
          className="bg-background-layer2"
        >
          Enter a name for this action!
        </Tooltip>
      ) : null}
    </NodeTemplate>
  );
};

export default memo(ActionNode);
