import { useState, useContext, createContext } from "react";

const ConfirmDeleteContext = createContext({
  openConfirmDeleteModal: (cb: any, message?: string) => {},
  closeConfirmModal: () => {},
  handleDeleteConfirm: () => {},
  setIsOpen: (isOpen: boolean) => {},
  message: "",
  isOpen: false
});

export const useConfirmDelete = () => {
  const context = useContext(ConfirmDeleteContext);
  if (!context) {
    throw new Error(
      "useConfirmDelete must be used within a ConfirmDeleteProvider"
    );
  }
  return context;
};

export const ConfirmDeleteProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmCallback, setConfirmCallback] = useState(null);
  const [message, setMessage] = useState("");

  const openConfirmDeleteModal = (callback: any, message: string) => {
    setConfirmCallback(() => callback);
    setMessage(message || "");
    setIsModalOpen(true);
  };

  const closeConfirmModal = () => {
    setIsModalOpen(false);
    setConfirmCallback(null);
  };

  const handleDeleteConfirm = () => {
    if (confirmCallback) {
      confirmCallback();
    }
    closeConfirmModal();
  };

  const value = {
    isOpen: isModalOpen,
    setIsOpen: setIsModalOpen,
    openConfirmDeleteModal,
    closeConfirmModal,
    handleDeleteConfirm,
    message
  };

  return (
    <ConfirmDeleteContext.Provider value={value}>
      {children}
    </ConfirmDeleteContext.Provider>
  );
};
