import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import PanelCreationSteps from "./components/panel-creation-steps.component";
import { useAuthStore, useDashboardStore } from "../../store";

//Panels
import {
  BarChart,
  LineChart,
  PieChart,
  GaugeChart,
  TempChart,
  GaugeForm,
  LineChartForm,
  PieChartForm,
  BarChartForm,
  InfoGraphForm,
  HeatMapForm,
  TableForm,
  NumericInfoGraph
} from "./components/graphs";
import { chartdata, data } from "./sample-data";
import HeatMap from "./components/graphs/heatmap.component";
import useCreateHeatmapStore from "../../store/dashboard/create-heatmap.store";
import TableComponent from "./components/table/dash-table.component";
import dateService from "@services/date.service";
import useCreateTableStore from "@store/dashboard/create-table.store";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useCreateDashboardPanel } from "@app/shared/hooks/post/create-panel";
import { IPanelCreatePayload } from "@interfaces/dashboard.interface";
import { PanelDataSourceSelector } from "./components/forms/panel-data-source-selector.component";

interface IPanelType {
  name: string;
  component: React.ReactNode;
  formComponent: React.ReactNode;
}

interface IPanels {
  BAR_CHART: IPanelType;
  LINE_CHART: IPanelType;
  PIE_CHART: IPanelType;
  GAUGE_1: IPanelType;
  GAUGE_2: IPanelType;
  NUMERIC: IPanelType;
}

const INITIAL_GAUGE_STATE = {
  minThres: 120,
  maxThres: 180,
  minDisplay: 0,
  maxDisplay: 240,
  unit: "C",
  gradientEnabled: true
};

const graphDefinitions = {
  LINE_CHART: {
    primarySource: "data-points",
    maxParamsLength: 5,
    datatype: "number"
  },
  BAR_CHART: {
    primarySource: "data-points",
    maxParamsLength: 5,
    datatype: "number"
  },
  PIE_CHART: {
    primarySource: "data-points",
    maxParamsLength: 1,
    datatype: "enum"
  },
  GAUGE_1: {
    primarySource: "shadows, data-points",
    maxParamsLength: 1,
    datatype: "number"
  },
  GAUGE_2: {
    primarySource: "shadows, data-points",
    maxParamsLength: 1,
    datatype: "number"
  },
  NUMERIC: {
    primarySource: "shadows, data-points",
    maxParamsLength: 1,
    datatype: "number"
  },
  HEATMAP: {
    primarySource: "shadows, data-points",
    maxParamsLength: 1,
    datatype: "number"
  },
  TABLE: {
    primarySource: "shadows, data-points",
    maxParamsLength: 1000,
    datatype: "number"
  }
};

function DashboardNewPanelInfo() {
  const navigate = useNavigate();

  const [updateAuthUser] = useAuthStore((state) => [state.updateAuthUser]);
  const [
    activeDashboard,
    createPanelState,
    createPanelAppearance,
    clearCreatePanelState
  ] = useDashboardStore((state) => [
    state.activeDashboard,
    state.createPanelState,
    state.createPanelAppearance,
    state.clearCreatePanelState
  ]);

  const [submit, setSubmit] = useState(false);

  const activePanel = useMemo(
    () => createPanelState.selectedPanel,
    [createPanelState.selectedPanel]
  );

  const [panelData, setPanelData] = useState({ ...INITIAL_GAUGE_STATE });
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    updateAuthUser({ panelCreationStatus: "1" });
  }, [updateAuthUser]);

  const [createHeatmapInputValues, resetInputValues] = useCreateHeatmapStore(
    (state) => [state.inputValues, state.resetInputValues]
  );

  const [resetCreateTableState] = useCreateTableStore((state) => [
    state.reset
  ]);

  const createPanelMutation = useCreateDashboardPanel(activeDashboard?.id);

  const createPanel = (payload: IPanelCreatePayload) => {
    createPanelMutation.mutate(payload, {
      onSuccess: (panelId) => {
        if (panelId?.length) {
          navigate(`/dashboard/${activeDashboard.id}`);
        }
      }
    });
  };

  const handleClick = () => {
    let definition = { title: createPanelState.panelName };
    let data_config = { realtime: {}, source: {} };
    const table_data_config = {};
    let reqObj: IPanelCreatePayload = {};
    const { selectedPanel } = createPanelState;

    if (selectedPanel.includes("GAUGE")) {
      definition["options"] = {
        unit: panelData.unit,
        max_thres: panelData.minThres,
        min_thres: panelData.maxThres,
        "gauge-type": selectedPanel === "GAUGE_1" ? "c" : "v",
        max_display: panelData.maxDisplay,
        min_display: panelData.minDisplay,
        "gradient-enable": panelData.gradientEnabled
      };

      const data = createPanelState.inputValues.selectedParams[0];
      data_config["source"] = {
        "data-point-param": data.value,
        "device-data-point-id":
          createPanelState.inputValues.filters.devices[0].deviceDataPointId
      };
      data_config["options"] = {
        "show-max-val": true,
        "show-min-val": true
      };
      reqObj["panel_type"] = "GAUGE";
    } else if (selectedPanel === "LINE_CHART") {
      definition["options"] = {
        labels: [chartData.map((d: any) => d.label)],
        max_thres: 256,
        min_thres: 1,
        label_colors: [chartData.map((d: any) => d.color)]
      };
      chartData.forEach((data) => {
        if (!createPanelState.inputValues.devicesInDataPoints.length) return;
        data_config.source[data.label] = {
          "data-point-param": data.linesParams.value,
          "device-data-point-id":
            createPanelState.inputValues.filters.devices[0].deviceDataPointId
        };
      });

      data_config["time_range"] = {
        end: createPanelState.inputValues.timePeriod.end,
        start: createPanelState.inputValues.timePeriod.start
      };
      reqObj["panel_type"] = selectedPanel;
    } else if (selectedPanel === "PIE_CHART") {
      definition["options"] = {
        max_thres: 256,
        min_thres: 1,
        label_colors: [chartData.map((d: any) => d.color)]
      };

      data_config.source = {
        "data-point-param": chartData[0].linesParams.value,
        "device-data-point-id":
          createPanelState.inputValues.filters.devices[0].deviceDataPointId
      };

      data_config["time_range"] = {
        end: createPanelState.inputValues.timePeriod.end,
        start: createPanelState.inputValues.timePeriod.start
      };
      reqObj["panel_type"] = selectedPanel;
    } else if (selectedPanel === "BAR_CHART") {
      definition["options"] = {
        labels: chartData.map((d: any) => d.label),
        max_thres: 256,
        min_thres: 1,
        bar_colors: chartData.map((d: any) => d.color),
        orientation: createPanelAppearance.orientation?.value ?? "horizontal"
      };
      chartData.forEach((data) => {
        data_config.source[data.label] = {
          "data-point-param": data.barParams.value,
          "device-data-point-id":
            createPanelState.inputValues.filters.devices[0].deviceDataPointId,
          agg_kind: data.aggParam
        };
      });

      data_config["time_range"] = {
        end: createPanelState.inputValues.timePeriod.end,
        start: createPanelState.inputValues.timePeriod.start
      };
      reqObj["panel_type"] = selectedPanel;
    } else if (selectedPanel === "NUMERIC") {
      definition["options"] = {
        labels: chartData.map((d: any) => d.label),
        minValue: chartData[0].minValue,
        maxValue: chartData[0].maxValue,
        display_unit: chartData[0].unit
      };
      chartData.forEach((data) => {
        data_config.source[data.label] = {
          "data-point-param": data.infoParams.value,
          "device-data-point-id":
            createPanelState.inputValues.filters.devices[0].deviceDataPointId,
          agg_kind: data.aggParam,
          time_range: {
            end: createPanelState.inputValues.timePeriod.end,
            start: createPanelState.inputValues.timePeriod.start
          }
        };
      });

      reqObj["panel_type"] = selectedPanel;
    } else if (selectedPanel === "HEATMAP") {
      definition["options"] = {
        ...chartData[0],
        param: createPanelState.inputValues.selectedParams[0]
      };

      data_config["source"] = {};
      const selectedParam =
        createPanelState.inputValues.selectedParams[0].value;

      chartData.forEach((data) => {
        data_config.source[selectedParam] = {
          "data-point-param": selectedParam,
          "device-data-point-id":
            createPanelState.inputValues.filters.devices[0].deviceDataPointId,
          aggregate: {
            period:
              createPanelState.inputValues.timeBucket +
              " " +
              createPanelState.inputValues.timeBucketUnit,
            kind: [data.aggregationMode]
          },
          time_range: createPanelState.inputValues.timePeriod
        };
      });

      reqObj["panel_type"] = selectedPanel;
    } else if (selectedPanel === "TABLE") {
      definition["columnState"] = chartData[0].columnState;
      definition["panel_type"] = selectedPanel;

      chartData.forEach((data) => {
        table_data_config["data_point_id"] = data.dataPoints.map(
          (dp) => dp.value
        )[0];

        table_data_config["select"] = [];

        if (!data.aggregateParams) {
          table_data_config["select"].push({
            param: "device_id",
            alias: "device_id"
          });
          table_data_config["select"].push(
            ...data.columns.map((col) => {
              return {
                param: col.parameter.name,
                alias: col.label
              };
            })
          );
        } else {
          table_data_config["select"].push({
            expression: {
              pattern: "time_bucket('{timeBucket}', timestamp)",
              values: {
                timeBucket: data.timeBucket + " " + data.timeBucketUnit
              }
            },
            alias: "bucket"
          });

          table_data_config["select"].push({
            param: "device_id",
            alias: "device_id"
          });

          table_data_config["select"].push(
            ...data.columns.map((col) => {
              if (col.parameter.type === "string") {
                return {
                  expression: {
                    pattern: "toolkit_experimental.freq_agg(0.05, {col})",
                    values: {
                      col: col.parameter.name
                    }
                  },
                  alias: col.label
                };
              } else {
                return {
                  param: col.parameter.name,
                  alias: col.label,
                  agg: col.aggregationMode
                };
              }
            })
          );

          table_data_config["group"] = ["bucket", "device_id"];
        }

        table_data_config["where"] = [
          {
            timestamp: {
              lte: dateService
                .parseRelativeDate(data.timePeriod.end)
                .toISOString()
            }
          },
          {
            timestamp: {
              gte: dateService
                .parseRelativeDate(data.timePeriod.start)
                .toISOString()
            }
          }
        ];

        if (data.filters.devices.length) {
          table_data_config["where"].push({
            device_id: {
              in: data.filters.devices.map((device) => device.value)
            }
          });
        }
      });

      reqObj["panel_type"] = "GENERIC";
      definition["panel_type"] = "TABLE";
    }

    reqObj = {
      ...reqObj,
      title: createPanelState.panelName,
      panel_description: "A single widget with multiple tag based graph",
      definition: JSON.stringify(definition),
      data_config: JSON.stringify(data_config)
    };

    if (selectedPanel !== "TABLE") {
      createPanel(reqObj);
    } else {
      reqObj = {
        ...reqObj,
        title: createPanelState.panelName,
        panel_description: "A single widget with multiple tag based graph",
        definition: JSON.stringify(definition),
        data_config: JSON.stringify(table_data_config)
      };
      createPanel(reqObj);
      resetCreateTableState();
    }
  };

  const handleGaugeChange = (data) => {
    setPanelData({ ...data });
  };

  const PANELS: IPanels = useMemo(
    () => ({
      LINE_CHART: {
        name: "Line Chart",
        component: (
          <LineChart
            title={createPanelState.panelName}
            chartdata={chartdata}
            categories={["Item A", "Item B", "Item C"]}
            colors={["yellow", "blue", "green"]}
            datakey="temp"
            yMin={500}
            yMax={5000}
            showExample
          />
        ),
        formComponent: (
          <LineChartForm
            params={createPanelState.inputValues.selectedParams || []}
            onChangeHandler={(data) => setChartData(data)}
            enableCreatebutton={(flag) => setSubmit(flag)}
          />
        )
      },
      BAR_CHART: {
        name: "Bar Chart",
        component: (
          <BarChart
            title={createPanelState.panelName}
            chartdata={chartdata}
            categories={["Item A", "Item B", "Item C"]}
            colors={["yellow", "blue", "green"]}
            datakey="temp"
            yMin={500}
            yMax={5000}
            showExample
          />
        ),
        formComponent: (
          <BarChartForm
            params={createPanelState.inputValues.selectedParams || []}
            onChangeHandler={(data) => setChartData(data)}
            enableCreatebutton={(flag) => setSubmit(flag)}
          />
        )
      },
      PIE_CHART: {
        name: "Pie Chart",
        component: (
          <PieChart
            title={createPanelState.panelName}
            data={data}
            category="Item A"
            colors={["yellow", "blue", "green"]}
            datakey="hosts"
            variant="donut"
          />
        ),
        formComponent: (
          <PieChartForm
            params={createPanelState.inputValues.selectedParams || []}
            onChangeHandler={(data) => setChartData(data)}
            enableCreatebutton={(flag) => setSubmit(flag)}
          />
        )
      },
      GAUGE_1: {
        name: "Gauge 1",
        component: (
          <GaugeChart
            minValue={panelData.minThres}
            maxValue={panelData.maxThres}
            min={panelData.minDisplay}
            max={panelData.maxDisplay}
            title={createPanelState.panelName}
          />
        ),
        formComponent: (
          <GaugeForm
            onChangeHandler={handleGaugeChange}
            initState={INITIAL_GAUGE_STATE}
          />
        )
      },
      GAUGE_2: {
        name: "Gauge 2",
        component: (
          <TempChart
            title={createPanelState.panelName}
            minValue={panelData.minThres}
            maxValue={panelData.maxThres}
            min={panelData.minDisplay}
            max={panelData.maxDisplay}
            showExample
          />
        ),
        formComponent: (
          <GaugeForm
            onChangeHandler={handleGaugeChange}
            initState={INITIAL_GAUGE_STATE}
          />
        )
      },
      NUMERIC: {
        name: "Number Infographic",
        component: (
          <NumericInfoGraph
            // datakey="amount"
            // category="year"
            // dataPrev={metricData[0]}
            // dataCurr={metricData[1]}
            title={createPanelState.panelName}
            maxValue={1000}
            minValue={0}
            showEample
            info={38.9}
          />
        ),
        formComponent: (
          <InfoGraphForm
            params={createPanelState.inputValues.selectedParams || []}
            onChangeHandler={(data) => setChartData(data)}
            enableCreatebutton={(flag) => setSubmit(flag)}
          />
        )
      },
      HEATMAP: {
        name: "Map Demo",
        component: (
          <HeatMap
            title="heatmap"
            configValues={createHeatmapInputValues}
            showSampleHeatmap
          />
        ),
        formComponent: (
          <HeatMapForm
            params={createPanelState.inputValues.selectedParams || []}
            onChangeHandler={(data) => setChartData(data)}
            enableCreatebutton={(flag) => setSubmit(flag)}
          />
        )
      },
      TABLE: {
        name: "Table",
        component: (
          <TableComponent
            title="table"
            showSampleTable
            onChangeHandler={(data) => setChartData(data)}
          />
        ),
        formComponent: (
          <TableForm
            params={createPanelState.inputValues.selectedParams || []}
            onChangeHandler={(data) => setChartData(data)}
            enableCreatebutton={(flag) => setSubmit(flag)}
          />
        )
      }
    }),
    [
      createHeatmapInputValues,
      createPanelState.inputValues.selectedParams,
      createPanelState.panelName,
      panelData.maxDisplay,
      panelData.maxThres,
      panelData.minDisplay,
      panelData.minThres
    ]
  );

  return (
    <main className="flex-1 mx-8 py-4 pb-0 mt-7 overflow-y-scroll space-y-4 mr-28 lg:px-8 sm:px-6 bg-background text-contentColor rounded-md">
      <div className="flex w-full">
        <div className="w-6/12 relative">
          <div className="mt-7 mb-5 w-11/12">
            <PanelCreationSteps />
          </div>
          <div>
            <h1 className="text-lg text-left font-medium mb-2.5">
              Set Panel Options
            </h1>
            <div className="relative overflow-visible flex flex-col pr-2">
              {createPanelState.selectedPanel === "TABLE" ? (
                PANELS[activePanel]?.formComponent || null
              ) : (
                <PanelDataSourceSelector
                  onChangeHandler={setChartData}
                  enableCreatebutton={(flag) => setSubmit(flag)}
                  graphDefinitions={graphDefinitions}
                >
                  {PANELS[activePanel]?.formComponent ?? null}
                </PanelDataSourceSelector>
              )}
            </div>
          </div>
          <div className="w-11/12 flex items-center my-12 space-x-4">
            <button
              onClick={() => {
                resetInputValues();
                clearCreatePanelState();
                navigate("/dashboard/new-panel-type");
              }}
              className="w-1/2 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-background-layer1"
            >
              Back
            </button>

            <button
              onClick={handleClick}
              disabled={submit}
              className={`w-1/2 px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none   ${
                !submit
                  ? "hover:bg-opacity-80 bg-primary"
                  : "bg-gray-500 cursor-not-allowed"
              }`}
            >
              Create
            </button>
          </div>
        </div>

        <div className="w-6/12">
          <div className="ml-8 mt-7 bg-background-layer0.5 rounded relative">
            <div className="px-5 py-7 mb-7 relative">
              <div className="flex items-center">
                <InformationCircleIcon className="w-5 h-5 text-primaryLight" />

                <h1 className="font-medium text-sm ml-3.5 text-primaryLight">
                  Example Chart
                </h1>
              </div>
              <div className=" flex justify-center items-center">
                <div className="w-[95%] flex pb-2 flex-col bg-background-layer1 my-5 rounded-md">
                  {PANELS[activePanel]?.component ?? null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default DashboardNewPanelInfo;
