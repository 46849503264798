import produce from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../../app/shared/config";
import { IDevice, IFleet, IProject } from "../../interfaces";

export interface IFleetCreationFlow {
  fleet_name: string;
  fleet_description?: string;
}

export interface IDeviceCreationFlow {
  device_name?: string;
  newly_created_device_id?: string;
}

interface FleetAndDevicesState {
  projects: IProject[];
  fleets: IFleet[];
  fleetCreation: IFleetCreationFlow;
  devices: IDevice[];
  deviceCreation: IDeviceCreationFlow;
  combinedDevices: IDevice[];
  selectedProject: IProject;
  selectedFleet: IFleet;
  setProjects: (x: IProject[]) => void;
  setSelectedProject: (x: IProject) => void;
  setFleets: (x: IFleet[]) => void;
  setFleetCreation: (x: IFleetCreationFlow) => void;
  setSelectedFleet: (x: IFleet) => void;
  setDevices: (x: IDevice[]) => void;
  setDeviceCreation: (x: IDeviceCreationFlow) => void;
  setCombinedDevices: (x: IDevice[]) => void;
}

const useFleetAndDevicesStore = create<FleetAndDevicesState>()(
  persist(
    devtools(
      (set) => ({
        projects: [],
        fleets: [],
        fleetCreation: {} as IFleetCreationFlow,
        devices: [],
        deviceCreation: {} as IDeviceCreationFlow,
        combinedDevices: [],
        selectedProject: {} as IProject,
        selectedFleet: {} as IFleet,
        setProjects: (projectsItems) =>
          set(
            produce((state) => {
              state.projects = projectsItems;
            }),
            false,
            "fleet-and-devices/setProjects"
          ),
        setSelectedProject: (project) =>
          set(
            produce((state) => {
              state.selectedProject = project;
            }),
            false,
            "fleet-and-devices/setSelectedProject"
          ),
        setFleets: (fleetItems) =>
          set(
            produce((state) => {
              state.fleets = fleetItems;
            }),
            false,
            "fleet-and-devices/setFleets"
          ),
        setFleetCreation: (data) =>
          set(
            produce((state) => {
              state.fleetCreation = { ...data };
            }),
            false,
            "fleet-and-devices/setFleetCreation"
          ),
        setSelectedFleet: (fleet) =>
          set(
            produce((state) => {
              state.selectedFleet = fleet;
            }),
            false,
            "fleet-and-devices/setSelectedFleet"
          ),
        setDevices: (deviceItems) =>
          set(
            produce((state) => {
              state.devices = deviceItems;
            }),
            false,
            "fleet-and-devices/setDevices"
          ),
        setDeviceCreation: (data) =>
          set(
            produce((state) => {
              state.deviceCreation = { ...data };
            }),
            false,
            "fleet-and-devices/setDeviceCreation"
          ),
        setCombinedDevices: (deviceItems) =>
          set(
            produce((state) => {
              state.combinedDevices = deviceItems;
            }),
            false,
            "fleet-and-devices/setCombinedDevices"
          )
      }),
      { name: "fleet-and-devices", serialize: { options: true } }
    ),
    {
      name: "fleet-and-devices", // unique name
      getStorage: () => appConstants.keys.storage // (optional) by default, 'localStorage' is used
    }
  )
);

export default useFleetAndDevicesStore;
