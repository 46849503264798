import React, { useEffect, useMemo, useState } from "react";
import Editor from "@monaco-editor/react";
import Modal from "@app/shared/components/modal.component";
import ActionTypeDropdown from "../action-type-dropdown.component";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
  ActionType,
  IContext,
  IInput
} from "@app/rule-engine/rule-engine.helper";

interface IUserMetadataActionDefinitionEditor {
  actionDefinition: any;
  setActionDefinition: (x: any) => void;
  setActionType: (v: ActionType) => void;
  actionType: ActionType;
  fetchedContexts: IContext[];
  inputs: IInput[];
}

interface IUserMetadataActionDefinition {
  user_id: string;
  payload: any;
}

const UserMetadataActionDefinitionEditor: React.FC<
  IUserMetadataActionDefinitionEditor
> = ({
  actionDefinition,
  setActionDefinition,
  setActionType,
  actionType,
  fetchedContexts,
  inputs
}) => {
  const [userId, setUserId] = useState(actionDefinition.userId ?? "");
  const [payload, setPayload] = useState(actionDefinition.payload ?? {});

  const [jsonEditorModalOpen, setJsonEditorModalOpen] = useState(false);
  const [modalJSON, setModalJSON] = useState<IUserMetadataActionDefinition>();

  const hasInvalidVarsError = useMemo(() => {
    let val = userId;

    if (val) {
      if (val.startsWith("{{") && val.endsWith("}}") && val.length > 4) {
        let toTest = val.substring(2, val.length - 2);
        if (
          fetchedContexts.some((el) => el.key === toTest) ||
          inputs?.some((input) => input.key === toTest)
        ) {
          return false;
        } else {
          return true;
        }
      }
    }

    let fieldValue = userId;

    if (!fieldValue) {
      return false;
    }

    if (!val) {
      return false;
    }

    return false;
  }, [userId, fetchedContexts, inputs]);

  useEffect(() => {
    setActionDefinition({
      userId,
      payload,
      hasErrors: hasInvalidVarsError || !userId.length
    });
  }, [userId, payload, setActionDefinition, hasInvalidVarsError]);

  return (
    <>
      <div className="bg-background-layer1 w-full h-full p-4 rounded-md border border-background-layer3">
        <div className="flex justify-between">
          <ActionTypeDropdown
            setActionType={setActionType}
            actionTypes={ActionType}
            setActionDefinition={setActionDefinition}
            buttonComponent={
              <div className="flex gap-2 bg-background-layer3 rounded-sm px-2 py-1 items-center">
                <span className="uppercase">{actionType}</span>
                <ChevronDownIcon width={16} />
              </div>
            }
          />
          <button
            className="bg-background-layer3 text-contentColor px-2 py-1 rounded-sm"
            onClick={() => setJsonEditorModalOpen(true)}
          >
            JSON
          </button>
        </div>
        {/* User ID */}
        <div className="flex flex-col gap-2 nodrag mt-2">
          <input
            placeholder="User ID (UUID)"
            value={userId}
            className="nodrag bg-background w-full px-2 py-1 text-contentColor border-background-layer3 rounded-sm border-2"
            onChange={(e) => setUserId(e.target.value.trim())}
          />
          {hasInvalidVarsError ? (
            <span className="text-red-500">
              Invalid Variabled reference: {userId}
            </span>
          ) : !userId.length ? (
            <span className="text-red-500">Device ID cannot be empty.</span>
          ) : null}
        </div>

        {/* Payload */}
        <div className="flex flex-col gap-2 nodrag mt-2">
          <h3 className="text-sm">Payload:</h3>
          <Editor
            height="200px"
            width="100%"
            theme="vs-dark"
            language="json"
            value={JSON.stringify(payload, null, 2)}
            onChange={(e) => {
              try {
                setPayload(JSON.parse(e));
              } catch (err) {}
            }}
            options={{
              readOnly: false,
              minimap: {
                enabled: false
              },
              lineNumbers: "off"
            }}
          />
        </div>
      </div>
      <Modal
        open={jsonEditorModalOpen}
        setOpen={setJsonEditorModalOpen}
        title="JSON Editor"
        className="w-full max-w-2xl"
        disableClickOutside
      >
        <div className="flex flex-col gap-4 p-6 bg-background-layer1 text-contentColor">
          <h1 className="text-lg font-bold">Set User Metadata Action JSON</h1>
          <p>
            All your changes will be overwritten using this JSON editor.
            <br />
          </p>
          <Editor
            height="500px"
            width="100%"
            theme="vs-dark"
            language="json"
            value={JSON.stringify(modalJSON ?? {}, null, 2)}
            onChange={(e) => {
              try {
                setModalJSON(JSON.parse(e));
              } catch (err) {}
            }}
            options={{
              readOnly: false,
              minimap: {
                enabled: false
              }
            }}
          />
          <div className="flex gap-4 justify-end">
            <button
              className="bg-background-layer3 text-contentColor px-2 py-1 rounded-sm"
              onClick={() => setJsonEditorModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="bg-green-500 text-contentColor px-2 py-1 rounded-sm"
              onClick={() => {
                const { user_id, payload } = modalJSON;

                setUserId(user_id);
                setPayload(payload);

                setJsonEditorModalOpen(false);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UserMetadataActionDefinitionEditor;
