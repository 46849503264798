import React from "react";
import L from "leaflet";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import { fData } from "./ride-mock-data";

import "leaflet/dist/leaflet.css";

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const MapEnergy = ({ title }) => {
  return (
    <div className="h-[90%] p-2 mb-2">
      <MapContainer
        center={[18.5323391, 73.84241486]}
        zoom={12}
        zoomSnap={0.45}
        style={{
          width: "100%",
          height: "100%",
          marginTop: "6px",
          borderRadius: "6px",
          zIndex: 0
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
        />
        <GeoJSON
          key={""}
          onEachFeature={(feature, layer) => {
            layer.bindPopup(`Speed: ${feature.properties.speed.toFixed(2)}`);
            layer.on("mouseover", function (e) {
              this.openPopup();
            });
            layer.on("mouseout", function (e) {
              this.closePopup();
            });
          }}
          style={(feature) => {
            return {
              color: feature.properties.color,
              weight: 3,
              fillOpacity: 0.5,
              fillColor: feature.properties.color
            };
          }}
          // @ts-ignore
          data={fData}
        />
      </MapContainer>
    </div>
  );
};

export default React.memo(MapEnergy);
