import { useState, useEffect, useMemo, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import Select from "react-select";
import { isEmpty } from "lodash";
import { Switch } from "@headlessui/react";
import { useDashboardStore } from "@store/index";
import { Button } from "@tremor/react";
import { PlusIcon } from "@heroicons/react/24/outline";

interface IInfoInput {
  infoParams: any;
  label: string;
  aggParam: string;
  time: string;
  paramName: string;
  labelName: string;
  timeName: string;
  aggParamName: string;
  showMin?: boolean;
  showMax?: boolean;
  unit: string;
}

interface IInfoError {
  infoParams: boolean;
  label: boolean;
  time: boolean;
  aggParam: boolean;
}

export const InfoGraphForm = ({
  onChangeHandler,
  enableCreatebutton,
  params
}) => {
  const [infos, setInfos] = useState([]);
  const [infoInputs, setInfoInputs] = useState<IInfoInput[]>([]);
  const [error, setErrors] = useState<IInfoError[]>([]);

  const [selectOptions, setSelectOptions] = useState(params);

  const [setCreatePanelAppearance] = useDashboardStore((state) => [
    state.setCreatePanelAppearance
  ]);

  useEffect(() => {
    setSelectOptions(params);
  }, [params]);

  const initialValues = useMemo(
    () =>
      infoInputs.map((line) => {
        return {
          [line.timeName]: "",
          [line.paramName]: "",
          [line.labelName]: ""
        };
      }),
    [infoInputs]
  );

  const aggregationParams = [
    { value: "max", label: "Max" },
    { value: "min", label: "Min" },
    { value: "mean", label: "Mean" },
    { value: "sum", label: "Sum" }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleAddLine = (e: any) => {
    if (infos.length < params.length) {
      const newEntry = infos.length;
      setInfos((infos) => [...infos, newEntry]);
      setErrors((errors) => [
        ...errors,
        { infoParams: false, time: false, aggParam: false, label: false }
      ]);
      setInfoInputs((inputs) => [
        ...inputs,
        {
          infoParams: {},
          time: "",
          aggParam: "",
          label: "",
          timeName: `infoLabel_${newEntry}`,
          paramName: `infoParam_${newEntry}`,
          labelName: `infoLabel_${newEntry}`,
          aggParamName: `infoAggParam_${newEntry}`,
          showMin: false,
          showMax: false,
          unit: "KWHr"
        }
      ]);
    }
  };

  const handleChange = (idx: number, key: string, value: any) => {
    let newInputs = [...infoInputs];
    newInputs[idx][key] = value;
    setInfoInputs(newInputs);
    if (key === "showMin" || key === "showMax" || key === "unit") {
      setCreatePanelAppearance((prev) => ({
        ...prev,
        [key]: value
      }));
    }
  };

  const isValid = useCallback(() => {
    let errVal = true;
    let errors = [...error];

    infos.forEach((bar) => {
      if (!infoInputs[bar].label.length) {
        errors[bar].label = true;
        errVal = false;
      } else errors[bar].label = false;

      // if (!infoInputs[bar].time.length) {
      //   errors[bar].time = true;
      //   errVal = false;
      // } else errors[bar].time = false;

      if (!infoInputs[bar].aggParam.length) {
        errors[bar].aggParam = true;
        errVal = false;
      } else errors[bar].aggParam = false;

      if (isEmpty(infoInputs[bar].infoParams)) {
        errors[bar].infoParams = true;
        errVal = false;
      } else errors[bar].infoParams = false;
    });

    setErrors(errors);

    return errVal;
  }, [error, infoInputs, infos]);

  useEffect(() => {
    if (infoInputs.length) {
      if (isValid()) {
        onChangeHandler(infoInputs);
        enableCreatebutton(false);
      } else {
        enableCreatebutton(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoInputs]);

  const getBorderStyle = (error: boolean) => {
    return {
      control: (base) =>
        error
          ? {
              ...base,
              borderColor: "red",
              boxShadow: "none",
              padding: "4px",
              ":hover": { borderColor: "red" },
              ":focus": { borderColor: "red" }
            }
          : { ...base, padding: "4px" }
    };
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        {infos.map((ele, index) => (
          <div
            key={index}
            className="mb-2 border-b pb-2 border-background-layer3"
          >
            <div className="flex flex-row">
              <div className="w-6/12">
                <label className="text-sm font-medium text-gray-600">
                  Info Parameter
                </label>
                <Field
                  as="select"
                  id={infoInputs[ele].infoParams}
                  name={infoInputs[ele].infoParams}
                  placeholder="Info Parameter"
                  component={Select}
                  options={selectOptions}
                  classNames={{
                    menu: () => "!bg-background-layer1 !text-contentColor",
                    control: () =>
                      `!bg-background !text-contentColor ${
                        error[ele]["infoParams"]
                          ? "!border-red-500 focus:!ring-red-300 focus:!border-red-400"
                          : "!border-background-layer3 focus:!ring-blue-300 focus:!border-blue-400"
                      } sm:!text-sm`,
                    valueContainer: () => "!text-contentColor",
                    singleValue: () => "!text-contentColor",
                    menuList: () => "!text-contentColor",
                    option: () =>
                      "!text-contentColor hover:!bg-background-layer2 !bg-background-layer1 !border-background-layer3",
                    noOptionsMessage: () =>
                      "!text-contentColor !bg-background-layer1",
                    multiValue: () =>
                      "!bg-background-layer3 !text-contentColor",
                    multiValueLabel: () => "!text-contentColor"
                  }}
                  className={`block w-10/12 bg-background mt-2 border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm`}
                  onChange={(e) => handleChange(ele, "infoParams", e)}
                  maxMenuHeight={180}
                />
              </div>
              <div className="w-6/12">
                <label className="text-sm font-medium text-gray-600">
                  Label
                </label>
                <Field
                  type="text"
                  id={infoInputs[ele].labelName}
                  name={infoInputs[ele].labelName}
                  placeholder="Info Label"
                  autoComplete="off"
                  value={infoInputs[ele].label}
                  onChange={(e) => handleChange(ele, "label", e.target.value)}
                  className={`block w-10/12 bg-background mt-2 border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm ${
                    error[ele]["label"]
                      ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                      : "border-background-layer3 focus:ring-blue-300 focus:border-blue-400"
                  }`}
                />
              </div>
            </div>
            <div className="flex flex-row my-2">
              <div className="w-6/12">
                <label className="text-sm font-medium text-gray-600">
                  Aggregation Parameter
                </label>
                <Field
                  as="select"
                  id={infoInputs[ele].aggParamName}
                  name={infoInputs[ele].aggParamName}
                  classNames={{
                    menu: () => "!bg-background-layer1 !text-contentColor",
                    control: () =>
                      `!bg-background !p-0 !text-contentColor ${
                        error[ele]["aggParam"]
                          ? "!border-red-500 focus:!ring-red-300 focus:!border-red-400"
                          : "!border-background-layer3 focus:!ring-blue-300 focus:!border-blue-400"
                      } sm:!text-sm`,
                    valueContainer: () => "!text-contentColor",
                    singleValue: () => "!text-contentColor",
                    menuList: () => "!text-contentColor",
                    option: () =>
                      "!text-contentColor hover:!bg-background-layer2 !bg-background-layer1 !border-background-layer3",
                    noOptionsMessage: () =>
                      "!text-contentColor !bg-background-layer1",
                    multiValue: () =>
                      "!bg-background-layer3 !text-contentColor",
                    multiValueLabel: () => "!text-contentColor"
                  }}
                  className={`block w-10/12 bg-background mt-2 border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm`}
                  placeholder="Aggregation Parameter"
                  component={Select}
                  options={aggregationParams}
                  styles={getBorderStyle(error[ele]["aggParam"])}
                  onChange={(e) => handleChange(ele, "aggParam", e.value)}
                  maxMenuHeight={180}
                />
              </div>
              <div className="w-6/12">
                <label className="text-sm font-medium text-gray-600">
                  Unit
                </label>
                <Field
                  type="text"
                  id={infoInputs[ele].unit}
                  name={infoInputs[ele].unit}
                  placeholder="Unit"
                  autoComplete="off"
                  value={infoInputs[ele].unit}
                  onChange={(e) => handleChange(ele, "unit", e.target.value)}
                  className={`block w-10/12 bg-background mt-2 border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm`}
                />
              </div>
            </div>
            <div className="flex flex-row my-2 mt-4">
              <div className="w-6/12 flex items-center gap-1">
                <label className="text-sm font-medium text-gray-600">
                  Show Min Value
                </label>
                <Switch
                  checked={infoInputs[ele].showMin}
                  onChange={(val) => handleChange(ele, "showMin", val)}
                  className={`${
                    infoInputs[ele].showMin ? "bg-green-500" : "bg-gray-200"
                  } relative inline-flex h-5 w-9 items-center rounded-full`}
                >
                  <span className="sr-only">Enable Show Min</span>
                  <span
                    className={`${
                      infoInputs[ele].showMin
                        ? "translate-x-5"
                        : "translate-x-1"
                    } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                  />
                </Switch>
              </div>
              <div className="w-6/12 flex items-center gap-1">
                <label className="text-sm font-medium text-gray-600">
                  Show Max Value
                </label>
                <Switch
                  checked={infoInputs[ele].showMax}
                  onChange={(val) => handleChange(ele, "showMax", val)}
                  className={`${
                    infoInputs[ele].showMax ? "bg-green-500" : "bg-gray-200"
                  } relative inline-flex h-5 w-9 items-center rounded-full`}
                >
                  <span className="sr-only">Enable Show Max</span>
                  <span
                    className={`${
                      infoInputs[ele].showMax
                        ? "translate-x-5"
                        : "translate-x-1"
                    } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                  />
                </Switch>
              </div>
            </div>
          </div>
        ))}
        <Button
          disabled={infos.length >= params.length}
          className={`text-sm bg-primary hover:bg-primaryLight border-b border-transparent my-2 ${
            infos.length < params.length
              ? "cursor-pointer hover:border-primary"
              : "text-gray-600 cursor-not-allowed"
          }`}
          onClick={handleAddLine}
        >
          <span className="flex gap-2">
            <PlusIcon width={16} /> Add Info
          </span>
        </Button>
      </Form>
    </Formik>
  );
};
