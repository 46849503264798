import { decode } from "js-base64";
import { useEffect, useState } from "react";
import { useAuthStore, useFleetAndDevicesStore } from "../../../store";
import { networkService } from "../../../services";
import { useNavigate } from "react-router-dom";
import { Info } from "../../shared/icons";
import { generateCertsH } from "./fad-certi.helper";
import { Tooltip } from "react-tooltip";
import { ProgressBar } from "../../shared/components";
import { ArrowDownCircleIcon } from "@heroicons/react/24/outline";

interface ICertificates {
  "connection_settings.json": string;
  "device_cert.pem": string;
  "device_private_key.pem": string;
  "mqtt_broker_cert.pem": string;
  "root_ca_cert.pem": string;
}

interface ICertificateResponse {
  certificates: ICertificates;
  device: string;
  ok: number;
}

const convertB64ToURL = (b64Str: string) => {
  return window.URL.createObjectURL(new Blob([decode(b64Str)]));
};

const DownloadCertificates = () => {
  const navigate = useNavigate();
  const [user] = useAuthStore((state) => [state.user]);
  const [selectedProject, selectedFleet, deviceCreation, setDeviceCreation] =
    useFleetAndDevicesStore((state) => [
      state.selectedProject,
      state.selectedFleet,
      state.deviceCreation,
      state.setDeviceCreation
    ]);

  const [certificates, setCertificates] = useState<ICertificates>(undefined);
  const [certsHUrl, setCertsHUrl] = useState<string>("");
  const [loadingProgress, setLoadingProgress] = useState<number | null>(null);

  useEffect(() => {
    let timer;

    const updateLoaderProgress = () => {
      // fake timer
      if (loadingProgress === 100) {
        clearTimeout(timer);
        setLoadingProgress(null);
        return;
      } else {
        setLoadingProgress((prev) => {
          if (prev === null) {
            return 0;
          }

          if (prev === 99) {
            return prev;
          }

          return prev + 1;
        });
      }

      // give a realistic delay with random number
      let newTimerDelay = Math.floor(Math.random() * 200) + 50;
      if (!certificates) {
        timer = setTimeout(() => {
          updateLoaderProgress();
        }, newTimerDelay);
      }
    };

    updateLoaderProgress();

    let fakeLoaderStoppedAt = null;

    const fetchCertificates = async () => {
      setLoadingProgress(0);
      const certificateResponse =
        await networkService.post<ICertificateResponse>(
          `projects/${selectedProject.id}/fleets/${selectedFleet.id}/devices/${deviceCreation.newly_created_device_id}/certificates`,
          {},
          {
            headers: { "ORG-ID": user.selectedOrg.id },
            onDownloadProgress: (progressEvent) => {
              if (fakeLoaderStoppedAt === null) {
                clearTimeout(timer);
                fakeLoaderStoppedAt = loadingProgress;
              }

              let percentCompleted = Math.floor(
                (progressEvent.loaded / progressEvent.total) * 100
              );

              if (percentCompleted === 100) {
                setLoadingProgress(null);
                return;
              }

              let newLoadingProgress =
                fakeLoaderStoppedAt +
                ((100 - loadingProgress) / 100) * percentCompleted;

              setLoadingProgress(
                newLoadingProgress <= 100 ? newLoadingProgress : 100
              );
            },
            noLoader: true
          }
        );

      if (certificateResponse.ok) {
        if (certificateResponse.certificates) {
          setCertificates(certificateResponse.certificates);
          let device_cert = decode(
            certificateResponse.certificates["device_cert.pem"]
          );
          let device_private_key = decode(
            certificateResponse.certificates["device_private_key.pem"]
          );
          let root_ca_cert = decode(
            certificateResponse.certificates["root_ca_cert.pem"]
          );

          let connection_settings = JSON.parse(
            decode(
              certificateResponse.certificates["connection_settings.json"]
            )
          );

          const certsH = generateCertsH(
            device_cert,
            device_private_key,
            root_ca_cert,
            connection_settings
          );

          const blob = new Blob([certsH], { type: "text/plain" });
          const url = window.URL.createObjectURL(blob);
          setCertsHUrl(url);
        }
      }
    };

    if (!certificates && deviceCreation?.newly_created_device_id) {
      fetchCertificates();
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownload = (b64Str: string, filename: string) => {
    const url = convertB64ToURL(b64Str);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const handleDone = () => {
    setDeviceCreation({ device_name: "", newly_created_device_id: "" });
    navigate("/fleet-and-devices/projects");
  };

  return (
    <>
      <div>
        <div className="py-6 border-b border-background-layer3 mb-5">
          <h1 className="font-medium text-2xl">
            Download certificate and keys
          </h1>
          <h1 className="py-1 font-normal text-sm opacity-50">
            Download certificate and key files to install on your device
          </h1>
        </div>
        {certificates ? (
          <div className="flex w-full h-full">
            <div className="w-1/2 h-full">
              <div className="flex flex-col mb-5">
                <label className="font-medium text-lg mb-3">
                  Server Public Certificate{" "}
                </label>
                <label className="font-normal text-xs opacity-70 mb-2">
                  You can activate certificate now, or later. The certificate
                  must be active for a device to connect to Golain.
                </label>
                <label className="font-normal text-xs text-contentColorLight">
                  Server Public Certificate
                </label>
                <div className="flex">
                  <input
                    type="text"
                    value={
                      certificates["mqtt_broker_cert.pem"]
                        ? "mqtt_broker_cert.pem"
                        : ""
                    }
                    readOnly
                    className="flex w-full p-3 mt-2 mr-2 bg-background text-contentColor border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                  />

                  <button
                    onClick={() =>
                      handleDownload(
                        certificates["mqtt_broker_cert.pem"],
                        "mqtt_broker_cert.pem"
                      )
                    }
                    className="flex items-center justify-center mt-2 ml-2 px-6 space-x-2 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-background-layer1"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_2478_28552)">
                        <path
                          d="M2.25 14.25H15.75V15.75H2.25V14.25ZM9.75 9.879L14.3032 5.325L15.3638 6.3855L9 12.75L2.63625 6.38625L3.69675 5.325L8.25 9.8775V1.5H9.75V9.879Z"
                          fill="#546CCC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2478_28552">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>Download</span>
                  </button>
                </div>
              </div>
              <div className="border-background-layer3 border-b w-full mb-5"></div>
              <div className="flex flex-col mb-5">
                <label className="font-medium text-lg mb-3">
                  Device Certificates{" "}
                </label>
                <label className="font-normal text-xs opacity-70 mb-2">
                  The key files are unique to this certificate and can’t
                  downloaded after you leave this page. Download them now and
                  save in a secure place.
                </label>
                <label className="flex items-center font-normal space-x-2 text-xs text-[#E21B17]">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM9 5H11V7H9V5ZM9 9H11V15H9V9Z"
                      fill="#E21B17"
                    />
                  </svg>
                  <span>
                    This is the only time you can download these key files.
                  </span>
                </label>
                <div className="mb-5">
                  <label className="font-normal text-xs text-contentColorLight">
                    Device Certificates
                  </label>
                  <div className="flex">
                    <input
                      type="text"
                      value={
                        certificates["device_cert.pem"]
                          ? "device_cert.pem"
                          : ""
                      }
                      readOnly
                      className="flex w-full p-3 mt-2 mr-2 bg-background text-contentColor border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                    />

                    <button
                      onClick={() =>
                        handleDownload(
                          certificates["device_cert.pem"],
                          "device_cert.pem"
                        )
                      }
                      className="flex items-center justify-center mt-2 ml-2 px-6 space-x-2 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-background-layer1"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_2478_28552)">
                          <path
                            d="M2.25 14.25H15.75V15.75H2.25V14.25ZM9.75 9.879L14.3032 5.325L15.3638 6.3855L9 12.75L2.63625 6.38625L3.69675 5.325L8.25 9.8775V1.5H9.75V9.879Z"
                            fill="#546CCC"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2478_28552">
                            <rect width="18" height="18" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span>Download</span>
                    </button>
                  </div>
                </div>

                <div className="mb-5">
                  <label className="font-normal text-xs text-contentColorLight">
                    Private Key
                  </label>
                  <div className="flex">
                    <input
                      type="text"
                      value={
                        certificates["device_private_key.pem"]
                          ? "device_private_key.pem"
                          : ""
                      }
                      readOnly
                      className="flex w-full p-3 mt-2 mr-2 bg-background text-contentColor border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                    />

                    <button
                      onClick={() =>
                        handleDownload(
                          certificates["device_private_key.pem"],
                          "device_private_key.pem"
                        )
                      }
                      className="flex items-center justify-center mt-2 ml-2 px-6 space-x-2 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-background-layer1"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_2478_28552)">
                          <path
                            d="M2.25 14.25H15.75V15.75H2.25V14.25ZM9.75 9.879L14.3032 5.325L15.3638 6.3855L9 12.75L2.63625 6.38625L3.69675 5.325L8.25 9.8775V1.5H9.75V9.879Z"
                            fill="#546CCC"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2478_28552">
                            <rect width="18" height="18" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span>Download</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-background-layer3 border-l mx-7"></div>
            <div className="w-1/2">
              <div className="flex flex-col mb-5">
                <label className="font-medium text-lg mb-3">
                  Root Certificate{" "}
                </label>
                <label className="font-normal text-xs opacity-70 mb-2">
                  Download the room CA certificate file that corresponds to the
                  type of data endpoint and cipher suite you are using.{" "}
                </label>
                <div className="mb-5">
                  <label className="font-normal text-xs text-contentColorLight">
                    Root Certificate
                  </label>
                  <div className="flex">
                    <input
                      type="text"
                      value={
                        certificates["root_ca_cert.pem"]
                          ? "root_ca_cert.pem"
                          : ""
                      }
                      readOnly
                      className="flex w-full p-3 mt-2 mr-2 bg-background text-contentColor border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                    />

                    <button
                      onClick={() =>
                        handleDownload(
                          certificates["root_ca_cert.pem"],
                          "root_ca_cert.pem"
                        )
                      }
                      className="flex items-center justify-center mt-2 ml-2 px-6 space-x-2 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-background-layer1"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_2478_28552)">
                          <path
                            d="M2.25 14.25H15.75V15.75H2.25V14.25ZM9.75 9.879L14.3032 5.325L15.3638 6.3855L9 12.75L2.63625 6.38625L3.69675 5.325L8.25 9.8775V1.5H9.75V9.879Z"
                            fill="#546CCC"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2478_28552">
                            <rect width="18" height="18" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span>Download</span>
                    </button>
                  </div>
                </div>

                <div className="mb-5">
                  <label className="font-normal text-xs text-contentColorLight">
                    Connection Settings [json]
                  </label>
                  <div className="flex">
                    <input
                      type="text"
                      value={
                        certificates["connection_settings.json"]
                          ? "connection_settings.json"
                          : ""
                      }
                      readOnly
                      className="flex w-full p-3 mt-2 mr-2 bg-background text-contentColor border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                    />

                    <button
                      onClick={() =>
                        handleDownload(
                          certificates["connection_settings.json"],
                          "connection_settings.json"
                        )
                      }
                      className="flex items-center justify-center mt-2 ml-2 px-6 space-x-2 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-background-layer1"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_2478_28552)">
                          <path
                            d="M2.25 14.25H15.75V15.75H2.25V14.25ZM9.75 9.879L14.3032 5.325L15.3638 6.3855L9 12.75L2.63625 6.38625L3.69675 5.325L8.25 9.8775V1.5H9.75V9.879Z"
                            fill="#546CCC"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2478_28552">
                            <rect width="18" height="18" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span>Download</span>
                    </button>
                  </div>
                </div>
              </div>
              <a
                href={certsHUrl}
                data-tooltip-id="download-certi-tooltip"
                data-tooltip-content="Paste this file in the include/ folder of your PlatformIO project."
                download={"certs.h"}
                className="text-[#546CCC] flex items-center gap-2 italic mb-4"
              >
                <ArrowDownCircleIcon width={22} />
                <span>
                  Download certs.h header file for PlatformIO project.
                </span>
              </a>
              <button
                onClick={handleDone}
                className="px-16 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
              >
                Done
              </button>
            </div>
          </div>
        ) : deviceCreation?.newly_created_device_id ? (
          loadingProgress && (
            <div className="flex items-center justify-center w-full h-[55vh]">
              <div className="flex flex-col items-center justify-center">
                <ProgressBar progress={loadingProgress} />
              </div>
            </div>
          )
        ) : (
          <div className="flex items-center justify-center w-full h-[55vh]">
            <div className="flex flex-col items-center teconli justify-center">
              No certificates available to download!
            </div>
          </div>
        )}
      </div>
      <Tooltip id="download-certi-tooltip" />
    </>
  );
};

export default DownloadCertificates;
