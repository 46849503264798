import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  BaseSyntheticEvent
} from "react";
import { Formik, Form, Field } from "formik";
import Select from "react-select";
import useCreateTableStore from "@store/dashboard/create-table.store";
import ColumnItem from "./table-form-column-item.component";
import {
  Badge,
  AccordionList,
  Accordion,
  AccordionHeader,
  AccordionBody
} from "@tremor/react";
import { PlusIcon, SparklesIcon } from "@heroicons/react/24/outline";
import { Switch } from "@headlessui/react";
import ColumnParamLabel from "./table-form-column-param-label.component";
import { isObject } from "@utils/helper.util";
import dateService from "@services/date.service";
import { useGetDataPointDefinitions } from "@app/shared/hooks/get";
import { useGetShadowDefinitions } from "@app/shared/hooks/get/shadow-definitions";
import { useGetDevices } from "@app/shared/hooks/get/devices";
import { useGetDeviceDataPoints } from "@app/shared/hooks/get/device-data-points";

interface ITableError {
  dataSource: boolean;
  columns: true;
}

const DATE_FORMAT = "YYYY-MM-DD HH:mm";

const timePeriodSelectOptions = [
  { value: "m", label: "Months" },
  { value: "w", label: "Weeks" },
  { value: "d", label: "Days" },
  { value: "h", label: "Hours" },
  { value: "mins", label: "Minutes" }
];

const timeBucketSelectOptions = [
  { value: "mins", label: "Minutes" },
  { value: "hours", label: "Hours" },
  { value: "days", label: "Days" }
];

const tagOptions = [
  {
    value: "tag-1",
    label: "Tag 1"
  },
  {
    value: "tag-2",
    label: "Tag 2"
  }
];

export const TableForm = ({ params, onChangeHandler, enableCreatebutton }) => {
  const [devicesSelectOptions, setDevicesSelectOptions] = useState([]);
  const [allParams, setAllParams] = useState<any[]>([]);

  const [selectedColumnToEdit, setSelectedColumnToEdit] = useState(null);
  const [startTimePeriodUnit, setStartTimePeriodUnit] = useState("m");
  const [endTimePeriodUnit, setEndTimePeriodUnit] = useState("d");

  const [inputValues, setColumns, setInputValues] = useCreateTableStore(
    (state) => [state.inputValues, state.setColumns, state.setInputValues]
  );

  const { columns } = inputValues;

  const [error, setErrors] = useState<ITableError>({
    dataSource: true,
    columns: true
  });

  const initialValues = useMemo(
    () => ({
      "table-parameter": ""
    }),
    []
  );

  const { data: dataPointDefs } = useGetDataPointDefinitions({
    fields: "data_point_proto_structure"
  });

  const dataPointDefsSelectOptions = useMemo(
    () =>
      dataPointDefs?.map((dp) => ({
        value: dp.id,
        label: dp.name
      })) || [],
    [dataPointDefs]
  );

  const { data: shadowDefs } = useGetShadowDefinitions();

  const shadowsSelectOptions = useMemo(
    () =>
      shadowDefs?.map((shadowDef) => ({
        value: shadowDef.id,
        label: shadowDef.name
      })) || [],
    [shadowDefs]
  );

  const { data: devices } = useGetDevices();

  const { data: deviceDataPoints } = useGetDeviceDataPoints();

  useEffect(() => {
    const params = [];

    if (inputValues.dataPoints.length && dataPointDefs?.length) {
      inputValues.dataPoints.forEach((dp) => {
        const dataPoint = dataPointDefs.find((d) => d.id === dp.value);
        Object.keys(dataPoint.data_point_proto_structure).forEach(
          (paramKey) => {
            const param = dataPoint.data_point_proto_structure[paramKey];

            if (
              columns.find(
                (c) => c.parameter.value === dataPoint.id + "/" + param.id
              )
            ) {
              return;
            }

            if (isObject(param.structure)) {
              return;
            }

            params.push({
              value: dataPoint.name + "/" + paramKey,
              label: (
                <ColumnParamLabel
                  paramName={paramKey}
                  dataSourceName={dataPoint.name}
                  dataSource={"dataPoint"}
                />
              ),
              dataSourceName: dataPoint.name,
              dataSource: "dataPoint",
              name: paramKey,
              type: param.structure,
              enumBadgeColours: param.options?.reduce((acc, curr) => {
                acc[curr] = "gray";
                return acc;
              }, {})
            });
          }
        );
      });
    }

    if (inputValues.shadow?.id) {
      Object.keys(inputValues.shadow.shadow_proto_structure).forEach(
        (paramKey) => {
          params.push({
            value: inputValues.shadow.name + "/" + paramKey,
            label: (
              <ColumnParamLabel
                paramName={paramKey}
                dataSourceName={inputValues.shadow.name}
                dataSource={"shadow"}
              />
            ),
            dataSourceName: inputValues.shadow.name,
            dataSource: "shadow",
            name: paramKey
          });
        }
      );
    }

    setAllParams(params);

    return () => {};
  }, [
    columns,
    dataPointDefs,
    inputValues.dataPoints,
    inputValues.shadow,
    shadowDefs
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleChange = (key: string, value: any) => {
    let newInputs = { ...inputValues };

    if (key === "dataPoints" && value.length && value[0] !== null) {
      setErrors({ ...error, dataSource: false });

      const devicesInDp = [];
      value.forEach((dp) => {
        deviceDataPoints.forEach((ddp) => {
          if (ddp.data_point_definition_id === dp.value) {
            devicesInDp.push({
              id: ddp.device_id,
              name: devices.find((d) => d.id === ddp.device_id).device_name
            });
          }
        });
      });

      newInputs.devicesInDataPoints = devicesInDp;
      setDevicesSelectOptions(
        devicesInDp.map((d) => ({
          value: d.id,
          label: d.name
        }))
      );
    }

    if (key === "shadow" && value) {
      const devicesInShadow = devices.filter(
        (d) => d.shadow_definition_id === value
      );

      newInputs.devicesInDataPoints = devicesInShadow;
      newInputs.devicesInDataPoints = devicesInShadow;

      setDevicesSelectOptions(
        devicesInShadow.map((d) => ({
          value: d.id,
          label: d.device_name
        }))
      );
    }

    if (key === "timePeriod") {
      newInputs.startDate = dateService
        .parseRelativeDate(value.start)
        .format(DATE_FORMAT);
      newInputs.endDate = dateService
        .parseRelativeDate(value.end)
        .format(DATE_FORMAT);
    }

    if (key === "shadow") {
      if (value) {
        setErrors({ ...error, dataSource: false });
        newInputs.shadow = shadowDefs.find((s) => s.id === value);
      } else {
        newInputs.shadow = {};
        newInputs.columns = inputValues.columns.filter(
          (c) => c.parameter.dataSource !== "shadow"
        );
        if (!inputValues.dataPoints.length) {
          setErrors({ ...error, dataSource: true });
          newInputs.devicesInDataPoints = [];
          setDevicesSelectOptions([]);
        }
      }
    } else if (key === "dataPoints") {
      if (value[0] === null) {
        newInputs.dataPoints = [];
        newInputs.columns = inputValues.columns.filter(
          (c) => c.parameter.dataSource !== "dataPoint"
        );
        if (!inputValues.shadow?.id) {
          setErrors({ ...error, dataSource: true });
          newInputs.devicesInDataPoints = [];
          setDevicesSelectOptions([]);
        }
      } else {
        newInputs.dataPoints = value;
      }
    } else if (key === "devices") {
      newInputs.filters = { ...newInputs.filters, devices: value };
    } else if (key === "tags") {
      newInputs.filters = { ...newInputs.filters, tags: value };
    } else {
      newInputs[key] = value;
    }

    setInputValues(newInputs);
  };

  const isValid = useCallback(() => {
    let hasErrors = false;
    let errors: ITableError = { ...error };

    if (!inputValues.dataPoints?.length && !inputValues.shadow) {
      errors.dataSource = true;
      hasErrors = true;
    } else if (inputValues.columns.length === 0) {
      errors.columns = true;
      hasErrors = true;
    } else {
      errors.dataSource = false;
      hasErrors = false;
    }

    setErrors(errors);

    return !hasErrors;
  }, [error, inputValues]);

  useEffect(() => {
    if (isValid()) {
      enableCreatebutton && enableCreatebutton(false);
      onChangeHandler && onChangeHandler([inputValues]);
    } else {
      enableCreatebutton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues]);

  const newParamInNewColumn = useMemo(() => {
    let param =
      allParams.find(
        (p) => !columns.find((c) => c.parameter.value === p.value)
      ) ?? null;
    return param;
  }, [allParams, columns]);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        <div className="mb-2">
          <AccordionList>
            <Accordion className="overflow-visible">
              <AccordionHeader>Data Config</AccordionHeader>
              <AccordionBody className="text-black">
                <div className="flex flex-col">
                  {/* Select Data Source - Data Points and/or Shadow */}
                  <div className="w-10/12">
                    <label className="text-sm font-medium text-gray-600">
                      Data Points
                    </label>
                    <Field
                      as="select"
                      id={"table-data-points"}
                      name={"table-data-points"}
                      value={inputValues.dataPoints}
                      placeholder="Data Points"
                      // isMulti
                      isClearable={true}
                      options={dataPointDefsSelectOptions}
                      component={Select}
                      styles={{
                        control: (base) => ({
                          ...base,
                          borderColor: error.dataSource ? "red" : "#E5E7EB"
                        })
                      }}
                      className={`block w-10/12 2xl
                   mt-1 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                     error.dataSource
                       ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                       : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                   }`}
                      onChange={(e) => {
                        handleChange("dataPoints", [e]);
                      }}
                    />
                  </div>
                  <div className="w-10/12 mt-2">
                    <label className="text-sm font-medium text-gray-600">
                      Shadow
                    </label>
                    <Field
                      as="select"
                      id={"table-shadow"}
                      name={"table-shadow"}
                      value={
                        inputValues.shadow
                          ? {
                              label: inputValues.shadow.name,
                              value: inputValues.shadow.id
                            }
                          : undefined
                      }
                      isClearable={true}
                      placeholder="Shadow"
                      options={shadowsSelectOptions}
                      component={Select}
                      styles={{
                        control: (base) => ({
                          ...base,
                          borderColor: error.dataSource ? "red" : "#E5E7EB"
                        })
                      }}
                      className={`block w-10/12 2xl
                   mt-1 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                     error.dataSource
                       ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                       : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                   }`}
                      onChange={(e) => {
                        handleChange("shadow", e?.value ?? null);
                      }}
                    />
                  </div>
                  {error.dataSource && (
                    <p className="text-xs text-red-500 mt-1">
                      Please select either a data-point-definition or a shadow
                      as a data-source!
                    </p>
                  )}
                </div>
                <div className="flex flex-row my-4 gap-4">
                  {/* START DATE */}
                  <div className="w-6/12">
                    <label className="text-sm font-medium text-gray-600">
                      Start Date
                    </label>
                    <div>
                      <div className="flex">
                        <Field
                          as="input"
                          type="number"
                          id={"heatmap-start-date"}
                          name={"heatmap-start-date"}
                          placeholder="-1"
                          max={0}
                          value={parseInt(inputValues.timePeriod.start)}
                          className={`block w-10/12 lg:w-6/12 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                            error["heatmap-params"]
                              ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                              : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                          }`}
                          onChange={(e: BaseSyntheticEvent) =>
                            handleChange("timePeriod", {
                              start:
                                parseInt(e.target.value) + startTimePeriodUnit,
                              end: inputValues.timePeriod.end
                            })
                          }
                        />
                        <Field
                          id={"heatmap-time-period-unit"}
                          name={"heatmap-time-period-unit"}
                          placeholder="Months"
                          component={Select}
                          options={timePeriodSelectOptions}
                          value={timePeriodSelectOptions.filter(
                            (opt) => opt.value === startTimePeriodUnit
                          )}
                          className={`block w-10/12 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                            error["lineParams"]
                              ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                              : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                          }`}
                          onChange={(e) => {
                            setStartTimePeriodUnit(e.value as string);
                            handleChange("timePeriod", {
                              start:
                                parseInt(inputValues.timePeriod.start) +
                                e.value,
                              end: inputValues.timePeriod.end
                            });
                          }}
                          maxMenuHeight={140}
                        />
                      </div>
                    </div>
                  </div>
                  {/* END DATE */}
                  <div className="w-6/12">
                    <label className="text-sm font-medium text-gray-600">
                      End Date
                    </label>
                    <div>
                      <div className="flex">
                        <Field
                          as="input"
                          type="number"
                          id={"heatmap-end-date"}
                          name={"heatmap-end-date"}
                          placeholder="-1"
                          max={0}
                          // min={parseInt(inputValues.timePeriod.start)}
                          value={parseInt(inputValues.timePeriod.end)}
                          className={`block w-10/12 lg:w-6/12 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                            error["heatmap-params"]
                              ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                              : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                          }`}
                          onChange={(e: BaseSyntheticEvent) =>
                            handleChange("timePeriod", {
                              end:
                                parseInt(e.target.value) + endTimePeriodUnit,
                              start: inputValues.timePeriod.start
                            })
                          }
                        />
                        <Field
                          id={"heatmap-time-period-unit"}
                          name={"heatmap-time-period-unit"}
                          placeholder="Months"
                          component={Select}
                          options={timePeriodSelectOptions}
                          value={timePeriodSelectOptions.filter(
                            (opt) => opt.value === endTimePeriodUnit
                          )}
                          className={`block w-10/12 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                            error["lineParams"]
                              ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                              : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                          }`}
                          onChange={(e) => {
                            setEndTimePeriodUnit(e.value as string);
                            handleChange("timePeriod", {
                              end:
                                parseInt(inputValues.timePeriod.end) + e.value,
                              start: inputValues.timePeriod.start
                            });
                          }}
                          maxMenuHeight={140}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex gap-2 items-center mt-4 mb-2">
                  <label className="text-base font-medium text-gray-600">
                    Aggregate Parameters:
                  </label>
                  <Switch
                    checked={inputValues.aggregateParams}
                    onChange={(val) => handleChange("aggregateParams", val)}
                    className={`${
                      inputValues.aggregateParams
                        ? "bg-green-500"
                        : "bg-gray-200"
                    } relative inline-flex h-5 w-9 items-center rounded-full`}
                  >
                    <span className="sr-only">Enable notifications</span>
                    <span
                      className={`${
                        inputValues.aggregateParams
                          ? "translate-x-5"
                          : "translate-x-1"
                      } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                    />
                  </Switch>
                </div>
                {/* TIME BUCKET */}
                {inputValues.aggregateParams && (
                  <div className="w-6/12">
                    <label className="text-sm font-medium text-gray-600">
                      Aggregation Time Bucket
                    </label>
                    <div className="flex">
                      <Field
                        as="input"
                        type="number"
                        id={"table-column-time-bucket"}
                        name={"table-column-time-bucket"}
                        placeholder="1"
                        min={1}
                        value={inputValues.timeBucket}
                        className={`block w-10/12 lg:w-6/12 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                          false
                            ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                            : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                        }`}
                        onChange={(e: BaseSyntheticEvent) => {
                          handleChange("timeBucket", e.target.value);
                        }}
                      />
                      <Field
                        as="select"
                        id={"table-column-time-bucket-unit"}
                        name={"table-column-time-bucket-unit"}
                        placeholder="Days"
                        component={Select}
                        options={timeBucketSelectOptions}
                        value={timeBucketSelectOptions.filter(
                          (opt) => opt.value === inputValues.timeBucketUnit
                        )}
                        className={`block w-10/12 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                          false
                            ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                            : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                        }`}
                        onChange={(e) => {
                          handleChange("timeBucketUnit", e.value);
                        }}
                        maxMenuHeight={180}
                      />
                    </div>
                  </div>
                )}
              </AccordionBody>
            </Accordion>

            <Accordion className="overflow-visible">
              <AccordionHeader>
                <div className="flex gap-3">
                  <span>Filters</span>
                  {inputValues.filters.devices?.length ? (
                    <Badge color={"stone"}>
                      Devices: {inputValues.filters.devices?.length}
                    </Badge>
                  ) : null}
                  {inputValues.filters.tags?.length ? (
                    <Badge color={"stone"}>
                      Tags: {inputValues.filters.tags?.length}
                    </Badge>
                  ) : null}
                </div>
              </AccordionHeader>
              <AccordionBody className="text-black">
                <div className="w-10/12">
                  <label className="text-sm font-medium text-gray-600">
                    Devices
                  </label>
                  <Field
                    as="select"
                    id={"table-devices"}
                    name={"table-devices"}
                    value={inputValues.filters?.devices ?? []}
                    placeholder="All Devices"
                    isMulti
                    options={devicesSelectOptions}
                    component={Select}
                    className={`block w-10/12 2xl mt-1 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm
                               border-gray-300 focus:ring-blue-300 focus:border-blue-400`}
                    onChange={(e) => {
                      handleChange("devices", e);
                    }}
                  />
                </div>
                <div className="w-10/12 mt-2">
                  <label className="text-sm font-medium text-gray-600">
                    Tags
                  </label>
                  <Field
                    as="select"
                    id={"table-tags"}
                    name={"table-tags"}
                    value={inputValues.filters?.tags ?? []}
                    placeholder="Select Tags"
                    isMulti
                    options={tagOptions}
                    component={Select}
                    className={`block w-10/12 2xl mt-1 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm 
                              border-gray-300 focus:ring-blue-300 focus:border-blue-400`}
                    onChange={(e) => {
                      handleChange("tags", e);
                    }}
                  />
                </div>
              </AccordionBody>
            </Accordion>

            <Accordion>
              <AccordionHeader>
                <div className="flex gap-3">
                  <span>Columns</span>
                  <Badge
                    tooltip={
                      !inputValues.columns.length
                        ? "Add at least 1 column!"
                        : undefined
                    }
                    color={inputValues.columns.length ? "stone" : "red"}
                  >
                    {inputValues.columns.length}
                  </Badge>
                </div>
              </AccordionHeader>
              <AccordionBody className="text-black max-h-72 overflow-y-scroll">
                <div className="flex flex-col gap-4">
                  {inputValues.dataPoints.length ? (
                    newParamInNewColumn && (
                      <div className="flex gap-2">
                        <Badge
                          size="xs"
                          className="cursor-pointer select-none"
                          onClick={() => {
                            let param = newParamInNewColumn;
                            setColumns([
                              {
                                label:
                                  param.type === "string"
                                    ? param.name + "_freq"
                                    : param.name,
                                parameter: param,
                                aggregationMode: "avg"
                              },
                              ...columns
                            ]);
                          }}
                        >
                          <span className="flex gap-1 text-xs">
                            <PlusIcon width={14} />
                            Add Column
                          </span>
                        </Badge>
                        <Badge
                          color="emerald"
                          size="xs"
                          className="cursor-pointer select-none"
                          onClick={() => {
                            setColumns([
                              ...allParams
                                .filter(
                                  (p) =>
                                    !columns.find(
                                      (c) => c.parameter.value === p.value
                                    )
                                )
                                .map((param) => ({
                                  label:
                                    param.type === "string"
                                      ? param.name + "_freq"
                                      : param.name,
                                  parameter: param,
                                  aggregationMode: "avg"
                                })),
                              ...columns
                            ]);
                          }}
                        >
                          <span className="flex gap-1 text-xs">
                            <SparklesIcon width={14} />
                            Add All Columns
                          </span>
                        </Badge>
                      </div>
                    )
                  ) : (
                    <div className="flex justify-center items-center h-28">
                      Select a data source to add columns!
                    </div>
                  )}
                  {(inputValues.dataPoints.length || inputValues.shadow?.id) &&
                    columns.map((col, idx) => (
                      <ColumnItem
                        key={idx}
                        index={idx}
                        setColumns={(columns) => {
                          setColumns(columns);
                          handleChange("columns", columns);
                        }}
                        setSelectedColumnToEdit={setSelectedColumnToEdit}
                        paramOptions={
                          allParams.filter(
                            (p) =>
                              !columns.find(
                                (c) => c.parameter.value === p.value
                              )
                          ) ?? []
                        }
                        editOpen={selectedColumnToEdit === idx}
                        allParams={allParams}
                        inputValues={inputValues}
                      />
                    ))}
                </div>
              </AccordionBody>
            </Accordion>
          </AccordionList>
        </div>
      </Form>
    </Formik>
  );
};
