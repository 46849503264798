import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { FleetAndDevicesProjects } from ".";
import FleetHeader from "./components/fleet-header.component";

const FleetAndDevicesProvider: React.FC = () => {
  const location = useLocation();

  const flag =
    location.pathname === "/fleet-and-devices/projects" ||
    location.pathname === "/fleet-and-devices/projects/";

  return (
    <>
      <FleetHeader
        baseURL={"/fleet-and-devices/projects"}
        showCreate
        showDelete
        showRefresh
      />
      {flag ? (
        <>
          <FleetAndDevicesProjects />
        </>
      ) : (
        <>
          <main className="flex-1 flex flex-col mx-8 mt-7 xl:mr-28 lg:px-8 sm:px-6 bg-background text-contentColor rounded-md">
            <Outlet />
          </main>
        </>
      )}
    </>
  );
};

export default FleetAndDevicesProvider;
