import { useState, useEffect, useMemo, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import Select from "react-select";
import { isEmpty } from "lodash";
import { Button } from "@tremor/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useDashboardStore } from "@store/index";

interface ILineInput {
  barParams: any;
  label: string;
  color: string;
  aggParam: string;
  labelName: string;
  colorName: string;
  paramName: string;
  aggParamName: string;
}

interface ILineError {
  barParams: boolean;
  label: boolean;
  color: boolean;
  aggParam: boolean;
}

const barOrientationOptions = [
  { value: "horizontal", label: "Horizontal" },
  { value: "vertical", label: "Vertical" }
];

export const BarChartForm = ({
  onChangeHandler,
  enableCreatebutton,
  params
}) => {
  const [bars, setBars] = useState([]);
  const [barsInputs, setBarsInputs] = useState<ILineInput[]>([]);
  const [error, setErrors] = useState<ILineError[]>([]);

  const [selectOptions, setSelectOptions] = useState(params);

  const [createPanelAppearance, setCreatePanelAppearance] = useDashboardStore(
    (state) => [state.createPanelAppearance, state.setCreatePanelAppearance]
  );

  useEffect(() => {
    setSelectOptions(params);
  }, [params]);

  const initialValues = useMemo(
    () =>
      barsInputs.map((line) => {
        return {
          [line.labelName]: "",
          [line.colorName]: "",
          [line.paramName]: ""
        };
      }),
    [barsInputs]
  );

  const colorOptions = [
    { value: "slate", label: "slate" },
    { value: "gray", label: "gray" },
    { value: "zinc", label: "zinc" },
    { value: "neutral", label: "neutral" },
    { value: "stone", label: "stone" },
    { value: "red", label: "red" },
    { value: "orange", label: "orange" },
    { value: "amber", label: "amber" },
    { value: "yellow", label: "yellow" },
    { value: "lime", label: "lime" },
    { value: "green", label: "green" },
    { value: "emerald", label: "emerald" },
    { value: "teal", label: "teal" },
    { value: "cyan", label: "cyan" },
    { value: "sky", label: "sky" },
    { value: "blue", label: "blue" },
    { value: "indigo", label: "indigo" },
    { value: "violet", label: "violet" },
    { value: "purple", label: "purple" },
    { value: "fuchsia", label: "fuchsia" },
    { value: "pink", label: "pink" },
    { value: "rose", label: "rose" }
  ];

  const aggregationParams = [
    { value: "max", label: "Max" },
    { value: "min", label: "Min" },
    { value: "mean", label: "Mean" },
    { value: "sum", label: "Sum" },
    { value: "count", label: "Count" }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleAddLine = (e: any) => {
    e.preventDefault();
    if (bars.length < params.length) {
      const newEntry = bars.length;
      setBars((bars) => [...bars, newEntry]);
      setErrors((errors) => [
        ...errors,
        { barParams: false, label: false, color: false, aggParam: false }
      ]);
      setBarsInputs((inputs) => [
        ...inputs,
        {
          barParams: {},
          label: "",
          color: "",
          aggParam: "",
          labelName: `barLabel_${newEntry}`,
          colorName: `barColor_${newEntry}`,
          paramName: `barParam_${newEntry}`,
          aggParamName: `barAggParam_${newEntry}`
        }
      ]);
    }
  };

  const handleChange = (idx: number, key: string, value: any) => {
    let newInputs = [...barsInputs];
    newInputs[idx][key] = value;
    setBarsInputs(newInputs);
  };

  const isValid = useCallback(() => {
    let errVal = true;
    let errors = [...error];

    bars.forEach((bar) => {
      if (!barsInputs[bar].label.length) {
        errors[bar].label = true;
        errVal = false;
      } else errors[bar].label = false;

      if (!barsInputs[bar].color.length) {
        errors[bar].color = true;
        errVal = false;
      } else errors[bar].color = false;

      if (!barsInputs[bar].aggParam.length) {
        errors[bar].aggParam = true;
        errVal = false;
      } else errors[bar].aggParam = false;

      if (isEmpty(barsInputs[bar].barParams)) {
        errors[bar].barParams = true;
        errVal = false;
      } else errors[bar].barParams = false;
    });

    setErrors(errors);

    return errVal;
  }, [bars, barsInputs, error]);

  useEffect(() => {
    if (barsInputs.length) {
      if (isValid()) {
        onChangeHandler(barsInputs);
        enableCreatebutton(false);
      } else {
        enableCreatebutton(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barsInputs]);

  const getBorderStyle = (error: boolean) => {
    return {
      control: (base) =>
        error
          ? {
              ...base,
              borderColor: "red",
              boxShadow: "none",
              padding: "4px",
              ":hover": { borderColor: "red" },
              ":focus": { borderColor: "red" }
            }
          : { ...base, padding: "4px" }
    };
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        <div className="w-6/12">
          <label className="text-sm font-medium text-gray-600">
            Orientation
          </label>
          <Field
            as="select"
            id={"bar-chart-orientation"}
            name={"bar-chart-orientation"}
            placeholder="Orientation"
            component={Select}
            options={barOrientationOptions}
            className={`block w-10/12 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm border-background-layer3 focus:ring-primaryLight focus:border-primary`}
            value={
              createPanelAppearance.orientation ?? barOrientationOptions[0]
            }
            onChange={(val) =>
              setCreatePanelAppearance({
                orientation: val
              })
            }
            maxMenuHeight={180}
          />
        </div>
        <hr className="border-background-layer3 mt-3 mb-2" />
        {bars.map((ele, index) => (
          <div key={index} className="mb-2">
            <div className="flex flex-row">
              <div className="w-6/12">
                <label className="text-sm font-medium text-gray-600">
                  Line Parameter
                </label>
                <Field
                  as="select"
                  id={barsInputs[ele].barParams}
                  name={barsInputs[ele].barParams}
                  placeholder="Bar Parameter"
                  component={Select}
                  options={selectOptions}
                  styles={getBorderStyle(error[ele]["barParams"])}
                  className={`block w-10/12 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                    error[ele]["lineParams"]
                      ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                      : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                  }`}
                  onChange={(e) => handleChange(ele, "barParams", e)}
                  maxMenuHeight={180}
                />
              </div>
              <div className="w-6/12">
                <label className="text-sm font-medium text-gray-600">
                  Label
                </label>
                <Field
                  type="text"
                  id={barsInputs[ele].labelName}
                  name={barsInputs[ele].labelName}
                  placeholder="Bar Label"
                  autoComplete="off"
                  value={barsInputs[ele].label}
                  onChange={(e) => handleChange(ele, "label", e.target.value)}
                  className={`block w-10/12 p-3 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                    error[ele]["label"]
                      ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                      : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                  }`}
                />
              </div>
            </div>
            <div className="flex flex-row my-2 border-b-2">
              <div className="w-6/12">
                <label className="text-sm font-medium text-gray-600">
                  Aggregation Parameter
                </label>
                <Field
                  as="select"
                  id={barsInputs[ele].aggParamName}
                  name={barsInputs[ele].aggParamName}
                  className={`block w-10/12 py-3 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                    error[ele]["aggParam"]
                      ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                      : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                  }`}
                  placeholder="Aggregation Parameter"
                  component={Select}
                  options={aggregationParams}
                  styles={getBorderStyle(error[ele]["aggParam"])}
                  onChange={(e) => handleChange(ele, "aggParam", e.value)}
                  maxMenuHeight={180}
                />
              </div>
              <div className="w-6/12">
                <label className="text-sm font-medium text-gray-600">
                  Color
                </label>
                <Field
                  as="select"
                  id={barsInputs[ele].colorName}
                  name={barsInputs[ele].colorName}
                  className={`block w-10/12 py-3 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                    error[ele]["color"]
                      ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                      : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                  }`}
                  placeholder="Bar Color"
                  component={Select}
                  options={colorOptions}
                  styles={getBorderStyle(error[ele]["color"])}
                  onChange={(e) => handleChange(ele, "color", e.value)}
                  maxMenuHeight={180}
                />
              </div>
            </div>
          </div>
        ))}

        <Button
          disabled={bars.length >= params.length}
          tooltip={
            bars.length >= params.length
              ? "Select a few paramaters first."
              : ""
          }
          className={`text-sm bg-primary hover:bg-primaryLight border-b border-transparent my-2 ${
            bars.length < params.length
              ? "cursor-pointer hover:border-primary"
              : "text-gray-600 cursor-not-allowed "
          }`}
          onClick={handleAddLine}
        >
          <span className="flex gap-2">
            <PlusIcon width={16} /> Add Bar{" "}
          </span>
        </Button>
      </Form>
    </Formik>
  );
};
