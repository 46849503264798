import produce from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../../app/shared/config";

export interface IOnboardingDetails {
  org_name: string;
  type: string;
  website_link?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

interface IOnboardingState {
  onboardingDetails: IOnboardingDetails;
  setOnboardingDetails: (x: IOnboardingDetails) => void;
}

const useOnboardingStore = create<IOnboardingState>()(
  persist(
    devtools(
      (set) => ({
        onboardingDetails: {} as IOnboardingDetails,
        setOnboardingDetails: (details) =>
          set(
            produce((state) => {
              state.onboardingDetails = details;
            })
          )
      }),
      { name: "onboarding-flow", serialize: { options: true } }
    ),
    {
      name: "onboarding-flow", // unique name
      getStorage: () => appConstants.keys.storage // (optional) by default, 'localStorage' is used
    }
  )
);

export default useOnboardingStore;
