import { useEffect, useRef } from "react";
import { useConfirmDelete } from "../hooks/use-confirm-delete.hooks";
import Modal from "./modal.component";

const ConfirmDeleteModal = () => {
  const deleteButtonRef = useRef(null);
  const {
    handleDeleteConfirm,
    closeConfirmModal,
    isOpen,
    message,
    setIsOpen
  } = useConfirmDelete();

  useEffect(() => {
    // focus delete button
    if (isOpen && deleteButtonRef.current) {
      deleteButtonRef.current.focus();
    }
  }, [isOpen]);

  const handleDelete = () => {
    handleDeleteConfirm();
  };

  return (
    <Modal open={isOpen} setOpen={setIsOpen}>
      <div className="flex flex-col gap-4 p-6 max-w-md bg-background text-contentColor">
        <h2 className="text-xl font-semibold mb-2">Confirm Delete</h2>
        <p className="mb-4">{message}</p>
        <div className="flex justify-end">
          <button
            onClick={closeConfirmModal}
            className="px-4 py-2 border border-background-layer3 rounded mr-2"
          >
            Cancel
          </button>
          <button
            ref={deleteButtonRef}
            onClick={handleDelete}
            className="px-4 py-2 bg-red-500 text-white rounded"
          >
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;
