import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../store";

const Logout: React.FC = () => {
  const navigate = useNavigate()
  const setAuthState = useAuthStore((state) => state.setAuthState);

  React.useEffect(() => {
    setAuthState();
    sessionStorage.removeItem("casdoor-state");
    sessionStorage.clear();
    navigate("/");
  }, [setAuthState, navigate]);

  return (<>Logout</>);
};

export default Logout;
