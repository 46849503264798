import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";

const HTTPHeadersEditor = ({ headers, setHeaders }) => {
  return (
    <div className="flex flex-col gap-2">
      {headers.map((header, ind) => {
        return (
          <div className="flex gap-2 items-center" key={ind}>
            <input
              placeholder="Key"
              className="nodrag bg-background w-full px-2 py-1 text-contentColor border-background-layer3 rounded-sm border-2"
              value={header.key}
              onChange={(e) => {
                const _headers = [...headers];
                const _header = {
                  ..._headers[ind],
                  key: e.target.value
                };
                _headers[ind] = _header;
                setHeaders(_headers);
              }}
            />
            <input
              placeholder="Value"
              className="nodrag bg-background w-full px-2 py-1 text-contentColor border-background-layer3 rounded-sm border-2"
              value={header.value}
              onChange={(e) => {
                const _headers = [...headers];
                const _header = {
                  ..._headers[ind],
                  value: e.target.value
                };
                _headers[ind] = _header;
                setHeaders(_headers);
              }}
            />
            <button
              className="bg-red-500 text-white p-1 rounded-sm"
              onClick={() => {
                const _headers = [...headers];
                _headers.splice(ind, 1);
                setHeaders(_headers);
              }}
            >
              <XMarkIcon width={16} />
            </button>
          </div>
        );
      })}
      <button
        disabled={headers.some((header) => !header.key?.trim())}
        className="bg-background-layer3 text-contentColor px-2 py-1 rounded-sm disabled:opacity-50"
        onClick={() => {
          const _headers = [...headers];
          _headers.push({ key: "", value: "" });
          setHeaders(_headers);
        }}
      >
        Add Header
      </button>
    </div>
  );
};

export default HTTPHeadersEditor;
