import { useEffect, useRef, useState } from "react";
import dateService from "../../../../services/date.service";

export function interpolateColors(
  color1: string,
  color2: string,
  fraction: number
): string {
  // Validate the fraction (should be between 0 and 1)
  fraction = Math.max(0, Math.min(1, fraction));

  // Extract RGB values from the input colors
  const r1 = parseInt(color1.substring(1, 3), 16);
  const g1 = parseInt(color1.substring(3, 5), 16);
  const b1 = parseInt(color1.substring(5, 7), 16);
  const r2 = parseInt(color2.substring(1, 3), 16);
  const g2 = parseInt(color2.substring(3, 5), 16);
  const b2 = parseInt(color2.substring(5, 7), 16);

  // Interpolate the RGB values
  const rInterpolated = Math.round(r1 + (r2 - r1) * fraction);
  const gInterpolated = Math.round(g1 + (g2 - g1) * fraction);
  const bInterpolated = Math.round(b1 + (b2 - b1) * fraction);

  // Convert back to hexadecimal color representation
  const interpolatedColor = `#${(
    (rInterpolated << 16) |
    (gInterpolated << 8) |
    bInterpolated
  )
    .toString(16)
    .padStart(6, "0")}`;

  return interpolatedColor;
}

export function calculateInterpolationFraction(min, max, value) {
  if (min === max) {
    // If min and max are the same, return 1 if value is equal to min, or 0 otherwise
    return value === min ? 1 : 0;
  } else {
    // Calculate the interpolation fraction using the formula: (value - min) / (max - min)
    return (value - min) / (max - min);
  }
}


export interface IHeatMapInput {
  inputValues: {
    param?: {
      data: {
        cardinality: string;
        name: string;
        structure: string;
      };
      label: string;
      value: string;
    };
    minColor?: string;
    maxColor?: string;
    startDate?: string;
    endDate?: string;
    timeBucket?: number;
    timeBucketUnit?: "mins" | "hours" | "days";
    aggregationMode?:
      | "sum"
      | "avg"
      | "min"
      | "max"
      | "count"
      | "first"
      | "last";
    boxRoundness?: number;
    showValues?: boolean;
    rowsCount?: number;
    timePeriod?: {
      start: string;
      end: string;
    };
  };
  setInputValues: (x: IHeatMapInput["inputValues"]) => void;
  resetInputValues: () => void;
}

export const initialValues: IHeatMapInput["inputValues"] = {
  minColor: "#FFFFFF",
  maxColor: "#FF0000",
  startDate: dateService
    .getMomentDate()
    .subtract(30, "days")
    .format("YYYY-MM-DD"),
  endDate: dateService.getCurrentDate(),
  timeBucket: 1,
  timeBucketUnit: "days",
  aggregationMode: "avg",
  boxRoundness: 0,
  showValues: false,
  rowsCount: 7,
  timePeriod: {
    start: "-5m",
    end: "0d"
  }
};

export const RANDOM_DATA = [
  49, 60, 3, 81, 30, 24, 21, 31, 7, 93, 47, 7, 27, 29, 33, 44, 60, 44, 76, 83,
  54, 51, 17, 80, 87, 94, 39, 83, 21, 97, 2, 43, 19, 92, 15, 92, 6, 17, 96, 61,
  96, 86, 46, 95, 26, 35, 14, 47, 41, 80, 46, 71, 85, 38, 42, 95, 71, 91, 1,
  80, 45, 79, 14, 33, 5, 73, 32, 72, 55, 98, 76, 25, 22, 25, 52, 16, 69, 29,
  80, 37, 63, 60, 70, 59, 81, 99, 75, 47, 81, 80, 11, 76, 47, 92, 72, 19, 12,
  21, 95, 31
];

export const useDebounce = (value, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState("");
  const timerRef = useRef<any>();

  useEffect(() => {
    timerRef.current = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
};
