import React, { memo, useEffect, useMemo, useState } from "react";
import { Handle, Position } from "reactflow";
import NodeTemplate from "../node-template.component";
import RuleTriggerTypeSelector from "@app/rule-engine/rule-trigger-type-selector.component";
import useRuleEngineStore, {
  MQTTDefDirection
} from "@store/rule-engine/rule-engine.store";
import Select from "react-select";
import { reactSelectClassNames } from "@app/shared/utils/helper.util";
import { IOption } from "@interfaces/shared.interface";
import { useGetDevices } from "@app/shared/hooks/get/devices";

const dataKindValues: IOption[] = [
  { label: "Device Shadow", value: "device-shadow" }
];

const directionOptions: IOption[] = [
  { label: "Any", value: "any" },
  { label: "Ingress", value: "ingress" },
  { label: "Egress", value: "egress" }
];

const RuleTriggerTypeNode = ({ data, id, isConnectable }) => {
  const [rules, setRules] = useRuleEngineStore((state) => [
    state.rules,
    state.setRules
  ]);

  const rule = rules[data.name];

  const [direction, setDirection] = useState(
    directionOptions.find(
      (dOpt) => dOpt.value === (rule.definition?.direction ?? "any")
    )
  );

  const { data: devices } = useGetDevices({}, (devices) => {});

  const deviceOptions = useMemo(
    () => devices?.map((d) => ({ label: d.device_name, value: d.id })) || [],
    [devices]
  );

  const [selectedDevices, setSelectedDevices] = useState<IOption[]>([]);

  useEffect(() => {
    setRules((rules) => {
      const _rules = { ...rules };

      _rules[data.name] = {
        ..._rules[data.name],
        definition: {
          ..._rules[data.name].definition,
          direction: direction.value as MQTTDefDirection,
          data_kind: "device-shadow"
        }
      };

      return _rules;
    });

    return () => {};
  }, [direction, data.name, setRules]);

  useEffect(() => {
    if (deviceOptions?.length && rule.definition?.devices?.length) {
      setSelectedDevices(
        rule.definition.devices.map((devId) =>
          deviceOptions.find((d) => d.value === devId)
        )
      );
    }

    return () => {};
  }, [deviceOptions]);

  useEffect(() => {
    if (!deviceOptions.length) return;

    setRules((rules) => {
      const _rules = { ...rules };

      _rules[data.name] = {
        ..._rules[data.name],
        definition: {
          ..._rules[data.name].definition,
          devices: selectedDevices.map((d) => d.value)
        }
      };

      return _rules;
    });
  }, [selectedDevices]);

  return (
    <NodeTemplate
      style={{
        maxWidth: 300
      }}
    >
      <div className="flex items-center gap-1">
        <h3>Trigger Type:</h3>
        <RuleTriggerTypeSelector
          ruleTriggerType={rule.triggerType}
          setRuleTriggerType={(newVal) => {
            const newRules = { ...rules };
            newRules[data.name] = {
              ...newRules[data.name],
              triggerType: newVal
            };

            setRules(newRules);
          }}
        />
      </div>
      {rule.triggerType === "MQTT" ? (
        <>
          <hr className="border-background-layer3 mt-2" />

          <div className="flex flex-col mt-2">
            <label className="text-base font-medium text-contentColor">
              Data Kind
            </label>
            <Select
              placeholder="Data Kind"
              isSearchable={false}
              options={dataKindValues}
              value={dataKindValues[0]}
              // onChange={(e) => handleInput(e, "access")}
              classNames={reactSelectClassNames}
            />
          </div>

          <div className="flex flex-col mt-2 nodrag">
            <label className="text-base font-medium text-contentColor">
              Direction
            </label>
            <Select
              placeholder="Direction"
              isSearchable={false}
              options={directionOptions}
              value={direction}
              onChange={(val: IOption) => setDirection(val)}
              classNames={reactSelectClassNames}
            />
          </div>

          <div className="flex flex-col mt-2 nodrag">
            <label className="text-base font-medium text-contentColor">
              Devices
            </label>
            <Select
              placeholder="Devices"
              isSearchable={false}
              options={deviceOptions}
              value={selectedDevices}
              onChange={(val: IOption[]) => setSelectedDevices(val)}
              classNames={reactSelectClassNames}
              isMulti
            />
          </div>
        </>
      ) : null}
      <Handle
        type="source"
        position={Position.Right}
        id="trigger-type-node-handle"
        isConnectable={false}
      />
    </NodeTemplate>
  );
};

export default memo(RuleTriggerTypeNode);
