import { useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { OnboardingStep } from "../../../interfaces";
import { IServiceProvider } from "./../../../interfaces/service-provider.interface";
import { Buffer } from "buffer";
import { FilterFn } from "@tanstack/react-table";
import { rankItem } from "@tanstack/match-sorter-utils";
import { ClassNamesConfig } from "react-select";

export function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function copyToClipboard(value: string) {
  navigator.clipboard.writeText(value);
  toast.success("Copied to clipboard");
}

export const pasteFromClipboard = async () => {
  return await navigator.clipboard.readText();
};

export function getAuthRedirect(status: string, returnUrl: string = null) {
  const pathname = "/onboarding";

  if (!status) return pathname;
  return status === OnboardingStep.Welcome
    ? `${pathname}/organization-details`
    : status === OnboardingStep.Profile
    ? `${pathname}/availability`
    : status === OnboardingStep.Availability
    ? `${pathname}/organization`
    : returnUrl
    ? returnUrl
    : "/events";
}
export function addMonthsToDate(date: Date, toadd: number = 3): Date {
  date.setMonth(date.getMonth() + toadd);
  return date;
}

export function getPropertyValue<T>(
  object: { [key: string]: any },
  propertyPath: string,
  defaultValue: any = null
): T {
  return doesObjectContainProperty(object, propertyPath)
    ? propertyPath.split(".").reduce((previous, current) => {
        return previous[current];
      }, object)
    : defaultValue;
}

export function doesObjectContainProperty(
  object: { [key: string]: any },
  propertyPath: string
): boolean {
  // If there's nothing to check
  if (typeof object !== "object" || !object || !Object.keys(object).length) {
    return false;
  }

  // If there's nothing to check
  if (!propertyPath || !propertyPath.length) {
    return false;
  }

  try {
    // Iterate through propertyPath to dig into the object
    const finalValue = propertyPath.split(".").reduce((previous, current) => {
      // No hasOwnProperty check
      return typeof previous !== "undefined" && previous !== null
        ? previous[current]
        : undefined;
    }, object);
    // We specifically want to check for undefined & null to check if value exist here
    return typeof finalValue !== "undefined" && finalValue !== null;
  } catch (error) {
    // If the path has a wrong turn, the reduce function will throw an error
    return false;
  }
}
export function getProfilePayload(
  values: IServiceProvider
): Partial<IServiceProvider> {
  const { roles, profilePic, organization, ...payload } = values;
  return payload;
}
export function getOnboardingStep(status: string) {
  if (!status) return "0";
  return status;
}

export function joinWalkthroughNameAndStep(
  walkthroughName: string,
  step: string
) {
  return `${walkthroughName}/${step}`;
}

export function isObject(value) {
  return typeof value === "object" && value !== null && !Array.isArray(value);
}

export function decodeBase64toUTF8(encodedData: string) {
  return Buffer.from(encodedData, "base64").toString("utf8");
}

export const downloadFile = (
  filename: string,
  data: string,
  extension: string = "proto"
) => {
  if (filename) {
    const url = window.URL.createObjectURL(
      new Blob([data], { type: "text/plain" })
    );

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${filename}.${extension}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }
};
export function parseAggregatedEnumsStringToJSON(input: string): any {
  // Replace the outer parentheses with curly braces
  let jsonString = input.replace(/^\(/, "{").replace(/\)$/, "}");

  // Replace colons with commas for arrays
  jsonString = jsonString.replace(/(\[[^\]]+\]):/g, "$1,");

  // Replace all other colons with commas
  jsonString = jsonString.replace(/,(\w+):/g, ',"$1":');

  // Add double quotes around keys
  jsonString = jsonString.replace(/(\w+):/g, '"$1":');

  // Parse the string into a JSON object
  return JSON.parse(jsonString);
}

export const reactTableFuzzyFilter: FilterFn<any> = (
  row,
  columnId,
  value,
  addMeta
) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

export const reactSelectClassNames: ClassNamesConfig = {
  menu: () => "!bg-background-layer1 !text-contentColor",
  control: () =>
    "!bg-background !text-contentColor !border-background-layer3 !rounded-md focus:!ring focus:!ring-opacity-40 focus:!ring-primary focus:!border-primaryLight sm:!text-sm",
  valueContainer: () => "!text-contentColor disabled:!bg-background-layer3",
  singleValue: () => "!text-contentColor disabled:!bg-background-layer3",
  menuList: () => "!text-contentColor",
  option: ({ isDisabled }) =>
    ` hover:!bg-background-layer2 !bg-background-layer1 !border-background-layer3 ${
      isDisabled
        ? "!bg-background-layer0.5 !text-contentColorLight"
        : "!text-contentColor"
    }`,
  noOptionsMessage: () => "!text-contentColor !bg-background-layer1",
  multiValue: () => "!bg-background-layer3 !text-contentColor",
  multiValueLabel: () => "!text-contentColor",
  placeholder: () => "!text-contentColorLighter"
};

type SubjectKeyValue = Record<string, string>;

export function parseX509Subject(subject: string): SubjectKeyValue {
  const keyValuePairs = subject.split(", ");
  const result: SubjectKeyValue = {};

  keyValuePairs.forEach((keyValue) => {
    const [key, value] = keyValue.split("=");
    result[key.trim()] = value.trim();
  });

  return result;
}

export const shadowFieldsGenerator = (structure: any) => {
  const fieldArray = [];
  Object.keys(structure).map((key) => {
    if (
      structure[key].cardinality === "repeated" &&
      structure[key].structure === "array"
    ) {
      fieldArray.push(...shadowFieldsGenerator(structure[key].structure));
    } else {
      fieldArray.push(structure[key]);
    }

    return key;
  });

  return fieldArray;
};
