import { Edge } from "reactflow";
import { IRule } from "@store/rule-engine/rule-engine.store";

export interface IRuleData {
  name?: string;
  description?: string;
  inputs?: IInput[];
  fetchedContexts?: IContext[];
  conditions?: { condition: string }[];
}

export interface IInput {
  key: string;
  type: string;
}

export type IContextType =
  | "device-shadow"
  | "device-location"
  | "device-data"
  | "device-health"
  | "fleet-shadow"
  | "fleet-data"
  | "user-metadata";

export interface IContext {
  key: string;
  type: IContextType;
  device_id: string;
  fleet_id: string;
  user_id: string;
  error?: boolean;
  shadow_definition_id?: string;
}

export interface HTTPResponse {
  status_code: number;
  body: any;
}

interface Variable {
  kind: "device_id" | "fleet_id" | "user_id" | undefined;
  value: string;
}

export interface ICondition {
  statement: string;
  trueSequence?: string[];
  falseSequence?: string[];
  trueResponse?: string;
  falseResponse?: string;
}

export const contextFieldRequiredMap = {
  "device-shadow": ["device_id", "key", "type"],
  "device-location": ["device_id", "key", "type"],
  "device-data": ["key", "type"],
  "device-health": ["device_id", "key", "type"],
  "fleet-shadow": ["fleet_id", "key", "type"],
  "fleet-data": ["fleet_id", "key", "type"],
  "user-metadata": ["key", "type"]
};

export enum ActionType {
  HTTP = "HTTP",
  DEVICE_SHADOW = "DEVICE_SHADOW",
  USER_METADATA = "USER_METADATA",
  USER_ASSOC = "USER_ASSOC",
  USER_DIS_ASSOC = "USER_DIS_ASSOC"
}

export const getId = () => `dndnode_${Math.random()}`;
export const getEdgeId = () => `dndedge_${Math.random()}`;

export function deserializeVariable(variable: string) {
  if (!variable) return "";

  if (variable.startsWith("$")) {
    return `{{${variable.substring(1)}}}`;
  } else {
    return `${variable}`;
  }
}

export function serializeVariable(variable: string) {
  if (!variable) return "";

  if (
    variable.length > 4 &&
    variable.startsWith("{{") &&
    variable.endsWith("}}")
  ) {
    return "$" + variable.substring(2, variable.length - 2);
  } else {
    return variable;
  }
}

export const extractVariableFromContext = (context: IContext) => {
  let variable: Variable = {
    kind: undefined,
    value: ""
  };

  const { device_id, fleet_id, user_id } = context;

  if (device_id) {
    variable.kind = "device_id";
    if (
      device_id.length > 4 &&
      device_id.startsWith("{{") &&
      device_id.endsWith("}}")
    ) {
      variable.value = device_id.substring(2, device_id.length - 2);
    }
  }

  if (user_id) {
    variable.kind = "user_id";
    if (
      user_id.length > 4 &&
      user_id.startsWith("{{") &&
      user_id.endsWith("}}")
    ) {
      variable.value = user_id.substring(2, user_id.length - 2);
    }
  }

  if (fleet_id) {
    variable.kind = "fleet_id";
    if (
      fleet_id.length > 4 &&
      fleet_id.startsWith("{{") &&
      fleet_id.endsWith("}}")
    ) {
      variable.value = fleet_id.substring(2, fleet_id.length - 2);
    }
  }

  return variable;
};

export function getDetailsFromHandleId(handleId: string) {
  const [_, conditionString, conditionType] = handleId.split("-");
  const conditionId = parseInt(conditionString);
  return { conditionId, conditionType };
}

export function deserializeRuleJSON(rule) {
  const ruleData = {
    inputs: Object.keys(rule.additional_params ?? {}).map((key) => ({
      key,
      type: rule.additional_params[key]
    })),
    fetchedContexts: rule.fetched_context?.map((context) => ({
      device_id: deserializeVariable(context.device_id),
      user_id: deserializeVariable(context.user_id),
      fleet_id: deserializeVariable(context.fleet_id),
      shadow_definition_id: context.meta?.shadow_definition_id,
      key: context.key,
      type: context.type,
      error: false
    })),
    conditions: rule.definition.conditions
  };

  const _rule: IRule = {
    name: rule.name,
    description: rule.description,
    triggerType: rule.trigger_type,
    id: rule.id,
    version: rule.version,
    createdAt: rule.created_at
  };

  if (rule.trigger_type === "MQTT") {
    _rule["definition"] = { ...(rule.definition?.definition ?? {}) };
  }

  return { rule: _rule, ruleData };
}

export function serializeRuleJSON(
  ruleDetails: IRule,
  ruleData,
  actionData,
  responseData,
  edges: Edge[]
) {
  const ruleJSON = {
    name: ruleDetails.name,
    description: ruleDetails.description,
    trigger_type: ruleDetails.triggerType,

    additional_params: ruleData.inputs?.reduce((acc, cur) => {
      acc[cur.key] = cur.type;

      return acc;
    }, {}),

    fetched_context: ruleData.fetchedContexts?.map((context: IContext) => {
      const _context = {};

      contextFieldRequiredMap[context.type].forEach((key) => {
        if (key !== "key" && key !== "type") {
          _context[key] = serializeVariable(context[key]);
        } else {
          _context[key] = context[key];
        }
      });

      if (context.type === "user-metadata" && context.user_id.length) {
        _context["user_id"] = serializeVariable(context.user_id);
      }

      if (context.type === "device-shadow") {
        _context["meta"] = {};
        _context["meta"]["shadow_definition_id"] =
          context.shadow_definition_id;
      }

      return _context;
    }),

    conditions: ruleData.conditions?.map((condition, ind) => {
      const conditionObj = {
        condition_statement: condition.statement.replace(/{{(.*?)}}/g, "$1")
      };

      const false_responseEdge = edges.find(
        (edge) =>
          edge.sourceHandle === `conditions-${ind}-false` &&
          edge.targetHandle.startsWith("http-response-")
      );

      if (false_responseEdge) {
        const falseResData = responseData[false_responseEdge.target];

        conditionObj["false_response"] = falseResData;
      }

      const true_responseEdge = edges.find(
        (edge) =>
          edge.sourceHandle === `conditions-${ind}-true` &&
          edge.targetHandle.startsWith("http-response-")
      );

      if (true_responseEdge) {
        const trueResData = responseData[true_responseEdge.target];

        conditionObj["true_response"] = trueResData;
      }

      const false_sequenceEdge = edges.find(
        (edge) =>
          edge.targetHandle?.startsWith("action-sequence-") &&
          edge.sourceHandle === `conditions-${ind}-false`
      );

      if (false_sequenceEdge) {
        const _actionData = Object.keys(
          actionData[false_sequenceEdge.target]
        ).map((actionNodeId) => {
          const _actionData = {
            action_id:
              actionData[false_sequenceEdge.target][actionNodeId].action_id,
            additional_params: Object.keys(
              actionData[false_sequenceEdge.target][actionNodeId].inputValues
            ).reduce((acc, cur) => {
              acc[cur] = serializeVariable(
                actionData[false_sequenceEdge.target][actionNodeId]
                  .inputValues[cur]
              );
              return acc;
            }, {})
          };

          return _actionData;
        });

        conditionObj["false_sequence"] = _actionData;
      }

      const true_sequenceEdge = edges.find(
        (edge) =>
          edge.sourceHandle === `conditions-${ind}-true` &&
          edge.targetHandle.startsWith("action-sequence-")
      );

      if (true_sequenceEdge) {
        const _actionData = Object.keys(
          actionData[true_sequenceEdge.target]
        ).map((actionNodeId) => {
          const _actionData = {
            action_id:
              actionData[true_sequenceEdge.target][actionNodeId].action_id,
            additional_params: Object.keys(
              actionData[true_sequenceEdge.target][actionNodeId].inputValues ||
                {}
            ).reduce((acc, cur) => {
              acc[cur] = serializeVariable(
                actionData[true_sequenceEdge.target][actionNodeId].inputValues[
                  cur
                ]
              );
              return acc;
            }, {})
          };

          return _actionData;
        });

        conditionObj["true_sequence"] = _actionData;
      }

      return conditionObj;
    })
  };

  if (ruleDetails.triggerType === "MQTT") {
    ruleJSON["definition"] = {
      ...ruleDetails.definition
    };
  }

  return ruleJSON;
}

export function validateContextVariable(
  context: IContext,
  shadowDefs: any[],
  varToValidate: string
) {
  const varsSplit = varToValidate.split(".");

  if (varsSplit[0] !== context.key) {
    return false;
  }

  const selectedShadowDef = shadowDefs.find(
    (s) => s.id === context.shadow_definition_id
  );

  if (!selectedShadowDef) {
    console.error(`Shadow Def ID in context ${context.key} does not exist.`);
    return false;
  }

  const shadowProto = selectedShadowDef.shadow_proto_structure;

  let currentLevel = shadowProto;

  for (let i = 1; i < varsSplit.length; i++) {
    const currentVarPart = varsSplit[i];

    if (currentLevel.hasOwnProperty(currentVarPart)) {
      // Move to the next level in the structure
      currentLevel = currentLevel[currentVarPart].structure;

      // If the current level is a leaf node (no more nested structure), exit the loop
      if (!currentLevel) {
        break;
      }
    } else {
      // The current variable part is not found in the structure
      return false;
    }
  }

  // If the loop completes without returning false, the variable is valid
  return true;
}
