import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import UserAuthenticationHeader from "./components/ua-header.component";
import { UserAuthenticationProjects } from ".";

const UserAuthenticationProvider: React.FC = () => {
  const location = useLocation();

  const flag =
    location.pathname === "/user-authentication/projects" ||
    location.pathname === "/user-authentication/projects/";

  return (
    <>
      <UserAuthenticationHeader />
      {flag ? (
        <>
          <UserAuthenticationProjects />
        </>
      ) : (
        <>
          <main className="flex-1 mx-8 mt-7 space-y-4 lg:mr-28 overflow-y-auto lg:px-8 sm:px-6 bg-background text-contentColor rounded-md">
            <Outlet />
          </main>
        </>
      )}
    </>
  );
};

export default UserAuthenticationProvider;
