import { Tooltip } from "react-tooltip";
import useWalkthroughStore from "../../../store/walkthrough.store";
import { joinWalkthroughNameAndStep } from "../utils/helper.util";
import ArrowRight from "../icons/arrow-right.icon";
import ArrowLeft from "../icons/arrow-left.icon";
import { Close } from "../icons";
import { useLocation } from "react-router-dom";

const walkthroughFlows = {
  "golain-overview": {
    collaborators: {
      title: "Invite Collaborators!",
      img: "https://bacrialm.sirv.com/walkthrough/collaborators.png",
      next: "create-device",
      content: "Invite your team to collaborate on your project.",
      prev: ""
    },
    "create-device": {
      title: "Create a Device!",
      img: "https://bacrialm.sirv.com/walkthrough/create-device.png",
      next: "dashboard",
      content: "Create a device to start sending data to Golain.",
      prev: "collaborators"
    },
    dashboard: {
      title: "Dashboard",
      img: "",
      next: "logs",
      content: "Choose from a variety of panels to visualize your data.",
      prev: "create-device"
    },
    logs: {
      title: "Logs",
      img: "",
      next: "ota",
      content: "View your device's logs and debug your device.",
      prev: "dashboard"
    },
    ota: {
      title: "Over-the-Air Updates!",
      img: "",
      next: "consumer-auth",
      content: "Update your device's firmware over-the-air.",
      prev: "logs"
    },
    "consumer-auth": {
      title: "Consumer Authentication",
      img: "",
      next: "",
      content: "Authenticate and manage your device's users.",
      prev: "ota"
    }
  }
};

function Walkthrough(props) {
  const [
    activeWalkthrough,
    activeWalkthroughItem,
    setActiveWalkthrough,
    setActiveWalkthroughItem
  ] = useWalkthroughStore((state) => [
    state.activeWalkthrough,
    state.activeWalkthroughItem,
    state.setActiveWalkthrough,
    state.setActiveWalkthroughItem
  ]);

  const location = useLocation();

  const item = activeWalkthrough
    ? walkthroughFlows[activeWalkthrough][activeWalkthroughItem]
    : null;

  function handleWalkthoughClose() {
    setActiveWalkthrough("");
    setActiveWalkthroughItem("");
  }

  return (
    <div className="walkthrough-tooltip-container">
      <Tooltip
        isOpen={
          !!activeWalkthrough && !location.pathname.startsWith("/rule-engine")
        }
        id={joinWalkthroughNameAndStep(
          activeWalkthrough,
          activeWalkthroughItem
        )}
        variant="light"
        className="walkthrough-tooltip"
        style={{
          zIndex: 9999
        }}
        border={"1px solid black"}
        clickable
        render={(props) =>
          activeWalkthrough &&
          activeWalkthroughItem && (
            <div className="relative">
              <button
                onClick={handleWalkthoughClose}
                className="absolute top-0 right-0"
              >
                <Close />
              </button>
              <div className="flex flex-col items-center justify-cente border-black ">
                {item.img && (
                  <img
                    src={item.img}
                    alt={item.title}
                    style={{
                      border: "1px solid #bbb"
                    }}
                    className="w-auto max-h-36 rounded-sm"
                  />
                )}
                <div className="mt-2">
                  <h3 className="text-lg font-semibold">{item.title}</h3>
                  <p className="text-sm">{item.content}</p>
                </div>
              </div>
              <div className="flex gap-2 mt-4 items-center justify-start">
                <button
                  disabled={!item.prev}
                  className="w-8 h-8 flex items-center justify-center disabled:bg-gray-600 bg-black rounded-full"
                  onClick={() => {
                    if (item.prev) {
                      setActiveWalkthroughItem(item.prev);
                    }
                  }}
                >
                  <ArrowLeft />
                </button>
                {Object.keys(walkthroughFlows[activeWalkthrough]).indexOf(
                  activeWalkthroughItem
                ) + 1}
                /{Object.keys(walkthroughFlows[activeWalkthrough]).length}
                <button
                  disabled={!item.next}
                  className="w-8 h-8 flex items-center justify-center disabled:bg-gray-600 bg-black rounded-full"
                  onClick={() => {
                    if (item.next) {
                      setActiveWalkthroughItem(item.next);
                    }
                  }}
                >
                  <ArrowRight />
                </button>
              </div>
            </div>
          )
        }
      />
    </div>
  );
}

export default Walkthrough;
