import React, { useRef, useState } from "react";
import Slider, { Settings } from 'react-slick';
import { ICarousel } from '../../../interfaces';

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Carousel: React.FC<ICarousel> = ({header, childPerPage, childCount, children}) => {

    const sliderRef = useRef<Slider>();
    const [ page, setPage ] = useState(0);

    const maxPages = Math.ceil(childCount/childPerPage)
    const liArray = new Array(maxPages).fill(Math.random());

    const settings: Settings = {
        dots: false,
        infinite: false,
        speed: 200,
        slidesToShow: childPerPage,
        slidesToScroll: 1,
        arrows: false,
        swipe: false,
    }

    const handlePage = (pgType: string) => {
        if(pgType === 'NEXT') {
            if(page < maxPages-1){
                setPage(page+1);
                sliderRef?.current?.slickNext();
            } else {
                setPage(0);
                sliderRef?.current?.slickPrev();
            }
            
        } else {
            if(page > 0) {
                setPage(page-1);
                sliderRef?.current?.slickPrev();
            } else {
                setPage(maxPages-1);
                sliderRef?.current?.slickNext();
            }
        }
    }

    return(
        <div className="relative w-full my-2 py-2">
            <div className="relative w-full flex flex-row items-center justify-between">
                <h4 className="text-lg font-medium">{header}</h4>
                <div className="flex flex-row items-center justify-start">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer" onClick={() => handlePage('PREV')}>
                    <rect x="36" y="36" width="36" height="36" rx="18" transform="rotate(-180 36 36)" fill="white" fillOpacity="0.19"/>
                    <path d="M22.6666 18L13.3333 18" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M18 22.666L13.3333 17.9993L18 13.3327" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <ul className="flex flex-row">
                    {liArray.map((l, index) => (
                        <li key={index} className={`block mx-[5px] w-[9px] h-[9px] bg-[#D9D9D9] rounded-lg transition-colors ${page === index ? 'w-[20px] bg-[#166FF6]' : ''}`}></li>
                    ))}
                </ul>
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer" onClick={() => handlePage('NEXT')}>
                    <rect width="36" height="36" rx="18" fill="white" fillOpacity="0.19"/>
                    <path d="M13.3334 18H22.6667" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M18 13.332L22.6667 17.9987L18 22.6654" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                </div>
            </div>
            <Slider ref={sliderRef} {...settings}>
                {children}
            </Slider>
        </div>
    )
}

export default Carousel;