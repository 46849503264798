import React, { useEffect } from "react";
import { Outlet } from "react-router";
import { useAuthStore } from "../../store";
import * as Setting from "./casdoor-setting.helper";
import { networkService } from "../../services";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

interface IRefreshTokenResponse {
  data: { access_token: string; refresh_token: string; expiry: string };
  ok: number;
}

const Jump = () => {
  const [token, expiry, refreshToken] = useAuthStore((state) => [
    state.token,
    state.expiry,
    state.refreshToken
  ]);

  const [searchParams] = useSearchParams();
  const inviteToken = searchParams.get("token");

  useEffect(() => {
    if (!token || !expiry || !refreshToken) {
      let url = "";

      if (inviteToken) {
        url = Setting.getCasdoorSDKForRedictPath(
          `/callback?token=${inviteToken}`
        ).getSigninUrl();
      } else {
        url = Setting.getCasdoorSDKForRedictPath(`/callback`).getSigninUrl();
      }

      Setting.goToLink(url);
    } else {
      // check expiry
      const now = new Date();
      const expiryDate = new Date(expiry);
      if (now > expiryDate) {
        const response = networkService.post<IRefreshTokenResponse>(
          process.env.REACT_APP_CASDOOR_TOKEN_SERVER_URL +
            "/api/creds/refresh",
          {
            refresh_token: refreshToken
          }
        );
        response.then((res) => {
          Setting.SetToken(res.data);
          Setting.goToLink("/");
        });

        response.catch((err) => {
          toast.warn("Session expired, please login again!");
          Setting.goToLink(Setting.CasdoorSDK.getSigninUrl());
        });
      }
    }
  }, [expiry, inviteToken, refreshToken, token]);

  return <Outlet />;
};

export default Jump;
