import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { RoutePermission } from "../models";
import useLangStore from "../store/lang.store";
import { Auth } from "./auth/auth.component";
import Login from "./auth/login.component";
import Logout from "./auth/logout.component";
import Layout from "./layout";
import {
  OnboardingIndividualDetails,
  OnboardingOrganizationDetails
} from "./onboarding";

import { IntlProvider } from "react-intl";
import { DEFAULT_LOCALE, messages } from "../i18n";
import {
  LayoutOnboarding,
  NotFound,
  PrivateRoute,
  Unauthorized
} from "./shared/components";
import { RoutePermissionType } from "./shared/config";
import { LogsAndActivitiesProvider } from "./logs-and-activities";
import {
  AboutFleet,
  ProvisioningPolicy,
  ShadowPolicy,
  CreateDevice,
  AboutDevice,
  ApplyShadow,
  ApplyDataPoints,
  DeviceCreationSuccess,
  FleetAndDevicesProvider,
  NewProjectDetails,
  DeviceDetails,
  DownloadCertificates
} from "./fleet-and-devices";

import {
  DashboardHome,
  PanelDetails,
  NewPanelType,
  NewPanelOptions
} from "./dashboard";
import HomeLandingPage from "./landing-home/home-landing.component";
import { ProvisionsProjects } from "./provisions";
import { DeviceRulesProjects } from "./device-rules";
import { InsightsProjects } from "./insights";
import {
  APIKeys,
  Members,
  OrgDetails,
  ProjectDetails,
  SettingsProvider
} from "./settings";
import Jump from "./auth/jump.component";
import { ReleaseProjects } from "./ota-updates/release";
import CreateDeployment from "./ota-updates/deployment/ota-create-deployment.component";
import {
  DeploymentDetails,
  OTADeploymentProvider,
  SelectTargetSoftwareAndDevice,
  SetDeployDetails,
  SetRolloutSchedule
} from "./ota-updates/deployment";
import Invite from "./auth/invite.component";
import { UserAuthenticationProvider } from "./user-authentication";
import FADCertificates from "./fleet-and-devices/certificates/fad-certificates.component";
import Definitions from "./definitions/definitions.component";
import ShadowDefinitionsAll from "./definitions/shadows/shadow-definitions-all.component";
import ShadowDefinitionTabsRenderer from "./definitions/shadows/shadow-definition-tabs-renderer.component";
import DataPointsAll from "./definitions/data-points/data-points-all.component";
import useThemeStore from "@store/theme.store";
import RuleEngineAll from "./rule-engine/rule-engine-all.component";
import DatapointDefinitionTabsRenderer from "./definitions/data-points/data-points-tabs-renderer.component";
import FADCertificateInfo from "./fleet-and-devices/certificates/fad-certificate-info.component";
import Invites from "./settings/org-settings-components/invites.component";
import RuleEngine from "./rule-engine/rule-engine.component";

function AppRouter() {
  const loginPermission = React.useMemo(
    () => RoutePermission.factory([], RoutePermissionType.OnlyLoginRequired),
    []
  );
  const lang = useLangStore((state) => state.value);
  const locale = lang;

  const [theme, setTheme] = useThemeStore((state) => [
    state.theme,
    state.setTheme
  ]);

  useEffect(() => {
    const documentElement = document.documentElement;

    if (theme === "none") {
      // if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      //   documentElement.classList.add("dark-default", "dark");
      //   documentElement.classList.remove("golain");
      //   setTheme("dark-default");
      // } else {
      documentElement.classList.add("golain");
      documentElement.classList.remove("dark-default", "dark");
      setTheme("golain");
      // }
    } else if (theme === "dark-default") {
      documentElement.classList.add("dark-default", "dark");
      documentElement.classList.remove("golain");
    } else if (theme === "golain") {
      documentElement.classList.remove("dark-default", "dark");
      documentElement.classList.add("golain");
    }
  }, [setTheme, theme]);

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={DEFAULT_LOCALE}
      messages={messages[locale]}
    >
      <Routes>
        <Route path="/callback" element={<Auth />} />
        <Route element={<Jump />}>
          <Route path="/invite" element={<Invite />} />
          <Route index element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          {/* A secure/private route is set like this*/}
          <Route element={<PrivateRoute routePermission={loginPermission} />}>
            <Route element={<Layout />}>
              {/* Home */}
              <Route path="home" element={<HomeLandingPage />} />

              {/* Fleet & Devices routes */}
              <Route
                path="fleet-and-devices/projects"
                element={<FleetAndDevicesProvider />}
              >
                {/* Project Creation Routes */}
                <Route
                  path="project-details"
                  element={<NewProjectDetails />}
                />

                {/* Fleet Creation routes */}
                <Route path="about-fleet" element={<AboutFleet />} />
                <Route
                  path="provisioning-policy"
                  element={<ProvisioningPolicy />}
                />
                <Route path="shadow-policy" element={<ShadowPolicy />} />
                <Route path="create-device" element={<CreateDevice />} />

                {/* Device Creation routes */}
                <Route path="about-device" element={<AboutDevice />} />
                <Route path="apply-shadow" element={<ApplyShadow />} />
                <Route
                  path="apply-data-points"
                  element={<ApplyDataPoints />}
                />
                <Route
                  path="download-certificates"
                  element={<DownloadCertificates />}
                />
                <Route
                  path="device-creation-successful"
                  element={<DeviceCreationSuccess />}
                />
                <Route path="device-details" element={<DeviceDetails />} />
              </Route>

              <Route
                path="fleet-and-devices/certificates"
                element={<FADCertificates />}
              ></Route>
              <Route
                path="fleet-and-devices/certificates/:id"
                element={<FADCertificateInfo />}
              ></Route>

              {/* Logs and Activities routes */}
              <Route
                path="logs-and-activities/projects"
                element={<LogsAndActivitiesProvider />}
              ></Route>

              {/* OTA Updates Routes */}
              <Route path="ota-updates/projects">
                <Route path="release" element={<ReleaseProjects />} />

                <Route path="deployment" element={<OTADeploymentProvider />}>
                  <Route
                    path="create-deployment"
                    element={<CreateDeployment />}
                  >
                    <Route
                      path="software-and-device"
                      element={<SelectTargetSoftwareAndDevice />}
                    />
                    <Route
                      path="rollout-schedule"
                      element={<SetRolloutSchedule />}
                    />
                    <Route
                      path="deploy-details"
                      element={<SetDeployDetails />}
                    />
                  </Route>
                  <Route
                    path="deployment-details"
                    element={<DeploymentDetails />}
                  />
                </Route>
              </Route>

              {/* Rule Engine routes */}
              <Route path="/rule-engine" element={<RuleEngineAll />}>

              <Route path=":id" element={<RuleEngine />} />
              </Route>

              {/* User Authentication routes */}
              <Route
                path="/user-authentication/projects"
                element={<UserAuthenticationProvider />}
              ></Route>

              {/* Dashboard routes */}
              <Route path="/dashboard" element={<DashboardHome />}>
                <Route path=":id" element={<PanelDetails />} />
                {/* Panel Creation Routes */}
                <Route path="new-panel-type" element={<NewPanelType />} />
                <Route
                  path="new-panel-options"
                  element={<NewPanelOptions />}
                />
              </Route>

              {/* Definitions routes */}
              <Route path="/definitions" element={<Definitions />}>
                <Route path="shadows" element={<ShadowDefinitionsAll />} />\
                <Route
                  path="shadows/:id"
                  element={<ShadowDefinitionTabsRenderer />}
                />
                <Route path="data-points" element={<DataPointsAll />} />
                <Route
                  path="data-points/:id"
                  element={<DatapointDefinitionTabsRenderer />}
                />
              </Route>

              {/* Settings Routes */}

              {/* Org Settings Routes */}
              <Route
                path="/settings/organization-settings"
                element={<SettingsProvider />}
              >
                <Route path="org-details" element={<OrgDetails />} />
                {/* <Route path="teams" element={<Teams />} /> */}
                <Route path="members" element={<Members />} />
                <Route path="invites" element={<Invites />} />
              </Route>

              {/* Project Settings Routes */}
              <Route
                path="/settings/project-settings"
                element={<SettingsProvider />}
              >
                <Route path="project-details" element={<ProjectDetails />} />
                <Route path="api-keys" element={<APIKeys />} />
              </Route>

              {/* Provisions Routes */}
              <Route
                path="provisions/projects"
                element={<ProvisionsProjects />}
              ></Route>

              {/* Device Rules Routes */}
              <Route
                path="device-rules/projects"
                element={<DeviceRulesProjects />}
              ></Route>

              {/* Insights Routes */}
              <Route
                path="insights/projects"
                element={<InsightsProjects />}
              ></Route>
            </Route>

            {/* onboarding routes */}
            <Route element={<LayoutOnboarding />}>
              <Route
                index
                path="onboarding/organization-details"
                element={<OnboardingOrganizationDetails />}
              ></Route>
              <Route
                path="onboarding/individual-details"
                element={<OnboardingIndividualDetails />}
              ></Route>
            </Route>
          </Route>
        </Route>

        {/* generic routes */}
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="error" element={<NotFound />} />
        <Route path="not-found" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </IntlProvider>
  );
}

export default AppRouter;
