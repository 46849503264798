import { ArrowsUpDownIcon } from "@heroicons/react/24/outline";
import React, { useEffect } from "react";
import "./table.style.css";

interface Item<T> {
  attributes: Record<string, T>;
}

interface SortConfig {
  key: keyof Item<any>["attributes"];
  direction: "ascending" | "descending";
}

interface SearchConfig {
  key: keyof Item<any>["attributes"];
  searchTerm: string;
}

interface ManupulateData {
  row: Item<any>["attributes"][];
  filteredItems: Item<any>["attributes"][];
  sort: (key: keyof Item<any>["attributes"]) => void;
  search: (key: keyof Item<any>["attributes"], searchTerm: string) => void;
  sortConfig: SortConfig | null;
  searchConfig: SearchConfig | null;
}

const useManipulateData = (row: Item<any>["attributes"][]): ManupulateData => {
  const [sortConfig, setSortConfig] = React.useState<SortConfig | null>(null);
  const [searchConfig, setSearchConfig] = React.useState<SearchConfig | null>(
    null
  );

  const filteredItems = React.useMemo(() => {
    let sortableItems = [...row];
    if (sortConfig) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    if (searchConfig) {
      sortableItems = sortableItems.filter((item) => {
        return item[searchConfig.key]
          .toLowerCase()
          .startsWith(searchConfig.searchTerm.toLocaleLowerCase());
      });
    }

    return sortableItems;
  }, [row, sortConfig, searchConfig]);

  const sort = (key: keyof Item<any>["attributes"]) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const search = (key: keyof Item<any>["attributes"], searchTerm: string) => {
    setSearchConfig({ key, searchTerm });
  };

  return { row, filteredItems, sort, search, sortConfig, searchConfig };
};

type GenericTableProps = {
  column: string[];
  row: Record<string, any>[];
  searchField: string;
  searchTerm: string;
  accordion?: React.ReactElement;
};

const GenericTable: React.FC<GenericTableProps> = ({
  column,
  row,
  searchField,
  searchTerm,
  accordion
}) => {
  const [expandedRows, setExpandedRows] = React.useState([]);

  const { filteredItems, search, sort } = useManipulateData(row);

  useEffect(() => {
    search(searchField, searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchField]);

  const handleRowExpand = (idx: number) => {
    const currentExpandedRows = expandedRows;

    const isExpanded = currentExpandedRows.includes(idx);

    const updatedExpandedRows = isExpanded
      ? currentExpandedRows.filter((val: number) => val !== idx)
      : currentExpandedRows.concat(idx);

    setExpandedRows(updatedExpandedRows);
  };

  return (
    <table className="min-w-full generic-table overflow-x-auto border-separate border-spacing-0">
      <thead>
        <tr>
          {accordion && (
            <th
              scope="col"
              className="px-6 pt-4 pr-16 pb-4 whitespace-nowrap"
            ></th>
          )}
          {column.map((item, index) => (
            <th
              key={index}
              scope="col"
              className="px-6 pt-4 pr-16 pb-4 whitespace-nowrap"
            >
              <button
                type="button"
                onClick={() => sort(item)}
                className="flex items-center gap-2 space-x-3 text-xs font-normal text-left text-contentColorLight uppercase hover:text-primary"
              >
                {item}
                <ArrowsUpDownIcon width={14} />
              </button>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-contentColorLight ">
        {filteredItems.map((item, rowIdx) => (
          <React.Fragment key={rowIdx}>
            <tr className="transition-colors duration-300 bg-background hover:bg-background-layer0.5">
              {/* Accordion btn section */}
              {accordion && (
                <td className="px-6 py-4 text-contentColor whitespace-nowrap">
                  <button
                    className="flex items-center"
                    onClick={() => handleRowExpand(rowIdx)}
                  >
                    <svg
                      className={
                        expandedRows.includes(rowIdx) ? "rotate-180" : ""
                      }
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 6L8 10L12 6"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </td>
              )}

              {/* Actual table divisions */}
              {Object.keys(item).map((key, index) => (
                <td
                  key={index}
                  className={`px-6 py-4 text-contentColor text-sm whitespace-nowrap`}
                >
                  {item[key]}
                </td>
              ))}
            </tr>

            {/* Accordion body */}
            {expandedRows.includes(rowIdx) && accordion}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default GenericTable;
