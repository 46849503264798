import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

type Props = {};

const Definitions = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const pathSplits = location.pathname.split("/");

  if (pathSplits.length < 2) {
    navigate("/definitions/shadows");
  }

  const subPath =
    location.pathname.split("/")[2]?.toLowerCase() === "shadows"
      ? "shadows"
      : "Data-Points";

  return (
    <>
      <div className="bg-background border-b border-background-layer3 lg:px-8 sm:px-6 ">
        <div className="relative flex flex-col justify-bet"></div>
        <div className="flex flex-col justify-between items-start pt-4 w-full">
          <h4 className="text-xl font-semibold text-contentColor">
            {"Definitions"}
          </h4>
        </div>
        <div className="mt-4 mb-2 flex items-center gap-1">
          <div className="text-sm text-contentColorLight">{`Defintions >`}</div>
          <Link
            to={`/definitions/${subPath}`}
            className="text-sm text-contentColorLight hover:underline"
          >
            {subPath}
          </Link>
        </div>
      </div>

      <main className="rounded-md bg-background text-contentColor m-4 p-4">
        <Outlet />
      </main>
    </>
  );
};

export default Definitions;
