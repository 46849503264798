import produce from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../../app/shared/config";
import { Color } from "@tremor/react";

interface IColumnOptions {
  [protoOption: string]: Color;
}

export interface IColumn {
  aggregationMode?: "sum" | "avg" | "min" | "max" | "count" | "first" | "last";
  parameter: {
    value: string;
    label: string;
    name: string;
    type: string;
    enumBadgeColours?: IColumnOptions;
    dataSourceName?: string;
    dataSource?: "dataPoint" | "shadow";
  };
  label: string;
}

export interface ITableState {
  title: string;
  inputValues: {
    columnState?: any;
    shadow?: any;
    dataPoints?: any[];
    aggregateParams?: boolean;
    devicesInDataPoints?: any[];
    columns?: IColumn[];
    startDate?: string;
    endDate?: string;
    timePeriod?: {
      start: string;
      end: string;
    };
    timeBucket?: number;
    timeBucketUnit?: "mins" | "hours" | "days";
    filters: {
      devices: any[];
      tags: any[];
    };
  };
  setInputValues: (x: any) => void;
  setColumns: (x: any) => void;
  setColumnState: (x: any) => void;
  reset: () => void;
}

const initialVals: ITableState["inputValues"] = {
  columnState: {},
  shadow: "",
  dataPoints: [],
  aggregateParams: true,
  devicesInDataPoints: [],
  columns: [],
  filters: {
    devices: [],
    tags: []
  },
  timePeriod: {
    start: "-1m",
    end: "0d"
  },
  timeBucket: 1,
  timeBucketUnit: "days" as "mins" | "hours" | "days"
};

const useCreateTableStore = create<ITableState>()(
  persist(
    devtools(
      (set) => ({
        title: "",
        inputValues: initialVals,
        setInputValues: (inputValues) =>
          set(
            produce((state) => {
              state.inputValues = inputValues;
            })
          ),
        setColumns: (columns) => {
          set(
            produce((state) => {
              state.inputValues.columns = columns;
            })
          );
        },
        setColumnState: (columnState) =>
          set(
            produce((state) => {
              state.inputValues.columnState = columnState;
            })
          ),
        reset: () =>
          set(
            produce((state) => {
              state.inputValues = initialVals;
            })
          )
      }),

      { name: "create-table", serialize: { options: true } }
    ),
    {
      name: "create-table", // unique name
      getStorage: () => appConstants.keys.storage // (optional) by default, 'localStorage' is used
    }
  )
);

export default useCreateTableStore;
