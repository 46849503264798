import { Formik, Field, Form, ErrorMessage } from "formik";
import { copyToClipboard } from "../shared/utils/helper.util";
import { useAuthStore, useFleetAndDevicesStore } from "../../store";
import { FieldError } from "../shared/components";
import { loggerService, networkService } from "../../services";
import { IProjectResponse, IProject } from "../../interfaces";
import { data } from "../shared/utils/data-file.util";
import { useState } from "react";
import ShowError from "../shared/components/error.component";
import { object, string } from "yup";

interface INewOptResponse {
  data: {
    projectId: string;
  };
  ok: number;
}

const URL_REGEX =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

const userAuthFormSchema = object().shape({
  connectionName: string().required("Connection name is required"),
  issuerURL: string()
    .required("Issue URL is required")
    .matches(URL_REGEX, "Issuer URL must be valid!"),
  clientId: string().required("Client ID is required")
});

const Projects: React.FC = () => {
  const [showErrorComponent, setShowErrorComponent] = useState(false);
  const [user] = useAuthStore((state) => [state.user]);
  const [selectedProject, setProjects, setSelectedProject] =
    useFleetAndDevicesStore((state) => [
      state.selectedProject,
      state.setProjects,
      state.setSelectedProject
    ]);

  const { props } = selectedProject;

  const [noData, setNoData] = useState(false);

  const handleSubmit = async (
    values: {
      connectionName: string;
      issuerURL: string;
      clientId: string;
    },
    { resetForm }
  ) => {
    const payload = {
      issuer_url: values.issuerURL,
      client_id: values.clientId,
      connection_name: values.connectionName,
      connection_type: "External"
    };

    try {
      const response = await networkService.post<INewOptResponse>(
        `projects/${selectedProject.id}/consumers/opt_in`,
        payload,
        {
          headers: {
            "ORG-ID": user.selectedOrg.id
          }
        }
      );

      if (response.ok) {
        const res = await networkService.get<IProjectResponse>(
          "projects",
          {},
          {
            headers: {
              "ORG-ID": user.selectedOrg.id
            }
          }
        );

        setShowErrorComponent(false);

        const { projects } = res.data;

        if (projects) {
          setProjects(projects);
          const currentProject = projects.filter(
            (proj) => proj.id === response.data.projectId
          );
          setSelectedProject(currentProject[0]);
        } else {
          setProjects([]);
          setSelectedProject({} as IProject);
        }
      }
    } catch (error) {
      loggerService.error(error);
      setShowErrorComponent(true);
    }
  };

  function copyTo(id: string) {
    let c = document.getElementById(id);
    copyToClipboard(c["value"]);
  }

  if (showErrorComponent) {
    return <ShowError />;
  }

  if (!selectedProject.consumer_enabled && !noData) {
    return (
      <div className="h-full flex flex-grow flex-col justify-center items-center">
        <div className="mb-[38px] flex flex-col justify-center items-center">
          <h1 className="text-2xl text-center font-medium mb-2.5">
            {data.userAuthPageEmptState.heading}
          </h1>
          <p className="text-sm text-contentColorLight text-center w-[468px]">
            {data.userAuthPageEmptState.subHeading[0]}
          </p>
          <p className="text-sm mt-4 text-contentColorLight text-center w-[468px]">
            {data.userAuthPageEmptState.subHeading[1]}
          </p>
        </div>
        <div className="text-center">
          <button
            onClick={() => setNoData(true)}
            className="inline-block px-12 py-3 mt-6 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
          >
            Opt-In to Consumer Authentication
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <main className="flex-1 mx-8 mt-7 space-y-4 lg:mr-28 overflow-y-auto lg:px-8 sm:px-6 bg-background rounded-md">
        <Formik
          initialValues={{ connectionName: "", issuerURL: "", clientId: "" }}
          onSubmit={handleSubmit}
          validationSchema={userAuthFormSchema}
        >
          <Form className="flex flex-col flex-1">
            <div>
              <h1 className="text-lg text-left font-medium mt-5 mb-2.5">
                {selectedProject.consumer_enabled
                  ? "OIDC Settings"
                  : "Create OIDC Connection"}
              </h1>

              <div className="flex-1 w-[396px]">
                <div className="mb-5">
                  <label className="text-sm font-medium text-contentColor">
                    Connection Name
                  </label>

                  {selectedProject.consumer_enabled ? (
                    <div className="flex items-end">
                      <Field
                        type="text"
                        id="connectionName"
                        name="connectionName"
                        value={
                          props?.consumer_connection_name
                            ? props?.consumer_connection_name
                            : ""
                        }
                        placeholder="Connection Name"
                        className="block w-10/12 p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primary sm:text-sm"
                      />
                      <div>
                        <button
                          type="button"
                          onClick={() => copyTo("connectionName")}
                          className="block ml-3.5 bg-background-layer1 p-3 rounded"
                        >
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.41667 5.50016V2.75016C6.41667 2.50705 6.51324 2.27389 6.68515 2.10198C6.85706 1.93007 7.09022 1.8335 7.33333 1.8335H18.3333C18.5764 1.8335 18.8096 1.93007 18.9815 2.10198C19.1534 2.27389 19.25 2.50705 19.25 2.75016V15.5835C19.25 15.8266 19.1534 16.0598 18.9815 16.2317C18.8096 16.4036 18.5764 16.5002 18.3333 16.5002H15.5833V19.2502C15.5833 19.7562 15.1708 20.1668 14.6603 20.1668H3.67308C3.5522 20.1676 3.43236 20.1444 3.32046 20.0987C3.20856 20.0529 3.1068 19.9855 3.02102 19.9004C2.93524 19.8152 2.86714 19.7139 2.82063 19.6023C2.77412 19.4907 2.75012 19.371 2.75 19.2502L2.75275 6.41683C2.75275 5.91083 3.16525 5.50016 3.67492 5.50016H6.41667ZM4.58517 7.3335L4.58333 18.3335H13.75V7.3335H4.58517ZM8.25 5.50016H15.5833V14.6668H17.4167V3.66683H8.25V5.50016ZM6.41667 10.0835H11.9167V11.9168H6.41667V10.0835ZM6.41667 13.7502H11.9167V15.5835H6.41667V13.7502Z"
                              fill="#546CCC"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <Field
                        type="text"
                        id="connectionName"
                        name="connectionName"
                        placeholder="Connection Name"
                        className="block w-10/12 p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      />
                      <ErrorMessage name="connectionName">
                        {(msg) => <FieldError message={msg} />}
                      </ErrorMessage>
                    </>
                  )}
                </div>

                <div className="mb-5">
                  <label className="text-sm font-medium text-contentColorLight">
                    Issuer URL
                  </label>

                  {selectedProject.consumer_enabled ? (
                    <div className="flex items-end">
                      <Field
                        type="text"
                        id="issuerURL"
                        name="issuerURL"
                        value={
                          props?.consumer_issuer_url
                            ? props?.consumer_issuer_url
                            : ""
                        }
                        placeholder="Issuer URL"
                        className="block w-10/12 p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      />
                      <div>
                        <button
                          type="button"
                          onClick={() => copyTo("issuerURL")}
                          className="block ml-3.5 bg-background-layer1 p-3 rounded"
                        >
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.41667 5.50016V2.75016C6.41667 2.50705 6.51324 2.27389 6.68515 2.10198C6.85706 1.93007 7.09022 1.8335 7.33333 1.8335H18.3333C18.5764 1.8335 18.8096 1.93007 18.9815 2.10198C19.1534 2.27389 19.25 2.50705 19.25 2.75016V15.5835C19.25 15.8266 19.1534 16.0598 18.9815 16.2317C18.8096 16.4036 18.5764 16.5002 18.3333 16.5002H15.5833V19.2502C15.5833 19.7562 15.1708 20.1668 14.6603 20.1668H3.67308C3.5522 20.1676 3.43236 20.1444 3.32046 20.0987C3.20856 20.0529 3.1068 19.9855 3.02102 19.9004C2.93524 19.8152 2.86714 19.7139 2.82063 19.6023C2.77412 19.4907 2.75012 19.371 2.75 19.2502L2.75275 6.41683C2.75275 5.91083 3.16525 5.50016 3.67492 5.50016H6.41667ZM4.58517 7.3335L4.58333 18.3335H13.75V7.3335H4.58517ZM8.25 5.50016H15.5833V14.6668H17.4167V3.66683H8.25V5.50016ZM6.41667 10.0835H11.9167V11.9168H6.41667V10.0835ZM6.41667 13.7502H11.9167V15.5835H6.41667V13.7502Z"
                              fill="#546CCC"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <Field
                        type="text"
                        id="issuerURL"
                        name="issuerURL"
                        placeholder="Issuer URL"
                        className="block w-10/12 p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      />
                      <ErrorMessage name="issuerURL">
                        {(msg) => <FieldError message={msg} />}
                      </ErrorMessage>
                    </>
                  )}
                </div>

                <div className="mb-5">
                  <label className="text-sm font-medium text-contentColorLight">
                    Client ID
                  </label>

                  {selectedProject.consumer_enabled ? (
                    <div className="flex items-end">
                      <Field
                        type="text"
                        id="clientId"
                        name="clientId"
                        value={
                          props?.consumer_client_id
                            ? props?.consumer_client_id
                            : ""
                        }
                        placeholder="Client ID"
                        className="block w-10/12 p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      />
                      <div>
                        <button
                          type="button"
                          onClick={() => copyTo("clientId")}
                          className="block ml-3.5 bg-background-layer1 p-3 rounded"
                        >
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.41667 5.50016V2.75016C6.41667 2.50705 6.51324 2.27389 6.68515 2.10198C6.85706 1.93007 7.09022 1.8335 7.33333 1.8335H18.3333C18.5764 1.8335 18.8096 1.93007 18.9815 2.10198C19.1534 2.27389 19.25 2.50705 19.25 2.75016V15.5835C19.25 15.8266 19.1534 16.0598 18.9815 16.2317C18.8096 16.4036 18.5764 16.5002 18.3333 16.5002H15.5833V19.2502C15.5833 19.7562 15.1708 20.1668 14.6603 20.1668H3.67308C3.5522 20.1676 3.43236 20.1444 3.32046 20.0987C3.20856 20.0529 3.1068 19.9855 3.02102 19.9004C2.93524 19.8152 2.86714 19.7139 2.82063 19.6023C2.77412 19.4907 2.75012 19.371 2.75 19.2502L2.75275 6.41683C2.75275 5.91083 3.16525 5.50016 3.67492 5.50016H6.41667ZM4.58517 7.3335L4.58333 18.3335H13.75V7.3335H4.58517ZM8.25 5.50016H15.5833V14.6668H17.4167V3.66683H8.25V5.50016ZM6.41667 10.0835H11.9167V11.9168H6.41667V10.0835ZM6.41667 13.7502H11.9167V15.5835H6.41667V13.7502Z"
                              fill="#546CCC"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <Field
                        type="text"
                        id="clientId"
                        name="clientId"
                        placeholder="Client ID"
                        className="block w-10/12 p-3 mt-2 border-background-layer3 bg-background rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                      />
                      <ErrorMessage name="clientId">
                        {(msg) => <FieldError message={msg} />}
                      </ErrorMessage>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-3">
              {selectedProject.consumer_enabled === false ? (
                <>
                  <button
                    type="submit"
                    className="inline-block px-16 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
                  >
                    Done
                  </button>
                  <button
                    type="button"
                    onClick={() => setNoData(false)}
                    className="ml-2 px-8 py-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white"
                  >
                    Cancel
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
          </Form>
        </Formik>
      </main>
    </>
  );
};

export default Projects;
