import React from "react";

const NodeTemplate = ({ children, ...props }) => {
  return (
    <div
      {...props}
      className="p-3 text-sm border-2 border-background-layer3 rounded-md bg-background-layer0.5 text-contentColor"
    >
      {children}
    </div>
  );
};

export default NodeTemplate;
