import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { ICreateDeviceResponse } from "@interfaces/fad.interface";

export const createDevice = async (
  projectId: string,
  orgId: string,
  fleetId: string,
  payload
) => {
  const { data, ok } = await networkService.post<ICreateDeviceResponse>(
    `projects/${projectId}/fleets/${fleetId}/devices/`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.deviceId;
  } else return null;
};

export const useCreateDevice = (fleetId: string) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: { device_name: string; shadow_definition_id: string }) =>
      await createDevice(
        selectedProject.id,
        user.selectedOrg.id,
        fleetId,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getDevices");
      }
    }
  );

  return res;
};
