import { useRef } from "react";

const REGEX = /({{.*?}})/g;

export function VariableInput({ value = "", onChange, ...props }) {
  const ref = useRef(null);

  const syncScroll = (e) => {
    ref.current.scrollTop = e.target.scrollTop;
    ref.current.scrollLeft = e.target.scrollLeft;
  };
  return (
    <div className="input-container">
      <input
        placeholder={props.placeholder ?? ""}
        name={props.name ?? ""}
        className="nodrag bg-background mt-2 mb-1 w-full px-2 py-1 text-contentColor border-background-layer3 rounded-sm border-2"
        onChange={onChange}
        value={value}
        onScroll={syncScroll}
        {...props}
      />
      <div
        ref={ref}
        className="input-renderer overflow-x-auto bg-background border border-background-layer3"
      >
        {value.split(REGEX).map((word, i) => {
          if (word.match(REGEX) !== null) {
            return (
              <span key={i} className="text-yellow-600 italic">
                {word}
              </span>
            );
          } else {
            return <span key={i}>{word}</span>;
          }
        })}
      </div>
    </div>
  );
}
