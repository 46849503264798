import { useContext, createContext } from "react";

type IPanelErrorContext = {
  panelError: string;
  setPanelError: (x: string) => void;
};

const defaultValue = {
  panelError: "awd",
  setPanelError: (x: string) => null
};

const DashPanelErrorContext = createContext(defaultValue);

export const PanelErrorProvider = DashPanelErrorContext.Provider;

export const usePanelError = () =>
  useContext<IPanelErrorContext>(DashPanelErrorContext as any);
