import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

interface ITransitionedMenuProps {
  buttonComponent: JSX.Element;
  menuPosition?: "left" | "right";
  children: JSX.Element[] | JSX.Element;
}

const TransitionedMenu: React.FC<ITransitionedMenuProps> = ({
  buttonComponent,
  menuPosition = "left",
  children
}) => {
  return (
    <Menu
      as="div"
      className="relative flex text-left border-background-layer3"
    >
      <Menu.Button>{buttonComponent}</Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute top-7 w-fit z-10 mt-2 ${
            menuPosition === "left"
              ? "left-0 origin-top-right"
              : "right-0 origin-top-left"
          } rounded-md bg-background-layer1 shadow-lg ring-1 ring-background-layer2 ring-opacity-5 focus:outline-none`}
        >
          <div className="py-1">{children}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default TransitionedMenu;
