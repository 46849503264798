import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import NodeTemplate from "../node-template.component";
import { ArrowDownIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import {
  Handle,
  Position,
  useUpdateNodeInternals,
  Node,
  Edge,
  useReactFlow
} from "reactflow";
import { Tooltip } from "react-tooltip";
import StackedList from "../stacked-list.component";
import TransitionedMenu from "@app/shared/components/transitioned-men.component";
import { Menu } from "@headlessui/react";
import FetchedContextItem from "../action-editor-node/fetched-context-item.component";
import Modal from "@app/shared/components/modal.component";
import { Editor } from "@monaco-editor/react";
import {
  ICondition,
  IContext,
  IContextType,
  IInput,
  contextFieldRequiredMap,
  deserializeVariable
} from "@app/rule-engine/rule-engine.helper";
import { InputsItem } from "../action-editor-node";
import { toast } from "react-toastify";
import useRuleEngineStore from "@store/rule-engine/rule-engine.store";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import {
  deserializeActionData,
  evalConditionResponse,
  evalConditionSequence
} from "./conditions-parser.helper";
import { ConditionsItem } from "./conditions-item";
import { useGetShadowDefinitions } from "@app/shared/hooks/get/shadow-definitions";
import { IAction } from "@app/shared/hooks/get/actions";

interface IFetchedActionAdditionalParamValues {
  action_id: string;
  additional_params: Record<string, string>;
}

const RuleEditorNode = ({ id, data, isConnectable }) => {
  const reactFlow = useReactFlow();
  const addedElements = useRef(false);

  const [
    ruleData,
    actionData,
    localRulesData,
    setRuleData,
    setResponseData,
    setActionData
  ] = useRuleEngineStore((state) => [
    state.ruleData,
    state.actionData,
    state.localRulesData,
    state.setRuleData,
    state.setResponseData,
    state.setActionData
  ]);

  const selectedProject = useFleetAndDevicesStore(
    (state) => state.selectedProject
  );
  const { selectedOrg } = useAuthStore((state) => state.user);

  const [inputs, setInputs] = useState<IInput[]>(ruleData.inputs || []);
  const [fetchedContexts, setFetchedContexts] = useState<IContext[]>(
    ruleData.fetchedContexts || []
  );
  const [conditions, setConditions] = useState<ICondition[]>(
    ruleData.conditions ?? []
  );

  const actionDataRef = useRef(actionData);

  const [newContextType, setNewContextType] = useState("device-shadow");

  const [jsonEditorModalOpen, setJsonEditorModalOpen] = useState<
    "inputs" | "contexts" | "conditions" | ""
  >("");
  const [modalJSON, setModalJSON] = useState({});
  const [invalidConnectionTooltipOpen, setInvalidConnectionTooltipOpen] =
    useState(false);
  const invalidConnTimer = useRef(null);

  const updateNodeInternals = useUpdateNodeInternals();

  const onGetAction = useCallback(
    (
      node: Node,
      actionData: IAction,
      action: IFetchedActionAdditionalParamValues
    ) => {
      const _newActionData = { ...actionDataRef.current };

      const _actionData = deserializeActionData(actionData);
      const nodeActionData = _newActionData[node.id];

      let maxId = -1;

      if (nodeActionData) {
        Object.keys(nodeActionData).forEach((key) => {
          // key is of type "action-node-N" where N is an index
          const id = parseInt(key.split("-")[2]);
          if (id > maxId) maxId = id;
        });
      }

      actionDataRef.current = {
        ...actionDataRef.current,
        [node.id]: {
          ...actionDataRef.current[node.id],
          [`action-node-${maxId + 1}`]: {
            ..._actionData,
            inputValues: Object.keys(action.additional_params).reduce(
              (acc, cur) => {
                acc[cur] = deserializeVariable(action.additional_params[cur]);
                return acc;
              },
              {}
            ),
            action_id: action.action_id
          }
        }
      };
      setActionData(actionDataRef.current);
    },
    [setActionData]
  );

  const onGetRule = useCallback(
    (nodesToAdd, edgestoAdd, edgestoDelete, conditions) => {
      setTimeout(() => {
        data.setElements(
          (e) => {
            return {
              nodes: [...reactFlow.getNodes(), ...nodesToAdd],
              edges: [
                ...reactFlow
                  .getEdges()
                  .filter((e) => !edgestoDelete.includes(e.id)),
                ...edgestoAdd
              ]
            };
          },
          "destroyFuture",
          false
        );
      }, 100);
      setRuleData((prev) => ({
        ...prev,
        conditions: conditions
      }));

      setConditions(conditions);
    },
    [data, reactFlow, setRuleData]
  );

  const parseConditionJSON = useCallback(
    (json) => {
      const _conditions = [] as ICondition[];

      const curNode = reactFlow.getNode(id);
      const _nodesToAdd: Node[] = [];
      const _edgesToAdd: Edge[] = [];
      const _edgesToDelete = [];

      (json as any[]).forEach((condition, ind) => {
        let _newCondition: ICondition = {
          statement: condition["condition_statement"]
        };
        const hasFalseResponse =
          condition.false_response &&
          (condition.false_response.status_code ||
            condition.false_response.body);

        const hasTrueResponse =
          condition.true_response &&
          (condition.true_response.status_code ||
            condition.true_response.body);

        if (hasFalseResponse) {
          const { edge, node, response } = evalConditionResponse(
            curNode,
            condition,
            "false_response",
            ind
          );

          _nodesToAdd.push(node);
          _edgesToAdd.push(edge);
          setResponseData((prev) => ({ ...prev, [node.id]: response }));
        }

        if (hasTrueResponse) {
          const { edge, node, response } = evalConditionResponse(
            curNode,
            condition,
            "true_response",
            ind
          );

          _nodesToAdd.push({
            ...node,
            position: {
              y: node.position.y,
              x: hasFalseResponse ? node.position.x + 400 : node.position.x
            }
          });
          _edgesToAdd.push(edge);
          setResponseData((prev) => ({ ...prev, [node.id]: response }));
        }

        if (condition.true_sequence?.length) {
          const { edge, node } = evalConditionSequence(
            curNode,
            condition,
            "true_sequence",
            ind,
            selectedOrg.id,
            selectedProject.id,
            data.setEditAction,
            onGetAction
          );

          _nodesToAdd.push(node);
          _edgesToAdd.push(edge);
        }

        if (condition.false_sequence?.length) {
          const { edge, node } = evalConditionSequence(
            curNode,
            condition,
            "false_sequence",
            ind,
            selectedOrg.id,
            selectedProject.id,
            data.setEditAction,
            onGetAction
          );

          _nodesToAdd.push({
            ...node,
            position: {
              y: node.position.y,
              x: condition.true_sequence?.length
                ? node.position.x + 400
                : node.position.x
            }
          });
          _edgesToAdd.push(edge);
        }

        _conditions.push(_newCondition);
      });

      const oldEdges = reactFlow.getEdges();

      _edgesToAdd.forEach((edge) => {
        const edgeToDelete = oldEdges.find((oldEdge) => {
          if (
            oldEdge.source === edge.source &&
            oldEdge.sourceHandle === edge.sourceHandle
          ) {
            // compare targets
            if (
              (edge.targetHandle.startsWith("http-response") &&
                oldEdge.targetHandle.startsWith("http-response")) ||
              (edge.targetHandle.startsWith("action-sequence") &&
                oldEdge.targetHandle.startsWith("action-sequence"))
            ) {
              return true;
            }
          }

          return false;
        });
        if (edgeToDelete) {
          _edgesToDelete.push(edgeToDelete.id);
        }
      });

      onGetRule(_nodesToAdd, _edgesToAdd, _edgesToDelete, _conditions);
    },
    [data, onGetRule]
  );

  useEffect(() => {
    if (!id) return;

    if (localRulesData[data.title]) {
      const localRuleData = localRulesData[data.title];
      setTimeout(() => {
        setRuleData(localRuleData.ruleData);
        setActionData(localRuleData.actionData);
        setResponseData(localRuleData.responseData);

        setConditions(localRuleData.ruleData.conditions || []);
        setInputs(localRuleData.ruleData.inputs || []);
        setFetchedContexts(localRuleData.ruleData.fetchedContexts || []);
      }, 1);
    } else if (!data.foundLocalData && data.fetchedRuleData) {
      if (!addedElements.current) {
        setTimeout(() => {
          parseConditionJSON(data.fetchedRuleData.conditions || []);
          addedElements.current = true;
        }, 1);
      }
      setInputs(data.fetchedRuleData.inputs || []);
      setFetchedContexts(data.fetchedRuleData.fetchedContexts || []);
    }
  }, [
    id,
    data.fetchedRuleData,
    data.foundLocalData,
    data.title,
    localRulesData,
    parseConditionJSON,
    setActionData,
    setResponseData,
    setRuleData
  ]);

  useEffect(() => {
    if (
      conditions.length &&
      conditions[conditions.length - 1].statement.trim() === ""
    )
      return;

    setRuleData((ruleData) => ({
      ...ruleData,
      conditions
    }));
  }, [conditions]);

  useEffect(() => {
    const timer = invalidConnTimer.current;

    return () => {
      timer && clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (inputs.length && inputs[inputs.length - 1].key.trim() === "") return;

    setRuleData((ruleData) => ({
      ...ruleData,
      inputs
    }));
  }, [inputs]);

  useEffect(() => {
    const disabled = fetchedContexts.some((context) => {
      const requiredFields = contextFieldRequiredMap[context.type];
      return requiredFields.some((field) => context[field].trim() === "");
    });

    if (!disabled) {
      setRuleData((ruleData) => ({
        ...ruleData,
        fetchedContexts
      }));
    }
  }, [fetchedContexts]);

  useEffect(() => {
    updateNodeInternals(id);
  }, [conditions, id, updateNodeInternals]);

  const disabledInput = useMemo(() => {
    if (inputs[inputs.length - 1]?.key.trim() === "") {
      return true;
    }

    const inpMap = {};
    let foundDuplicate = false;

    inputs.forEach((el) => {
      if (el.key in inpMap) {
        foundDuplicate = true;
      }
      inpMap[el.key] = true;
    });

    return foundDuplicate;
  }, [inputs]);

  const onModalSaveClick = useCallback(() => {
    if (!Array.isArray(modalJSON)) {
      toast.error(jsonEditorModalOpen + " must be an array.");
      return;
    }

    if (jsonEditorModalOpen === "contexts") {
      setFetchedContexts(modalJSON as IContext[]);
    } else if (jsonEditorModalOpen === "inputs") {
      setInputs(modalJSON as IInput[]);
    } else if (jsonEditorModalOpen === "conditions") {
      parseConditionJSON(modalJSON);
    }
    setJsonEditorModalOpen("");
  }, [jsonEditorModalOpen, modalJSON, parseConditionJSON]);

  const { data: shadowDefs } = useGetShadowDefinitions({
    fields: "shadow_proto_structure"
  });

  return (
    <NodeTemplate>
      <Handle
        type="target"
        position={Position.Left}
        id="rule-editor-trigger-handle"
        isConnectable={false}
      />
      <div className="flex text-xs min-h-[300px]">
        <div className="min-w-[250px] pr-3 flex flex-col border-dashed border-r border-background-layer3">
          <div className="flex justify-center gap-2 mb-3 text-base">
            <div>Inputs</div>
            <button
              className="bg-background-layer3 text-xs text-contentColor px-2 py-1 rounded-sm"
              onClick={() => setJsonEditorModalOpen("inputs")}
            >
              JSON
            </button>
          </div>
          <StackedList
            data={inputs}
            draggable={false}
            render={(input: IInput, ind: number, inputs: IInput[]) => (
              <InputsItem
                ind={ind}
                input={input}
                inputs={inputs}
                setInputs={setInputs}
              />
            )}
          />
          <div className="nodrag mt-3">
            <button
              disabled={disabledInput}
              className="bg-green-500 text-white w-full px-2 py-1 rounded-sm disabled:opacity-50"
              onClick={() => {
                setInputs((prev) => [
                  ...prev,
                  {
                    key: "",
                    type: "string"
                  }
                ]);
              }}
            >
              Add Input
            </button>
          </div>
        </div>
        <div className="min-w-[300px] px-2 flex flex-col border-dashed border-r border-background-layer3">
          <div className="flex justify-center gap-2 mb-3 text-base">
            <div>Fetched Context</div>
            <button
              className="bg-background-layer3 text-xs text-contentColor px-2 py-1 rounded-sm"
              onClick={() => setJsonEditorModalOpen("contexts")}
            >
              JSON
            </button>
          </div>
          <StackedList
            data={fetchedContexts}
            setData={setFetchedContexts}
            draggable={true}
            separatorElement={() => (
              <span data-tooltip-id="fetched-context-arrow-down-tooltip">
                <ArrowDownIcon width={20} className="text-center mx-auto" />
              </span>
            )}
            ignoreLastElementForDrag={
              fetchedContexts?.length &&
              fetchedContexts[fetchedContexts?.length - 1]?.key.trim() === ""
            }
            render={(
              context: IContext,
              ind: number,
              allContexts: IContext[]
            ) => (
              <FetchedContextItem
                context={context}
                ind={ind}
                setFetchedContexts={setFetchedContexts}
                contextFieldRequiredMap={contextFieldRequiredMap}
                allContexts={allContexts}
                inputs={inputs}
                shadowDefs={shadowDefs}
              />
            )}
          />
          <div className="nodrag mt-3">
            <button
              data-tooltip-id="fetched-context-add-tooltip"
              disabled={fetchedContexts?.some((c) => c.error)}
              className="bg-yellow-500 text-white w-full px-2 py-1 rounded-sm disabled:opacity-50"
              onClick={() => {}}
            >
              Add Context
            </button>
          </div>
        </div>
        <div className="min-w-[350px] pl-3 flex flex-col">
          <div className="flex justify-center gap-2 mb-3 text-base">
            <div>Conditions</div>
            <button
              className="bg-background-layer3 text-xs text-contentColor px-2 py-1 rounded-sm"
              onClick={() => setJsonEditorModalOpen("conditions")}
            >
              JSON
            </button>
          </div>
          <StackedList
            data={conditions}
            setData={setConditions}
            draggable={false}
            separatorElement={(ind) => (
              <span
                key={`conditions-separator-${ind}`}
                data-tooltip-id="conditions-arrow-down-tooltip"
              >
                <ArrowDownIcon
                  width={20}
                  className="text-center mx-auto text-green-400"
                />
              </span>
            )}
            ignoreLastElementForDrag={
              conditions[conditions.length - 1]?.statement.trim() === ""
            }
            render={(
              condition: ICondition,
              ind: number,
              allContexts: IContext[]
            ) => (
              <ConditionsItem
                ind={ind}
                setConditions={setConditions}
                condition={condition}
                reactFlow={reactFlow}
                setInvalidConnectionTooltipOpen={
                  setInvalidConnectionTooltipOpen
                }
                invalidConnTimer={invalidConnTimer}
                setElements={data.setElements}
              />
            )}
          />
          <div className="nodrag mt-3">
            <button
              disabled={conditions.some(
                (cond) => cond.statement.trim() === ""
              )}
              className="bg-purple-500 text-white w-full px-2 py-1 rounded-sm disabled:opacity-50"
              onClick={() => {
                setConditions((prev) => [
                  ...prev,
                  {
                    statement: ""
                  }
                ]);
              }}
            >
              Add Condition
            </button>
          </div>
        </div>
      </div>

      <Tooltip
        id={"invalid-connection-tooltip"}
        isOpen={invalidConnectionTooltipOpen}
        place="bottom"
        className="bg-background-layer2"
      >
        A condition can only have a single true/false response/action sequence.
      </Tooltip>

      <Tooltip
        id={"fetched-context-arrow-down-tooltip"}
        place="bottom"
        className="bg-background-layer2"
      >
        Fetched Contexts are run sqeuentially, from top to bottom. <br />
        You can use the output of one context in any subsequent contexts.
      </Tooltip>

      <Tooltip
        id={"conditions-arrow-down-tooltip"}
        place="bottom"
        className="bg-background-layer2"
      >
        Next conditions will be evaluated if this condition <br />
        evaluates to true.
      </Tooltip>

      {AddFetchedContextTooltip(
        fetchedContexts,
        newContextType,
        setNewContextType,
        setFetchedContexts
      )}
      <Modal
        open={!!jsonEditorModalOpen}
        setOpen={(val: boolean) =>
          setJsonEditorModalOpen((prev) => (val ? prev : ""))
        }
        title="JSON Editor"
        className="w-full max-w-2xl"
        disableClickOutside
      >
        <div className="flex flex-col gap-4 p-6 bg-background-layer1 text-contentColor">
          <h1 className="text-lg font-bold">Set Fetched Context</h1>
          <p>
            All your changes will be overwritten using this JSON editor.
            <br />
          </p>
          <Editor
            height="500px"
            width="100%"
            theme="vs-dark"
            language="json"
            value={JSON.stringify(modalJSON, null, 2)}
            onChange={(e) => {
              try {
                setModalJSON(JSON.parse(e));
              } catch (err) {}
            }}
            options={{
              readOnly: false,
              minimap: {
                enabled: false
              }
            }}
          />
          <div className="flex gap-4 justify-end">
            <button
              className="bg-background-layer3 text-contentColor px-2 py-1 rounded-sm"
              onClick={() => setJsonEditorModalOpen("")}
            >
              Cancel
            </button>
            <button
              disabled={!Array.isArray(modalJSON)}
              className="bg-green-500 text-contentColor disabled:text-gray-300 disabled:cursor-not-allowed px-2 py-1 rounded-sm"
              onClick={onModalSaveClick}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </NodeTemplate>
  );
};

export default memo(RuleEditorNode);

/* Tooltip for creating a new context */
function AddFetchedContextTooltip(
  fetchedContexts: IContext[],
  newContextType: string,
  setNewContextType,
  setFetchedContexts
) {
  return (
    <Tooltip
      id={"fetched-context-add-tooltip"}
      place="bottom"
      clickable
      className="bg-background-layer2 nodrag"
    >
      {!fetchedContexts?.some((c) => c.error) ? (
        <>
          <div>Select the type of fetched context you want to add.</div>

          <div className="flex gap-2 justify-center mt-2">
            <TransitionedMenu
              buttonComponent={
                <div
                  className={`flex px-2 py-1 rounded-sm uppercase text-contentColor items-center bg-background-layer3 `}
                >
                  {newContextType}
                  <ChevronDownIcon width={16} className="ml-2" />
                </div>
              }
            >
              {Object.keys(contextFieldRequiredMap).map((type) => (
                <Menu.Item key={"fetched-context-type-" + type}>
                  {({ active }) => (
                    <button
                      className={`w-full flex gap-1 items-center uppercase ${
                        active && "bg-background-layer2"
                      } min-w-[6rem] text-left whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer3 transition-all duration-200`}
                      onClick={() => setNewContextType(type)}
                    >
                      {type}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </TransitionedMenu>
            <button
              className="bg-green-500 text-white px-2 py-1 rounded-sm"
              onClick={() => {
                setFetchedContexts((prev) => [
                  ...prev,
                  {
                    key: "",
                    type: newContextType as IContextType,
                    device_id: "",
                    fleet_id: "",
                    user_id: ""
                  }
                ]);
              }}
            >
              Add
            </button>
          </div>
        </>
      ) : (
        <>
          Fetched Context of type
          <span className="text-yellow-500 mx-1">
            {fetchedContexts[fetchedContexts.length - 1]?.type}
          </span>
          requires: <br />
          {contextFieldRequiredMap[
            fetchedContexts[fetchedContexts.length - 1]?.type
          ]
            .filter((field) => {
              return (
                fetchedContexts[fetchedContexts.length - 1]?.[field].trim() ===
                ""
              );
            })
            .map((field) => (
              <span className="text-blue-500 mr-1">{field}, </span>
            ))}
          to be filled
        </>
      )}
    </Tooltip>
  );
}
