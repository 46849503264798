import React from "react";

const Sidebar = () => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <aside className="bg-background-layer2 text-contentColor">
      <h1 className="text-2xl font-medium mb-3">Nodes</h1>
      <div className="description mb-2">
        Drag these nodes to the playground to start building your rule!
      </div>
      <div
        className="dndnode "
        onDragStart={(event) => onDragStart(event, "actionSequenceNode")}
        draggable
      >
        Action Sequence Node
      </div>
      <div
        className="dndnode "
        onDragStart={(event) => onDragStart(event, "httpResponseNode")}
        draggable
      >
        HTTP Response Node
      </div>
    </aside>
  );
};

export default Sidebar;
