import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import Select from "react-select";
import { FieldError } from "../shared/components";
import useOnboardingStore from "../../store/onboarding/onboarding.store";
import { useAuthStore } from "../../store";
import { data } from "../shared/utils/data-file.util";

function OnboardingOrganizationDetails() {
  const navigate = useNavigate();
  const [updateAuthUser] = useAuthStore((state) => [state.updateAuthUser]);
  const [onboardingDetails, setOnboardingDetails] = useOnboardingStore(
    (state) => [state.onboardingDetails, state.setOnboardingDetails]
  );
  const [selectedOrgType, setSelectedOrgType] = useState<{
    value: string;
    label: string;
  }>({ value: "Personal", label: "Personal" });
  const [inputFieldValues, setInputFieldValues] = useState({
    organizationName: "",
    organizationUrl: ""
  });
  const [inputFieldErrors, setInputFieldErrors] = useState({
    organizationName: "",
    organizationUrl: ""
  });

  useEffect(() => {
    if (onboardingDetails?.org_name) {
      setInputFieldValues({
        organizationName: onboardingDetails.org_name,
        organizationUrl: onboardingDetails.website_link
      });
    }
  }, [onboardingDetails]);

  useEffect(() => {
    updateAuthUser({
      onboardingStatus: "0"
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    if (!inputFieldValues.organizationName.trim()) {
      return setInputFieldErrors({
        ...inputFieldErrors,
        organizationName: "Please enter organization name."
      });
    } else if (
      inputFieldValues.organizationName.trim() &&
      inputFieldErrors.organizationName
    ) {
      setInputFieldErrors({ ...inputFieldErrors, organizationName: "" });
    }

    const payload = {
      org_name: inputFieldValues.organizationName,
      type: selectedOrgType.value,
      website_link: inputFieldValues.organizationUrl
    };

    setOnboardingDetails({ ...onboardingDetails, ...payload });
    navigate("/onboarding/individual-details");
  };

  const handleOrgTypeSelection = (option: {
    value: string;
    label: string;
  }) => {
    setSelectedOrgType(option);
  };

  const handleFieldChange = (e: any, id: string) => {
    const payload = { ...inputFieldValues };
    payload[id] = e.target.value;
    setInputFieldValues({ ...payload });
  };

  const options = [{ value: "Personal", label: "Personal" }];

  return (
    <div className="flex flex-col flex-1 mt-[3.5rem]">
      <Formik
        initialValues={{ organizationName: "", organizationUrl: "" }}
        onSubmit={handleSubmit}
      >
        <Form className="flex flex-col flex-1">
          <div>
            <h3 className="text-2xl font-bold text-gray-800">
              {data.onboarding.orgDetails.left.heading}
            </h3>

            <p className="mt-2 text-base text-gray-500">
              {data.onboarding.orgDetails.left.subHeading}
            </p>
          </div>

          <div className="flex-1 space-y-6 mt-8">
            <div className="flex-1">
              <div className="mb-5">
                <label className="text-sm font-medium text-[#B7B9C1]">
                  Organization Name
                </label>

                <Field
                  type="text"
                  id="organizationName"
                  name="organizationName"
                  placeholder="Organization Name"
                  value={inputFieldValues.organizationName}
                  onChange={(e) => handleFieldChange(e, "organizationName")}
                  className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                />
                {inputFieldErrors.organizationName ? (
                  <FieldError message={inputFieldErrors.organizationName} />
                ) : null}
              </div>

              <div className="mb-5">
                <label className="text-sm font-medium text-[#B7B9C1]">
                  Type Of Organisation{" "}
                </label>

                <Select
                  id="typeOfOrg"
                  name="typeOfOrg"
                  styles={{
                    container: (baseStyles, state) => ({
                      ...baseStyles,
                      marginTop: "8px"
                    }),
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: "4px"
                    })
                  }}
                  placeholder="Select"
                  options={options}
                  value={selectedOrgType}
                  onChange={(e) => handleOrgTypeSelection(e)}
                  isSearchable={false}
                  classNames={{
                    menu: () => "!bg-background-layer1 !text-contentColor",
                    control: () =>
                      "!bg-background !text-contentColor !border-background-layer3 !rounded-md focus:!ring focus:!ring-opacity-40 focus:!ring-primary focus:!border-primaryLight sm:!text-sm",
                    valueContainer: () => "!text-contentColor",
                    singleValue: () => "!text-contentColor",
                    menuList: () => "!text-contentColor",
                    option: () =>
                      "!text-contentColor hover:!bg-background-layer2 !bg-background-layer1 !border-background-layer3",
                    noOptionsMessage: () =>
                      "!text-contentColor !bg-background-layer1",
                    multiValue: () =>
                      "!bg-background-layer3 !text-contentColor",
                    multiValueLabel: () => "!text-contentColor"
                  }}
                />
              </div>

              <div className="mb-5">
                <label className="text-sm font-medium text-[#B7B9C1]">
                  Organisation Website link
                </label>

                <Field
                  type="text"
                  id="organizationUrl"
                  name="organizationUrl"
                  placeholder="Organisation Website link"
                  value={inputFieldValues.organizationUrl}
                  onChange={(e) => handleFieldChange(e, "organizationUrl")}
                  className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                />
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="w-1/2 px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
          >
            Next
          </button>
        </Form>
      </Formik>
    </div>
  );
}

export default OnboardingOrganizationDetails;
