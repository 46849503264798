import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { object, string } from "yup";
import { FieldError } from "../shared/components";
import useOnboardingStore, {
  IOnboardingDetails
} from "../../store/onboarding/onboarding.store";
import { toast } from "react-toastify";
import {
  useAuthStore,
  useDashboardStore,
  useFleetAndDevicesStore
} from "../../store";
import jwtDecode from "jwt-decode";
import { data } from "../shared/utils/data-file.util";
import { useGetOrganizations } from "@app/shared/hooks/get/organizations";
import { useCreateOrg } from "@app/shared/hooks/post/create-org";

const individualDetailsSchema = object().shape({
  firstName: string().required("Please enter first name"),
  lastName: string().required("Please enter last name"),
  // mobileNumber: string().required("Please enter mobile number"),
  emailId: string().email().required("Please enter email")
});

function OnboardingIndividualDetails() {
  const navigate = useNavigate();
  const [token, updateAuthUser] = useAuthStore((state) => [
    state.token,
    state.updateAuthUser
  ]);
  const decoded: { name: string; owner: string } = jwtDecode(token);

  const [onboardingDetails, setOnboardingDetails] = useOnboardingStore(
    (state) => [state.onboardingDetails, state.setOnboardingDetails]
  );

  const [setProjects, setFleets, setDevices, setCombinedDevices] =
    useFleetAndDevicesStore((state) => [
      state.setProjects,
      state.setFleets,
      state.setDevices,
      state.setCombinedDevices
    ]);

  const [setDashboards] = useDashboardStore((state) => [state.setDashboards]);

  useEffect(() => {
    updateAuthUser({
      onboardingStatus: "1"
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { refetch: refetchOrgs } = useGetOrganizations();
  const createOrgMutation = useCreateOrg();

  const handleSubmit = async (
    values: {
      firstName: string;
      lastName: string;
      // mobileNumber: string;
      emailId: string;
    },
    { resetForm }
  ) => {
    const payload = {
      ...onboardingDetails,
      email: values.emailId,
      firstname_user: values.firstName,
      lastname_user: values.lastName
    };

    createOrgMutation.mutate(payload, {
      onSuccess: (orgId) => {
        refetchOrgs().then(({ data }) => {
          const newOrg = data.find((org) => org.id === orgId);

          updateAuthUser({
            selectedOrg: newOrg,
            organization: data
          });

          toast.success(
            `${payload.org_name} organization created successfully!!!`
          );

          setOnboardingDetails({} as IOnboardingDetails);
          setProjects([]);
          setFleets([]);
          setDevices([]);
          setCombinedDevices([]);
          setDashboards([]);

          navigate("/fleet-and-devices/projects");
        });
      }
    });
  };

  const initialValues = {
    firstName: onboardingDetails.firstName ?? "",
    lastName: onboardingDetails.lastName ?? "",
    // mobileNumber: "",
    emailId: decoded.name
  };

  return (
    <div className="flex flex-col flex-1 mt-[3.5rem]">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={individualDetailsSchema}
      >
        <Form className="flex flex-col flex-1">
          <div>
            <h3 className="text-2xl font-bold text-gray-800">
              {data.onboarding.indivDetails.left.heading}
            </h3>

            <p className="mt-2 text-base text-gray-500">
              {data.onboarding.indivDetails.left.subHeading}
            </p>
          </div>

          <div className="flex-1 space-y-6 mt-8">
            <div className="flex-1">
              <div className="mb-5 flex justify-between">
                <div className="w-full">
                  <label className="text-sm font-medium text-[#B7B9C1]">
                    First Name
                  </label>

                  <Field
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    value={onboardingDetails.firstName}
                    onChange={(e) =>
                      setOnboardingDetails({
                        ...onboardingDetails,
                        firstName: e.target.value
                      })
                    }
                    className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                  />
                  {!onboardingDetails.firstName?.trim() ? (
                    <ErrorMessage name="firstName">
                      {(msg) => <FieldError message={msg} />}
                    </ErrorMessage>
                  ) : null}
                </div>

                <div className="ml-6 w-full">
                  <label className="text-sm font-medium text-[#B7B9C1]">
                    Last Name
                  </label>

                  <Field
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    value={onboardingDetails.lastName}
                    onChange={(e) =>
                      setOnboardingDetails({
                        ...onboardingDetails,
                        lastName: e.target.value
                      })
                    }
                    className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                  />
                  {!onboardingDetails.lastName?.trim() ? (
                    <ErrorMessage name="lastName">
                      {(msg) => <FieldError message={msg} />}
                    </ErrorMessage>
                  ) : null}
                </div>
              </div>

              {/* <div className="mb-5">
                                <label className="text-sm font-medium text-[#B7B9C1]">Mobile Number</label>

                                <Field
                                    type="number"
                                    id="mobileNumber"
                                    name="mobileNumber"
                                    placeholder="Mobile Number"
                                    className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                                />
                                <ErrorMessage name="mobileNumber">
                                    {(msg) => <FieldError message={msg} />}
                                </ErrorMessage>
                            </div> */}

              <div className="mb-5">
                <label className="text-sm font-medium text-[#B7B9C1]">
                  Email ID
                </label>

                <Field
                  type="email"
                  id="emailId"
                  name="emailId"
                  placeholder="Email ID"
                  className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                />
                <ErrorMessage name="emailId">
                  {(msg) => <FieldError message={msg} />}
                </ErrorMessage>
              </div>
            </div>
          </div>

          <div className="flex items-center mt-12 space-x-4">
            <Link
              to="/onboarding/organization-details"
              className="w-1/2 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white"
            >
              Back
            </Link>

            <button
              type="submit"
              className="w-1/2 px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
            >
              Next
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default OnboardingIndividualDetails;
