import produce from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../../app/shared/config";
import {
  IDashboard,
  ILineDefination,
  IPieDefinations,
  IGaugeDefination,
  IBarDefination
} from "../../interfaces";
import dateService from "@services/date.service";

export interface IFilterProps {
  type: string;
  span?: string;
  rangeStart?: Date;
  rangeEnd?: Date;
  start: string;
  stop: string;
}
interface ICreatePanelState {
  panelName?: string;
  // *********************** old types
  dataSource?: string;
  dataCollection?: Array<string>;
  dataPointsCollection?: string;
  dataPointsType?: Array<string>;
  selectedParams?: any;
  selectedSource?: any;
  selectedPanel?: string;
  // *********************** old types ^

  // *********************** new panel creation flow
  inputValues?: {
    dataPoints?: any[];
    aggregateParams?: boolean;
    devicesInDataPoints?: any[];
    selectedParams?: any[];
    startDate?: string;
    endDate?: string;
    timePeriod?: {
      start: string;
      end: string;
    };
    timeBucket?: number;
    timeBucketUnit?: "mins" | "hours" | "days";
    filters: {
      devices: any[];
      tags: any[];
    };
  };
  // *********************** new panel creation flow
}

const initialVals: ICreatePanelState["inputValues"] = {
  dataPoints: [],
  aggregateParams: true,
  devicesInDataPoints: [],
  selectedParams: [],
  filters: {
    devices: [],
    tags: []
  },
  timePeriod: {
    start: "-10d",
    end: "0d"
  },
  timeBucket: 1,
  timeBucketUnit: "days" as "mins" | "hours" | "days"
};

interface IDashboardState {
  dashboards: IDashboard[];
  panels: (
    | ILineDefination
    | IPieDefinations
    | IGaugeDefination
    | IBarDefination
  )[];
  carousels: {
    [x: string]: (
      | ILineDefination
      | IPieDefinations
      | IGaugeDefination
      | IBarDefination
    )[];
  };
  activeFilter: IFilterProps;
  createPanelState: ICreatePanelState;
  zoomLevel: number;
  newDashboard: string;
  setNewDashboard: (x: string) => void;
  showAddPanel: boolean;
  setShowAddPanel: (x: boolean) => void;
  activeDashboard: IDashboard;
  editingLayout: boolean;
  // all the layouts for all breakpoints ({lg: [...], md: [...], ...}})
  layouts: any;
  // panel specific additional stuff
  createPanelAppearance: any;
  setCreatePanelAppearance: (x: any) => void;
  setEditingLayout: (x: boolean) => void;
  setLayouts: (x: any) => void;
  setPanels: (
    x: (
      | ILineDefination
      | IPieDefinations
      | IGaugeDefination
      | IBarDefination
    )[]
  ) => void;
  setCarousels: (x: {
    [x: string]: (
      | ILineDefination
      | IPieDefinations
      | IGaugeDefination
      | IBarDefination
    )[];
  }) => void;
  setDashboards: (x: IDashboard[]) => void;
  setActiveDashboard: (x: IDashboard) => void;
  setActiveFilter: (x: IFilterProps) => void;
  setCreatePanelState: (x: ICreatePanelState) => void;
  setInputValues: (x: ICreatePanelState["inputValues"]) => void;
  setPanelName: (x: ICreatePanelState["panelName"]) => void;
  clearCreatePanelState: () => void;
  setZoomLevel: (x: number) => void;
}

const useDashboardStore = create<IDashboardState>()(
  persist(
    devtools(
      (set) => ({
        dashboards: [],
        showAddPanel: true,
        panels: [],
        zoomLevel: 100,
        carousels: {},
        newDashboard: "",
        setNewDashboard: (val) => {
          set(
            produce((state) => {
              state.newDashboard = val;
            }),
            false,
            "dashboard/setNewDashboard"
          );
        },
        setShowAddPanel: (val) => {
          set(
            produce((state) => {
              state.showAddPanel = val;
            }),
            false,
            "dashboard/setShowAddPanel"
          );
        },
        editingLayout: false,
        activeDashboard: {} as IDashboard,
        activeFilter: {
          type: "time",
          span: "1h",
          start: dateService.getCurrentUTCDate().subtract(1, "hours").format(),
          stop: dateService.getCurrentUTCDate().format()
        } as IFilterProps,
        createPanelState: {
          inputValues: initialVals
        },
        selectedParams: [],
        layouts: {},
        createPanelAppearance: {},
        setLayouts: (layout) =>
          set(
            produce((state) => {
              state.layouts = layout;
            }),
            false,
            "dashboard/setLayouts"
          ),
        setPanels: (panelData) =>
          set(
            produce((state) => {
              state.panels = panelData;
            }),
            false,
            "dashboard/setPanels"
          ),
        setCarousels: (carouselData) =>
          set(
            produce((state) => {
              state.carousels = carouselData;
            }),
            false,
            "dashboard/setCarousels"
          ),
        setDashboards: (dashboardItems) =>
          set(
            produce((state) => {
              state.dashboards = dashboardItems;
            }),
            false,
            "dashboard/setDashboards"
          ),
        setActiveDashboard: (dashboard) => {
          set(
            produce((state) => {
              state.activeDashboard = dashboard;
            }),
            false,
            "dashboard/setActiveDashboard"
          );
        },
        setActiveFilter: (filter) =>
          set(
            produce((state) => {
              state.activeFilter = filter;
            }),
            false,
            "dashboard/setActiveFilter"
          ),
        setCreatePanelState: (panelState) =>
          set(
            produce((state) => {
              state.createPanelState = panelState;
            }),
            false,
            "dashboard/setCreatePanelState"
          ),
        setInputValues: (inputValues: ICreatePanelState["inputValues"]) =>
          set(
            produce((state: IDashboardState) => {
              state.createPanelState.inputValues = inputValues;
            }),
            false,
            "dashboard/setInputValues"
          ),
        setPanelName: (name: string) =>
          set(
            produce((state: IDashboardState) => {
              state.createPanelState.panelName = name;
            }),
            false,
            "dashboard/setPanelName"
          ),
        setZoomLevel: (zoom) =>
          set(
            produce((state) => {
              state.zoomLevel = zoom;
            }),
            false,
            "dashboard/setZoomLevel"
          ),
        setEditingLayout: (editing) =>
          set(
            produce((state) => {
              state.editingLayout = editing;
            }),
            false,
            "dashboard/setEditingLayout"
          ),
        setCreatePanelAppearance: (defOrFunc) =>
          set(
            produce((state) => {
              state.createPanelAppearance =
                typeof defOrFunc === "function"
                  ? defOrFunc(state.createPanelAppearance)
                  : defOrFunc;
            }),
            false,
            "dashboard/setEditingLayout"
          ),

        clearCreatePanelState: () =>
          set(
            produce((state) => {
              state.createPanelState = {
                inputValues: initialVals
              } as ICreatePanelState;
              state.createPanelAppearance = {};
            }),
            false,
            "dashboard/clearCreatePanelState"
          )
      }),

      { name: "fleet-and-devices", serialize: { options: true } }
    ),
    {
      name: "dashboard", // unique name
      getStorage: () => appConstants.keys.storage // (optional) by default, 'localStorage' is used
    }
  )
);

export default useDashboardStore;
