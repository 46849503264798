import React, { useState } from "react";
import TransitionedMenu from "./transitioned-men.component";
import { Menu } from "@headlessui/react";
import {
  useAuthStore,
  useDashboardStore,
  useFleetAndDevicesStore
} from "@store/index";
import { PlusIcon } from "@heroicons/react/24/outline";
import { loggerService } from "@services/logger";
import { IDashboard } from "@interfaces/dashboard.interface";
import networkService from "@services/network.service";
import { useNavigate } from "react-router-dom";
import { ICreateDashbaordResponse } from "@interfaces/shared.interface";
import ShowDashCreate from "@app/dashboard/components/dash-create-modal";

type Props = {};

const DashboardSelector = (props: Props) => {
  const navigate = useNavigate();
  const [user] = useAuthStore((state) => [state.user]);
  const [showDashCreateOpen, setShowDashCreateOpen] = useState(false);

  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);

  const [
    dashboards,
    activeDashboard,
    setNewDashboard,
    setLayouts,
    setActiveDashboard,
    setPanels
  ] = useDashboardStore((state) => [
    state.dashboards,
    state.activeDashboard,
    state.setNewDashboard,
    state.setLayouts,
    state.setActiveDashboard,
    state.setPanels
  ]);

  const handleCreateNewTab = (name: string, description: string) => {
    (async () => {
      try {
        const dashbaordResponse =
          await networkService.post<ICreateDashbaordResponse>(
            `projects/${selectedProject.id}/dashboards`,
            {
              dashboard_name: name,
              dashboard_description: description
            },
            {
              headers: {
                "ORG-ID": user.selectedOrg.id
              }
            }
          );
        const { dashboardId } = dashbaordResponse.data;
        if (dashboardId) {
          setNewDashboard(dashboardId);
        } else {
          setNewDashboard("");
        }
        setShowDashCreateOpen(false);
      } catch (error) {
        loggerService.error(error);
      }
    })();
  };

  const handleTabChange = (dashbaord: IDashboard) => {
    setActiveDashboard(dashbaord);
    if (dashbaord.meta_data?.layouts) {
      setLayouts(dashbaord.meta_data.layouts);
    } else {
      setLayouts({});
    }
    if (dashbaord.id === activeDashboard.id) {
      return;
    }
    setPanels([]);
    navigate(`/dashboard/${dashbaord.id}`);
  };

  return (
    <>
      <ShowDashCreate
        showDashCreateOpen={showDashCreateOpen}
        setShowDashCreateOpen={setShowDashCreateOpen}
        handleCreate={handleCreateNewTab}
      />
      <div className="bg-background">
        <div className="relative flex flex-col justify-bet">
          <div className="relative flex flex-row justify-start items-center mt-1 w-full">
            <>
              <div className="relative w-10/12 mb-2 justify-start">
                <TransitionedMenu
                  buttonComponent={
                    <div
                      className={`px-3 py-1 -mb-1 -ml-1 whitespace-nowrap flex justify-center text-sm items-center hover:bg-background-layer1 hover:text-contentColor rounded-md text-contentColor focus:ring-1 focus:ring-offset-1 focus:ring-offset-background-layer2 focus:ring-background-layer2`}
                    >
                      {activeDashboard.id
                        ? activeDashboard.dashboard_name
                        : "Select Dashboard"}
                    </div>
                  }
                >
                  <>
                    {dashboards.map((dashbaord) => (
                      <Menu.Item key={dashbaord.id}>
                        <button
                          key={dashbaord.id}
                          className={`w-full flex gap-1 items-center text-left whitespace-nowrap px-4 py-2 text-sm ${
                            activeDashboard.id === dashbaord.id
                              ? "bg-background-layer3 text-contentColor"
                              : "text-contentColor hover:bg-background-layer2"
                          } `}
                          onClick={() => handleTabChange(dashbaord)}
                        >
                          {dashbaord.dashboard_name}
                        </button>
                      </Menu.Item>
                    ))}
                    <Menu.Item>
                      <button
                        className={`flex gap-2 whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer2`}
                        onClick={() => setShowDashCreateOpen(true)}
                      >
                        <PlusIcon width={14} />
                        <span className="whitespace-nowrap text-sm">
                          Create Dashboard
                        </span>
                      </button>
                    </Menu.Item>
                  </>
                </TransitionedMenu>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardSelector;
