import { EMPTY, from, Subject } from "rxjs";
import {
  buffer,
  catchError,
  debounceTime,
  mergeMap,
  switchMap
} from "rxjs/operators";
import appConstants from "../../../app/shared/config";
import networkService from "../../network.service";

import {
  ICapturedPayload,
  ILogMessage,
  LogLevel,
  TransportTemplate
} from "../logger.interface";
import { AbstractTransporter } from "./base.transporter";
import { useAuthStore } from "../../../store";

interface LogBatch<T> {
  level: LogLevel;
  payload: T;
}

/**
 * Default ConsoleTransporter template
 */
const defaultHttpTransporterTemplate = <T extends ILogMessage>(
  p: ICapturedPayload
) =>
  ({
    message: p.message,
    payload: p.payload,
    timestamp: p.timestamp,
    userAgent: navigator.userAgent
  } as T);

/**
 * HTTP transporter
 */
export default class HttpTransporter<
  T extends ILogMessage
> extends AbstractTransporter<T> {
  batcher$ = new Subject<LogBatch<T>>();

  /**
   * @param template optional if not given, using default (no template, as message comes is written)
   */
  constructor(template?: TransportTemplate<T>) {
    super(template || defaultHttpTransporterTemplate);

    this.batcher$
      .pipe(
        buffer(this.batcher$.pipe(debounceTime(1500))),
        switchMap((batches) => this.toHttpRequests(batches))
      )
      .subscribe();
  }

  /**
   * Writes/sends payload to configured url and given Http Method
   */
  doWrite(payload: T, level: LogLevel): void {
    this.batcher$.next({ level, payload });
  }

  /**
   * Creates http observable with given params (url, httpMethod, level & payload)
   */
  private toHttpRequests(batches: Array<LogBatch<T>>) {
    const dataSource = batches.slice(-20).map((batch) => {
      return this._preparePayload(batch.payload);
    });

    return from(dataSource)
      .pipe(
        mergeMap((payload) =>
          networkService.post(appConstants.urls.logError, payload, {
            headers: {
              "ORG-ID": useAuthStore.getState().user?.selectedOrg?.id
            },
            isErrorLog: true
          })
        )
      )
      .pipe(
        catchError(() => {
          return EMPTY;
        })
      );
  }

  private _preparePayload(error: ILogMessage) {
    const payload = error.payload.map((row) => {
      if (row instanceof Error) {
        return row.stack;
      }
      return row;
    });

    return {
      sourceApp: "Scheduler React",
      message: error.message,
      stackTrace: error.userAgent,
      additionalInfo: JSON.stringify(payload)
    };
  }
}
