import { BarChart } from "@tremor/react";
import { IGraph } from "../../../../interfaces";
import { useEffect, useRef, useState } from "react";
import { useDashboardStore } from "@store/index";

/* 
    Component: Bar Graph
    Props:  {
        chartdata : data distribution [Array of objects]
        title : Title of graph [ panel name ]
        categories : items, keys from data to chart bars for [ Array of string ]
        colors : [ Array of string ] should be same in length of categories
        datakey :  name of key from data that represent value representation over all objects
        yMax: maximun vaues on y axis 
        yMin: minimum vaues on y axis
    }
    Author: Aaditya Kashid
*/
const BarGraph: React.FC<IGraph> = ({
  chartdata,
  categories,
  colors,
  datakey,
  yMax,
  yMin,
  orientation,
  showExample = false
}) => {
  const valueFormatter = (number: number) =>
    Intl.NumberFormat("us").format(number).toString();
  const containerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(200);
  const [settingHeight, setSettingHeight] = useState(false);
  const heightTimeout = useRef<NodeJS.Timeout | null>(null);

  const { orientation: createPanelOrientation } = useDashboardStore(
    (state) => state.createPanelAppearance
  );

  useEffect(() => {
    if (showExample) {
      return;
    }
    const timeout = heightTimeout.current;
    const resizeObserver = new ResizeObserver((parentDiv) => {
      const { height } = parentDiv[0].contentRect;
      setSettingHeight(true);
      setHeight(height);

      if (timeout) {
        clearTimeout(timeout);
      }
      heightTimeout.current = setTimeout(() => {
        setSettingHeight(false);
      }, 1000);
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
      clearTimeout(timeout);
    };
  }, [showExample]);

  return (
    <>
      <div
        onMouseDown={(e) => {
          // to prevent react-grid-layout from dragging
          e.preventDefault();
          e.stopPropagation();
        }}
        ref={containerRef}
        className="px-4 pb-1 flex-grow h-full"
      >
        {!settingHeight ? (
          <BarChart
            style={{
              height: showExample ? "300px" : `${height - 40}px`
            }}
            data={chartdata}
            index={datakey}
            categories={categories}
            colors={colors}
            valueFormatter={valueFormatter}
            yAxisWidth={40}
            minValue={yMin}
            maxValue={yMax}
            layout={
              showExample
                ? createPanelOrientation
                  ? createPanelOrientation.value
                  : "horizontal"
                : orientation ?? "horizontal"
            }
          />
        ) : null}
      </div>
    </>
  );
};

export default BarGraph;
