import { useState, useEffect, useMemo, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import Select from "react-select";

interface IPieInput {
  linesParams: any;
  label: string;
  color: string;
  colorName: string;
}

interface IPieError {
  color: boolean;
}

export const PieChartForm = ({
  onChangeHandler,
  enableCreatebutton,
  params
}) => {
  const pieData = useMemo(() => {
    let data = [];
    if (
      params.length === 1 &&
      params[0].label.includes("enum") &&
      "options" in params[0].data &&
      params[0].data.options.length
    ) {
      data = params[0].data.options.map((optn, index) => {
        return {
          linesParams: params[0],
          color: "",
          colorName: `color_${index}`,
          label: optn
        };
      });
    } else {
      data = params.map((param, index) => {
        return {
          linesParams: param,
          color: "",
          colorName: `color_${index}`,
          label: param.data.value
        };
      });
    }
    return data;
  }, [params]);

  const [autoSelect, setAutoSelect] = useState(false);
  const [linesInputs, setLinesInputs] = useState<IPieInput[]>(pieData);

  const [error, setErrors] = useState<IPieError[]>(
    pieData.map((param) => {
      return { color: false };
    })
  );

  useEffect(() => {
    setLinesInputs(pieData);
    setErrors(
      pieData.map((param) => {
        return { color: false };
      })
    );
  }, [pieData]);

  const initialValues = useMemo(
    () =>
      linesInputs.map((line) => {
        return { [line.colorName]: "" };
      }),
    [linesInputs]
  );

  const lineColorOptions = [
    { value: "slate", label: "slate" },
    { value: "gray", label: "gray" },
    { value: "zinc", label: "zinc" },
    { value: "neutral", label: "neutral" },
    { value: "stone", label: "stone" },
    { value: "red", label: "red" },
    { value: "orange", label: "orange" },
    { value: "amber", label: "amber" },
    { value: "yellow", label: "yellow" },
    { value: "lime", label: "lime" },
    { value: "green", label: "green" },
    { value: "emerald", label: "emerald" },
    { value: "teal", label: "teal" },
    { value: "cyan", label: "cyan" },
    { value: "sky", label: "sky" },
    { value: "blue", label: "blue" },
    { value: "indigo", label: "indigo" },
    { value: "violet", label: "violet" },
    { value: "purple", label: "purple" },
    { value: "fuchsia", label: "fuchsia" },
    { value: "pink", label: "pink" },
    { value: "rose", label: "rose" }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  function getRandIdx(max) {
    return Math.floor(Math.random() * max);
  }

  const handleAutoColorSelect = () => {
    let newInputs = [...linesInputs];
    newInputs.forEach((inpt) => {
      inpt.color = lineColorOptions[getRandIdx(lineColorOptions.length)].value;
    });
    setLinesInputs(newInputs);
  };

  const handleChange = (idx: number, key: string, value: any) => {
    let newInputs = [...linesInputs];
    newInputs[idx][key] = value;
    setLinesInputs(newInputs);
  };

  const isValid = useCallback(() => {
    let errVal = true;
    let errors = [...error];

    linesInputs.forEach((line, index) => {
      if (!line.color.length) {
        errors[index].color = true;
        errVal = false;
      } else errors[index].color = false;
    });

    setErrors(errors);

    return errVal;
  }, [error, linesInputs]);

  useEffect(() => {
    if (linesInputs.length) {
      if (isValid()) {
        onChangeHandler(linesInputs);
        enableCreatebutton(false);
      } else {
        enableCreatebutton(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linesInputs]);

  const getBorderStyle = (error: boolean) => {
    return {
      control: (base) =>
        error
          ? {
              ...base,
              borderColor: "red",
              boxShadow: "none",
              padding: "4px",
              ":hover": { borderColor: "red" },
              ":focus": { borderColor: "red" }
            }
          : { ...base, padding: "4px" }
    };
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        <div className="mb-2 ml-2 flex flex-row items-center">
          <Field
            type="checkbox"
            id="gradientEnabled"
            name="gradientEnabled"
            autoComplete="off"
            checked={autoSelect}
            className="block p-2 my-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
            onChange={(e) => {
              setAutoSelect(e.target.checked);
              if (e.target.checked) {
                setErrors(
                  pieData.map((param) => {
                    return { color: false };
                  })
                );
              }
              handleAutoColorSelect();
            }}
          />
          <label className="text-sm font-medium text-contentColorLight ml-3">
            Auto Select Colors
          </label>
        </div>
        {!autoSelect && (
          <div className="text-base mt-2">
            {linesInputs.length ? "Select color for:" : null}
            <div className="grid grid-cols-2">
              {linesInputs.map((line, index) => (
                <div key={index} className="">
                  <div className="flex flex-row">
                    <div className="w-full">
                      <label className="text-sm text-contentColorLight">
                        {line.linesParams.label}{" "}
                      </label>
                      <Field
                        as="select"
                        id={line.colorName}
                        name={line.colorName}
                        className={`block w-10/12 pb-3 mt-1 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                          error[index]["color"]
                            ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                            : "border-background-layer3 focus:ring-blue-300 focus:border-blue-400"
                        }`}
                        placeholder="Select Color"
                        component={Select}
                        options={lineColorOptions}
                        styles={getBorderStyle(error[index]["color"])}
                        onChange={(e) => handleChange(index, "color", e.value)}
                        maxMenuHeight={180}
                        classNames={{
                          menu: () =>
                            "!bg-background-layer1 !text-contentColor !-mt-2 !pt-0",
                          control: () =>
                            `!bg-background !text-contentColor !border-background-layer3 !rounded-md focus:!ring focus:!ring-opacity-40 focus:!ring-primary focus:!border-primaryLight sm:!text-sm
                              ${
                                error[index]["color"]
                                  ? "!border-red-500 focus:!ring-red-300 focus:!border-red-400"
                                  : "!border-background-layer3 focus:!ring-blue-300 focus:!border-blue-400"
                              }
                            `,
                          valueContainer: () => "!text-contentColor",
                          singleValue: () => "!text-contentColor",
                          menuList: () => "!text-contentColor",
                          option: () =>
                            "!text-contentColor hover:!bg-background-layer2 !bg-background-layer1 !border-background-layer3",
                          noOptionsMessage: () =>
                            "!text-contentColor !bg-background-layer1",
                          multiValue: () =>
                            "!bg-background-layer3 !text-contentColor",
                          multiValueLabel: () => "!text-contentColor"
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Form>
    </Formik>
  );
};
