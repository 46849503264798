import { useLocation, useNavigate, Link } from "react-router-dom";
import { IFleet } from "@interfaces/index";
import {
  useAuthStore,
  useDashboardStore,
  useSidebarStore
} from "@store/index";
import useFleetAndDevicesStore from "@store/fleet-and-devices/fleet-and-devices.store";
import SettingsDropdownMenu from "./settings-dropdown-menu";
import HelpDropdownMenu from "./help-dropdown-menu";
import { joinWalkthroughNameAndStep } from "../utils/helper.util";
import useWalkthroughStore from "@store/walkthrough.store";
import ProjectsDropdownMenu from "./common-header/projects-dropdown-menu.component";
import OrgDropdownMenu from "./common-header/org-dropdown-menu.component";
import useOTAReleaseStore from "@store/ota/ota-release.store";
import ezpedalLogo from "@assets/images/ezpedal-logo.png";
import {
  InformationCircleIcon,
  UserPlusIcon
} from "@heroicons/react/24/outline";
import DashboardSelector from "./dashboard-selector";

const WALKTHROUGH_NAME = "golain-overview";
const WALKTHROUGH_ITEM_NAME = "collaborators";

function CommonHeader() {
  const navigate = useNavigate();
  const location = useLocation();

  const [sidebarOpen, toggleSidebar] = useSidebarStore((state) => [
    state.sidebarOpen,
    state.toggleSidebar
  ]);

  const [setActiveWalkthrough, setActiveWalkthroughItem] = useWalkthroughStore(
    (state) => [state.setActiveWalkthrough, state.setActiveWalkthroughItem]
  );

  const [
    setActiveDashboard,
    setDashboards,
    setPanels,
    setCarousels,
    setLayouts
  ] = useDashboardStore((state) => [
    state.setActiveDashboard,
    state.setDashboards,
    state.setPanels,
    state.setCarousels,
    state.setLayouts
  ]);

  const [setReleaseList, setActiveRelease, setDeploymentList] =
    useOTAReleaseStore((state) => [
      state.setReleaseList,
      state.setActiveRelease,
      state.setDeploymentList
    ]);

  const [{ selectedOrg, organization }, updateAuthUser] = useAuthStore(
    (state) => [state.user, state.updateAuthUser]
  );

  const [
    projects,
    selectedProject,
    setSelectedProject,
    setFleets,
    setSelectedFleet
  ] = useFleetAndDevicesStore((state) => [
    state.projects,
    state.selectedProject,
    state.setSelectedProject,
    state.setFleets,
    state.setSelectedFleet
  ]);

  // const handleSearch = (event: any) => {
  //     console.log(event.target.value);
  // }

  const handleProjectSelection = (projectId) => {
    if (projectId === selectedProject.id) {
      return;
    }

    const selectedProjectId = projectId;

    if (selectedProjectId === "Create Project") {
      return navigate("fleet-and-devices/projects/project-details");
    }

    const project = projects.find(
      (project) => project.id === selectedProjectId
    );

    setSelectedProject(project);

    handleReRoutesOnProjectChange();
  };

  const handleReRoutesOnProjectChange = () => {
    // reset states:
    // DASHBOARDS:
    setActiveDashboard({} as any);
    setDashboards([]);
    setCarousels({});
    setPanels([]);
    setLayouts({});

    // FLEETS and DEVICES:
    setSelectedFleet({} as IFleet);
    setFleets([]);

    // OTA DEPLOYMENTS and RELEASES:
    setReleaseList([]);
    setActiveRelease({} as any);
    setDeploymentList([]);

    if (location.pathname.includes("/dashboard/")) {
      return navigate("/dashboard", { replace: true });
    }

    if (location.pathname.includes("/definitions/shadows")) {
      return navigate("/definitions/shadows", { replace: true });
    }

    if (location.pathname.includes("/definitions/data-points")) {
      return navigate("/definitions/data-points", { replace: true });
    }

    const fadProjectSubPaths = [
      "project-details",
      "about-fleet",
      "provisioning-policy",
      "shadow-policy",
      "create-device",
      "about-device",
      "apply-shadow",
      "apply-data-points",
      "download-certificates",
      "device-creation-successful",
      "device-details"
    ];

    if (
      fadProjectSubPaths.some((path) =>
        location.pathname.startsWith("/fleet-and-devices/projects/" + path)
      )
    ) {
      return navigate("/fleet-and-devices/projects");
    }

    if (
      location.pathname.includes(
        "/ota-updates/projects/deployment/deployment-details"
      ) ||
      location.pathname.includes(
        "/ota-updates/projects/deployment/create-deployment"
      )
    ) {
      return navigate("/ota-updates/projects/deployment");
    }
  };

  const handleOrgSelection = async (orgId: string) => {
    const selectedOrgId = orgId;

    if (selectedOrgId === "Create Org") {
      return navigate("/onboarding/organization-details");
    }

    if (selectedOrgId === selectedOrg.id) {
      return;
    }

    updateAuthUser({
      selectedOrg: organization.find((org) => org.id === selectedOrgId)
    });

    handleReRoutesOnProjectChange();
  };

  return (
    <>
      <header className="bg-background z-50 fixed w-full top-0 left-0 text-contentColor border-b text-xs border-b-background-layer2">
        <nav className="flex items-center justify-between h-16 px-4 lg:px-8 sm:px-6">
          <button
            onClick={() => toggleSidebar(!sidebarOpen)}
            className="text-contentColor pr-4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
          {/* <img src={ezpedalLogo} alt="clientLogo" className="max-w-[2rem]" /> */}
          <div className="flex w-8/12 items-center gap-2">
            <OrgDropdownMenu
              organizations={organization}
              selectedOrg={selectedOrg}
              handleOrgSelection={handleOrgSelection}
            />

            <span
              className="bg-gray-300 text-xl h-4"
              style={{ width: 1, transform: "rotate(20deg)" }}
            ></span>

            <ProjectsDropdownMenu
              projects={projects}
              selectedProject={selectedProject}
              handleProjectSelection={handleProjectSelection}
            />
            {location.pathname.includes("/dashboard/") ? (
              <>
                <span
                  className="bg-background-layer3 text-xl h-4"
                  style={{ width: 1, transform: "rotate(20deg)" }}
                />
                <span className="text-contentColorLight mx-2 text-sm italic">
                  dashboards
                </span>
                <span
                  className="bg-background-layer3 text-xl h-4"
                  style={{ width: 1, transform: "rotate(20deg)" }}
                />
                <DashboardSelector />
              </>
            ) : null}
          </div>

          <div className="flex justify-center items-center">
            <div
              onClick={() => {
                setActiveWalkthrough(WALKTHROUGH_NAME);
                setActiveWalkthroughItem(WALKTHROUGH_ITEM_NAME);
              }}
              className="ml-6 cursor-pointer w-10 h-10 flex justify-center items-center bg-background-layer1 rounded-full"
            >
              <InformationCircleIcon
                className="text-primaryLight"
                width={20}
              />
            </div>

            <Link
              data-tooltip-id={joinWalkthroughNameAndStep(
                WALKTHROUGH_NAME,
                WALKTHROUGH_ITEM_NAME
              )}
              to="/settings/organization-settings/invites"
            >
              <div className="ml-6 w-10 h-10 flex justify-center items-center bg-background-layer1 rounded-full">
                <UserPlusIcon className="text-primaryLight" width={20} />
              </div>
            </Link>
            <SettingsDropdownMenu />

            <HelpDropdownMenu />
          </div>
        </nav>
      </header>
    </>
  );
}

export default CommonHeader;
