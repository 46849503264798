import { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { useOverlayLoaderStore } from "../store";
import axios from "./axios.service";
import * as Setting from "../app/auth/casdoor-setting.helper";
import { loggerService } from "./logger";

interface IConfigOptions {
  noLoader?: boolean;
  blockErrorToast?: boolean;
  isErrorLog?: boolean;
}

class NetworkService {
  private static _instance: NetworkService;

  public static getInstance(): NetworkService {
    if (!NetworkService._instance) {
      NetworkService._instance = new NetworkService();
    }

    return NetworkService._instance;
  }

  get<T>(
    url: string,
    params: any = null,
    config: AxiosRequestConfig & IConfigOptions = {}
  ): Promise<T> {
    const axiosConfig = this._prepareRequest(url, config);
    axiosConfig.method = "GET";
    if (params !== null) {
      axiosConfig.params = params;
    }
    return this._getResponse<T>(axiosConfig);
  }

  post<T>(
    url: string,
    data: any,
    config: AxiosRequestConfig & IConfigOptions = {}
  ): Promise<T> {
    const axiosConfig = this._prepareRequest(url, config);
    axiosConfig.method = "POST";
    axiosConfig.data = data;
    return this._getResponse<T>(axiosConfig);
  }

  put<T>(url: string, data: any, config: AxiosRequestConfig = {}): Promise<T> {
    const axiosConfig = this._prepareRequest(url, config);
    axiosConfig.method = "PUT";
    axiosConfig.data = data;
    return this._getResponse<T>(axiosConfig);
  }

  delete<T>(
    url: string,
    params: any = null,
    config: AxiosRequestConfig & IConfigOptions = {}
  ): Promise<T> {
    const axiosConfig = this._prepareRequest(url, config);
    axiosConfig.method = "DELETE";
    if (params !== null) {
      axiosConfig.params = params;
    }
    return this._getResponse<T>(axiosConfig);
  }

  patch<T>(
    url: string,
    data: any,
    config: AxiosRequestConfig & IConfigOptions = {}
  ): Promise<T> {
    const axiosConfig = this._prepareRequest(url, config);
    axiosConfig.method = "PATCH";
    axiosConfig.data = data;
    return this._getResponse<T>(axiosConfig);
  }

  private _prepareRequest(
    url: string,
    config: AxiosRequestConfig & IConfigOptions
  ): AxiosRequestConfig {
    if ("noLoader" in config && config.noLoader) {
      useOverlayLoaderStore.getState().setLoader(false);
    } else {
      // useOverlayLoaderStore.getState().setLoader(true);
    }
    const axiosConfig: AxiosRequestConfig = Object.assign(
      {
        url: url
      } as Partial<AxiosRequestConfig>,
      config
    );

    return axiosConfig;
  }

  private async _getResponse<T>(
    config: AxiosRequestConfig & IConfigOptions
  ): Promise<T> {
    try {
      const response = await axios(config);
      useOverlayLoaderStore.getState().setLoader(false);
      return response.data;
    } catch (error) {
      if ("blockErrorToast" in config && config.blockErrorToast) {
        // do nothing
      } else {
        toast.error(error.message, { pauseOnFocusLoss: false });
        if (error.message === "Invalid or expired token/key!") {
          Setting.goToLink(Setting.CasdoorSDK.getSigninUrl());
        }
      }
      useOverlayLoaderStore.getState().setLoader(false);
      if (!config.isErrorLog) {
        loggerService.error(error);
      }
      throw error;
    }
  }
}

const networkService = NetworkService.getInstance();
export default networkService;
