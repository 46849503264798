import {
  IContext,
  IInput,
  extractVariableFromContext,
  validateContextVariable
} from "@app/rule-engine/rule-engine.helper";
import { VariableInput } from "@app/rule-engine/variable-input.component";
import TransitionedMenu from "@app/shared/components/transitioned-men.component";
import { reactSelectClassNames } from "@app/shared/utils/helper.util";
import { Menu } from "@headlessui/react";
import {
  ChevronDownIcon,
  InformationCircleIcon,
  KeyIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import { IOption } from "@interfaces/shared.interface";
import { useEffect, useMemo } from "react";
import ReactSelect from "react-select";
import { Tooltip } from "react-tooltip";

interface IFetchedContextItem {
  context: IContext;
  ind: number;
  setFetchedContexts: any;
  allContexts: IContext[];
  inputs: IInput[];
  contextFieldRequiredMap: Record<string, string[]>;
  shadowDefs?: any;
}

const FetchedContextItem: React.FC<IFetchedContextItem> = ({
  context,
  ind,
  setFetchedContexts,
  contextFieldRequiredMap,
  allContexts,
  inputs,
  shadowDefs
}) => {
  const hasInvalidVarsError = useMemo(() => {
    const prevContexts: IContext[] = allContexts?.slice(0, ind) || [];

    const variable = extractVariableFromContext(context);

    if (variable.value) {
      if (
        prevContexts.some((c) =>
          validateContextVariable(c, shadowDefs, variable.value)
        ) ||
        inputs.some((input) => input.key === variable.value)
      ) {
        return false;
      } else {
        return true;
      }
    }

    let fieldValue = context[variable.kind];

    if (!fieldValue) {
      return false;
    }

    return false;
  }, [
    allContexts,
    context.device_id,
    context.fleet_id,
    context.user_id,
    ind,
    inputs
  ]);

  const hasEmptyFieldError = useMemo(() => {
    const requiredFields = contextFieldRequiredMap[context.type];

    if (requiredFields.length) {
      for (let i = 0; i < requiredFields.length; ++i) {
        if (
          requiredFields[i] !== "key" &&
          !context[requiredFields[i]].trim()
        ) {
          return true;
        }
      }
    }

    return false;
  }, [
    context.device_id,
    context.fleet_id,
    context.user_id,
    context.type,
    context.key,
    contextFieldRequiredMap
  ]);

  const hasDuplicateKeyError = useMemo(() => {
    if (
      allContexts?.some(
        (el, _ind) => _ind !== ind && el.key === context.key
      ) ||
      inputs.some((input) => input.key === context.key)
    ) {
      return true;
    }

    return false;
  }, [allContexts, context.key, ind, inputs]);

  const selectedShadowDef = useMemo(
    () => shadowDefs?.find((s) => s.id === context.shadow_definition_id),
    [context.shadow_definition_id, shadowDefs]
  );

  useEffect(() => {
    if (
      hasDuplicateKeyError ||
      hasEmptyFieldError ||
      hasInvalidVarsError ||
      !selectedShadowDef ||
      !context.key.length
    ) {
      setFetchedContexts((prev) => {
        const _new = [...prev];
        const _newContext = {
          ...prev[ind],
          error: true
        };
        _new[ind] = _newContext;

        return _new;
      });
    } else {
      setFetchedContexts((prev) => {
        const _new = [...prev];
        const _newContext = {
          ...prev[ind],
          error: false
        };
        _new[ind] = _newContext;

        return _new;
      });
    }
  }, [
    hasDuplicateKeyError,
    hasEmptyFieldError,
    hasInvalidVarsError,
    ind,
    setFetchedContexts,
    context.key
  ]);

  return (
    <>
      <div
        key={ind}
        className="flex flex-col nodrag bg-background-layer1 gap-2 p-2 rounded-sm items-center text-xs border border-background-layer3"
      >
        <div className="flex justify-between w-full">
          <div className="gap-2 flex items-center">
            <TransitionedMenu
              buttonComponent={
                <div className="flex gap-2 bg-background-layer3 rounded-sm px-2 py-1 items-center">
                  <span className="uppercase">{context.type}</span>
                  <ChevronDownIcon width={16} />
                </div>
              }
            >
              {Object.keys(contextFieldRequiredMap)
                .filter(
                  (key) =>
                    ![
                      "device-data",
                      "device-health",
                      "fleet-shadow",
                      "fleet-data"
                    ].includes(key)
                )
                .map((type) => (
                  <Menu.Item key={type}>
                    {({ active }) => (
                      <button
                        className={`w-full flex gap-1 items-center uppercase ${
                          active && "bg-background-layer2"
                        } min-w-[6rem] text-left whitespace-nowrap px-4 py-2 text-sm text-contentColor hover:bg-background-layer3 transition-all duration-200`}
                        onClick={() => {
                          setFetchedContexts((prev) => {
                            const newInputs = [...prev];
                            const newInput = {
                              ...newInputs[ind],
                              type,
                              device_id: "",
                              user_id: "",
                              fleet_id: ""
                            };
                            newInputs[ind] = newInput;
                            return newInputs;
                          });
                        }}
                      >
                        {type}
                      </button>
                    )}
                  </Menu.Item>
                ))}
            </TransitionedMenu>
            {hasInvalidVarsError ||
            hasEmptyFieldError ||
            hasDuplicateKeyError ? (
              <span data-tooltip-id={"fetched-context-error-tooltip" + ind}>
                <InformationCircleIcon width={16} color="red" />
              </span>
            ) : null}
          </div>
          <div className="flex gap-2">
            <XMarkIcon
              width={16}
              className="text-red-500 min-w-[16px] cursor-pointer"
              onClick={() => {
                setFetchedContexts((prev) => {
                  const newInputs = [...prev];
                  newInputs.splice(ind, 1);
                  return newInputs;
                });
              }}
            />
          </div>
        </div>
        <>
          <div className="flex relative gap-2 w-full">
            <KeyIcon width={16} />
            <input
              placeholder="Key"
              className="nodrag bg-background w-full px-2 py-1 text-contentColor border-background-layer3 rounded-sm border-2"
              onChange={(e) => {
                setFetchedContexts((prev) => {
                  const newInputs = [...prev];
                  const newInput = {
                    ...newInputs[ind],
                    key: e.target.value.trim()
                  };
                  newInputs[ind] = newInput;
                  return newInputs;
                });
              }}
              value={context.key}
            />
          </div>
          {hasDuplicateKeyError ? (
            <span className="text-red-500 text-left w-full">
              Contexts and Inputs must have unique keys.
            </span>
          ) : !context.key.length ? (
            <span className="text-red-500 text-left w-full">
              Key cannot be empty.
            </span>
          ) : null}
        </>
        {context.type === "device-shadow" ? (
          <div className="flex  flex-col w-full">
            <label className="w-full mb-0">Shadow Definition:</label>
            <ReactSelect
              options={
                shadowDefs?.map((s) => ({
                  label: s.name,
                  value: s.id
                })) || []
              }
              value={
                selectedShadowDef
                  ? {
                      label: selectedShadowDef.name,
                      value: selectedShadowDef.id
                    }
                  : undefined
              }
              onChange={(e: IOption) => {
                setFetchedContexts((prev) => {
                  const newInputs: IContext[] = [...prev];
                  const newInput: IContext = {
                    ...newInputs[ind],
                    shadow_definition_id: e.value
                  };
                  newInputs[ind] = newInput;
                  return newInputs;
                });
              }}
              classNames={reactSelectClassNames}
              className="w-full my-0 z-50"
            />
            {!selectedShadowDef ? (
              <span className="text-red-500 text-left w-full">
                Select a shadow definition!
              </span>
            ) : null}
          </div>
        ) : null}
        {contextFieldRequiredMap[context.type].includes("device_id") ? (
          <div className="flex  flex-col w-full">
            <label className="flex gap-2 w-full" htmlFor="device_id">
              Device ID (UUID):
            </label>
            <VariableInput
              value={context.device_id || ""}
              onChange={(e) => {
                setFetchedContexts((prev) => {
                  const newInputs = [...prev];
                  const newInput = {
                    ...newInputs[ind],
                    device_id: e.target.value.trim()
                  };
                  newInputs[ind] = newInput;
                  return newInputs;
                });
              }}
            />
            {hasInvalidVarsError ? (
              <span className="text-red-500 text-left w-full">
                Invalid Variabled reference: {context.device_id}
              </span>
            ) : hasEmptyFieldError ? (
              <span className="text-red-500 text-left w-full">
                Device ID cannot be empty!
              </span>
            ) : null}
          </div>
        ) : null}
        {contextFieldRequiredMap[context.type].includes("fleet_id") ? (
          <div className="flex flex-col w-full">
            <label className="flex gap-2 w-full" htmlFor="fleet_id">
              Fleet ID (UUID):
            </label>
            <VariableInput
              value={context.fleet_id || ""}
              onChange={(e) => {
                setFetchedContexts((prev) => {
                  const newInputs = [...prev];
                  const newInput = {
                    ...newInputs[ind],
                    fleet_id: e.target.value.trim()
                  };
                  newInputs[ind] = newInput;
                  return newInputs;
                });
              }}
            />
            {hasInvalidVarsError ? (
              <span className="text-red-500 text-left w-full">
                Invalid Variabled reference: {context.fleet_id}
              </span>
            ) : hasEmptyFieldError ? (
              <span className="text-red-500 text-left w-full">
                Fleet ID cannot be empty!
              </span>
            ) : null}
          </div>
        ) : null}
        {context.type === "user-metadata" ||
        contextFieldRequiredMap[context.type].includes("user_id") ? (
          <div className="flex flex-col w-full">
            <label className="flex gap-2 w-full" htmlFor="user_id">
              User ID (UUID):
            </label>
            <VariableInput
              value={context.user_id || ""}
              onChange={(e) => {
                setFetchedContexts((prev) => {
                  const newInputs = [...prev];
                  const newInput = {
                    ...newInputs[ind],
                    user_id: e.target.value.trim()
                  };
                  newInputs[ind] = newInput;
                  return newInputs;
                });
              }}
            />
            {hasInvalidVarsError ? (
              <span className="text-red-500 text-left w-full">
                Invalid Variabled reference: {context.user_id}
              </span>
            ) : null}
          </div>
        ) : null}
      </div>
      <Tooltip
        id={"fetched-context-error-tooltip" + ind}
        place="bottom"
        className="bg-background-layer2"
      >
        {hasInvalidVarsError
          ? `The variable '${
              context.device_id || context.fleet_id || context.user_id
            }' referenced in this context are not defined!`
          : hasEmptyFieldError
          ? `Some required fields are empty!`
          : hasDuplicateKeyError
          ? `Each context must have a unique key!`
          : null}
      </Tooltip>
    </>
  );
};

export default FetchedContextItem;
