import { ExclamationCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { usePanelError } from "../../../shared/utils/dash-panel-error.context";
import {
  useAuthStore,
  useDashboardStore,
  useFleetAndDevicesStore
} from "@store/index";
import networkService from "@services/network.service";
import { toast } from "react-toastify";
import { useConfirmDelete } from "@app/shared/hooks/use-confirm-delete.hooks";

interface HeaderProps {
  panel?: any;
  disableZoom?: boolean;
}
const GraphHeader: React.FC<HeaderProps> = ({
  panel,
  disableZoom = false
}) => {
  const { panelError } = usePanelError();

  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);

  const [user] = useAuthStore((state) => [state.user]);

  const [panels, activeDashboard, setPanels] = useDashboardStore((state) => [
    state.panels,
    state.activeDashboard,
    state.setPanels
  ]);

  const { openConfirmDeleteModal } = useConfirmDelete();

  const deletePanel = async () => {
    const res = await networkService.delete<any>(
      `projects/${selectedProject.id}/dashboards/${activeDashboard.id}/panels/${panel.id}`,
      {},
      {
        headers: {
          "ORG-ID": user.selectedOrg.id
        }
      }
    );

    if (res.ok) {
      setPanels(panels.filter((p) => p.id !== panel.id));
      toast.success("Panel deleted successfully");
    }
  };

  const onDeleteClick = () => {
    openConfirmDeleteModal(
      deletePanel,
      "Are you sure you want to this delete panel?"
    );
  };

  return (
    <div className="flex flex-row justify-between items-center px-4 py-2">
      <span className="flex gap-2 items-center">
        <h5 className="text-sm ">{panel.title}</h5>
        {panelError && (
          <a
            href="/"
            onClick={(e) => e.preventDefault()}
            data-tooltip-id="panel-error-tooltip"
            data-tooltip-content={panelError}
          >
            <ExclamationCircleIcon width={20} color="red" />
          </a>
        )}
      </span>
      <button onClick={onDeleteClick} className="z-10">
        <TrashIcon className="h-4 w-4 text-red-400 hover:text-red-500" />
      </button>
    </div>
  );
};

export default GraphHeader;
