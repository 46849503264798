import { DonutChart } from "@tremor/react";
import { IPieGraph } from "@interfaces/index";
/* 
    Component: Pie Graph [ Numeric Graph ]
    Props:  {
        data: data distibution [ Array of Objects ]
        title : Title of graph [ panel name ]
        colors: [ array f string ] same length of data 
        category : name of key from that represents value of data distribution [ string ]
        datakey :  name of key from data that represents label/header of data distribution  [ string ]
    }
    Author: Aaditya Kashid
*/

const BGS = {
  slate: "bg-slate-500",
  gray: "bg-gray-500",
  zinc: "bg-zinc-500",
  neutral: "bg-neutral-500",
  stone: "bg-stone-500",
  red: "bg-red-500",
  orange: "bg-orange-500",
  amber: "bg-amber-500",
  yellow: "bg-yellow-500",
  lime: "bg-lime-500",
  green: "bg-green-500",
  emerald: "bg-emerald-500",
  teal: "bg-teal-500",
  cyan: "bg-cyan-500",
  sky: "bg-sky-500",
  blue: "bg-blue-500",
  indigo: "bg-indigo-500",
  violet: "bg-violet-500",
  purple: "bg-purple-500",
  fuchsia: "bg-fuchsia-500",
  pink: "bg-pink-500",
  rose: "bg-rose-500"
};

const PieGraph: React.FC<IPieGraph> = ({
  data,
  title,
  category,
  colors,
  datakey,
  variant
}) => {
  colors = colors.flat();

  let total = 0;
  data.forEach((d) => {
    total += parseFloat(d[category]);
  });

  const percent = (val: number, total: number) => {
    const p = ((val / total) * 100).toFixed(1);
    return Number(p) > 9 ? p : "0" + p;
  };

  return (
    <>
      <div
        onMouseDown={(e) => {
          // to prevent react-grid-layout from dragging
          e.preventDefault();
          e.stopPropagation();
        }}
        className=" flex flex-row h-full justify-between items-center px-3 pb-6"
      >
        <div className="w-6/12">
          <DonutChart
            data={data}
            index={datakey}
            category={category}
            colors={colors}
            className="mt-4"
            variant={variant}
          />
        </div>
        <div className="w-6/12 px-4">
          <h4 className="text-lg font-bold capitalize my-2">{datakey}</h4>
          <ul>
            {data.map((d, i) => (
              <li className="flex items-center justify-start" key={i}>
                <div
                  className={`block w-3 h-3 bg ${BGS[colors[i]]} rounded-sm`}
                ></div>
                <span className="text-sm text-gray-700 ml-2">
                  {percent(d[category], total)}%
                </span>
                <span className="text-xs text-gray-400 ml-2">
                  {d[datakey]}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default PieGraph;
