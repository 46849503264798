import data from "./data.json";
const fData = [];

data.data.forEach((d) => {
  const color =
    d.speed_category === "high"
      ? "#ff3b3b"
      : d.speed_category === "medium"
      ? "#ffef3b"
      : "#35d404";
  fData.push({
    type: "Feature",
    properties: { color, speed: d.avg_speed },
    geometry: JSON.parse(d.segment)
  });
});

const greenData = {
  type: "Feature",
  properties: { color: "#35d404" },
  geometry: {
    type: "LineString",
    coordinates: [
      [73.79993439, 18.5635891],
      [73.79988861, 18.56361961],
      [73.79988861, 18.56360435],
      [73.79985809, 18.56357956],
      [73.79969025, 18.56344223],
      [73.79972076, 18.56348801],
      [73.79962921, 18.56342888],
      [73.79955292, 18.56338882],
      [73.79969788, 18.56348038],
      [73.84008026, 18.64295769],
      [73.84022522, 18.64325714],
      [73.84027863, 18.64326096],
      [73.84030151, 18.6431694],
      [73.8403244, 18.64307213],
      [73.84031677, 18.64309311],
      [73.84031677, 18.6430645],
      [73.84030914, 18.64304924],
      [73.84076691, 18.64288139],
      [73.84083557, 18.64287376],
      [73.84090424, 18.64287567],
      [73.84095001, 18.64293098],
      [73.84095764, 18.64302444],
      [73.84117126, 18.64366531],
      [73.84127808, 18.64381981],
      [73.84519958, 18.64263916],
      [73.84528351, 18.64261818],
      [73.84412384, 18.63598251],
      [73.84412384, 18.63586807],
      [73.84423828, 18.63581467],
      [73.84659576, 18.63552094],
      [73.84658051, 18.63545418],
      [73.84657288, 18.63536644],
      [73.84658051, 18.63528824],
      [73.84657288, 18.63525963],
      [73.84656525, 18.63522911],
      [73.8465271, 18.63520432],
      [73.84653473, 18.63519096],
      [73.84651947, 18.63521194],
      [73.84647369, 18.63525391],
      [73.84651184, 18.63523483],
      [73.84655762, 18.63521767],
      [73.84655762, 18.63523293],
      [73.84655762, 18.63524437],
      [73.84654236, 18.63525772],
      [73.84654999, 18.63529015],
      [73.8465271, 18.63529778],
      [73.84648895, 18.63527679],
      [73.84650421, 18.63525391],
      [73.84650421, 18.63522148],
      [73.84648895, 18.6352272],
      [73.8465271, 18.63525009],
      [73.84651947, 18.6350708],
      [73.84651947, 18.63513184],
      [73.84654236, 18.6350441],
      [73.84656525, 18.63506508],
      [73.846138, 18.63502884],
      [73.84620667, 18.63513947],
      [73.84631348, 18.63513756],
      [73.84641266, 18.63512802],
      [73.84648895, 18.63514519],
      [73.84648895, 18.63516045],
      [73.84651184, 18.63521767],
      [73.84648895, 18.63524437],
      [73.84648132, 18.63533783],
      [73.84647369, 18.63537598],
      [73.84638214, 18.6353569],
      [73.84640503, 18.6354084],
      [73.84645081, 18.63536263],
      [73.84648132, 18.63529587],
      [73.84653473, 18.63528061],
      [73.84648132, 18.63528442]
    ]
  }
};

const yellowData = {
  type: "Feature",
  properties: { color: "#ffef3b" },
  geometry: {
    type: "LineString",
    coordinates: [
      [73.8404007, 18.64301682],
      [73.84059143, 18.64294624],
      [73.84099579, 18.64319229],
      [73.84111023, 18.64351273],
      [73.84120941, 18.64373016],
      [73.84133911, 18.6438694],
      [73.84508514, 18.64266777],
      [73.84533691, 18.6425724],
      [73.84519196, 18.64126778],
      [73.84457397, 18.63779259],
      [73.84455872, 18.63763428],
      [73.84449005, 18.63743973],
      [73.8441925, 18.63629723],
      [73.84412384, 18.63609886],
      [73.84534454, 18.63576698],
      [73.84580994, 18.63573456],
      [73.84653473, 18.63570404],
      [73.84661102, 18.6356163]
    ]
  }
};

const redData = {
  type: "Feature",
  properties: { color: "#ff3b3b" },
  geometry: {
    type: "LineString",
    coordinates: [
      [73.84159088, 18.64382744],
      [73.84202576, 18.64367294],
      [73.84243011, 18.64354324],
      [73.84286499, 18.64339066],
      [73.84328461, 18.64323235],
      [73.84377289, 18.64307404],
      [73.84430695, 18.64292145],
      [73.84480286, 18.64276123],
      [73.84533691, 18.64234543],
      [73.8453064, 18.64200401],
      [73.84526062, 18.64168358],
      [73.84519958, 18.6414299],
      [73.84514618, 18.64103317],
      [73.84508514, 18.64065933],
      [73.84501648, 18.64023018],
      [73.84496307, 18.63986588],
      [73.8449173, 18.63955498],
      [73.84485626, 18.63919258],
      [73.84480286, 18.63884163],
      [73.84472656, 18.63845825],
      [73.84464264, 18.6380825],
      [73.84443665, 18.63716698],
      [73.84434509, 18.63684654],
      [73.84425354, 18.63654327],
      [73.84441376, 18.63579559],
      [73.84470367, 18.63578606],
      [73.84503937, 18.63578224],
      [73.84557343, 18.63577271],
      [73.84603882, 18.63574409],
      [73.84630585, 18.63571739]
    ]
  }
};

const rideData = {
  type: "FeatureCollection",
  features: [greenData, yellowData, redData]
};

export { rideData, fData };
