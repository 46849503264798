import {
  ActionType,
  serializeVariable
} from "@app/rule-engine/rule-engine.helper";

interface IAction {
  name: string;
  description: string;
  action_type: string;
  additional_params: Record<string, any>;
  fetched_contexts: any[];
  definition: any;
}

const serializeActionJSON = (actionData) => {
  if (
    !actionData.inputs &&
    !actionData.actionDefinition &&
    !actionData?.actionType &&
    !actionData?.fetchedContexts
  )
    return actionData;

  const _actionData = { ...actionData };

  const _actionInputs = [...actionData?.inputs];
  const _actionDefinition = { ..._actionData?.actionDefinition };

  if (_actionDefinition["deviceId"]) {
    _actionDefinition.device_id = serializeVariable(
      _actionDefinition["deviceId"]
    );
    delete _actionDefinition["deviceId"];
  }

  if (_actionDefinition["userId"]) {
    _actionDefinition.user_id = serializeVariable(_actionDefinition["userId"]);
    delete _actionDefinition["userId"];
  }

  if (_actionDefinition["userId"]) {
    _actionDefinition.fleet_id = serializeVariable(
      _actionDefinition["userId"]
    );

    delete _actionDefinition["userId"];
  }

  const additional_params = _actionInputs?.reduce((acc, curr) => {
    acc[curr.key] = curr.type;
    return acc;
  }, {});

  const actionJSON: IAction = {
    name: actionData.name,
    description: actionData.name,
    action_type: _actionData?.actionType,
    additional_params: additional_params,
    fetched_contexts: _actionData?.fetchedContexts || [],
    definition: _actionDefinition || {}
  };

  if (_actionData?.actionType === ActionType.HTTP) {
    const newHeaders = _actionDefinition?.headers.reduce((acc, curr) => {
      acc[curr.key] = curr.value;
      return acc;
    }, {});

    actionJSON.definition.headers = newHeaders;

    if (_actionDefinition.method === "get") {
      const paramsString = _actionDefinition.params?.reduce(
        (acc, cur) => acc + cur.key + "=" + cur.value,
        ""
      );
      if (paramsString) {
        actionJSON.definition.url += "?" + paramsString;
      }
    }

    actionJSON.definition.method =
      _actionData?.actionDefinition?.method.toUpperCase();

    delete _actionDefinition["hasErrors"];
    delete _actionDefinition["params"];
  } else if (_actionData?.actionType === ActionType.DEVICE_SHADOW) {
    actionJSON.definition.meta = {
      shadow_definition_id: _actionDefinition.shadow_definition_id
    };

    // delete additional payload
    delete _actionDefinition["shadow_definition_id"];
  }

  return actionJSON;
};

export { serializeActionJSON };
