import { useState, useMemo } from "react";
import { Form, Formik, Field } from "formik";
import useFleetAndDevicesStore from "../../../../store/fleet-and-devices/fleet-and-devices.store";
import { toast } from "react-toastify";
import ShadowEditor from "../../components/fad-shadow-editor.component";
import useShadowStore from "../../../../store/shadow/shadow.store";
import { useGetShadowDefinitions } from "@app/shared/hooks/get/shadow-definitions";
import { IProtoStructObj } from "@interfaces/fad.interface";
import { useGetShadow } from "@app/shared/hooks/get/shadow";
import {
  downloadFile,
  decodeBase64toUTF8,
  shadowFieldsGenerator
} from "@app/shared/utils/helper.util";
import { useUpdateDeviceShadow } from "@app/shared/hooks/post/update-device-shadow";
import { Button } from "@tremor/react";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";

interface IDeviceShadowsTabProps {
  deviceId: string;
}

function DeviceShadowTab(props: IDeviceShadowsTabProps) {
  const { deviceId } = props;
  const [shadowEditorReadOnly, setShadowEditorReadOnly] = useState(false);
  const [selectedFleet] = useFleetAndDevicesStore((state) => [
    state.selectedFleet
  ]);
  const [setMaximizeShadow, setShadow] = useShadowStore((state) => [
    state.setMaximizeShadow,
    state.setShadow
  ]);

  const [inputFieldValues, setInputFieldValues] = useState(null);

  const { data: shadowDefinitions } = useGetShadowDefinitions({
    fields: "shadow_proto_structure,shadow_proto",
    devices: `${deviceId}`
  });

  const { data: shadowValues } = useGetShadow(selectedFleet.id, deviceId);

  const inputFields: IProtoStructObj[][] = useMemo(() => {
    if (shadowDefinitions?.length) {
      return shadowDefinitions.map((obj) => {
        return shadowFieldsGenerator(obj.shadow_proto_structure);
      });
    }

    return [];
  }, [shadowDefinitions]);

  const initialValues = useMemo(() => {
    const obj = {};
    if (inputFields?.length) {
      inputFields.map((field) =>
        field.map(
          (option) =>
            (obj[option.name] =
              option.cardinality === "repeated"
                ? []
                : option.structure === "number"
                ? 0
                : option.structure === "boolean"
                ? false
                : option.options
                ? option.options[0]
                : "")
        )
      );
    }
    setInputFieldValues({ ...obj, ...shadowValues });
    return obj;
  }, [inputFields, shadowValues]);

  const updateShadowMutation = useUpdateDeviceShadow(
    selectedFleet.id,
    deviceId
  );

  const submitCustomShadowProto = async () => {
    const payload = {
      shadow: {}
    };

    Object.keys(inputFieldValues).forEach((key) => {
      if (typeof inputFieldValues[key] === "string") {
        if (inputFieldValues[key].trim().length) {
          payload.shadow[key] = inputFieldValues[key].trim();
        }
      } else {
        payload.shadow[key] = inputFieldValues[key];
      }
    });

    updateShadowMutation.mutate(payload, {
      onSuccess: (ok) => {
        if (ok) toast.success("Shadow Updated!!!");
      }
    });
  };

  const handleMaximizeEditor = (shadow: string) => {
    setShadow(shadow);
    setMaximizeShadow({ state: true, readOnly: true });
  };

  const handleFieldChange = (e: any, id: string, index: number = -1) => {
    const payload = { ...inputFieldValues };

    if (typeof payload[id] === "boolean") {
      payload[id] = e.target.checked;
      setInputFieldValues({ ...payload });
    } else if (Array.isArray(payload[id])) {
      payload[id][index] = e.target.value ?? e.target.checked;
      setInputFieldValues({ ...payload });
    } else {
      payload[id] = e.target.value;
      setInputFieldValues({ ...payload });
    }
  };

  return (
    <div className="flex w-full h-full">
      <div className="w-5/12 pb-8">
        <div className="w-10/12">
          <h1 className="text-lg text-left font-medium mb-2.5">
            Current Shadow
          </h1>
          <Formik
            initialValues={initialValues}
            onSubmit={submitCustomShadowProto}
          >
            <Form>
              <div className="max-h-[60vh] overflow-y-auto">
                {inputFields &&
                  inputFields[0]?.map((field, index) => (
                    <div key={index} className="form-group mb-5 ml-2 w-10/12">
                      <label className="flex font-medium text-sm mb-2 capitalize">
                        {field.name}
                      </label>

                      {field.cardinality === "repeated" ? (
                        <>
                          {inputFieldValues?.[field.name]?.map(
                            (val, fieldInd) =>
                              renderInputField(
                                field,
                                val,
                                (e, id) => handleFieldChange(e, id, fieldInd),
                                () => {
                                  const newVals = [
                                    ...inputFieldValues?.[field.name]
                                  ];
                                  newVals.splice(index, 1);
                                  setInputFieldValues((prev) => ({
                                    ...prev,
                                    [field.name]: newVals
                                  }));
                                },
                                fieldInd
                              )
                          )}
                          <Button
                            type="button"
                            className="mt-2 self-end"
                            size="xs"
                            icon={PlusIcon}
                            variant="secondary"
                            onClick={() =>
                              setInputFieldValues((prev) => ({
                                ...prev,
                                [field.name]: [
                                  ...prev[field.name],
                                  field.structure === "string"
                                    ? ""
                                    : field.structure === "boolean"
                                    ? false
                                    : 0
                                ]
                              }))
                            }
                          />
                        </>
                      ) : (
                        renderInputField(
                          field,
                          inputFieldValues?.[field.name],
                          handleFieldChange
                        )
                      )}
                    </div>
                  ))}
              </div>
              <div className="mt-3 ml-2">
                <button
                  type="submit"
                  className="inline-block px-16 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
                >
                  Update
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
      <div className="border-4 border-background-layer3 rounded mx-3"></div>
      <div className="w-7/12">
        {shadowDefinitions?.map((shadowDef) => (
          <div key={shadowDef.id} className="mt-8 h-full">
            {shadowDef?.shadow_proto ? (
              <ShadowEditor
                isShadow={true}
                label={shadowDef.name}
                shadowDef={shadowDef}
                readOnly={shadowEditorReadOnly}
                setShadowEditorReadOnly={setShadowEditorReadOnly}
                customShadow={decodeBase64toUTF8(shadowDef.shadow_proto)}
                handleMaximizeEditor={() =>
                  handleMaximizeEditor(
                    decodeBase64toUTF8(shadowDef.shadow_proto)
                  )
                }
                downloadFile={() =>
                  downloadFile(
                    shadowDef.name,
                    decodeBase64toUTF8(shadowDef.shadow_proto)
                  )
                }
              />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}

export default DeviceShadowTab;

function renderInputField(
  field: IProtoStructObj,
  value: any,
  handleFieldChange: (e: any, id: string) => void,
  onDelete: () => void = null,
  index: number = null
) {
  return (
    <div key={field.name + index} className="flex gap-2">
      {field.structure === "boolean" ? (
        <div>
          <label className="inline-flex relative items-center cursor-pointer">
            <Field
              type="checkbox"
              id={field.name}
              name={field.name}
              className="sr-only peer"
              checked={value}
              onChange={(e) => handleFieldChange(e, field.name)}
            />
            <div className="w-11 h-6 bg-background-layer3  rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#0AB63B]"></div>
            <span className="ml-3 text-sm font-medium">Active</span>
          </label>
        </div>
      ) : field.options ? (
        <Field
          as="select"
          id={field.name}
          name={field.name}
          value={value}
          onChange={(e) => handleFieldChange(e, field.name)}
          className="block w-full border p-3 mt-2 bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
        >
          {field.options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </Field>
      ) : (
        <Field
          type={field.structure === "string" ? "text" : field.structure}
          id={field.name}
          name={field.name}
          value={value}
          onChange={(e) => handleFieldChange(e, field.name)}
          className="block w-full p-3 mt-2 border bg-background border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
        />
      )}
      {onDelete ? (
        <Button
          type="button"
          icon={TrashIcon}
          color="red"
          variant="light"
          onClick={onDelete}
        />
      ) : null}
    </div>
  );
}
