const Device = ({ width = 18, className = "" }) => {
  return (
    <svg
      className={`stroke-contentColor`}
      width={width}
      height={width}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M15 2.25H3C2.17157 2.25 1.5 2.92157 1.5 3.75V11.25C1.5 12.0784 2.17157 12.75 3 12.75H15C15.8284 12.75 16.5 12.0784 16.5 11.25V3.75C16.5 2.92157 15.8284 2.25 15 2.25Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M6 15.75H12" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M9 12.75V15.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default Device;
