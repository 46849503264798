import React, { useState, useMemo, useCallback, useEffect } from "react";
import { IGroupedLogs, IInsights, ILog } from "../../interfaces";
import LogsAndActivitiesHeader from "./components/laa-header.component";

import LogsAndActivitiesFilter from "./components/laa-filter-header.component";
import ShowError from "../shared/components/error.component";
import dateService from "@services/date.service";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowsUpDownIcon,
  Bars3Icon,
  ChevronDownIcon,
  ClipboardDocumentListIcon
} from "@heroicons/react/24/outline";
import { Badge } from "@tremor/react";
import { copyToClipboard } from "../shared/utils/helper.util";
import "./laa-projects.style.css";
import LogsChildComponent, {
  LevelBadgeColorMap
} from "./components/logs-child-grid.component";
import Select from "react-select";

import {
  ExpandedState,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  sortingFns,
  getSortedRowModel,
  ColumnDef,
  flexRender
} from "@tanstack/react-table";
import { Tooltip } from "react-tooltip";
import Filter from "./components/logs-filter.component";
import { useGetLogs } from "@app/shared/hooks/get/logs";

const ERROR_LEVEL_PRIORITY = {
  error: 3,
  warning: 2,
  info: 1,
  debug: 0
};

const PAGE_SIZE_OPTS = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "25", value: 25 }
];

const initialFilteringColumnState = {
  services: false,
  functions: false,
  tags: false,
  trace_id: false,
  startTime: false,
  endTime: false
};

const Projects: React.FC = () => {
  const insightKeys = useMemo(() => ["warning", "error", "info"], []);

  const [filteringColumns, setFilteringColumns] = useState<
    Record<string, boolean>
  >(initialFilteringColumnState);

  const [insights, setInsights] = useState<IInsights>();

  const [payload, setPayload] = useState({
    start: dateService.getCurrentUTCDate().subtract(1, "hours").format(),
    stop: dateService.getCurrentUTCDate().format()
  });

  const { data: logs, error: logsError } = useGetLogs(payload);

  const calculateInsights = useCallback(
    (logs: ILog[]) => {
      const tempInsightsObj: IInsights = {
        warning: 0,
        error: 0,
        success: 0
      };

      logs?.forEach((log) => {
        if (log.level.toLowerCase() === insightKeys[0]) {
          tempInsightsObj["warning"] += 1;
        } else if (log.level.toLowerCase() === insightKeys[1]) {
          tempInsightsObj["error"] += 1;
        } else if (log.level.toLowerCase() === insightKeys[2]) {
          tempInsightsObj["success"] += 1;
        }
      });

      setInsights(tempInsightsObj);
    },
    [insightKeys]
  );

  useEffect(() => {
    calculateInsights(logs);
  }, [calculateInsights, logs]);

  const groupedLogs = useMemo(() => {
    const logsFlat: IGroupedLogs[] = [];
    const traceIdToIndex = {};

    logs?.forEach((log: ILog) => {
      if (!traceIdToIndex.hasOwnProperty(log.trace_id)) {
        traceIdToIndex[log.trace_id] = logsFlat.length;
        logsFlat.push({
          trace_id: log.trace_id,
          services: new Set([log.service]),
          startTime: new Date(log.time),
          endTime: new Date(log.time),
          functions: new Set([log.function]),
          highest_level: log.level,
          tags: new Set([log.tag]),
          logs: [log]
        });
      } else {
        const index = traceIdToIndex[log.trace_id];
        logsFlat[index].services.add(log.service);
        logsFlat[index].functions.add(log.function);
        if (
          ERROR_LEVEL_PRIORITY[logsFlat[index].highest_level] <
          ERROR_LEVEL_PRIORITY[log.level]
        ) {
          logsFlat[index].highest_level = log.level;
        }
        logsFlat[index].tags.add(log.tag);
        logsFlat[index].logs.push(log);
        if (logsFlat[index].startTime > new Date(log.time)) {
          logsFlat[index].startTime = new Date(log.time);
        }
        if (logsFlat[index].endTime < new Date(log.time)) {
          logsFlat[index].endTime = new Date(log.time);
        }
      }
    });

    return logsFlat;
  }, [logs]);

  const columns = useMemo<ColumnDef<IGroupedLogs, any>[]>(
    () => [
      {
        header: "Trace ID",
        accessorKey: "trace_id",
        size: 250,
        enableSorting: false,
        filterFn: "includesString",
        cell: ({ row, getValue }) => {
          return (
            <div className="flex flex-row items-center gap-2">
              {
                <ChevronDownIcon
                  width={14}
                  className={`cursor-pointer transition-transform min-w-[14px] transform ${
                    row.getIsExpanded() ? "rotate-180" : "rotate-0"
                  }`}
                  onClick={() => row.toggleExpanded()}
                />
              }
              <span>{getValue()}</span>
              <button
                type="button"
                onClick={() => copyToClipboard(getValue())}
                className="block "
              >
                <ClipboardDocumentListIcon width={14} />
              </button>
            </div>
          );
        }
      },
      {
        header: "Services",
        accessorKey: "services",
        size: 70,
        enableSorting: false,
        filterFn: (row, id, filterValue) => {
          return Array.from(row.original.services).some((service: string) =>
            service.toLowerCase().includes(filterValue.toLowerCase())
          );
        },
        cell: (info) => {
          return (
            <div className="flex flex-row items-center gap-2">
              {Array.from(info.getValue()).map((service: string) => (
                <Badge
                  key={service}
                  size="xs"
                  color="indigo"
                  className="!text-xs"
                >
                  {service}
                </Badge>
              ))}
            </div>
          );
        }
      },
      {
        header: "Functions",
        accessorKey: "functions",
        size: 100,
        enableSorting: false,
        filterFn: (row, id, filterValue) => {
          return Array.from(row.original.functions).some((functionName) =>
            functionName.toLowerCase().includes(filterValue.toLowerCase())
          );
        },
        cell: (info) => {
          return (
            <div className="flex flex-row items-center flex-wrap gap-2">
              {Array.from(info.getValue()).map((functionName: string) => (
                <Badge
                  key={functionName}
                  size="xs"
                  color="indigo"
                  className="!text-xs"
                >
                  {functionName}
                </Badge>
              ))}
            </div>
          );
        }
      },
      {
        header: "Tags",
        accessorKey: "tags",
        size: 100,
        maxSize: 100,
        enableSorting: false,
        filterFn: (row, id, filterValue) => {
          return Array.from(row.original.tags).some((tag) =>
            tag.toLowerCase().includes(filterValue.toLowerCase())
          );
        },
        cell: (info) => {
          return (
            <div className="flex flex-row items-center flex-wrap gap-2">
              {Array.from(info.getValue()).map((tag: string) => (
                <Badge size="xs" key={tag} color="indigo" className="!text-xs">
                  {tag}
                </Badge>
              ))}
            </div>
          );
        }
      },
      {
        header: "Level",
        accessorKey: "highest_level",
        size: 100,
        enableSorting: true,
        filterFn: "includesString",
        sortingFn: (row1, row2, colId) => {
          const val1 =
            ERROR_LEVEL_PRIORITY[
              (row1.getValue(colId) as string).toLowerCase()
            ];
          const val2 =
            ERROR_LEVEL_PRIORITY[
              (row2.getValue(colId) as string).toLowerCase()
            ];
          return val1 < val2 ? -1 : val1 > val2 ? 1 : 0;
        },
        cell: (info) => {
          return (
            <Badge
              size="xs"
              color={LevelBadgeColorMap[info.getValue().toLowerCase()]}
              className="!text-xs"
            >
              {info.getValue()}
            </Badge>
          );
        }
      },
      {
        header: "Start Time",
        accessorKey: "startTime",
        filterFn: "includesString",
        size: 100,
        sortingFn: sortingFns.datetime,
        cell: (info) => {
          return (
            <div className="flex flex-row items-center gap-2">
              <span>{dateService.convertUTCToLocalDate(info.getValue())}</span>
            </div>
          );
        }
      },
      {
        header: "End Time",
        accessorKey: "endTime",
        filterFn: "includesString",
        size: 100,
        sortingFn: sortingFns.datetime,
        cell: (info) => {
          return (
            <div className="flex flex-row items-center gap-2">
              <span>{dateService.convertUTCToLocalDate(info.getValue())}</span>
            </div>
          );
        }
      }
    ],
    []
  );

  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const table = useReactTable({
    data: groupedLogs,
    columns,
    state: {
      expanded
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  });

  if (logsError) {
    return <ShowError />;
  }

  return (
    <>
      <LogsAndActivitiesHeader insights={insights} />
      <main className="flex-1 px-4 py-8 space-y-4 overflow-y-auto lg:px-8 sm:px-6">
        <LogsAndActivitiesFilter setPayload={setPayload} />
        <div
          className={`w-full ${
            !groupedLogs.length
              ? "h-full flex justify-center items-center"
              : ""
          }`}
        >
          {groupedLogs.length ? (
            <>
              <div className="h-full w-full overflow-x-scroll logs-table">
                <table className="h-full w-full">
                  <thead className="bg-gray-100">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          return (
                            <th
                              key={header.id}
                              colSpan={header.colSpan}
                              style={{
                                width: header.column.getSize()
                              }}
                              className="whitespace-nowrap"
                            >
                              {header.isPlaceholder ? null : (
                                <>
                                  <div
                                    className={`flex gap-2 items-center justify-center`}
                                  >
                                    {flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                                    {{
                                      asc: (
                                        <ArrowUpIcon
                                          width={16}
                                          className="min-w-[16px] cursor-pointer"
                                          onClick={header.column.getToggleSortingHandler()}
                                        />
                                      ),
                                      desc: (
                                        <ArrowDownIcon
                                          width={16}
                                          className="min-w-[16px] cursor-pointer"
                                          onClick={header.column.getToggleSortingHandler()}
                                        />
                                      )
                                    }[header.column.getIsSorted() as string] ||
                                      null}
                                    {header.column.getCanSort() &&
                                    !header.column.getIsSorted() ? (
                                      <ArrowsUpDownIcon
                                        width={16}
                                        className="min-w-[16px] cursor-pointer"
                                        onClick={header.column.getToggleSortingHandler()}
                                      />
                                    ) : null}
                                    {header.column.getCanFilter() ? (
                                      <>
                                        <div
                                          className="cursor-pointer"
                                          data-tooltip-id={
                                            "log-table-filter-" +
                                            header.column.id
                                          }
                                          onClick={() =>
                                            setFilteringColumns((prev) => {
                                              return {
                                                ...prev,
                                                [header.column.id]: true
                                              };
                                            })
                                          }
                                        >
                                          <Bars3Icon
                                            width={16}
                                            className="min-w-[16px]"
                                          />
                                        </div>
                                        <Tooltip
                                          isOpen={true}
                                          id={
                                            "log-table-filter-" +
                                            header.column.id
                                          }
                                          style={{
                                            zIndex: 30,
                                            display: filteringColumns[
                                              header.column.id
                                            ]
                                              ? undefined
                                              : "none"
                                          }}
                                          openOnClick
                                          variant="light"
                                          border={"1px solid black"}
                                          clickable
                                          render={(props) => {
                                            return (
                                              <Filter
                                                column={header.column}
                                                table={table}
                                                filteringColumns={
                                                  filteringColumns
                                                }
                                                setFilteringColumns={
                                                  setFilteringColumns
                                                }
                                              />
                                            );
                                          }}
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row) => {
                      return (
                        <>
                          <tr key={row.id} className="bg-white">
                            {row.getVisibleCells().map((cell) => {
                              return (
                                <td
                                  key={cell.id}
                                  className="mx-2 text-xs"
                                  style={{
                                    width: cell.column.getSize()
                                  }}
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                          {row.getIsExpanded() ? (
                            <tr className="bg-gray-100">
                              <td colSpan={row.getVisibleCells().length}>
                                <div className="w-full flex p-4 justify-center items-center">
                                  <LogsChildComponent
                                    key={
                                      row.original.trace_id +
                                      row.original.endTime
                                    }
                                    logs={row.original.logs}
                                  />
                                </div>
                              </td>
                            </tr>
                          ) : null}
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="flex my-4 pb-4 justify-center items-center gap-2 w-full">
                <button
                  className="border rounded p-1"
                  onClick={() => table.setPageIndex(0)}
                  disabled={!table.getCanPreviousPage()}
                >
                  {"<<"}
                </button>
                <button
                  className="border rounded p-1"
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  {"<"}
                </button>
                <button
                  className="border rounded p-1"
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                >
                  {">"}
                </button>
                <button
                  className="border rounded p-1"
                  onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                  disabled={!table.getCanNextPage()}
                >
                  {">>"}
                </button>
                <span className="flex items-center gap-1">
                  <div>Page</div>
                  <strong>
                    {table.getState().pagination.pageIndex + 1} of{" "}
                    {table.getPageCount()}
                  </strong>
                </span>
                <span className="flex items-center gap-1">
                  | Go to page:
                  <input
                    type="number"
                    value={table.getState().pagination.pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      table.setPageIndex(page);
                    }}
                    className="border py-1 px-3 rounded w-16"
                  />
                </span>
                <Select
                  className="w-20"
                  menuPlacement="top"
                  onChange={(val) => {
                    table.setPageSize(Number(val.value));
                  }}
                  value={PAGE_SIZE_OPTS.find(
                    (opt) => opt.value === table.getState().pagination.pageSize
                  )}
                  options={PAGE_SIZE_OPTS}
                  classNames={{
                    menu: () => "!bg-background-layer1 !text-contentColor",
                    control: () =>
                      "!bg-background !text-contentColor !border-background-layer3 !rounded-md focus:!ring focus:!ring-opacity-40 focus:!ring-primary focus:!border-primaryLight sm:!text-sm",
                    valueContainer: () => "!text-contentColor",
                    singleValue: () => "!text-contentColor",
                    menuList: () => "!text-contentColor",
                    option: () =>
                      "!text-contentColor hover:!bg-background-layer2 !bg-background-layer1 !border-background-layer3",
                    noOptionsMessage: () =>
                      "!text-contentColor !bg-background-layer1",
                    multiValue: () =>
                      "!bg-background-layer3 !text-contentColor",
                    multiValueLabel: () => "!text-contentColor"
                  }}
                />
              </div>
            </>
          ) : (
            <div className="w-full h-full">
              <div className="flex  justify-center items-center h-full">
                <span className="text-base text-contentColorLight">
                  No logs available for the selected time range.
                </span>
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default Projects;
