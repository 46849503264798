import produce from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { IAuthInfo, IServiceProvider } from "../interfaces";

interface IInviteData {
  faliure: any;
  orgId: string;
  orgName: string;
  success: string[];
}

interface AuthState {
  token: string;
  refreshToken: string;
  expiry: string;
  inviteToken: string;
  inviteUserData: IInviteData;
  user: IServiceProvider;
  setAuthToken: (x: {
    token: string;
    refreshToken: string;
    expiry: string;
  }) => void;
  setInviteToken: (x: string) => void;
  setInviteUserData: (x: IInviteData) => void;
  setAuthState: (x?: IAuthInfo) => void;
  setAuthUser: (x: IServiceProvider) => void;
  updateAuthUser: (x: Partial<IServiceProvider>) => void;
}

const useAuthStore = create<AuthState>()(
  persist(
    devtools(
      (set) => ({
        token: "",
        refreshToken: "",
        inviteToken: "",
        expiry: "",
        inviteUserData: {} as IInviteData,
        user: {} as IServiceProvider,
        setAuthToken: (tokens) =>
          set(
            produce((state) => {
              state.token = tokens.token;
              state.refreshToken = tokens.refreshToken;
              state.expiry = tokens.expiry;
            }),
            false,
            "auth/setAuthToken"
          ),
        setInviteToken: (token) =>
          set(
            produce((state) => {
              state.inviteToken = token;
            }),
            false,
            "auth/setInviteToken"
          ),
        setInviteUserData: (data) =>
          set(
            produce((state) => {
              state.inviteUserData = data;
            }),
            false,
            "auth/setInviteUserData"
          ),
        setAuthUser: (user) =>
          set(
            produce((state) => {
              state.user = user;
            }),
            false,
            "auth/setAuthUser"
          ),
        updateAuthUser: (changes) =>
          set(
            produce((state) => {
              state.user = { ...state.user, ...changes };
            }),
            false,
            "auth/updateAuthUser"
          ),
        setAuthState: (data) =>
          set(
            produce((state) => {
              state.token = data?.token || null;
              state.refreshToken = data?.refreshToken || null;
              state.expiry = data?.expiry || null;
              state.user = data?.user || ({} as IServiceProvider);
            }),
            false,
            "auth/setAuthState"
          )
      }),
      { name: "auth", serialize: { options: true } }
    ),
    {
      name: "auth", // unique name
      getStorage: () => localStorage // (optional) by default, 'localStorage' is used
    }
  )
);

export default useAuthStore;
