import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../store";
import { getOrganizations } from "@app/shared/hooks/get/organizations";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const mounted = useRef(false);

  const [setAuthUser, token, user] = useAuthStore((state) => [
    state.updateAuthUser,
    state.token,
    state.user
  ]);

  React.useEffect(() => {
    if (mounted.current) {
      return;
    }

    const updateAuthUser = async () => {
      if (user.selectedOrg?.id) {
        navigate("/home");
        return;
      }

      const data = await getOrganizations();
      if (data && data.length) {
        let userData = {
          ...user,
          selectedOrg: data[0],
          organization: data
        };

        setAuthUser(userData);
        navigate("/home");
      } else {
        setAuthUser({ ...user });
        navigate("/onboarding/organization-details");
      }
    };

    if (token) {
      updateAuthUser();
    }

    return () => {
      mounted.current = true;
    };
  }, []);

  return null;
};

export default Login;
