import React, { useMemo, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { object, string } from "yup";
import Select from "react-select";
import ReactFlagsSelect from "react-flags-select";
import { useNavigate } from "react-router-dom";

import { useAuthStore } from "@store/index";
import useFleetAndDevicesStore from "@store//fleet-and-devices/fleet-and-devices.store";
import { FieldError } from "../../shared/components";
import SuggestionPanel from "../components/fad-suggestion-panel.component";
import { useCreateProject } from "@app/shared/hooks/post/create-project";
import { useGetProjects } from "@app/shared/hooks/get";

const newProjectDetailsSchema = object().shape({
  projectName: string().required("Please enter project name.")
});

function NewProjectDetails() {
  /* Store Variables */
  const navigate = useNavigate();
  const [user] = useAuthStore((state) => [state.user]);
  const [setSelectedProject, setProjects] = useFleetAndDevicesStore(
    (state) => [state.setSelectedProject, state.setProjects]
  );
  const { selectedOrg, organization } = user;

  /* Memoized Variables */
  const orgOptions = useMemo(() => {
    return organization.map((org) => {
      return { value: org.id, label: org.org_name };
    });
  }, [organization]);

  const defaultSelectedOrg = useMemo(() => {
    return { value: selectedOrg.id, label: selectedOrg.org_name };
  }, [selectedOrg]);

  /* State Variables */
  const [locallySelectedOrg, setLocallySelectedOrg] = useState(undefined);
  const [selectedRegion, setSelectedRegion] = useState("IN");
  const [hostingPF, setHostingPF] = useState({ value: "AWS", label: "AWS" });

  const hostingPFOptions = [{ value: "AWS", label: "AWS" }];

  const createProjectMutation = useCreateProject();
  const { refetch: refetchProjects } = useGetProjects();

  const handleSubmit = async (
    values: {
      projectName: string;
    },
    { resetForm }
  ) => {
    const { projectName } = values;

    const payload = {
      project_name: projectName,
      region: selectedRegion,
      hosting_platform: hostingPF.value
    };

    createProjectMutation.mutate(payload, {
      onSuccess: (id) => {
        navigate("/fleet-and-devices/projects");
        refetchProjects().then(({ data: projects }) => {
          const newProj = projects.find((p) => p.id === id);
          setSelectedProject(newProj);
          setProjects(projects);
        });
      }
    });
  };

  return (
    <>
      <div className="flex w-full h-full">
        <div className="w-5/12">
          <Formik
            initialValues={{
              projectName: "",
              selectedOrg: defaultSelectedOrg,
              hostingPF: hostingPFOptions[0]
            }}
            validationSchema={newProjectDetailsSchema}
            onSubmit={handleSubmit}
          >
            <Form className="flex flex-col flex-1 mt-7">
              <div>
                <h1 className="text-lg text-left font-medium mb-2.5">
                  Create a new project
                </h1>

                <div className="flex-1">
                  <div className="mb-5">
                    <label className="text-sm font-medium text-[#B7B9C1]">
                      Selected Org.
                    </label>

                    <Select
                      id="selectedOrg"
                      name="selectedOrg"
                      value={
                        locallySelectedOrg
                          ? locallySelectedOrg
                          : defaultSelectedOrg
                      }
                      styles={{
                        container: (baseStyles, state) => ({
                          ...baseStyles,
                          marginTop: "8px",
                          width: "83.33%"
                        }),
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: "4px",
                          borderRadius: "6px"
                        })
                      }}
                      placeholder="Select"
                      onChange={setLocallySelectedOrg}
                      options={orgOptions}
                      isSearchable={false}
                      classNames={{
                        menu: () => "!bg-background-layer1 !text-contentColor",
                        control: () =>
                          "!bg-background !text-contentColor !border-background-layer3 !rounded-md focus:!ring focus:!ring-opacity-40 focus:!ring-primary focus:!border-primaryLight sm:!text-sm",
                        valueContainer: () => "!text-contentColor",
                        singleValue: () => "!text-contentColor",
                        menuList: () => "!text-contentColor",
                        option: () =>
                          "!text-contentColor hover:!bg-background-layer2 !bg-background-layer1 !border-background-layer3",
                        noOptionsMessage: () =>
                          "!text-contentColor !bg-background-layer1",
                        multiValue: () =>
                          "!bg-background-layer3 !text-contentColor",
                        multiValueLabel: () => "!text-contentColor"
                      }}
                    />
                  </div>

                  <div className="mb-5">
                    <label className="text-sm font-medium text-[#B7B9C1]">
                      Project Name
                    </label>

                    <Field
                      type="text"
                      id="projectName"
                      name="projectName"
                      placeholder="Project Name"
                      className="block w-10/12 p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                    />
                    <ErrorMessage name="projectName">
                      {(msg) => <FieldError message={msg} />}
                    </ErrorMessage>
                  </div>

                  <div className="mb-5">
                    <label className="text-sm font-medium text-[#B7B9C1]">
                      Region
                    </label>

                    <div className="w-10/12">
                      <ReactFlagsSelect
                        id="region"
                        selected={selectedRegion}
                        searchable
                        onSelect={setSelectedRegion}
                      />
                    </div>
                    <p className="flex items-center mt-2 space-x-2 text-sm italic text-[#00AC47]">
                      Select a region close to your users for the best
                      performance
                    </p>
                  </div>

                  <div className="mb-5">
                    <label className="text-sm font-medium text-[#B7B9C1]">
                      Hosting Platform
                    </label>

                    <Select
                      id="hostingPF"
                      name="hostingPF"
                      value={hostingPF}
                      styles={{
                        container: (baseStyles, state) => ({
                          ...baseStyles,
                          marginTop: "8px",
                          width: "83.33%"
                        }),
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: "4px",
                          borderRadius: "6px"
                        })
                      }}
                      placeholder="Select"
                      onChange={setHostingPF}
                      options={hostingPFOptions}
                      isSearchable={false}
                      classNames={{
                        menu: () => "!bg-background-layer1 !text-contentColor",
                        control: () =>
                          "!bg-background !text-contentColor !border-background-layer3 !rounded-md focus:!ring focus:!ring-opacity-40 focus:!ring-primary focus:!border-primaryLight sm:!text-sm",
                        valueContainer: () => "!text-contentColor",
                        singleValue: () => "!text-contentColor",
                        menuList: () => "!text-contentColor",
                        option: () =>
                          "!text-contentColor hover:!bg-background-layer2 !bg-background-layer1 !border-background-layer3",
                        noOptionsMessage: () =>
                          "!text-contentColor !bg-background-layer1",
                        multiValue: () =>
                          "!bg-background-layer3 !text-contentColor",
                        multiValueLabel: () => "!text-contentColor"
                      }}
                    />
                  </div>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-44 inline-block px-12 py-3 mt-6 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
                >
                  Next
                </button>
              </div>
            </Form>
          </Formik>
        </div>

        <div className="w-7/12">
          <SuggestionPanel type="project" />
        </div>
      </div>
    </>
  );
}

export default NewProjectDetails;
