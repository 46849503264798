import { useDebounce } from "@app/dashboard/components/forms/heatmap.helper";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";

import { Column, Table } from "@tanstack/react-table";

interface IFilterProps<T> {
  column: Column<T, any>;
  table: Table<any>;
  filteringColumns: Record<string, boolean>;
  setFilteringColumns: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
}

const Filter: React.FC<IFilterProps<any>> = React.memo(
  ({ column, table, filteringColumns, setFilteringColumns }) => {
    const [filterValue, setFilterValue] = useState("");
    const filteringThisColumn = filteringColumns[column.id];
    const sortedUniqueValues = React.useMemo(
      () =>
        column.id.toLowerCase().includes("time")
          ? []
          : Array.from(column.getFacetedUniqueValues().keys()).sort(),
      [column, filteringThisColumn]
    );

    const debouncedFilterValue = useDebounce(filterValue, 500);

    useEffect(() => {
      column.setFilterValue(debouncedFilterValue);

      return () => {};
    }, [debouncedFilterValue, column]);

    return (
      <>
        <div className="relative ">
          {typeof column.getFilterFn() === "string" && (
            <datalist id={column.id + "list"}>
              {sortedUniqueValues.slice(0, 5000).map((value: any) => (
                <option value={value} key={value} />
              ))}
            </datalist>
          )}
          <input
            type="text"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
            className="w-36 border rounded text-sm p-1"
            list={column.id + "list"}
          />
          <XMarkIcon
            className="absolute -top-1 -right-3 cursor-pointer"
            width={12}
            onClick={() => {
              setFilteringColumns((prev) => {
                return {
                  ...prev,
                  [column.id]: false
                };
              });
            }}
          />
        </div>
      </>
    );
  }
);

export default Filter;
