import { useState, useEffect, useMemo } from "react";
import { Buffer } from "buffer";
import useFleetAndDevicesStore from "@store/fleet-and-devices/fleet-and-devices.store";
import useShadowStore from "@store/shadow/shadow.store";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Editor from "@monaco-editor/react";
import { FieldError } from "../../../shared/components";
import { copyToClipboard, pasteFromClipboard } from "@utils/helper.util";
import Select from "react-select";
import { object, string } from "yup";
import DataPointEditor from "@app/fleet-and-devices/components/fad-data-point-editor.component";
import { useNavigate } from "react-router-dom";
import { useGetDataPointDefinitions } from "@app/shared/hooks/get";
import { useLinkDataPoint } from "@app/shared/hooks/post/link-data-point";
import { useUnlinkDataPoint } from "@app/shared/hooks/delete/unlink-data-point";
import { useGetDeviceDataPolicies } from "@app/shared/hooks/get/device-data-policies";
import { useCreateDataPointDefinition } from "@app/shared/hooks/post/create-data-point-definition";
import { useGetDeviceDataPoints } from "@app/shared/hooks/get/device-data-points";
import useThemeStore from "@store/theme.store";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";

interface IDataPointsTabProps {
  deviceId: string;
}

interface IDatapoint {
  id: string;
  name: string;
  message_name: string;
  data_point_proto: string;
  data_point_proto_descriptor: string;
  project_id: string;
  org_id: string;
  created_at: string;
  data_point_proto_structure: string;
  device_id: string;
  link: string;
}

interface IDataPolicy {
  id: string;
  policy_name: string;
  fleet_id: string;
  project_id: string;
  org_id: string;
  created_at: string;
}

const defaultDatapoint = `/*
*
* The following is an example of a datapoint definition.
* Here we create a root message called battery and define its fields.
* The root message can have nested messages and fields.
* 
* Battery has a sub message called cell, which is defined above it.
* We also have an enum called battery_state, which is used in the battery message.
*
* Uncomment by using Ctrl + / or Cmd + / on Mac
* 
* Note: The root message name, fields, and enums should be unique.
* 
**/

// syntax="proto3";
//
// enum battery_state{
//     UNKNOWN = 0;
//     CHARGING = 1;
//     DISCHARGING = 2;
//     CHARGED = 3;
//     DISCHARGED = 4;
//     OVERCHARGED = 5;
//     OVERDISCHARGED = 6;
//     OVERTEMPERATURE = 7;
//     OVERCURRENT = 8;
//     OVERVOLTAGE = 9;
//     UNDERVOLTAGE = 10;
//     UNDERTEMPERATURE = 11;
//     UNDERCURRENT = 12;
//     FAULT = 13;
// }

// message cell{
//     float voltage = 1;
//     float in_current = 2;
//     float out_current = 3;
//     float temperature = 4;
// }

// message battery{
//     float nominal_voltage = 1;
//     float nominal_capacity = 2;
//     float percentage_capacity = 3;
//     uint32 num_cells = 4;
//     repeated cell cells = 5;
//     float voltage = 6;
//     float in_current = 7;
//     float out_current = 8;
//     battery_state state = 9;
// }
`;

const newDatapointDefinitionSchema = object().shape({
  datapointName: string().required("Please enter data-point name."),
  rootProtoMsgName: string().required("Please enter message name.")
});

function DataPointsTab(props: IDataPointsTabProps) {
  const { deviceId } = props;
  const navigate = useNavigate();

  const [theme] = useThemeStore((state) => [state.theme]);
  const [selectedFleet] = useFleetAndDevicesStore((state) => [
    state.selectedFleet
  ]);
  const [datapoint, setMaximizeDatapoint, setDatapoint] = useShadowStore(
    (state) => [
      state.datapoint,
      state.setMaximizeDatapoint,
      state.setDatapoint
    ]
  );

  const [predefinedDatapoint, setPredefinedDatapoint] = useState([]);
  const [selectedDatapoint, setSelectedDatapoint] = useState<
    { value: string; label: string }[]
  >([]);
  const [datapoints, setDatapoints] = useState<IDatapoint[]>([]);
  const [createDatapoint, setCreateDatapoint] = useState(false);
  const [addNewDataPoint, setAddNewDataPoint] = useState(false);
  const [customDatapoint, setCustomDatapoint] = useState("");
  const [datapolicy, setDataPolicy] = useState<IDataPolicy | undefined>();
  const [errorMsgs, setErrorMsgs] = useState({
    predefinedDatapoint: "",
    customDatapoint: ""
  });

  const { refetch: refetchDataPoints } = useGetDataPointDefinitions(
    {
      devices: deviceId,
      fields: "message_name,data_point_proto"
    },
    (defs) => {
      setDatapoints(defs);
    }
  );

  const { data: deviceDataPoints } = useGetDeviceDataPoints();

  const { refetch: refetchPredefinedDataPoints } = useGetDataPointDefinitions(
    {},
    (defs) => {
      setPredefinedDatapoint(defs);
    }
  );

  const { refetch: refetchPolicies } = useGetDeviceDataPolicies(
    selectedFleet.id,
    {},
    (policies) => {
      setDataPolicy(policies[0]);
    }
  );

  useEffect(() => {
    setCustomDatapoint(defaultDatapoint);
    setDatapoint("");
  }, [setDatapoint]);

  useEffect(() => {
    if (datapoint) {
      setCustomDatapoint(datapoint);
    }
  }, [datapoint]);

  const unlinkDataPoint = async (dpId: string, dpName: string) => {
    const ddp = deviceDataPoints?.find(
      (ddp) =>
        ddp.data_point_definition_id === dpId && ddp.device_id === deviceId
    );

    if (!ddp) {
      toast.error("Could not find Device Data Point ID");
      return;
    }

    unlinkDataPointMutation.mutate(ddp.id, {
      onSuccess: (ok) => {
        if (ok) {
          toast.warning(`data-point ${dpName} unlinked`);
        }
      }
    });
  };

  const handleMaximizeEditor = (datapoint: string) => {
    setDatapoint(datapoint);
    setMaximizeDatapoint({ state: true, readOnly: true });
  };

  const handleDatapointEditor = (value: string) => {
    setCustomDatapoint(value);
  };

  const downloadFile = (filename: string, data: string) => {
    if (filename) {
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "text/plain" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}.proto`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  };

  const downloadFromEditor = () => {
    const filename = document.getElementById("datapointName")["value"];

    if (filename) {
      const url = window.URL.createObjectURL(
        new Blob([customDatapoint], { type: "text/plain" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}.proto`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      toast.warning("To download please enter data-point name.");
    }
  };

  const handleMaximizeDataPointEditor = () => {
    setDatapoint(customDatapoint);
    setMaximizeDatapoint({ state: true, readOnly: false });
  };

  const decodeBase64toUTF8 = (encodedData: string) => {
    return Buffer.from(encodedData, "base64").toString("utf8");
  };

  const useCreateDataPointMutation = useCreateDataPointDefinition();

  const submitCustomDatapoint = async (
    values: {
      datapointName: string;
      rootProtoMsgName: string;
    },
    { resetForm }
  ) => {
    if (!customDatapoint) {
      setErrorMsgs({
        ...errorMsgs,
        customDatapoint: "Please define data-point"
      });
    } else {
      setErrorMsgs({ ...errorMsgs, customDatapoint: "" });
    }

    const file = new File([customDatapoint], "datap.proto", {
      type: "text/plain"
    });

    const formData = new FormData();
    formData.append("protoFile", file);
    formData.append("name", values.datapointName);
    formData.append("message_name", values.rootProtoMsgName);

    useCreateDataPointMutation.mutate(formData, {
      onSuccess: (dataPointDefinitionId) => {
        const payload = {
          data_point_name: values.datapointName,
          device_data_policy_id: datapolicy.id,
          data_point_definition_id: dataPointDefinitionId,
          active: true
        };

        linkDataPointMutation.mutate(payload, {
          onSuccess: () => {
            handleAddNewDataPointFlowState();
          }
        });
      }
    });
  };

  const linkDataPointMutation = useLinkDataPoint(selectedFleet.id, deviceId);
  const unlinkDataPointMutation = useUnlinkDataPoint(
    selectedFleet.id,
    deviceId
  );

  const handleDatapointSelect = async (
    datapoint: { value: string; label: string }[]
  ) => {
    if (errorMsgs.predefinedDatapoint) {
      setErrorMsgs({ ...errorMsgs, predefinedDatapoint: "" });
    }
    if (deviceId) {
      if (selectedDatapoint.length < datapoint.length) {
        const newlyAdded = [...datapoint].pop();

        const payload = {
          data_point_name: newlyAdded.label,
          device_data_policy_id: datapolicy.id,
          data_point_definition_id: newlyAdded.value,
          active: true
        };

        linkDataPointMutation.mutate(payload);
      } else if (selectedDatapoint.length > datapoint.length) {
        const tempDP = selectedDatapoint.filter(
          ({ value: id1 }) => !datapoint.some(({ value: id2 }) => id2 === id1)
        );
        const ddp = deviceDataPoints?.find(
          (ddp) =>
            ddp.data_point_definition_id === tempDP[0].value &&
            ddp.device_id === deviceId
        );

        if (!ddp) {
          toast.error("Could not find Device Data Point ID");
          return;
        }

        unlinkDataPointMutation.mutate(ddp.id);
      }
    }

    setSelectedDatapoint([...datapoint]);
  };

  const handleAddNewDataPointFlowState = async () => {
    setSelectedDatapoint([]);

    setCustomDatapoint(defaultDatapoint);
    setDatapoint("");
    refetchDataPoints();
    refetchPolicies();
    refetchPredefinedDataPoints();

    if (createDatapoint || addNewDataPoint) {
      toast.success("Added new Data Point to the device!");
    }

    if (createDatapoint) {
      setCreateDatapoint(false);
    }

    if (addNewDataPoint) {
      setAddNewDataPoint(false);
    }
  };

  const options = useMemo(() => {
    const results =
      predefinedDatapoint?.filter(
        ({ id: id1 }) => !datapoints?.some(({ id: id2 }) => id2 === id1)
      ) || [];

    return results.map(({ name, id }) => {
      return { value: id, label: name };
    });
  }, [predefinedDatapoint, datapoints]);

  // const tags = ["Dummy Tag A", "Tag Something ", "Blah tag Blah", "Tag D", "Tag E"];

  return (
    <div className="flex w-full lg:h-[88%] md:h-[60vh]">
      {addNewDataPoint ? (
        <Formik
          initialValues={{ datapointName: "", rootProtoMsgName: "" }}
          validationSchema={newDatapointDefinitionSchema}
          onSubmit={submitCustomDatapoint}
        >
          <Form className="w-2/5">
            {createDatapoint ? (
              <>
                <div className="form-group mb-5 w-10/12">
                  <label className="flex font-medium text-sm mb-2">
                    Data-point Name
                  </label>
                  <Field
                    type="text"
                    id="datapointName"
                    name="datapointName"
                    placeholder="Data-point Name"
                    className="block w-full p-3 mt-2 bg-background text-contentColor border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                  />
                  <ErrorMessage name="datapointName">
                    {(msg) => <FieldError message={msg} />}
                  </ErrorMessage>
                </div>

                <div className="form-group mb-5 w-10/12">
                  <label className="flex font-medium text-sm mb-2">
                    Root proto message name
                  </label>
                  <Field
                    type="text"
                    id="rootProtoMsgName"
                    name="rootProtoMsgName"
                    placeholder="Root proto message name"
                    className="block w-full p-3 mt-2 bg-background text-contentColor border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                  />
                  <ErrorMessage name="rootProtoMsgName">
                    {(msg) => <FieldError message={msg} />}
                  </ErrorMessage>
                </div>

                <div className="form-group mb-5 lg:w-10/12">
                  <label className="flex font-medium text-sm mb-2">
                    Data-point Definition
                  </label>
                  <div className="p-2 bg-background-layer1 rounded-lg border border-solid border-background-layer3 w-min lg:w-full">
                    <div>
                      <div className="flex justify-between items-center">
                        <ul className="flex justify-center items-center">
                          <li className="flex items-center">
                            <button
                              type="button"
                              onClick={() => copyToClipboard(customDatapoint)}
                              className="text-xs text-contentColorLight flex justify-center items-center"
                            >
                              Copy
                            </button>
                            <div className="mx-3.5 h-3 border border-solid border-background-layer3"></div>
                          </li>

                          <li className="flex items-center">
                            <button
                              type="button"
                              onClick={() =>
                                pasteFromClipboard().then((res) =>
                                  setCustomDatapoint(res)
                                )
                              }
                              className="text-xs text-contentColorLight flex justify-center items-center"
                            >
                              Paste
                            </button>
                            <div className="mx-3.5 h-3 border border-solid border-background-layer3"></div>
                          </li>

                          <li className="flex items-center">
                            <button
                              type="button"
                              className="text-xs text-contentColorLight flex justify-center items-center"
                            >
                              Upload
                            </button>
                            <div className="mx-3.5 h-3 border border-solid border-background-layer3"></div>
                          </li>

                          <li className="flex items-center mr-3.5">
                            <button
                              type="button"
                              onClick={downloadFromEditor}
                              className="text-xs text-contentColorLight flex justify-center items-center"
                            >
                              Download
                            </button>
                          </li>
                        </ul>

                        <div className="flex justify-center items-center">
                          <button
                            onClick={handleMaximizeDataPointEditor}
                            type="button"
                            className="p-1 bg-background-layer2 rounded"
                          >
                            <ArrowsPointingOutIcon
                              width={16}
                              className="text-primaryLight"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="my-2 border border-solid border-background-layer3"></div>
                    </div>
                    <Editor
                      height="14vh"
                      language="cpp"
                      value={customDatapoint}
                      onChange={handleDatapointEditor}
                      theme={
                        theme === "golain" || theme === "none"
                          ? "vs"
                          : "vs-dark"
                      }
                      options={{
                        lineNumbers: "off",
                        minimap: { enabled: false },
                        scrollbar: { vertical: "hidden" },
                        overviewRulerBorder: false,
                        overviewRulerLanes: 0,
                        folding: false,
                        matchBrackets: "never",
                        theme:
                          theme === "golain" || theme === "none"
                            ? "vs"
                            : "vs-dark"
                      }}
                    />
                  </div>
                  {errorMsgs.customDatapoint ? (
                    <FieldError message={errorMsgs.customDatapoint} />
                  ) : (
                    ""
                  )}
                </div>

                <h1 className="flex mb-5 text-sm text-gray-400">
                  Or Select from &nbsp;
                  <span
                    className="font-medium text-primary cursor-pointer"
                    onClick={() => setCreateDatapoint(!createDatapoint)}
                  >
                    Predefined Data-point
                  </span>
                </h1>

                <div>
                  <button
                    type="submit"
                    className="mr-2 px-5 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
                  >
                    Create And Link
                  </button>

                  <button
                    onClick={handleAddNewDataPointFlowState}
                    className="px-5 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white"
                  >
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="form-group mb-5 w-10/12">
                  <label className="flex font-medium text-sm mb-2">
                    Select Data-point
                  </label>
                  <Select
                    isDisabled={createDatapoint}
                    placeholder="Select"
                    isSearchable={false}
                    options={options}
                    value={selectedDatapoint}
                    onChange={handleDatapointSelect}
                    isClearable={false}
                    isMulti
                    classNames={{
                      menu: () => "!bg-background-layer1 !text-contentColor",
                      control: () =>
                        "!bg-background !text-contentColor !border-background-layer3 !rounded-md focus:!ring focus:!ring-opacity-40 focus:!ring-primary focus:!border-primaryLight sm:!text-sm",
                      valueContainer: () => "!text-contentColor",
                      singleValue: () => "!text-contentColor",
                      menuList: () => "!text-contentColor",
                      option: () =>
                        "!text-contentColor hover:!bg-background-layer2 !bg-background-layer1 !border-background-layer3",
                      noOptionsMessage: () =>
                        "!text-contentColor !bg-background-layer1",
                      multiValue: () =>
                        "!bg-background-layer3 !text-contentColor",
                      multiValueLabel: () => "!text-contentColor"
                    }}
                  />
                  {errorMsgs.predefinedDatapoint ? (
                    <FieldError message={errorMsgs.predefinedDatapoint} />
                  ) : (
                    ""
                  )}
                </div>

                <h1 className="flex mb-5 text-sm text-gray-400">
                  Or &nbsp;
                  <span
                    className="font-medium text-primary cursor-pointer"
                    onClick={() => setCreateDatapoint(!createDatapoint)}
                  >
                    Create New Data-point
                  </span>
                </h1>
                <div>
                  <button
                    disabled={selectedDatapoint.length <= 0}
                    onClick={() => handleAddNewDataPointFlowState()}
                    className="mr-2 px-5 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
                  >
                    Done
                  </button>

                  <button
                    onClick={handleAddNewDataPointFlowState}
                    className="px-5 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white"
                  >
                    Cancel
                  </button>
                </div>
              </>
            )}
          </Form>
        </Formik>
      ) : (
        <>
          <div className="w-5/12 pb-8">
            <h1 className="text-lg text-left font-medium mb-2.5">
              Currently Available
            </h1>
            <div className="w-10/12 ">
              {datapoints?.map((datapoint, index) => (
                <div
                  key={index}
                  className="w-full h-auto border border-primary rounded-lg px-5 py-3.5 mb-4"
                >
                  <h1 className="flex items-center text-base text-left font-medium">
                    <span
                      onClick={() =>
                        unlinkDataPoint(datapoint.id, datapoint.name)
                      }
                      className="mr-2 cursor-pointer"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1701_2674)">
                          <rect width="16" height="16" rx="2" fill="#546CCC" />
                          <path
                            d="M12 5.40625L6.5 10.9062L4 8.40625"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1701_2674">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    {datapoint.name}
                  </h1>
                  {
                    <span className="text-sm text-left text-contentColorLight">
                      {datapoint.message_name}
                    </span>
                  }
                </div>
              ))}
              {!createDatapoint && (
                <button
                  className="flex items-center mb-5"
                  onClick={() => setAddNewDataPoint(true)}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 3.75V14.25"
                      stroke="#546CCC"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.75 9H14.25"
                      stroke="#546CCC"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="ml-1.5 text-[#546CCC] font-medium">
                    Add Data point
                  </span>
                </button>
              )}
            </div>
            {/* <h1 className="text-lg text-left font-medium mb-2.5">Tags</h1>
                <div className="w-10/12 mt-3.5">
                    <div className="flex flex-wrap">
                        {tags.map((tag, index) => (
                            <div key={index} className="px-3 py-2 mb-2.5 mr-2.5 flex items-center bg-white border border-solid rounded text-contentColorLight text-sm">
                                <h1 className="font-bold">{tag}</h1>
                                <button className="ml-2.5">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.5 4.5L4.5 13.5" stroke="#E21B17" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M4.5 4.5L13.5 13.5" stroke="#E21B17" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </button>
                            </div>
                        ))}
                    </div>
                    <button className="flex items-center mb-5">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 3.75V14.25" stroke="#546CCC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M3.75 9H14.25" stroke="#546CCC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <span className="ml-1.5 text-[#546CCC] font-medium">Add Tags</span>
                    </button>
                </div> */}
          </div>
          <div className="border-4 border-background-layer3 rounded mx-3"></div>
          <div className="w-7/12 max-h-[80vh] flex flex-col gap-12 px-2 pb-20 overflow-y-auto">
            {datapoints?.map((datapoint) => (
              <div key={datapoint.id} className="h-48">
                <DataPointEditor
                  isShadow={false}
                  label={datapoint.name}
                  readOnly={false}
                  customShadow={decodeBase64toUTF8(datapoint.data_point_proto)}
                  onEdit={() => {
                    navigate(`/definitions/data-points/${datapoint.id}`);
                  }}
                  handleMaximizeEditor={() =>
                    handleMaximizeEditor(
                      decodeBase64toUTF8(datapoint.data_point_proto)
                    )
                  }
                  downloadFile={() =>
                    downloadFile(
                      datapoint.name,
                      decodeBase64toUTF8(datapoint.data_point_proto)
                    )
                  }
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default DataPointsTab;
