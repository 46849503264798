import { useQuery } from "react-query";

import { IDataPointDefinitionResponse } from "@interfaces/shared.interface";
import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

export const getDataPointDefinitions = async (projectId, orgId, params) => {
  const { data, ok } = await networkService.get<IDataPointDefinitionResponse>(
    `projects/${projectId}/data_point_definitions`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.dataPoints;
  }
};

export const useGetDataPointDefinitions = (
  params: any = {},
  successCb: ((data: any[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);

  const res = useQuery(
    [
      "getDataPointDefinitions",
      selectedProject.id,
      user.selectedOrg.id,
      params
    ],
    async () => {
      const dpd = await getDataPointDefinitions(
        selectedProject.id,
        user.selectedOrg.id,
        params
      );

      successCb && successCb(dpd);

      return dpd;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id
    }
  );

  return res;
};
