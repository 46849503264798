import React from "react";
import Select from "react-select";
import { ISelectOption } from "../../../interfaces";

interface ISelectOptionCustom extends ISelectOption {
  data: any;
}

export const CustomSelectWithData = ({
  className,
  placeholder,
  field,
  form,
  options,
  isMulti = true,
  onChange,
  maxItems
}) => {
  const handleOnChange = (
    option: ISelectOptionCustom | ISelectOptionCustom[]
  ) => {
    form.setFieldValue(
      field.name,
      isMulti
        ? (option as ISelectOptionCustom[]).map(
            (item: ISelectOptionCustom) => item.value
          )
        : (option as ISelectOptionCustom).value
    );
    onChange && onChange(field.name, isMulti ? option : [option]);
  };

  const getValue = () => {
    if (options) {
      return (options as ISelectOptionCustom[]).filter(
        (option: ISelectOptionCustom) =>
          field.value?.indexOf(option.value) >= 0
      );
    } else {
      return [];
    }
  };

  return (
    <Select
      className={className}
      name={field.name}
      value={getValue()}
      onChange={handleOnChange}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      isOptionDisabled={
        maxItems ? () => getValue().length >= maxItems : undefined
      }
      styles={{
        control: (provided) => ({
          ...provided,
          paddingTop: 3,
          paddingBottom: 3
        })
      }}
      classNames={{
        menu: () => "!bg-background-layer1 !text-contentColor",
        control: () =>
          "!bg-background !text-contentColor !border-background-layer3 !rounded-md focus:!ring focus:!ring-opacity-40 focus:!ring-primary focus:!border-primaryLight sm:!text-sm",
        valueContainer: () => "!text-contentColor",
        singleValue: () => "!text-contentColor",
        menuList: () => "!text-contentColor",
        option: () =>
          "!text-contentColor hover:!bg-background-layer2 !bg-background-layer1 !border-background-layer3",
        noOptionsMessage: () => "!text-contentColor !bg-background-layer1",
        multiValue: () => "!bg-background-layer3 !text-contentColor",
        multiValueLabel: () => "!text-contentColor"
      }}
    />
  );
};

export default CustomSelectWithData;
