import { useEffect, useMemo, useState } from "react";
import Editor from "@monaco-editor/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";

import { Field, Form, Formik, ErrorMessage } from "formik";

import { toast } from "react-toastify";
import { IFleet } from "../../../interfaces";
import { useAuthStore } from "../../../store";
import useFleetAndDevicesStore, {
  IFleetCreationFlow
} from "../../../store/fleet-and-devices/fleet-and-devices.store";
import useShadowStore from "../../../store/shadow/shadow.store";
import { FieldError } from "../../shared/components";
import {
  copyToClipboard,
  pasteFromClipboard
} from "../../shared/utils/helper.util";
import SuggestionPanel from "../components/fad-suggestion-panel.component";
import FleetCreationSteps from "../components/fleet-creation-steps.component";
import { object, string } from "yup";
import { useGetShadowDefinitions } from "@app/shared/hooks/get/shadow-definitions";
import { useCreateShadowDefinitions } from "@app/shared/hooks/post/create-shadow-definition";
import { useCreateFleet } from "@app/shared/hooks/post/create-fleet";
import { useCreateDeviceDataPolicy } from "@app/shared/hooks/post/device-data-policy";
import { useGetFleets } from "@app/shared/hooks/get/fleets";
import useThemeStore from "@store/theme.store";

const defaultShadow =
  `/*syntax = "proto3";\n` +
  `message Shadow {\n` +
  `\tint32 red = 1;\n` +
  `\tint32 green = 2;\n` +
  `\tint32 blue = 3;\n` +
  `\tbool on = 4;\n` +
  `}*/`;

const newShadowDefinitionSchema = object().shape({
  shadowName: string().required("Please enter shadow name.")
});

function ShadowPolicy() {
  const navigate = useNavigate();
  const location = useLocation();
  const cameFromCreateShadowDef =
    location.state === "create-shadow-definition";

  const [theme] = useThemeStore((state) => [state.theme]);
  const [updateAuthUser] = useAuthStore((state) => [state.updateAuthUser]);
  const [fleetCreation, setFleetCreation] = useFleetAndDevicesStore(
    (state) => [state.fleetCreation, state.setFleetCreation]
  );
  const [shadow, setMaximizeShadow, setShadow] = useShadowStore((state) => [
    state.shadow,
    state.setMaximizeShadow,
    state.setShadow
  ]);
  const [setFleets, setSelectedFleet] = useFleetAndDevicesStore((state) => [
    state.setFleets,
    state.setSelectedFleet
  ]);

  const [createShadow, setCreateShadow] = useState(cameFromCreateShadowDef);
  // const [predefinedShadows, setPredefinedShadows] = useState([]);
  const [selectedShadow, setSelectedShadow] = useState<
    { value: string; label: string } | undefined
  >();
  const [customShadow, setCustomShadow] = useState(defaultShadow);
  const [recentCreatedShadow, setRecentCreatedShadow] = useState("");
  const [errorMsgs, setErrorMsgs] = useState({
    predefinedShadow: "",
    customShadow: ""
  });

  const { data: predefinedShadows } = useGetShadowDefinitions({
    fields: "shadow_proto_structure,shadow_proto"
  });
  const { refetch: refetchFleets } = useGetFleets();

  const createShadowDefMutation = useCreateShadowDefinitions();
  const createFleetMutation = useCreateFleet();
  const createDeviceDataPolicyMutation = useCreateDeviceDataPolicy();

  useEffect(() => {
    // if (!fleetCreation?.fleet_name?.trim()) {
    //   navigate("/fleet-and-devices/projects/about-fleet/", { replace: true });
    //   return;
    // }
    updateAuthUser({ fleetCreationStatus: "2" });
  }, [updateAuthUser]);

  useEffect(() => {
    if (shadow) {
      setCustomShadow(shadow);
    }
  }, [shadow]);

  const handleClick = async () => {
    if (selectedShadow) {
      createFleetMutation.mutate(
        {
          fleet_name: fleetCreation.fleet_name,
          fleet_description: fleetCreation.fleet_description,
          shadow_definition_id: selectedShadow.value
        },
        {
          onSuccess: (id) => {
            createDeviceDataPolicyMutation.mutate({
              data: {
                policy_name: "default",
                policy:
                  '{"v":1,"def":{"storage-format":"TSDB","retention-period":0}}'
              },
              fleetId: id
            });

            refetchFleets().then(({ data: fleets }) => {
              if (fleets?.length) {
                setFleets(fleets);

                const newlyCreatedFleet = fleets.filter(
                  (fleet) => fleet.id === id
                );
                setSelectedFleet({ ...newlyCreatedFleet[0] });
              } else {
                setFleets([]);
                setSelectedFleet({} as IFleet);
              }

              const createdFleetName = fleetCreation.fleet_name;

              navigate("/fleet-and-devices/projects/create-device/", {
                state: { fleetName: createdFleetName }
              });
              setFleetCreation({} as IFleetCreationFlow);
            });
          }
        }
      );
    } else {
      setErrorMsgs({
        ...errorMsgs,
        predefinedShadow: "Please select a shadow"
      });
    }
  };

  const handleMaximizeEditor = () => {
    setShadow(customShadow);
    setMaximizeShadow({ state: true, readOnly: false });
  };

  const submitCustomShadow = async (
    values: {
      shadowName: string;
    },
    { resetForm }
  ) => {
    if (!customShadow) {
      setErrorMsgs({ ...errorMsgs, customShadow: "Please define shadow" });
    } else {
      setErrorMsgs({ ...errorMsgs, customShadow: "" });
    }

    const file = new File([customShadow], "datap.proto", {
      type: "text/plain"
    });

    const formData = new FormData();
    formData.append("protoFile", file);
    formData.append("name", values.shadowName);

    createShadowDefMutation.mutate(formData, {
      onSuccess: (id) => {
        setRecentCreatedShadow(id);
        setCreateShadow(false);
        if (cameFromCreateShadowDef) {
          navigate("/definitions/shadows");
          toast.success("Created new Shadow Definition Successfully!");
        }
      }
    });
  };

  const handleShadowEditor = (value: string) => {
    setCustomShadow(value);
  };

  const handleShadowSelect = (shadow: { value: string; label: string }) => {
    if (errorMsgs.predefinedShadow) {
      setErrorMsgs({ ...errorMsgs, predefinedShadow: "" });
    }
    setSelectedShadow(shadow);
  };

  const downloadFile = () => {
    const filename = document.getElementById("shadowName")["value"];

    if (filename) {
      const url = window.URL.createObjectURL(
        new Blob([customShadow], { type: "text/plain" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}.proto`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      toast.warning("To download please enter shadow name.");
    }
  };

  const options = useMemo(() => {
    let selected = undefined;
    const shadows =
      predefinedShadows?.map(({ name, id }) => {
        if (recentCreatedShadow === id) {
          selected = { value: id, label: name };
        }

        return { value: id, label: name };
      }) || [];

    if (shadows.length) {
      if (!selected) {
        selected = shadows[0];
      }

      setSelectedShadow(selected);
    }

    return shadows;
  }, [predefinedShadows, recentCreatedShadow]);

  return (
    <>
      <div className="flex w-full h-full">
        <div className="w-5/12  pb-8">
          <div className="mt-7 mb-5 w-10/12">
            {!cameFromCreateShadowDef ? <FleetCreationSteps /> : null}
          </div>

          <div>
            <h1 className="text-lg text-left font-medium mb-2.5">
              Shadow Policy
            </h1>

            <Formik
              initialValues={{ shadowName: "" }}
              validationSchema={newShadowDefinitionSchema}
              onSubmit={submitCustomShadow}
            >
              <Form>
                {/* Conditional Render For Predefined Shadows or Shadow name */}
                {createShadow ? (
                  <div className="form-group mb-5 w-10/12">
                    <label className="flex font-medium text-sm mb-2">
                      Shadow Name
                    </label>
                    <Field
                      type="text"
                      id="shadowName"
                      name="shadowName"
                      placeholder="Shadow Name"
                      className="block w-full p-3 mt-2 bg-background text-contentColor border-background-layer3 border rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                    />
                    <ErrorMessage name="shadowName">
                      {(msg) => <FieldError message={msg} />}
                    </ErrorMessage>
                  </div>
                ) : (
                  <div className="form-group mb-5 w-10/12">
                    <label className="flex font-medium text-sm mb-2">
                      Predefined Shadows
                    </label>
                    <Select
                      isDisabled={createShadow}
                      placeholder="Select"
                      isSearchable={false}
                      options={options}
                      value={selectedShadow}
                      onChange={handleShadowSelect}
                      classNames={{
                        menu: () => "!bg-background-layer1 !text-contentColor",
                        control: () =>
                          "!bg-background !text-contentColor !border-background-layer3 !rounded-md focus:!ring focus:!ring-opacity-40 focus:!ring-primary focus:!border-primaryLight sm:!text-sm",
                        valueContainer: () => "!text-contentColor",
                        singleValue: () => "!text-contentColor",
                        menuList: () => "!text-contentColor",
                        option: () =>
                          "!text-contentColor hover:!bg-background-layer2 !bg-background-layer1 !border-background-layer3",
                        noOptionsMessage: () =>
                          "!text-contentColor !bg-background-layer1",
                        multiValue: () =>
                          "!bg-background-layer3 !text-contentColor",
                        multiValueLabel: () => "!text-contentColor"
                      }}
                    />
                    {errorMsgs.predefinedShadow ? (
                      <FieldError message={errorMsgs.predefinedShadow} />
                    ) : (
                      ""
                    )}
                  </div>
                )}

                {/* Conditional Render for Shadow Def Editor */}
                {createShadow && (
                  <div className="form-group mb-5 lg:w-10/12">
                    <label className="flex font-medium text-sm mb-2">
                      Shadow Definition
                    </label>
                    <div className="p-2 bg-background rounded-lg border border-solid border-background-layer3 w-min lg:w-full">
                      <div>
                        <div className="flex justify-between items-center">
                          <ul className="flex justify-center items-center">
                            <li className="flex items-center">
                              <button
                                type="button"
                                onClick={() => copyToClipboard(customShadow)}
                                className="text-xs text-contentColorLight flex justify-center items-center"
                              >
                                Copy
                              </button>
                              <div className="mx-3.5 h-3 border border-solid border-background-layer3"></div>
                            </li>

                            <li className="flex items-center">
                              <button
                                type="button"
                                onClick={() =>
                                  pasteFromClipboard().then((res) =>
                                    setCustomShadow(res)
                                  )
                                }
                                className="text-xs text-contentColorLight flex justify-center items-center"
                              >
                                Paste
                              </button>
                              <div className="mx-3.5 h-3 border border-solid border-background-layer3"></div>
                            </li>

                            <li className="flex items-center">
                              <button
                                type="button"
                                className="text-xs text-contentColorLight flex justify-center items-center"
                              >
                                Upload
                              </button>
                              <div className="mx-3.5 h-3 border border-solid border-background-layer3"></div>
                            </li>

                            <li className="flex items-center mr-3.5">
                              <button
                                type="button"
                                onClick={downloadFile}
                                className="text-xs text-contentColorLight flex justify-center items-center"
                              >
                                Download
                              </button>
                            </li>
                          </ul>

                          <div className="flex justify-center items-center">
                            <button
                              onClick={handleMaximizeEditor}
                              type="button"
                              className="p-1 bg-background-layer1 rounded"
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.6663 2H14.6663V6H13.333V3.33333H10.6663V2ZM1.33301 2H5.33301V3.33333H2.66634V6H1.33301V2ZM13.333 12.6667V10H14.6663V14H10.6663V12.6667H13.333ZM2.66634 12.6667H5.33301V14H1.33301V10H2.66634V12.6667Z"
                                  fill="#546CCC"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div className="my-2 border border-solid border-background-layer3"></div>
                      </div>
                      <Editor
                        height="14vh"
                        language="cpp"
                        value={customShadow}
                        onChange={handleShadowEditor}
                        theme={
                          theme === "golain" || theme === "none"
                            ? "vs"
                            : "vs-dark"
                        }
                        options={{
                          lineNumbers: "off",
                          minimap: { enabled: false },
                          scrollbar: { vertical: "hidden" },
                          overviewRulerBorder: false,
                          overviewRulerLanes: 0,
                          folding: false,
                          matchBrackets: "never",
                          theme:
                            theme === "golain" || theme === "none"
                              ? "vs"
                              : "vs-dark"
                        }}
                      />
                    </div>
                    {errorMsgs.customShadow ? (
                      <FieldError message={errorMsgs.customShadow} />
                    ) : (
                      ""
                    )}
                  </div>
                )}
                {!cameFromCreateShadowDef ? (
                  <h1 className="flex mb-5 text-sm text-contentColorLight">
                    {createShadow ? "Or Select from" : "Or"} &nbsp;
                    <span
                      className="font-medium text-primary cursor-pointer"
                      onClick={() => setCreateShadow(!createShadow)}
                    >
                      {createShadow
                        ? "Predefined Shadow"
                        : "Create New Shadow"}
                    </span>
                  </h1>
                ) : null}
                {createShadow && (
                  <div>
                    <button
                      type="submit"
                      className="px-5 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
                    >
                      Done
                    </button>
                  </div>
                )}
              </Form>
            </Formik>
          </div>

          {!cameFromCreateShadowDef ? (
            <div
              className={`w-10/12 ${
                createShadow ? "" : ""
              } flex items-center mt-12 space-x-4`}
            >
              <Link
                to="/fleet-and-devices/projects/provisioning-policy/"
                className="w-1/2 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white"
              >
                Back
              </Link>

              <button
                onClick={handleClick}
                className="w-1/2 px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
              >
                Next
              </button>
            </div>
          ) : null}
        </div>

        <div className="w-7/12">
          <SuggestionPanel type="shadow" />
        </div>
      </div>
    </>
  );
}

export default ShadowPolicy;
