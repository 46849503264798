import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useOverlayLoaderStore, useAuthStore } from "../../../store";
import useFleetAndDevicesStore from "../../../store/fleet-and-devices/fleet-and-devices.store";
import { FieldError } from "../../shared/components";
import DeviceCreationSteps from "../components/device-creation-steps.component";
import SuggestionPanel from "../components/fad-suggestion-panel.component";
import { toast } from "react-toastify";
import { useGetShadowDefinitions } from "@app/shared/hooks/get/shadow-definitions";
import { useCreateDevice } from "@app/shared/hooks/post/create-device";

function ApplyShadow() {
  /* Hooks */
  const navigate = useNavigate();
  const [setLoader] = useOverlayLoaderStore((state) => [state.setLoader]);
  const [updateAuthUser] = useAuthStore((state) => [state.updateAuthUser]);
  const [deviceCreation, selectedFleet, setDeviceCreation] =
    useFleetAndDevicesStore((state) => [
      state.deviceCreation,
      state.selectedFleet,
      state.setDeviceCreation
    ]);

  /* State Varaibles */
  const [loadNextBtn, setLoadNextBtn] = useState(false);
  const [selectedShadow, setSelectedShadow] = useState<
    { value: string; label: string } | undefined
  >();
  const [errorMsgs, setErrorMsgs] = useState({ predefinedShadow: "" });

  const { data: predefinedShadows } = useGetShadowDefinitions({
    fields: "shadow_proto_structure"
  });

  useEffect(() => {
    updateAuthUser({ deviceCreationStatus: "1" });
  }, [updateAuthUser]);

  const createDeviceMutation = useCreateDevice(selectedFleet?.id);

  const handleNext = async () => {
    if (!selectedShadow) {
      return setErrorMsgs({
        ...errorMsgs,
        predefinedShadow: "Please select shadow"
      });
    }

    setLoader(true);
    setLoadNextBtn(true);

    const payload = {
      device_name: deviceCreation.device_name,
      shadow_definition_id: selectedShadow.value
    };

    createDeviceMutation.mutate(payload, {
      onSuccess: (deviceId) => {
        toast.info(`Created device "${deviceCreation.device_name}".`);
        setDeviceCreation({
          device_name: "",
          newly_created_device_id: deviceId
        });
        navigate("/fleet-and-devices/projects/apply-data-points/");
      }
    });

    // const certificateResponse: any = await networkService.post(
    //     `projects/${selectedProject.id}/fleets/${selectedFleet.id}/devices/${deviceResponse.data.deviceId}/certificates`,
    //     {},
    //     {
    //         headers: { "ORG-ID": user.selectedOrg.id },
    //         responseType: 'arraybuffer'
    //     }
    // );
    // if (certificateResponse) {
    //     const url = window.URL.createObjectURL(new Blob([certificateResponse], { type: 'application/zip, "application/octet-stream;base64"' }));
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.setAttribute('download', 'certi_file.zip');
    //     document.body.appendChild(link);
    //     link.click();
    //     link.parentNode.removeChild(link);
    //     navigate("/fleet-and-devices/projects/apply-data-points/", {state: {deviceId: deviceResponse.data.deviceId}});
    // }

    setLoadNextBtn(false);
    setLoader(false);
  };

  const handleShadowSelect = (shadow: { value: string; label: string }) => {
    if (errorMsgs.predefinedShadow) {
      setErrorMsgs({ ...errorMsgs, predefinedShadow: "" });
    }
    setSelectedShadow(shadow);
  };

  const options = useMemo(() => {
    return (
      predefinedShadows?.map(({ name, id }) => {
        return { value: id, label: name };
      }) || []
    );
  }, [predefinedShadows]);

  return (
    <>
      <div className="flex w-full h-full">
        <div className="w-5/12 pb-8">
          <div className="mt-7 mb-5 w-10/12">
            <DeviceCreationSteps />
          </div>
          <div>
            <h1 className="text-lg text-left font-medium mb-2.5">
              Apply Shadows
            </h1>

            <form>
              <div className="form-group mb-5 w-10/12">
                <label className="flex font-medium text-sm mb-2">
                  Add Shadow
                </label>
                <Select
                  placeholder="Select"
                  isSearchable={false}
                  options={options}
                  onChange={handleShadowSelect}
                  classNames={{
                    menu: () => "!bg-background-layer1 !text-contentColor",
                    control: () =>
                      "!bg-background !text-contentColor !border-background-layer3 !rounded-md focus:!ring focus:!ring-opacity-40 focus:!ring-primary focus:!border-primaryLight sm:!text-sm",
                    valueContainer: () => "!text-contentColor",
                    singleValue: () => "!text-contentColor",
                    menuList: () => "!text-contentColor",
                    option: () =>
                      "!text-contentColor hover:!bg-background-layer2 !bg-background-layer1 !border-background-layer3",
                    noOptionsMessage: () =>
                      "!text-contentColor !bg-background-layer1",
                    multiValue: () =>
                      "!bg-background-layer3 !text-contentColor",
                    multiValueLabel: () => "!text-contentColor"
                  }}
                />
                {errorMsgs.predefinedShadow ? (
                  <FieldError message={errorMsgs.predefinedShadow} />
                ) : (
                  ""
                )}
              </div>
              <h1 className="text-sm text-gray-400">
                Not finding what you are looking for?
              </h1>
              <h1 className="text-sm text-primary">
                Go to{" "}
                <span className="font-medium italic">
                  fleet settings to create new Shadow.
                </span>
              </h1>
            </form>
          </div>

          <div className="w-10/12  flex items-center mt-12 space-x-4">
            <Link
              to="/fleet-and-devices/projects/about-device/"
              className="w-1/2 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-background-layer1"
            >
              Back
            </Link>

            <button
              onClick={handleNext}
              className="w-1/2 px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80"
            >
              {loadNextBtn ? "Loading..." : "Next"}
            </button>
          </div>
        </div>

        <div className="w-7/12">
          <SuggestionPanel type="device" />
        </div>
      </div>
    </>
  );
}

export default ApplyShadow;
