import useRuleEngineStore from "@store/rule-engine/rule-engine.store";
import RuleItem from "./rule-item.component";
import { Button } from "@tremor/react";
import { ArrowUturnLeftIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useGetRules } from "@app/shared/hooks/get/rules";
import { deserializeRuleJSON } from "./rule-engine.helper";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { useState } from "react";
import CreateRuleModal from "./create-rule-modal.component";
import dateService from "@services/date.service";
import { Outlet, useParams } from "react-router-dom";

type Props = {};

const RuleEngineAll = (props: Props) => {
  const [rules, localRulesData, setRules, clearRuleData] = useRuleEngineStore(
    (state) => [
      state.rules,
      state.localRulesData,
      state.setRules,
      state.clearRuleData
    ]
  );

  const params = useParams();
  const id = params["id"];

  const [showRuleModal, setShowRuleModal] = useState(false);

  const selectedProject = useFleetAndDevicesStore(
    (state) => state.selectedProject
  );
  const user = useAuthStore((state) => state.user);

  useGetRules({}, (rules) => {
    const _newRules = {};

    if (!rules?.length) {
      setRules({});
      return;
    }
    const loadedLocalRules = new Set<string>();

    rules.forEach((_rule) => {
      const { rule, ruleData } = deserializeRuleJSON(_rule);
      if (localRulesData[rule.name]) {
        if (rule.version > localRulesData[rule.name].ruleDetails.version) {
          // TODO: Give an option to discard local changes and use server side changes.
        }

        // localRuleData will be used (set in rule-editor-node component)
        _newRules[rule.name] = { ...rule, ruleData: undefined };

        loadedLocalRules.add(rule.name);
      } else {
        _newRules[rule.name] = { ...rule, ruleData };
      }
    });

    Object.keys(localRulesData).forEach((ruleName) => {
      if (
        !loadedLocalRules.has(ruleName) &&
        localRulesData[ruleName].orgId === user.selectedOrg.id &&
        localRulesData[ruleName].projId === selectedProject.id
      ) {
        _newRules[ruleName] = {
          ...localRulesData[ruleName].ruleDetails,
          ruleData: undefined
        };
      }
    });

    setRules(_newRules);
  });

  const onCreateRule = (newRuleData) => {
    clearRuleData();
    const newRules = { ...rules };

    newRules[newRuleData.name] = {
      ...newRuleData,
      createdAt: dateService.getCurrentUTCDate().utc()
    };

    setRules(newRules);
  };

  if (id) {
    return <Outlet />;
  }

  return (
    <main className="w-full h-auto py-8 overflow-y-auto lg:px-8 sm:px-6">
      <div className="mb-7">
        <h1 className="font-bold text-4xl mb-6">{"Rule Engine"}</h1>
        <p className="font-normal text-base">
          Rule Engine helps you connect your Golain devices to third party
          applications and perform actions!
        </p>
        <p className="font-normal text-base mb-6">
          Create a new Rule to get started!
        </p>
      </div>
      <div className="w-full my-3 mt-5">
        <div className="flex justify-between mb-2">
          <h1 className="font-medium text-lg">Rules:</h1>
          {Object.keys(rules).length ? (
            <Button
              className="bg-primary !text-white"
              size="xs"
              onClick={() => setShowRuleModal(true)}
            >
              <span className="flex gap-2 items-center">
                <PlusIcon width={20} /> <span>Create a new Rule!</span>
              </span>
            </Button>
          ) : null}
        </div>
        <hr className="border-background-layer3" />
        <div className="bg-background h-full min-h-[50vh] p-2">
          {Object.keys(rules).length ? (
            <div className="flex flex-col gap-2">
              {Object.values(rules)
                .sort((l, r) =>
                  dateService
                    .convertDateObjToMoment(r.createdAt)
                    .diff(dateService.convertDateObjToMoment(l.createdAt))
                )
                .map((rule, ind) => (
                  <RuleItem
                    key={rule.id ?? rule.name}
                    rule={rules[rule.name]}
                    ind={ind}
                  />
                ))}
            </div>
          ) : (
            <div className="flex justify-center items-center gap-2 text-center text-contentColorLight flex-col min-h-[400px]">
              You don't have any rules created yet!
              <Button
                className="bg-primary !text-white"
                size="xs"
                icon={PlusIcon}
                onClick={() => setShowRuleModal(true)}
              >
                Create a new Rule!
              </Button>
            </div>
          )}{" "}
        </div>
      </div>
      <CreateRuleModal
        open={showRuleModal}
        setOpen={setShowRuleModal}
        onCreateRule={onCreateRule}
      />
    </main>
  );
};

export default RuleEngineAll;

const LatestVersionToast = ({ onButtonClick }) => {
  return (
    <>
      A new version of this rule is available. Click here to discard your local
      changes and use the latest version:
      <Button icon={ArrowUturnLeftIcon} color="yellow">
        Discard Changes
      </Button>
    </>
  );
};
