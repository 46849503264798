import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  BaseSyntheticEvent,
  useRef
} from "react";
import { Formik, Form, Field } from "formik";
import Select from "react-select";

import { Badge } from "@tremor/react";
import {
  ChevronDownIcon,
  ExclamationCircleIcon,
  TagIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import { Disclosure, Switch } from "@headlessui/react";

import dateService from "@services/date.service";
import { useGetDataPointDefinitions } from "@app/shared/hooks/get";
import { useGetDevices } from "@app/shared/hooks/get/devices";
import { useGetDeviceDataPoints } from "@app/shared/hooks/get/device-data-points";
import CustomSelectWithData from "../dash-multi-select.component";
import { useDashboardStore } from "@store/index";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

interface ITableError {
  dataSource: boolean;
  devices: boolean;
  params: boolean;
  name: boolean;
}

const DATE_FORMAT = "YYYY-MM-DD HH:mm";

const timePeriodSelectOptions = [
  { value: "m", label: "Months" },
  { value: "w", label: "Weeks" },
  { value: "d", label: "Days" },
  { value: "h", label: "Hours" },
  { value: "mins", label: "Minutes" }
];

const timeBucketSelectOptions = [
  { value: "mins", label: "Minutes" },
  { value: "hours", label: "Hours" },
  { value: "days", label: "Days" }
];

const tagOptions = [
  {
    value: "tag-1",
    label: "Tag 1"
  },
  {
    value: "tag-2",
    label: "Tag 2"
  }
];

export const PanelDataSourceSelector = ({
  children,
  onChangeHandler,
  enableCreatebutton,
  graphDefinitions
}) => {
  const [devicesSelectOptions, setDevicesSelectOptions] = useState([]);

  const [allParams, setAllParams] = useState<any[]>([]);

  const [
    { inputValues, panelName, selectedPanel },
    setInputValues,
    setPanelName
  ] = useDashboardStore((state) => [
    state.createPanelState,
    state.setInputValues,
    state.setPanelName
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedPanel) {
      navigate(`/dashboard/new-panel-type`);
    }
  }, [navigate, selectedPanel]);

  const [startTimePeriodUnit, setStartTimePeriodUnit] = useState(
    inputValues.timePeriod.start.charAt(
      inputValues.timePeriod.start.length - 1
    )
  );
  const [endTimePeriodUnit, setEndTimePeriodUnit] = useState(
    inputValues.timePeriod.end.charAt(inputValues.timePeriod.end.length - 1)
  );

  const [showTagsFilter, setShowTagsFilter] = useState(false);

  const [error, setErrors] = useState<ITableError>({
    dataSource: true,
    devices: true,
    params: true,
    name: true
  });

  const initialValues = useMemo(
    () => ({
      "table-parameter": ""
    }),
    []
  );

  const { data: dataPointDefs } = useGetDataPointDefinitions({
    fields: "data_point_proto_structure"
  });

  const dataPointDefsSelectOptions = useMemo(
    () =>
      dataPointDefs?.map((dp) => ({
        value: dp.id,
        label: dp.name
      })) || [],
    [dataPointDefs]
  );

  const { data: devices } = useGetDevices();

  const { data: deviceDataPoints } = useGetDeviceDataPoints();

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleParamsSelect = (name, options) => {
    if (!options?.length) {
      setErrors((err) => ({
        ...err,
        params: true
      }));
    } else {
      setErrors((err) => ({
        ...err,
        params: false
      }));
    }
    setInputValues({
      ...inputValues,
      selectedParams: options
    });
  };

  const handleChange = (key: string, value: any) => {
    let newInputs = { ...inputValues };

    if (key === "dataPoints" && value.length && value[0] !== null) {
      setErrors({ ...error, dataSource: false });
      setAllParamsOnDataPointSelect(value[0]);

      const devicesInDp = [];
      value.forEach((dp) => {
        deviceDataPoints.forEach((ddp) => {
          if (ddp.data_point_definition_id === dp.value) {
            devicesInDp.push({
              id: ddp.device_id,
              name: devices.find((d) => d.id === ddp.device_id).device_name
            });
          }
        });
      });

      newInputs.devicesInDataPoints = devicesInDp;
      newInputs.filters = {
        devices: [],
        tags: newInputs.filters.tags
      };
      setDevicesSelectOptions(
        devicesInDp.map((d) => ({
          value: d.id,
          label: d.name
        }))
      );
    }

    if (key === "timePeriod") {
      newInputs.startDate = dateService
        .parseRelativeDate(value.start)
        .format(DATE_FORMAT);
      newInputs.endDate = dateService
        .parseRelativeDate(value.end)
        .format(DATE_FORMAT);
    }

    if (key === "dataPoints") {
      if (value[0] === null) {
        newInputs.dataPoints = [];

        setErrors({ ...error, dataSource: true });
        newInputs.devicesInDataPoints = [];
        setDevicesSelectOptions([]);
      } else {
        newInputs.dataPoints = value;
      }
    } else if (key === "devices") {
      newInputs.filters = {
        ...newInputs.filters,
        devices: [
          {
            ...value[0],
            deviceDataPointId: deviceDataPoints.find(
              (ddp) =>
                ddp.device_id === value[0].value &&
                ddp.data_point_definition_id ===
                  inputValues.dataPoints[0].value
            ).id
          }
        ]
      };
    } else if (key === "tags") {
      newInputs.filters = { ...newInputs.filters, tags: value };
    } else {
      newInputs[key] = value;
    }

    setInputValues(newInputs);
  };

  const isValid = useCallback(() => {
    let hasErrors = false;
    let errors: ITableError = { ...error };

    if (!inputValues.dataPoints?.length) {
      errors.dataSource = true;
      hasErrors = true;
    } else if (!inputValues.filters.devices?.length) {
      errors.devices = true;
      hasErrors = true;
    } else if (!inputValues.selectedParams?.length) {
      errors.params = true;
      hasErrors = true;
    } else {
      errors.dataSource = false;
      errors.devices = false;
      errors.params = false;

      hasErrors = false;
    }

    setErrors(errors);

    return !hasErrors;
  }, [error, inputValues]);

  useEffect(() => {
    if (isValid()) {
      enableCreatebutton && enableCreatebutton(false);
      onChangeHandler &&
        onChangeHandler((prev) => [
          {
            ...prev[0],
            ...inputValues
          }
        ]);
    } else {
      enableCreatebutton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues]);

  const setAllParamsOnDataPointSelect = (selectedDp) => {
    const source = dataPointDefs.find((data) => data.id === selectedDp.value);
    const dataDef = source.data_point_proto_structure;
    const keys = Object.keys(dataDef);

    if (keys.length) {
      let options = [];
      keys.forEach((key) => {
        if (
          typeof dataDef[key].structure === "string" &&
          !("options" in dataDef[key]) &&
          selectedPanel !== "PIE_CHART"
        ) {
          options.push({
            value: key,
            label: `${key} [ ${dataDef[key].structure} ]`,
            data: dataDef[key]
          });
        } else if (
          typeof dataDef[key].structure === "string" &&
          "options" in dataDef[key] &&
          dataDef[key].options.length > 0
        ) {
          options.push({
            value: key,
            label: `${dataDef[key].name} [ enum ]`,
            data: dataDef[key]
          });
        } else if (
          typeof dataDef[key].structure === "object" &&
          selectedPanel !== "PIE_CHART" &&
          dataDef[key].cardinality !== "repeated"
        ) {
          const structure = dataDef[key].structure;
          const structKeys = Object.keys(structure);
          structKeys.forEach((strKey) => {
            options.push({
              value: `${key},${strKey}`,
              label: `${dataDef[key].name}, ${strKey} [ ${structure[strKey].structure} ]`,
              data: structure[strKey]
            });
          });
        }
      });
      setAllParams(options.flat());
    }
  };

  // close Disclosure programatically
  const buttonRefs = useRef([]);
  const openedRef = useRef(null);

  const clickRecent = (index) => {
    const clickedButton = buttonRefs.current[index];
    if (clickedButton === openedRef.current) {
      openedRef.current = null;
      return;
    }

    if (Boolean(openedRef.current?.getAttribute("data-value"))) {
      openedRef.current?.click();
    }
    openedRef.current = clickedButton;
  };

  if (!selectedPanel) {
    return;
  }

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <div className="w-10/12">
            <label className="text-sm font-medium text-contentColor">
              Panel Name
            </label>
            <Field
              type="text"
              id={"table-data-points"}
              name={"table-data-points"}
              value={panelName}
              placeholder="Name"
              className={`block w-10/12 p-3 mt-2 rounded-md bg-background border-background-layer3 border focus:ring focus:ring-opacity-40 sm:text-sm ${
                error.name
                  ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                  : "border-background-layer3 focus:ring-primaryLight focus:border-primary"
              }`}
              onChange={(e) => {
                setPanelName(e.target.value.trim());
                if (e.target.value.trim()) {
                  setErrors((err) => ({
                    ...err,
                    name: false
                  }));
                } else {
                  setErrors((err) => ({
                    ...err,
                    name: true
                  }));
                }
              }}
            />
          </div>
          {error.name && (
            <p className="text-xs text-red-500 mt-1">
              Please enter panel name!
            </p>
          )}
          <div className="mb-2 mt-4">
            <Disclosure>
              {({ open }) => {
                return (
                  <>
                    <Disclosure.Button as="div">
                      <button
                        data-value={open}
                        ref={(ref) => {
                          buttonRefs.current[0] = ref;
                        }}
                        onClick={() => clickRecent(0)}
                        className="rounded-t-md flex w-full gap-2 items-center bg-background-layer1 p-3 justify-between"
                      >
                        <span className="flex gap-2">
                          <span>Data Config</span>
                          {error.dataSource || error.params ? (
                            <ExclamationCircleIcon color="red" width={20} />
                          ) : null}
                        </span>
                        <ChevronDownIcon
                          width={16}
                          className={`transition-transform ${
                            open ? "rotate-180" : "rotate-0"
                          } `}
                        />
                      </button>
                    </Disclosure.Button>
                    <Disclosure.Panel
                      unmount={false}
                      className={`py-3 px-6 border border-background-layer3 bg-background-layer1`}
                    >
                      <div className="flex flex-col">
                        {/* Select Data Source - Data Points and/or Shadow */}
                        <div className="w-10/12">
                          <label className="text-sm font-medium text-gray-600">
                            Data Points
                          </label>
                          <Field
                            as="select"
                            id={"table-data-points"}
                            name={"table-data-points"}
                            value={inputValues.dataPoints}
                            placeholder="Data Points"
                            // isMulti
                            isClearable={true}
                            options={dataPointDefsSelectOptions}
                            component={Select}
                            styles={{
                              control: (base) => ({
                                ...base,
                                borderColor: error.dataSource
                                  ? "red"
                                  : "#E5E7EB"
                              })
                            }}
                            className={`block w-10/12 2xl
                     mt-1 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                       error.dataSource
                         ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                         : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                     }`}
                            onChange={(e) => {
                              handleChange("dataPoints", [e]);
                            }}
                          />
                        </div>
                        {error.dataSource && (
                          <p className="text-xs text-red-500 mt-1">
                            Please select a data-point-definition as a
                            data-source!
                          </p>
                        )}
                        <div className="mt-4">
                          <label className="text-sm font-medium items-center cursor-pointer flex gap-1 text-gray-600">
                            Data Parameters{" "}
                            <span data-tooltip-id="panel-creation-info-tooltip">
                              <ExclamationCircleIcon
                                width={20}
                                className="text-blue-500"
                              />
                            </span>
                          </label>
                          <Field
                            as="select"
                            id="dataPointsType"
                            name="dataPointsType"
                            className="block w-10/12 bg-background mt-2 border-background-layer3 rounded-md focus:ring focus:ring-opacity-40 focus:ring-primary focus:border-primaryLight sm:text-sm"
                            component={CustomSelectWithData}
                            placeholder="Select Data Parameter"
                            options={allParams}
                            onChange={handleParamsSelect}
                            isMulti={true}
                            maxItems={
                              graphDefinitions[selectedPanel].maxParamsLength
                            }
                          />
                          {error.params && (
                            <p className="text-xs text-red-500 mt-1">
                              Please select parameters for this panel.
                            </p>
                          )}
                        </div>
                      </div>
                      {![
                        "LINE_CHART",
                        "BAR_CHART",
                        "GUAGE_1",
                        "GUAGE_1"
                      ].includes(selectedPanel) ? (
                        <div className="flex flex-row my-4 gap-4">
                          {/* START DATE */}
                          <div className="w-6/12">
                            <label className="text-sm font-medium text-gray-600">
                              Start Date
                            </label>
                            <div>
                              <div className="flex">
                                <Field
                                  as="input"
                                  type="number"
                                  id={"heatmap-start-date"}
                                  name={"heatmap-start-date"}
                                  placeholder="-1"
                                  max={0}
                                  value={parseInt(
                                    inputValues.timePeriod.start
                                  )}
                                  className={`block w-10/12 lg:w-6/12 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                                    error["heatmap-params"]
                                      ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                                      : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                                  }`}
                                  onChange={(e: BaseSyntheticEvent) =>
                                    handleChange("timePeriod", {
                                      start:
                                        parseInt(e.target.value) +
                                        startTimePeriodUnit,
                                      end: inputValues.timePeriod.end
                                    })
                                  }
                                />
                                <Field
                                  id={"heatmap-time-period-unit"}
                                  name={"heatmap-time-period-unit"}
                                  placeholder="Months"
                                  component={Select}
                                  options={timePeriodSelectOptions}
                                  value={timePeriodSelectOptions.filter(
                                    (opt) => opt.value === startTimePeriodUnit
                                  )}
                                  className={`block w-10/12 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                                    error["lineParams"]
                                      ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                                      : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                                  }`}
                                  onChange={(e) => {
                                    setStartTimePeriodUnit(e.value as string);
                                    handleChange("timePeriod", {
                                      start:
                                        parseInt(
                                          inputValues.timePeriod.start
                                        ) + e.value,
                                      end: inputValues.timePeriod.end
                                    });
                                  }}
                                  maxMenuHeight={140}
                                />
                              </div>
                            </div>
                          </div>
                          {/* END DATE */}
                          <div className="w-6/12">
                            <label className="text-sm font-medium text-gray-600">
                              End Date
                            </label>
                            <div>
                              <div className="flex">
                                <Field
                                  as="input"
                                  type="number"
                                  id={"heatmap-end-date"}
                                  name={"heatmap-end-date"}
                                  placeholder="-1"
                                  max={0}
                                  // min={parseInt(inputValues.timePeriod.start)}
                                  value={parseInt(inputValues.timePeriod.end)}
                                  className={`block w-10/12 lg:w-6/12 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                                    error["heatmap-params"]
                                      ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                                      : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                                  }`}
                                  onChange={(e: BaseSyntheticEvent) =>
                                    handleChange("timePeriod", {
                                      end:
                                        parseInt(e.target.value) +
                                        endTimePeriodUnit,
                                      start: inputValues.timePeriod.start
                                    })
                                  }
                                />
                                <Field
                                  id={"heatmap-time-period-unit"}
                                  name={"heatmap-time-period-unit"}
                                  placeholder="Months"
                                  component={Select}
                                  options={timePeriodSelectOptions}
                                  value={timePeriodSelectOptions.filter(
                                    (opt) => opt.value === endTimePeriodUnit
                                  )}
                                  className={`block w-10/12 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                                    error["lineParams"]
                                      ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                                      : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                                  }`}
                                  onChange={(e) => {
                                    setEndTimePeriodUnit(e.value as string);
                                    handleChange("timePeriod", {
                                      end:
                                        parseInt(inputValues.timePeriod.end) +
                                        e.value,
                                      start: inputValues.timePeriod.start
                                    });
                                  }}
                                  maxMenuHeight={140}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {!["LINE_CHART", "BAR_CHART"].includes(selectedPanel) ? (
                        <div className="flex gap-2 items-center mt-4 mb-2">
                          <label className="text-base font-medium text-gray-600">
                            Aggregate Parameters:
                          </label>
                          <Switch
                            checked={inputValues.aggregateParams}
                            onChange={(val) =>
                              handleChange("aggregateParams", val)
                            }
                            className={`${
                              inputValues.aggregateParams
                                ? "bg-green-500"
                                : "bg-gray-200"
                            } relative inline-flex h-5 w-9 items-center rounded-full`}
                          >
                            <span className="sr-only">Enable aggregation</span>
                            <span
                              className={`${
                                inputValues.aggregateParams
                                  ? "translate-x-5"
                                  : "translate-x-1"
                              } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                            />
                          </Switch>
                        </div>
                      ) : null}
                      {/* TIME BUCKET */}
                      {!["LINE_CHART", "BAR_CHART"].includes(selectedPanel) &&
                        inputValues.aggregateParams && (
                          <div className="w-6/12">
                            <label className="text-sm font-medium text-gray-600">
                              Aggregation Time Bucket
                            </label>
                            <div className="flex">
                              <Field
                                as="input"
                                type="number"
                                id={"table-column-time-bucket"}
                                name={"table-column-time-bucket"}
                                placeholder="1"
                                min={1}
                                value={inputValues.timeBucket}
                                className={`block w-10/12 lg:w-6/12 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                                  false
                                    ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                                    : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                                }`}
                                onChange={(e: BaseSyntheticEvent) => {
                                  handleChange("timeBucket", e.target.value);
                                }}
                              />
                              <Field
                                as="select"
                                id={"table-column-time-bucket-unit"}
                                name={"table-column-time-bucket-unit"}
                                placeholder="Days"
                                component={Select}
                                options={timeBucketSelectOptions}
                                value={timeBucketSelectOptions.filter(
                                  (opt) =>
                                    opt.value === inputValues.timeBucketUnit
                                )}
                                className={`block w-10/12 mt-2 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm ${
                                  false
                                    ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                                    : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                                }`}
                                onChange={(e) => {
                                  handleChange("timeBucketUnit", e.value);
                                }}
                                maxMenuHeight={180}
                              />
                            </div>
                          </div>
                        )}
                    </Disclosure.Panel>
                  </>
                );
              }}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button as="div">
                    <button
                      data-value={open}
                      ref={(ref) => {
                        buttonRefs.current[1] = ref;
                      }}
                      onClick={() => clickRecent(1)}
                      className="flex w-full gap-2 items-center bg-background-layer1 p-3 justify-between"
                    >
                      <span className="flex gap-2">
                        <span>Filters</span>

                        {inputValues.filters.devices?.length ? (
                          <Badge className="bg-background-layer2">
                            Device: {inputValues.filters.devices[0].label}
                          </Badge>
                        ) : (
                          <ExclamationCircleIcon color="red" width={20} />
                        )}
                        {inputValues.filters.tags?.length ? (
                          <Badge className="bg-background-layer2">
                            Tags: {inputValues.filters.tags?.length}
                          </Badge>
                        ) : null}
                      </span>
                      <ChevronDownIcon
                        width={16}
                        className={`transition-transform ${
                          open ? "rotate-180" : "rotate-0"
                        } `}
                      />
                    </button>
                  </Disclosure.Button>
                  <Disclosure.Panel
                    unmount={false}
                    className={`py-3 px-6 border border-background-layer3 bg-background-layer1`}
                  >
                    <div className="w-10/12">
                      <label className="text-sm font-medium text-gray-600">
                        Devices
                      </label>
                      <Field
                        as="select"
                        id={"table-devices"}
                        name={"table-devices"}
                        value={inputValues.filters?.devices ?? []}
                        placeholder="Select Device"
                        options={devicesSelectOptions}
                        component={Select}
                        className={`block w-10/12 2xl mt-1 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm
                    ${
                      error.devices
                        ? "border-red-500 focus:ring-red-300 focus:border-red-400"
                        : "border-gray-300 focus:ring-blue-300 focus:border-blue-400"
                    }`}
                        onChange={(e) => {
                          handleChange("devices", [e]);
                        }}
                      />
                      {error.devices && (
                        <p className="text-xs text-red-500 mt-1">
                          Please select a device!
                        </p>
                      )}
                    </div>
                    {!showTagsFilter ? (
                      <div className="flex mt-2 items-center gap-2 text-sm">
                        Apply Additional Filters:{" "}
                        <Badge
                          color="fuchsia"
                          size="xs"
                          onClick={() => setShowTagsFilter(true)}
                          className="cursor-pointer"
                        >
                          <span className="flex gap-2">
                            <TagIcon width={16} />
                            Tags
                          </span>
                        </Badge>
                      </div>
                    ) : (
                      <div className="w-10/12 mt-2">
                        <label className="text-sm w-10/12 flex justify-between font-medium text-gray-600">
                          Tags
                          <TrashIcon
                            width={16}
                            color="red"
                            className="cursor-pointer"
                            onClick={() => setShowTagsFilter(false)}
                          />
                        </label>
                        <Field
                          as="select"
                          id={"table-tags"}
                          name={"table-tags"}
                          value={inputValues.filters?.tags ?? []}
                          placeholder="Select Tags"
                          isMulti
                          options={tagOptions}
                          component={Select}
                          className={`block w-10/12 2xl mt-1 rounded-md focus:ring focus:ring-opacity-40 sm:text-sm 
                                border-gray-300 focus:ring-blue-300 focus:border-blue-400`}
                          onChange={(e) => {
                            handleChange("tags", e);
                          }}
                        />
                      </div>
                    )}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button as="div">
                    <button
                      data-value={open}
                      ref={(ref) => {
                        buttonRefs.current[2] = ref;
                      }}
                      onClick={() => clickRecent(2)}
                      className={`${
                        open ? "" : "rounded-b-md"
                      } flex w-full gap-2 items-center bg-background-layer1 p-3 justify-between`}
                    >
                      <span>
                        <span>Appearance</span>
                      </span>
                      <ChevronDownIcon
                        width={16}
                        className={`transition-transform ${
                          open ? "rotate-180" : "rotate-0"
                        } `}
                      />
                    </button>
                  </Disclosure.Button>
                  <Disclosure.Panel
                    unmount={false}
                    className={`py-3 rounded-b-md px-6 border border-background-layer3 bg-background-layer1`}
                  >
                    <div className="flex flex-col gap-4">{children}</div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            {/* <AccordionList>
              <Accordion
                defaultOpen={accordionOpen === 3}
                onClick={() => setAccordionOpen(3)}
              >
                <AccordionHeader></AccordionHeader>
                <AccordionBody className="text-black max-h-72 overflow-y-scroll"></AccordionBody>
              </Accordion>
            </AccordionList> */}
          </div>
        </Form>
      </Formik>
      <Tooltip id="panel-creation-info-tooltip">
        This panel type supports maximum
        {graphDefinitions[selectedPanel].maxParamsLength}
        parameters.{" "}
        {selectedPanel === "PIE_CHART" ? (
          <>
            <br />
            Pie Chart only supports parameters of `ENUM` type.
          </>
        ) : null}
      </Tooltip>
    </>
  );
};
